
export const RESET_TABLE_DATA="RESET_TABLE_DATA"
export const SET_TABLE_DATA="SET_TABLE_DATA"
export const UPDATE_TABLE_RECOMMAND_CAPTION = "UPDATE_TABLE_RECOMMAND_CAPTION"
export const UPDATE_TABLE_CAPTION_LOADING = "UPDATE_TABLE_CAPTION_LOADING"
export const UPDATE_TABLE_HTMLCODE = "UPDATE_TABLE_HTMLCODE"
export const UPDATE_TABLE_CAPTION_CHOICE= "UPDATE_TABLE_CAPTION_CHOICE"
export const UPDATE_TABLE_RESULT_HTML = "UPDATE_TABLE_RESULT_HTML"
export const UPDATE_TABLE_SAVE_TIME = "UPDATE_TABLE_SAVE_TIME"

export const setTableData = (tableData) => {
	return {
		type: SET_TABLE_DATA,
		payload: tableData
	};
};


export const setResetTableData=()=>{
	return {
		type: RESET_TABLE_DATA
		
	};

}

export const updateRecommandCaption=(toId,summaryCaption,naturalCaption,html)=>{
	return {
		type: UPDATE_TABLE_RECOMMAND_CAPTION,
		payload: {
			toId,
			summaryCaption,
			naturalCaption,
			html,
			
		}
	};
}


export const updateTableCaptionIsLoading=(toId,isLoading)=>{
	return {
		type: UPDATE_TABLE_CAPTION_LOADING,
		payload: {
			toId,
			isLoading
			
		}
	};
}

export const updateTablehtml=(toId,html)=>{
	return {
		type: UPDATE_TABLE_HTMLCODE,
		payload: {
			toId,
			html
			
		}
	};
}


export const updateTableCaptionChoice=(idx,choice)=>{
	return {
		type: UPDATE_TABLE_CAPTION_CHOICE,
		payload: {
			idx,
			choice
			
		}
	};
}

export const updateTableResultHtml=(resultHtml)=>{
	return {
		type: UPDATE_TABLE_RESULT_HTML,
		payload: {
			resultHtml
			
		}
	};
}

export const updateTableSaveTime = ()=>{

	return{

		type : UPDATE_TABLE_SAVE_TIME

	}
}


