import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";
import pretty from "pretty";
import SeoMetaTitle from "./SeoWorkDetailComponents/SeoMetaTitle";
import SeoMetaKeyword from "./SeoWorkDetailComponents/SeoMetaKeyword";
import SeoMetaDescription from "./SeoWorkDetailComponents/SeoMetaDescription";
import SeoMetaImage from "./SeoWorkDetailComponents/SeoMetaImage";

import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import Toast from "@/components/toast/Toast";
import LoadingBarFullDimed from "@/components/loading/LoadingBarFullDimed";

import CodeMirror from "@uiw/react-codemirror";
import CodeMirrorMerge from "react-codemirror-merge";
import { useCodeMirror } from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { html } from "@codemirror/lang-html";
import { material, materialLight } from "@uiw/codemirror-theme-material";
import { HtmlcodeData, MetaTagMock } from "@/mock/HtmlcodeData"; // 삭제 필요
import GuideImg1 from "@/assets/images/img-seo-guide1.png";
import GuideImg2 from "@/assets/images/img-seo-guide2.png";
import { useDispatch, useSelector } from "react-redux";
import { useHandleSeoCreate } from "@/services/core/seo/SeoCreateHandle";
import useSeoMetaPopup from "@/services/core/seo/SeoMetaTagPopup";
import { SeoSave } from "@/services/core/seo/SeoSave";
import { useCallback } from "react";
import { debounce } from "@/services/util/Debouns";
import { DebounceTime, DefaultAutoSaveTime } from "@/services/util/const";
import { setResetSeoData, setSeoData, updateSeoMetaTag, updateSeoSaveTime } from "@/services/store/action/seoAction";
import createMetaTag from "@/services/util/seoMeta";
import { SeoMetaSave } from "@/services/core/seo/SeoMetaSave";

export default function SeoWorkDetailPage() {
	// popup
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		// URL에서 쿼리 파라미터 제거
		const urlWithoutQueryParams = window.location.pathname;

		// History API를 사용하여 쿼리 파라미터 없이 현재 URL을 대체
		window.history.replaceState({}, "", urlWithoutQueryParams);
	}, []);

	const choiceData = useSelector((state) => state.seo.seoData.data.choiceData);
	const data = useSelector((state) => state.seo.seoData.data);
	const [newMetaTag, setNewMetaTag] = useState(data?.metaTag || "");
	const [oriMetaTag, setOriMetaTag] = useState("");
	const firstRender = useRef(true);
	const [isChangeTag, setIsChangeTag] = useState(false);
	const handleWorkNameUpdate = (newWorkName) => {};

	// 이름 변경

	const { isOpen: isSeoGuidePopup, open: openSeoGuidePopup, close: closeSeoGuidePopup } = usePopup(); // 사진변경

	const handleSeoAdd = async () => {
		navigate(`/tool/seo/add`);
	};

	const [newMetaTagValue, setNewMetaTagValue] = useState(data?.metaTag || "");
	const debouncedSave = useCallback(
		debounce((nextValue) => setNewMetaTagValue(nextValue), DebounceTime),
		[]
	);
	const {
		isOpen: isSeoCreatePopup,
		open: openSeoCreatePopup,
		close: closeSeoCreatePopup
	} = useSeoMetaPopup(newMetaTagValue);
	const handleChange = (value) => {
		debouncedSave(value);
	};

	const [showToast, setShowToast] = useState(false);
	const { isLoading, handleSeoCheck } = useHandleSeoCreate(data?.html, data?.url);

	const handleSeoReBuild = () => {
		handleSeoCheck(true);
	};

	const handleToastClick = async () => {
		try {
			await navigator.clipboard.writeText(newMetaTagValue);
			setShowToast(true);
		} catch (error) {
			console.error("클립보드 복사 에러", error);
		}
	};
	const handleAutoSave = async () => {
		var keyword = "";
		const skIds = [];
		data.choiceData.keywords.forEach((key, index, array) => {
			skIds.push(key.skid);
			// keyword.join(", ")
			// 배열의 마지막 요소인 경우의 처리
			if (index === array.length - 1) {
				// 여기서 원하는 작업을 수행하세요.
				// 예: 마지막 요소임을 확인하거나, 마지막에 특별한 처리를 하고 싶은 경우
				keyword += `${key.keyword}`;
			} else {
				keyword += `${key.keyword}, `;
			}
		});

		const req_data = {
			siId: data.resData.siId,
			skIds: skIds,
			sotId: data.choiceData.title.sotId
		};
		const res = await SeoSave(req_data);
		if (res.status == "Success") {
			dispatch(updateSeoSaveTime());
		}
	};
	useEffect(() => {
		window.addEventListener("beforeunload", handleAutoSave);

		return () => {
			window.removeEventListener("beforeunload", handleAutoSave);
		};
	}, [choiceData]);

	useEffect(() => {
		let saveTime = data.resData.saveSecond * 1000;
		if (!saveTime) {
			saveTime = DefaultAutoSaveTime;
		}
		const intervalId = setInterval(() => {
			// 자동 저장 기능
			handleAutoSave();
		}, saveTime);

		return () => clearInterval(intervalId); // 컴포넌트가 unmount될 때 interval 제거
	}, []);

	useEffect(() => {
		const routerSeo = async () => {
			const resp = handleAutoSave();
		};
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}

		return () => {
			routerSeo();
		};
	}, [location, choiceData]);

	const handleOrimetaTag = () => {
		var keyword = "";
		const skIds = [];
		if (data) {
			data?.choiceData?.keywords.forEach((key, index, array) => {
				skIds.push(key.skid);
				// keyword.join(", ")
				// 배열의 마지막 요소인 경우의 처리
				if (index === array.length - 1) {
					// 여기서 원하는 작업을 수행하세요.
					// 예: 마지막 요소임을 확인하거나, 마지막에 특별한 처리를 하고 싶은 경우
					keyword += `${key.keyword}`;
				} else {
					keyword += `${key.keyword}, `;
				}
			});
		}
		const metaTag = createMetaTag(
			data.choiceData.title.title,
			data.choiceData.description.text,
			keyword,
			data.url,
			data.resData.siteName
		);

		setOriMetaTag(metaTag);

		return metaTag;
	};
	const handleMetaSave = async (oriMetaTag) => {
		const datas = {
			siId: data.resData.siId,

			html: oriMetaTag
		};
		await SeoMetaSave(datas);
	};

	useEffect(() => {
		const orimeta = handleOrimetaTag();
		if (data.isChangeTag === false) {
		} else {
			if (isChangeTag) {
				const settintOriMetaTag = pretty(oriMetaTag, { ocd: true });
				setNewMetaTag(settintOriMetaTag);
				setNewMetaTagValue(settintOriMetaTag);
				setIsChangeTag(false);
				handleMetaSave(settintOriMetaTag);
			} else if (newMetaTag === "") {
				setNewMetaTag(orimeta);

				setNewMetaTagValue(orimeta);

				setIsChangeTag(false);
			} else if (data.metaTag !== undefined) {
				const settingNewMetaTag = pretty(newMetaTag, { ocd: true });
				setNewMetaTagValue(settingNewMetaTag);
			}
		}
	}, [data?.choiceData, isChangeTag]);

	return (
		<>
			<div className="container seowork-page">
				<LoadingBarFullDimed isLoading={isLoading} />
				<HeaderDetails
					subTitle={data.resData.workspaceName}
					workspaceId={data.resData.workspaceId}
					currentTime2={data.saveTime || undefined}
					className="service-seo"
					onWorkNameChange={handleWorkNameUpdate}
					saveTime={data.resData.saveSecond || DefaultAutoSaveTime}
				/>
				<div className="body-details">
					<div className="seowork-inner">
						<div className="codemirror-area">
							<div className="header-codemirror">
								<h4 className="title3">
									SEO 메타태그 추천값이 생성되었습니다.
									<br />
									메타태그에 포함할 콘텐츠를 선택해주세요.
								</h4>
								<Link to="#" className="btn-question" onClick={openSeoGuidePopup}>
									<i className="ico ico-question-md"></i>
									<span>SEO 메타태그는 어떻게 사용되나요?</span>
								</Link>
							</div>
							<div className="body-codemirror">
								<CodeMirror
									readOnly
									className="seo-codemirror-area"
									value={data.resData.html}
									extensions={[EditorView.lineWrapping, html()]}
									theme={materialLight}
								/>
							</div>
						</div>
						<div className="meta-ara">
							<form action="#;">
								<div className="item">
									<SeoMetaTitle setIsChangeTag={setIsChangeTag} />
								</div>
								<div className="item">
									<SeoMetaKeyword setIsChangeTag={setIsChangeTag} />
								</div>
								<div className="item">
									<SeoMetaDescription />
								</div>
								<div className="item">
									<SeoMetaImage />
								</div>
							</form>
						</div>
					</div>
				</div>
				<FooterDetails>
					<div className="wrap-left">
						<div className="btn-group">
							<button type="button" className="btn btn-lg btn-gray" onClick={handleSeoAdd}>
								<i className="ico ico-arrow-left-white"></i>
								처음으로
							</button>
							{/* [개발] 새 작업 생성 및 사용권 1장 차감 기능 추가 필요 */}

							<button
								type="button"
								className="btn btn-lg btn-outline-primary"
								onClick={handleSeoReBuild}
								disabled={isLoading}
							>
								<i className="ico ico-tool-md-primary"></i>
								다시 변환하기
							</button>
						</div>
					</div>
					<div className="wrap-right">
						<div className="guide-area">
							<i className="ico ico-info-sm"></i>
							<p>제목, 키워드 값을 변경해 여러번 내보내도 사용권이 차감되지 않아요.</p>
						</div>
						<Link to="#" className="btn btn-lg btn-primary" onClick={openSeoCreatePopup}>
							<i className="ico ico-export-white-sm"></i>
							메타태그 내보내기
						</Link>
					</div>
				</FooterDetails>
			</div>

			{/* SEO 메타태그는 어떻게 사용되나요? 가이드 팝업 */}
			<PopupLayout
				isOpen={isSeoGuidePopup}
				closePopup={closeSeoGuidePopup}
				title="SEO 메타태그는 어떻게 사용되나요?"
				size="md"
				className={"popup-seo-guide"}
				footerContent={
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeSeoGuidePopup}>
								확인
							</button>
						</div>
					</>
				}
			>
				<ul className="seo-guide-list">
					<li>
						<div className="title-area">
							<span className="num">1</span>
							<p>
								아래 이미지와 같이 <strong>메타태그 형태 코드 텍스트</strong>를 다운로드 받을 수 있습니다.
							</p>
						</div>
						<div className="img-box">
							<img src={GuideImg1} alt="메타태그 형태 코드 텍스트 예시" />
						</div>
					</li>
					<li>
						<div className="title-area">
							<span className="num">2</span>
							<p>
								메타태그 적용 시 아래와 같이 <strong>정보가 포함되어 공유</strong>됩니다.
							</p>
						</div>
						<div className="img-box">
							<img src={GuideImg2} alt="메타태그 적용 시 썸네일이미지, 타이틀, SEO 디스크립션 정보 포함 예시" />
						</div>
					</li>
				</ul>
			</PopupLayout>

			{/* 메타태그 내보내기 팝업 */}
			<PopupLayout
				isOpen={isSeoCreatePopup}
				closePopup={closeSeoCreatePopup}
				title="메타태그 내보내기"
				size="md"
				className="popup-seocreate"
				footerContent={
					<>
						<div className="wrap-left">
							<div className="guide-area">
								<i className="ico ico-warning-sm"></i>
								<p>썸네일 이미지는 별도의 CDN 서버에 업로드한 뒤 사용해야 합니다.</p>
							</div>
						</div>
						<div className="wrap-right">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={handleToastClick}>
								<i className="ico ico-copy-sm"></i>
								편집한 메타태그 복사
							</button>
						</div>
					</>
				}
			>
				<div className="seocreate-result">
					<div className="seocreate-result-area">
						<div className="title-area">
							<div className="title3">
								<strong>
									<span className="num">1</span>앨리 SEO 추천값이 적용된 메타태그 입니다.
								</strong>
								<button type="button" className="btn btn-xs btn-outline-gray" onClick={handleToastClick}>
									<i className="ico ico-copy-xs"></i>
									태그 복사
								</button>
							</div>
							<div className="title3">
								<strong>
									<span className="num">2</span>아래에서 <code className="font-green">content=""</code> 속성을 편집하고{" "}
									<code className="font-red">&lt;head&gt;&lt;/head&gt;</code> 내부에 적용해 보세요.
								</strong>
							</div>
						</div>
						<div className="diff-area">
							<CodeMirrorMerge className="code-mirror-merge" orientation="a-b" theme={material}>
								<CodeMirrorMerge.Original value={oriMetaTag} extensions={[EditorState.readOnly.of(true), html()]} />
								<CodeMirrorMerge.Modified
									value={newMetaTagValue}
									extensions={[html()]}
									editable={true}
									onChange={handleChange}
								/>
							</CodeMirrorMerge>
						</div>
					</div>

					{showToast && (
						<Toast
							message="메타태그가 복사되었습니다."
							onClose={() => {
								setShowToast(false);
							}}
							icon={<i className="ico ico-copy-white-sm"></i>}
							position="bottom"
							theme="dark"
						/>
					)}
				</div>
			</PopupLayout>
		</>
	);
}
