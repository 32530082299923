import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import usePopup from "@/hooks/usePopup";
import SettingTabs from "@/components/tabs/SettingTabs";
import PromotionBanner from "@/components/promotion/PromotionBanner";
import {
	LicenseCodeInput,
	LicenseDetailsPopup,
	LicenseExamination as LicenseDetails
} from "@/services/pages/setting/SettingLicensePage";
import { LicenseExamination } from "@/services/core/mypage/LicenseExamination";

export default function SettingLicensePage() {
	const [activeTab, setActiveTab] = useState("license");
	const { isOpen: isLicenseDetailsPopup, open: openLicenseDetailsPopup, close: closeLicenseDetailsPopup } = usePopup();
	const dispatch = useDispatch();
	const location = useLocation();
	const userData = useSelector((state) => state.user.userData);

	const [initialCode, setInitialCode] = useState("");

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get("code");
		if (code) {
			setInitialCode(code);
		}
		LicenseExamination(dispatch);
	}, [location.search, dispatch]);

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			<div className="container settings setting-license-page">
				<div className="content-box">
					<div className="content-header mb-6">
						<h2 className="title1">설정</h2>
					</div>
					<div className="content-body">
						<SettingTabs activeTab={activeTab} onTabChange={handleTabChange} />
						<div className="content-inner">
							<h3 className="sr-only">사용권</h3>
							<div className="settings-list">
								{/* 사용권 등록 */}
								<LicenseCodeInput initialCode={initialCode} />
								{/* 사용권 내역 */}
								<LicenseDetails userData={userData} openLicenseDetailsPopup={openLicenseDetailsPopup} />
							</div>
							<div className="settings-container">
								<PromotionBanner type="band" />
								<PromotionBanner type="card-md" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<LicenseDetailsPopup isOpen={isLicenseDetailsPopup} closePopup={closeLicenseDetailsPopup} />
		</>
	);
}
