import axios from "axios";
import HttpRequest, { USER_POST_LOGIN } from "../url/URL";

export const login = async (email, password) => {
	try {
		// 폼 데이터 객체 생성
		const formData = new URLSearchParams();
		formData.append("email", email);
		formData.append("password", password);
		// axios 요청 구성
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		// axios POST 요청
		const data = await axios.post(USER_POST_LOGIN, formData, config);

		return data;
	} catch (error) {
		throw error;
	}
};
