import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imgHtml from "@/assets/images/home/img-img2html@2x.jpg";
import imgContlast from "@/assets/images/home/img-contrast@2x.jpg";
import imgSeo from "@/assets/images/home/img-seo@2x.jpg";
import imgTable from "@/assets/images/home/img-table@2x.jpg";

// 애니메이션 시간 변수
const FADE_DURATION = 1000; // 페이드 인 지속 시간 (ms)
const CHAT_DELAY = 1000; // 채팅 시작 지연 시간 (ms)
const CHAT_INTERVAL = 600; // 채팅 항목 간격 (ms)
const BUTTON_DELAY = CHAT_DELAY + CHAT_INTERVAL * 2; // 버튼 지연 시간 (2개의 채팅 항목 기준)
const SHRINK_DELAY = 3000; // shrink 클래스 추가 지연 시간 (버튼 노출 후 2초 뒤)
const SLIDE_DURATION = FADE_DURATION + BUTTON_DELAY + SHRINK_DELAY + 1000; // 전체 슬라이드 전환 시간 (버튼 노출 후 2초 뒤 전환)

const ServiceOverview = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [isPlaying, setIsPlaying] = useState(true);
	const [shrink, setShrink] = useState(false);

	const slides = [
		{
			subject: (
				<>
					<strong className="copy">
						<span className="d-up-lg">이미지 </span>대체텍스트 타이핑
						<br className="d-down-sm" /> AI로.
					</strong>
					<span className="desc">이미지 HTML 변환기로 자동생성하고 편집해서 공유까지</span>
				</>
			),
			chat: [
				<>
					<span className="emoji">🤔</span>
					<span className="comment">
						<strong>대체텍스트 일일이 타이핑?</strong>
						<br /> 그 많은 카드뉴스와 포스터 이미지를 다?
					</span>
				</>,
				<>
					<span className="emoji">🔮</span>
					<span className="comment">
						이미지만 올리세요. <strong>HTML로 대체콘텐츠 생성</strong>하고 위지윅 에디터에서 편집하고 공유까지 한 번에
						가능해요!
					</span>
				</>
			],
			button: "이미지 HTML 변환기 바로가기",
			to: "/tool/img2html/add",
			img: imgHtml,
			imgAlt: "이미지 HTML 변환기 예시 이미지"
		},
		{
			subject: (
				<>
					<strong className="copy">
						흐릿한 글자
						<br className="d-down-sm" /> 선명하게.
					</strong>
					<span className="desc">배경과 글자 색상 명도대비 진단과 개선을 자동으로</span>
				</>
			),
			chat: [
				<>
					<span className="emoji">🤔</span>
					<span className="comment">
						<strong>글자색을 하나씩 언제 다 측정</strong>해?
						<br /> 색깔은 뭘로 바꿔야 하지?
					</span>
				</>,
				<>
					<span className="emoji">🔮</span>
					<span className="comment">
						이미지만 올리세요. <strong>글자의 명암비를 진단</strong>해주고 웹 접근성 지침 준수하는{" "}
						<strong>최적의 색상을 추천</strong>
						해줘요!
					</span>
				</>
			],
			button: "이미지 명도대비 최적화 바로가기",
			to: "/tool/contrast/add",
			img: imgContlast,
			imgAlt: "이미지 명도대비 최적화 예시 이미지"
		},
		{
			subject: (
				<>
					<strong className="copy">
						콘텐츠에
						<br className="d-down-sm" /> SEO 날개를.
					</strong>
					<span className="desc">제목, 키워드, 요약문, 썸네일 생성과 메타태그 추출로 SEO 한 번에</span>
				</>
			),
			chat: [
				<>
					<span className="emoji">🤔</span>
					<span className="comment">
						<strong>블로그 글 제목</strong> 뭘로 하지? 섬네일은
						<br /> 뭘로 하지? 검색엔진 최적화는 어떻게?
					</span>
				</>,
				<>
					<span className="emoji">🔮</span>
					<span className="comment">
						링크만 입력하세요. <strong>제목, 키워드, 요약문, 썸네일을 한 번에 생성</strong>해줘요. 생성한 결과를
						메타태그로 내보낼 수 있어요.
					</span>
				</>
			],
			button: "SEO 메타태그 생성기 바로가기",
			to: "/tool/seo/add",
			img: imgSeo,
			imgAlt: "SEO 메타태그 생성기 예시 이미지"
		},
		{
			subject: (
				<>
					<strong className="copy">
						복잡한 표<br className="d-down-sm" /> 이해하기 쉽게.
					</strong>
					<span className="desc">접근성 위반 자동검사 및 KWCAG 2.1 지침 기반 캡션 생성</span>
				</>
			),
			chat: [
				<>
					<span className="emoji">🤔</span>
					<span className="comment">
						<strong>표 캡션 하나하나 쓰기 번거로워.</strong>
						<br /> 화면에 표시도 안되는데 왜 필요해?
					</span>
				</>,
				<>
					<span className="emoji">🔮</span>
					<span className="comment">
						코드나 링크를 입력하세요. <strong>한 번에 표 5개의 캡션을 생성</strong>해줘요. 캡션은 시각장애인이 표 구조를
						파악할 때 큰 도움이 돼요.
					</span>
				</>
			],
			button: "표 접근성 최적화 바로가기",
			to: "/tool/table/add",
			img: imgTable,
			imgAlt: "표 접근성 최적화 예시 이미지"
		}
	];

	useEffect(() => {
		let slideTimer;
		let shrinkTimer;
		let zoomTimer;

		if (isPlaying) {
			slideTimer = setInterval(() => {
				setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
			}, SLIDE_DURATION); // 슬라이드 전환 시간

			shrinkTimer = setTimeout(() => {
				setShrink(true); // 버튼 노출 후 2초 뒤 shrink 클래스 추가
			}, BUTTON_DELAY); // 버튼 노출된 이후 트리거

			zoomTimer = setTimeout(() => {
				setShrink(false);
				setTimeout(() => {
					setShrink(true);
				}, 500); // 선명해진 뒤 0.5초 뒤에 줌 효과 시작
			}, BUTTON_DELAY + 500); // shrink 클래스 추가 후 0.5초 뒤에 줌 효과 트리거
		}

		return () => {
			clearInterval(slideTimer);
			clearTimeout(shrinkTimer);
			clearTimeout(zoomTimer);
		};
	}, [isPlaying, slides.length]);

	useEffect(() => {
		setShrink(false); // 슬라이드가 변경될 때마다 초기화
		const timer = setTimeout(() => {
			setShrink(true);
		}, BUTTON_DELAY + 500); // 버튼 노출 후 0.5초 뒤에 shrink 클래스 추가
		return () => clearTimeout(timer);
	}, [currentSlide]);

	const handlePausePlay = () => {
		setIsPlaying(!isPlaying);
	};

	const handlePagingClick = (index) => {
		setCurrentSlide(index);
		setIsPlaying(false);
	};

	return (
		<div className="service-overview">
			<div className="slider-container">
				{slides.map((slide, index) => (
					<div className={`service-item ${currentSlide === index ? "active" : ""}`} key={index}>
						<dl className="info">
							<dt className="subject">{slide.subject}</dt>
							<dd>
								<ul className="chat">
									{slide.chat.map((chatItem, chatIndex) => (
										<li className={chatIndex % 2 === 0 ? "left" : "right"} key={chatIndex}>
											{chatItem}
										</li>
									))}
								</ul>
								<div className="btn-group">
									{/* <Link to={slide.to} className="btn btn-black btn-lg"> */}
									<Link to={`/login?redirect=${encodeURIComponent(slide.to)}`} className="btn btn-black btn-lg">
										무료로 시작하기
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M5.33008 2.34375L10.9869 8.0006L5.33008 13.6575"
												stroke="white"
												strokeWidth="1.3"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</Link>
								</div>
							</dd>
						</dl>
						<div className={`visual ${currentSlide === index && shrink ? "shrink" : ""}`}>
							<img src={slide.img} alt={slide.imgAlt} className="img-content" />
						</div>
					</div>
				))}
			</div>
			<div className="paging">
				{slides.map((_, index) => (
					<button
						key={index}
						className={currentSlide === index ? "active btn-paging" : "btn-paging"}
						onClick={() => handlePagingClick(index)}
					>
						{index + 1} {currentSlide === index && <span>선택됨</span>}
					</button>
				))}

				<button className="btn btn-control" onClick={handlePausePlay}>
					{isPlaying ? (
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<title>일시정지</title>
							<path d="M5.99951 5L5.99951 16" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
							<path d="M13.9995 5L13.9995 16" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
						</svg>
					) : (
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<title>재생</title>
							<path
								d="M15.5152 9.14786C16.1636 9.53082 16.1636 10.4689 15.5152 10.8519L7.49419 15.5892C6.83456 15.9788 6.00146 15.5033 6.00146 14.7372L6.00146 5.2625C6.00146 4.49642 6.83456 4.02091 7.49419 4.4105L15.5152 9.14786Z"
								fill="white"
							/>
						</svg>
					)}
				</button>
			</div>
		</div>
	);
};

export default ServiceOverview;
