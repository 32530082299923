import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";

import CodeMirrorHtml from "@/components/plugins/CodeMirrorHtml.js";
// import W3CValidator from "./LinterWorkDetailComponents/W3CValidator";
import AxeValidator from "@/components/plugins/AxeValidator";

import { HtmlcodeData } from "@/mock/HtmlcodeData"; // 삭제 필요

const LinterWorkDetailPage = () => {
	const navigate = useNavigate();
	const [htmlCode, setCode] = useState(HtmlcodeData);
	const [cursorPosition, setCursorPosition] = useState({ line: 0, col: 0 });
	const [moveCursorFunction, setMoveCursorFunction] = useState(null);
	const [currentStep, setCurrentStep] = useState(2);
	const [validationResults, setValidationResults] = useState({
		w3cErrors: [],
		axeIssues: [],
		tableCaptionIssues: []
	});

	const handleCursorMove = useCallback((cursorFunc) => {
		setMoveCursorFunction(() => cursorFunc.moveCursor);
	}, []);

	const handleCursorPositionChange = useCallback((position) => {
		setCursorPosition(position);
	}, []);

	const moveToPosition = useCallback(
		(line, col) => {
			if (moveCursorFunction) {
				moveCursorFunction(line, col);
			}
		},
		[moveCursorFunction]
	);

	const handleCodeChange = useCallback(async (newCode) => {
		setCode(newCode);
	}, []);

	// const handleW3CValidation = (errors) => {
	// 	setValidationResults({ ...validationResults, w3cErrors: errors });
	// };

	const handleAxeValidation = (issues) => {
		setValidationResults({ ...validationResults, axeIssues: issues });
	};

	// const handleTableCaptionCheck = (issues) => {
	// 	setValidationResults({ ...validationResults, tableCaptionIssues: issues });
	// };

	const handleCompareButtonClick = () => {
		navigate("/tool/linter/compare");
	};

	const handleCaptionButtonClick = () => {
		setCurrentStep(3); // 캡션 진단 단계로 이동
	};

	// const nextStep = () => {
	// 	if (currentStep < 4) {
	// 		setCurrentStep(currentStep + 1);
	// 	}
	// };

	return (
		<>
			<div className="container linter-work-detail-page">
				<HeaderDetails className="service-linter" showSaveOptions={false} />
				<div className="body-details">
					<div className="linter-detail-container">
						<div className="col col-code-lint">
							<CodeMirrorHtml
								initialCode={htmlCode}
								onCursorMove={handleCursorMove}
								onCursorPositionChange={handleCursorPositionChange}
								onCodeChange={handleCodeChange}
							/>
							<div className="code-line-info">
								Line {cursorPosition.line}, Col {cursorPosition.col}
							</div>
						</div>
						<div className="col col-result">
							<div className="step-area">
								<ol className="step-list">
									{/* <li className={currentStep === 1 ? "on" : ""}>1. 웹표준</li> */}
									<li className={currentStep === 2 ? "on" : ""}>1. 문법 검사</li>
									<li className={currentStep === 4 ? "on" : ""}>2. 코드 비교</li>
								</ol>
							</div>
							<div className="results-area">
								{/* {currentStep === 1 && <W3CValidator htmlCode={htmlCode} onValidation={handleW3CValidation} />} */}
								{currentStep === 2 && (
									<AxeValidator
										htmlCode={htmlCode}
										onValidation={handleAxeValidation}
										onCaptionButtonClick={handleCaptionButtonClick}
									/>
								)}
								{currentStep === 4 && (
									<>
										<p className="text-center mt-10">
											모든 수정이 끝났나요?
											<br /> 변경 내역을 비교해 보세요.
										</p>
										<div className="text-center mt-5">
											<button type="button" className="btn btn-primary btn-md" onClick={handleCompareButtonClick}>
												👀 코드 수정 전 후 비교하기
											</button>
										</div>
									</>
								)}
								{/* {currentStep < 4 && (
									<button type="button" className="btn btn-md btn-primary" onClick={nextStep}>
										다음 단계로
									</button>
								)} */}
							</div>
						</div>
					</div>
				</div>
				<FooterDetails>
					<div className="wrap-left">
						<button type="button" className="btn btn-lg btn-primary">
							커스텀
						</button>
						<button type="button" className="btn btn-lg btn-lightgray" onClick={() => moveToPosition(10, 2)}>
							10, 2 이동
						</button>
						{/* <button type="button" className="btn btn-lg btn-lightgray" onClick={() => setCurrentStep(1)}>
							1. 웹표준
						</button> */}
						<button type="button" className="btn btn-lg btn-lightgray" onClick={() => setCurrentStep(2)}>
							1. 접근성
						</button>
						<button type="button" className="btn btn-lg btn-lightgray" onClick={() => setCurrentStep(3)}>
							2. 표 제목
						</button>
						<button type="button" className="btn btn-lg btn-lightgray" onClick={() => setCurrentStep(4)}>
							3. 결과
						</button>
					</div>
					<div className="wrap-right">
						<button type="button" className="btn btn-lg btn-primary">
							커스텀
						</button>
					</div>
				</FooterDetails>
			</div>
		</>
	);
};

export default LinterWorkDetailPage;
