import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import LoadingSpinner from "@/components/loading/LoadingSpinner";
import Pagination from "@/components/navigation/Pagination";

import PopupSearch from "@/pages/popup/PopupSearch";
import { useDispatch, useSelector } from "react-redux";
import { TrashCanLoaded, fetchTrashCanData } from "@/services/store/action/TrashCanAction";
import ListTaskWork from "@/services/core/workspace/ListTaskWork";
import ListTaskCardWork from "@/services/core/workspace/ListTaskCardWork";
import { TrashDeleteList, TrashIsAllDelete } from "@/services/core/workspace/TreashDelete";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { getCookie } from "@/services/cookies/UserCookies";
import { formatDate } from "@/services/util/TransForm";

export default function DeletePage({ hasNavigation = true }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [stateWork, setStateWork] = useState("Delete");
	const descState = useSelector((state) => state.trashSort.desc);
	const descValue = Object.values(descState).find((value) => value !== null);
	const TrashDataRowNumber = useSelector((state) => state.trash?.trashCanData?.data?.[0]?.rownum - 1 || 0);

	const [specialCondition, setSpecialCondition] = useState(true);

	useEffect(() => {
		const token = getCookie("token");
		if (!token) {
			navigate("/login");
		}
	}, [navigate]);

	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [resetSignal, setResetSignal] = useState(0); // 초기값은 0
	const [selectedItems, setSelectedItems] = useState(new Set());
	const Trash = useSelector((state) => state.trashSort);
	const TrashDesc = useSelector((state) => state.trashSort.desc);
	const TrashDescValue = Object.values(TrashDesc).find((value) => value !== null);

	const handleResetClick = () => {
		setSelectedItems(new Set());
		setSelectAllChecked(false);
		setResetSignal((prev) => prev + 1); // 버튼 클릭시 값 증가
		setSelectedItemsCount(0);
	};

	const handleSelectedItemsChange = (items) => {
		setSelectedItems(items);
	};
	const [count, setCount] = useState(15);
	const [after, setAfter] = useState(null);
	const [orderBy, setOrderBy] = useState(Trash.orderBy);
	const [desc, setDesc] = useState(Trash.isActive);

	const dataCount = useSelector((state) => state.dataCount.dataCount?.data);
	const allCount = dataCount?.allCount || null;
	const trashCount = dataCount?.trashCount || null;

	useEffect(() => {
		const actualDesc = TrashDescValue !== null ? TrashDescValue : desc;
		if (specialCondition) {
			const params = {
				after: after,
				count: count,
				search: "",
				service_type: "",
				order_by: orderBy,
				is_desc: actualDesc
			};
			dispatch(fetchTrashCanData(params));
		} else {
			const params = {
				after: after,
				count: count,
				search: "",
				service_type: "",
				order_by: orderBy,
				is_desc: desc
			};
			dispatch(fetchTrashCanData(params));
		}
	}, [dispatch, after, orderBy, desc, TrashDescValue]);

	const trashCanData = useSelector((state) => state.trash?.trashCanData?.data || []);
	const isLoading = useSelector((state) => state.trash.isLoading);
	const totalPages = useSelector((state) => Math.ceil(state.trash?.trashCanData?.meta.totalCount / 15)); // 예시로 총 3 페이지 가정
	const typeTransformations = {
		img_html: "img2html",
		img_improve: "contrast",
		web_standard: "table"
	};
	useEffect(() => {}, [isLoading]);
	const transformedData =
		!isLoading && trashCanData
			? trashCanData.map((item) => {
					const transformedType = typeTransformations[item.workspaceType] || item.workspaceType;
					return {
						id: item.workspaceId,
						service: {
							name: transformedType,
							type: transformedType
						},
						workName: item.workspaceName,
						fileShared: item.workspaceIsShare,
						workspaceFilePath: item.workspaceFilePath,
						fileType: item.workspaceFileType,
						fileSize: item.workspaceFileSpace,
						lastModified: formatDate(item.updateAt) || formatDate(item.createAt),
						createdDate: formatDate(item.createAt),
						delete: false
					};
			  })
			: [];

	// 검색 팝업
	const [isPopupSearchOpen, setIsPopupSearchOpen] = useState(false);
	const openPopupSearch = () => setIsPopupSearchOpen(true);
	const closePopupSearch = () => setIsPopupSearchOpen(false);

	// tab 메뉴
	const [activeTab, setActiveTab] = useState("listType");

	const handleTabClick = (tabName) => {
		setActiveTab(tabName); // 클릭한 탭으로 activeTab 업데이트
		if (tabName === "listType") {
			// setOrderBy(2)
			setSpecialCondition(true);
		}

		if (tabName === "cardTpe") {
			setSpecialCondition(false);
		}
	};

	// 페이징
	const [currentPage, setCurrentPage] = useState(1);

	const handlePageChange = (page) => {
		setCurrentPage(page);
		setAfter((page - 1) * count);
	};

	const handleDelete = async (id) => {
		const idList = Array.from(id);
		const resp = await TrashDeleteList(idList);
		if (resp === "Success") {
			// const
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));

			refreshData(TrashDataRowNumber);
			setSelectedItemsCount(0);
			setSelectAllChecked(false);
			setSelectedItems(new Set());
		}
		if (resp === "Fail") {
			alert({ message: "삭제를 실패하였습니다." });
			return;
		}
	};
	// 체크항목 개수
	const [selectedItemsCount, setSelectedItemsCount] = useState(0);
	const handleSelectedItemsCountChange = (count) => {
		setSelectedItemsCount(count);
	};

	const refreshData = async () => {
		const refreshParams = {
			after,
			count: count,
			search: "",
			service_type: "",
			order_by: 1,
			is_desc: true
		};
		dispatch(fetchTrashCanData(refreshParams));
		const dataCountResult = await DataCount();
		dispatch(setDataCount(dataCountResult));
	};

	const handleIsAllTrash = async () => {
		try {
			const response = await TrashIsAllDelete(true);

			if (response === "Success") {
				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));

				await refreshData();
			}
		} catch (error) {
			alert({ message: "휴지통 비우기에 실패하였습니다." });
		}
	};

	return (
		<>
			<div className="container delete-page">
				<div className="content-box">
					<div className="workitem-wrap">
						<div className="workitem-header">
							<div className="title-area">
								<h3 className="title1">휴지통</h3>
								<div className="status-area">
									{/* 체크된 항목이 없을 때만 안내 문구 노출 */}
									{selectedItemsCount === 0 && (
										<p className="guide-text">휴지통에 저장된 항목은 7일이 지나면 자동으로 완전히 삭제됩니다.</p>
									)}

									{/* 체크된 항목이 1개 이상일 경우 버튼 영역 노출 */}
									{selectedItemsCount > 0 && (
										<div className="checked-btn-area">
											<div className="checked-num">
												<span>{selectedItemsCount}</span>개 선택됨
											</div>
											<button
												type="button"
												onClick={() => handleResetClick()}
												className="btn btn-sm btn-outline-gray pl-3 pr-3"
											>
												<i className="ico ico-cancel-black-xs"></i>
												<span>선택 취소</span>
											</button>
											<button
												type="button"
												className="btn btn-sm btn-outline-gray pl-3 pr-3"
												onClick={() =>
													window.confirm({
														title: "이 작업을 영구 삭제할까요?",
														message: "삭제 후 복구할 수 없습니다.",
														onConfirm: (result) => {
															handleDelete(selectedItems);
														},
														confirmButtonText: "영구 삭제",
														cancelButtonText: "취소",
														buttonStatus: "invalid"
													})
												}
											>
												<i className="ico ico-delete-xs"></i>
												<span>작업 영구 삭제</span>
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="btn-area">
								{trashCount > 0 && (
									<button
										type="button"
										className="btn btn-sm btn-gray btn-delete pl-3 pr-3"
										onClick={() =>
											window.confirm({
												title: "휴지통의 모든 작업을 삭제하시겠어요?",
												content: `<p>휴지통에서 영구 삭제한 작업<strong class="delete-font-red">(${trashCount}건)</strong>은 복구할 수 없습니다.</p>`,
												onConfirm: (result) => {
													handleIsAllTrash();
												},
												confirmButtonText: "휴지통 비우기",
												cancelButtonText: "취소",
												buttonStatus: "invalid"
											})
										}
									>
										<i className="ico ico-delete-white-xs"></i>
										<span>휴지통 비우기</span>
									</button>
								)}

								<button type="button" className="btn btn-search" onClick={openPopupSearch}>
									<i className="ico ico-search-lg"></i>
									<span className="sr-only">문서 또는 서비스 검색</span>
								</button>
								{hasNavigation && (
									<nav className="tabmenu tabmenu-type3">
										<ul className="tab-list">
											<li className={activeTab === "listType" ? "on" : ""}>
												<Link to="#listType" onClick={() => handleTabClick("listType")}>
													<i className="ico ico-listview"></i>
													<span className="sr-only">리스트 형식</span>
												</Link>
											</li>
											<li className={activeTab === "cardTpe" ? "on" : ""}>
												<Link to="#cardTpe" onClick={() => handleTabClick("cardTpe")}>
													<i className="ico ico-cardview"></i>
													<span className="sr-only">카드 형식</span>
												</Link>
											</li>
										</ul>
									</nav>
								)}
							</div>
						</div>
						<div className="tab-content workitem-body">
							{activeTab === "listType" && (
								<>
									{isLoading ? (
										<div className="loading-area">
											<LoadingSpinner />
										</div>
									) : (
										<ListTaskWork
											reduxSort={"TRASHPAGE"}
											workItems={transformedData}
											itemCount={transformedData.length}
											onSelectedItemsCountChange={handleSelectedItemsCountChange}
											setOrderBy={setOrderBy}
											// setDesc={setDesc}
											desc={desc}
											orderBy={orderBy}
											work={stateWork}
											trashRestoreSuccess={fetchTrashCanData}
											onSelectAllCheckedChange={resetSignal}
											onSelectedItemsChange={handleSelectedItemsChange}
										/>
									)}

									{/* [개발] 영역 리스트 로딩시 로딩바 적용 */}

									{!isLoading && totalPages > 0 && (
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
									)}
								</>
							)}

							{activeTab === "cardTpe" && (
								<>
									{isLoading ? (
										<div className="loading-area">
											<LoadingSpinner />
										</div>
									) : (
										<ListTaskCardWork
											cardType={"TRASHPAGE"}
											workItems={transformedData}
											itemCount={transformedData.length}
											setOrderBy={setOrderBy}
											setDesc={setDesc}
											desc={desc}
											orderBy={orderBy}
											work={stateWork}
											onSelectedItemsCountChange={handleSelectedItemsCountChange}
											onSelectedItemsChange={handleSelectedItemsChange}
											setSelectAllChecked={setSelectAllChecked}
											cardOrder={"trash"}
										/>
									)}
									{!isLoading && totalPages > 0 && (
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* 검색 모달 팝업 */}
			<PopupSearch isOpen={isPopupSearchOpen} closePopup={closePopupSearch} extraValue="Trash" />
		</>
	);
}
