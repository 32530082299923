import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink, Element } from "react-scroll";
import Logo from "@/assets/images/logo@2x.png";

import GuideHeader from "@/components/GuideHeader";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";

import Button from "@/components/button/Button";

import Toast from "@/components/toast/Toast";
import ToastAlert from "@/components/toast/ToastAlert";
import useToastAlert from "@/hooks/useToastAlert";

import Dropdown from "@/components/form/Dropdown";
import Pagination from "@/components/navigation/Pagination";
import DragAndDrop from "@/components/form/DragAndDrop";

import PopupSearch from "@/pages/popup/PopupSearch";
import PopupPrivacy from "@/pages/popup/PopupPrivacy";
import PopupOpenlic from "@/pages/popup/PopupOpenlic";
import PopupAgreement from "@/pages/popup/PopupAgreement";
import PopupLogin from "@/pages/popup/PopupLogin";

import ModalConfirm from "@/components/modal/ModalConfirm";

export default function GuidePage() {
	const [isLoading, setIsLoading] = useState(false);

	const toggleLoading = () => {
		setIsLoading(!isLoading);
	};

	// 드롭다운
	const options = [
		{ value: null, label: "드롭다운" },
		{ value: "1", label: "option1", iconClass: "ico-img2html-sm" },
		{ value: "2", label: "option2", iconClass: "ico-contrast-sm" },
		{ value: "3", label: "option2", iconClass: "ico-seo-sm" },
		{ value: "4", label: "option3", iconClass: "ico-linter-sm" }
	];
	const options1 = [
		// { value: null, label: "드롭다운" },
		{ value: "1", label: "option1 option1" },
		{ value: "2", label: "option2" },
		{ value: "3", label: "option2" },
		{ value: "4", label: "option3" }
	];

	const handleSelect = (option) => {
		console.log("Selected option:", option);
	};
	const handleSelect1 = (option) => {
		console.log("Selected option:", option);
	};

	// tab 메뉴
	const [activeTab, setActiveTab] = useState("tabContent1"); // tabmenu-type1
	const [activeTab2, setActiveTab2] = useState("tabContent1"); // tabmenu-type2

	const handleTabClick = (tabName) => {
		setActiveTab(tabName); // 클릭한 탭으로 activeTab 업데이트
	};
	const handleTab2Click = (tabName) => {
		setActiveTab2(tabName); // 두 번째 탭 그룹의 선택 탭 업데이트
	};

	// 페이징
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = 3; // 예시로 총 3 페이지 가정

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	// popup
	const { isOpen: isDefaultPopup, open: openDefaultPopup, close: closeDefaultPopup } = usePopup();
	const { isOpen: isSmPopup, open: openSmPopup, close: closeSmPopup } = usePopup();
	const { isOpen: isMdPopup, open: openMdPopup, close: closeMdPopup } = usePopup();

	// toast popup
	const [showToast1, setShowToast1] = useState(false);
	const [showToast2, setShowToast2] = useState(false);
	const handleToastClick1 = () => {
		setShowToast1(true);
	};
	const handleToastClick2 = () => {
		setShowToast2(true);
	};
	// toast alert popup
	// const { showToast, openToast, closeToast } = useToastAlert();
	// const { showToast4, openToast4, closeToast4 } = useToastAlert();
	const toast1 = useToastAlert();
	const toast2 = useToastAlert();
	const toast3 = useToastAlert();

	const popupSearch = usePopup(); // 검색 팝업
	const popupPrivacy = usePopup(); // 개인정보처리방침
	const popupOpenlic = usePopup(); // 오픈소스라이선스 고지
	const popupAgreement = usePopup(); // 서비스 이용약관
	const popupLoginCommon = usePopup(); // 로그인 팝업

	// 사용권 차감 안내 팝업 추가
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	// ModalConfirm 커스텀 함수
	const handleOpenConfirm = () => {
		setIsConfirmOpen(true);
	};
	const handleCancel = () => {
		setIsConfirmOpen(false);
	};

	// ModalConfirm에서 '변환' 버튼 클릭 시 : toast Alert 추가
	const handleConfirm = () => {
		toast3.openToast();
		setIsConfirmOpen(false);
	};

	// alert 기본값
	// window.alert({ title: "Alert", message: "alert 창입니다. 메세지와 타이틀을 수정해주세요" });

	// confirm 기본값
	// window.confirm({
	// 	title: "Confirm",
	// 	message: "Confirm창 입니다. 메세지와 타이틀을 수정해주세요",
	// 	content: "<p>수정</p>", // 추가 마크업 없을 경우 미노출
	// 	onConfirm: (result) => console.log(result),
	// 	confirmButtonText: "확인",
	// 	cancelButtonText: "취소",
	// 	buttonStatus: "invalid" // 없을 경우 기본값
	// });

	return (
		<>
			<div className="markup-guide">
				<aside id="guide-nav">
					<h1 className="sidebar-title">
						<ScrollLink className="link-scroll" to="content" smooth={true} duration={500}>
							<h1 id="logo">
								<img src={Logo} alt="A11Y" />
							</h1>
						</ScrollLink>
					</h1>
					<h2 className="nav-title">컴포넌트</h2>
					<nav className="component-list">
						<ul>
							<li>
								<ScrollLink className="link-scroll" to="content-link" smooth={true} duration={500}>
									<span>페이지 링크</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-button" smooth={true} duration={500}>
									<span>버튼</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-form" smooth={true} duration={500}>
									<span>입력폼</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-dropdown" smooth={true} duration={500}>
									<span>드롭다운</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-tab" smooth={true} duration={500}>
									<span>탭메뉴</span>
								</ScrollLink>
							</li>
							{/* <li>
								<ScrollLink className="link-scroll" to="component-card" smooth={true} duration={500}>
									<span>카드</span>
								</ScrollLink>
							</li> */}
							<li>
								<ScrollLink className="link-scroll" to="component-box" smooth={true} duration={500}>
									<span>박스</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-table" smooth={true} duration={500}>
									<span>테이블</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-paging" smooth={true} duration={500}>
									<span>페이징</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-dropzone" smooth={true} duration={500}>
									<span>드랍존</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-alerts" smooth={true} duration={500}>
									<span>알럿 팝업</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-confirm" smooth={true} duration={500}>
									<span>컴펌 팝업</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-toast" smooth={true} duration={500}>
									<span>토스트 팝업</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-modal" smooth={true} duration={500}>
									<span>모달 팝업</span>
								</ScrollLink>
							</li>
						</ul>
					</nav>
					<h2 className="nav-title">구조 컴포넌트</h2>
					<nav className="component-list">
						<ul>
							<li>
								<ScrollLink className="link-scroll" to="component-popup" smooth={true} duration={500}>
									<span>기타 팝업</span>
								</ScrollLink>
							</li>
							<li>
								<ScrollLink className="link-scroll" to="component-sample" smooth={true} duration={500}>
									<span>sample</span>
								</ScrollLink>
							</li>
						</ul>
					</nav>
				</aside>
				<Element name="content" className="guide-content">
					<h2 className="title1">ALLY 공통 컴포넌트 가이드</h2>
					<div name="content-link">
						<h3 className="title2">페이지 링크</h3>
						<GuideHeader />
					</div>
					<div name="component-button">
						<h3 className="title2">button</h3>
						<div className="btn-group mt-4">
							<button type="button" className="btn btn-xs btn-primary">
								.btn-xs
							</button>
							<button type="button" className="btn btn-sm btn-primary">
								.btn-sm
							</button>
							<button type="button" className="btn btn-md btn-primary">
								.btn-md
							</button>
							<button type="button" className="btn btn-lg btn-primary">
								.btn-lg
							</button>
							<button type="button" className="btn btn-xl btn-primary">
								.btn-xl
							</button>
						</div>
						<h4 className="title3">
							button 컴포넌트 | 로딩이 있는 버튼 사용시 활용
							<button className="btn btn-xs btn-invalid ml-4" onClick={toggleLoading}>
								{isLoading ? "로딩 중지" : "로딩 시작"}
							</button>
						</h4>
						<div className="btn-group mt-4">
							<Button
								type="submit" // 기본값 : button
								id="btnIdTest" // id 미지정시 미노출
								icon={{ classes: "ico-tool-md" }} // 아이콘 클래스 수정
								text="btn-xs" // 버튼 내부 텍스트 수정
								loadingText="로딩 중..." // 로딩시 노출되는 텍스트입니다. 없을경우 : icon만 표시, loadingText={true}의 경우 : text를 그대로 가져옵니다.
								isLoading={isLoading} // 로딩 상태
								// className="btn-custom" // 추가 커스텀 클래스 : 필요시 정의
								size="xs" // 버튼 크기 : xs, sm, md, lg, xl
								color="primary" // 색상 :  primary, gray, lightgray, dark, invalid, outline-gray, outline-primary
							/>
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="btn-sm"
								loadingText="로딩 중..."
								isLoading={isLoading}
								size="sm"
								color="primary"
							/>
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="btn-md"
								loadingText="로딩 중..."
								isLoading={isLoading}
								size="md"
								color="primary"
							/>
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="btn-lg"
								loadingText="로딩 중..."
								isLoading={isLoading}
								size="lg"
								color="primary"
							/>
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="btn-xl"
								loadingText="로딩 중..."
								isLoading={isLoading}
								size="xl"
								color="primary"
							/>
						</div>
						<div className="btn-group mt-4">
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="xs" color="primary" />
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="sm" color="primary" />
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="md" color="primary" />
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="lg" color="primary" />
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="xl" color="primary" />
						</div>
						<h4 className="title3">button 컴포넌트 | 다양한 케이스 활용 예시</h4>
						<div className="btn-group mt-4">
							{/* 추가 다양한 케이스 */}
							<Button
								type="submit"
								id="btnIdTestFull"
								icon={{ classes: "ico-tool-md" }}
								text="0. 풀옵션"
								loadingText="로딩 중..."
								isLoading={isLoading}
								className="btn-full-custom"
								size="md"
								color="primary"
							/>
							{/* 1. 아이콘이 있고 텍스트 없는 경우 => 로딩 아이콘만 표시 */}
							<Button icon={{ classes: "ico-tool-md" }} isLoading={isLoading} size="md" color="primary" />
							{/* 2. 아이콘과 텍스트가 있는 경우 => 로딩 아이콘만 표시 */}
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="2. 아이콘, 텍스트 ✔ | 로딩 아이콘 ✔"
								isLoading={isLoading}
								size="md"
								color="primary"
							/>
							{/* 3. 아이콘과 텍스트가 있는 경우 => 로딩 아이콘과 로딩텍스트를 표시 */}
							<Button
								icon={{ classes: "ico-tool-md" }}
								text="3. 아이콘, 텍스트 ✔ | 로딩 아이콘, 텍스트 ✔"
								loadingText="로딩 중..."
								isLoading={isLoading}
								size="md"
								color="primary"
							/>
							{/* 4. 텍스트만 있는 경우 => 로딩 아이콘만 표시 */}
							<Button text="4. 텍스트 ✔ | 로딩 아이콘 ✔" isLoading={isLoading} size="md" color="primary" />
							{/* 5. 텍스트만 있는 경우 => 로딩 아이콘과 로딩텍스트를 표시 */}
							<Button
								text="5. 텍스트 ✔ | 로딩 아이콘, 텍스트 ✔"
								loadingText={true} // text를 그대로 가져옵니다.
								isLoading={isLoading}
								size="md"
								color="primary"
							/>
						</div>
					</div>
					<div name="component-form">
						<h3 className="title2">form</h3>
						<div className="mt-4">
							<span className="check-item">
								<input type="checkbox" id="select-chk1" />
								<label htmlFor="select-chk1">.check-item</label>
							</span>
							<span className="check-item-sm ml-4">
								<input type="checkbox" id="select-chk2" />
								<label htmlFor="select-chk2">.check-item-sm</label>
							</span>
						</div>
						<div className="mt-4">
							<form>
								<input type="text" id="text" title="타이틀" className="form-control-md" placeholder="form-control-md" />
							</form>
						</div>
						<div className="mt-4">
							<form>
								<input
									type="text"
									id="text"
									title="타이틀"
									className="form-control"
									placeholder="form-control : 기본"
								/>
							</form>
						</div>
					</div>
					<div name="component-dropdown">
						<h3 className="title2">dropdown</h3>
						<div className="mt-4">
							<Dropdown title="Dropdown" options={options} onSelect={handleSelect} />
						</div>
						<div className="mt-4">
							<Dropdown title="Dropdown" options={options1} onSelect={handleSelect1} size="sm" />
						</div>
					</div>
					<div name="component-tab">
						<h3 className="title2">tab</h3>
						<div className="mt-4">
							<nav className="tabmenu tabmenu-type1">
								<ul className="tab-list">
									<li className={activeTab === "tabContent1" ? "on" : ""}>
										<Link to="#tabContent1" onClick={() => handleTabClick("tabContent1")}>
											<span>tabmenu1</span>
										</Link>
									</li>
									<li className={activeTab === "tabContent2" ? "on" : ""}>
										<Link to="#tabContent2" onClick={() => handleTabClick("tabContent2")}>
											<span>tabmenu2</span>
										</Link>
									</li>
								</ul>
							</nav>
							<div className="tab-content">
								{activeTab === "tabContent1" && (
									<div>
										<p className="title3">tabContent1</p>
										<span>.tabmenu .tabmenu-type1 + .tab-content</span>
									</div>
								)}
								{activeTab === "tabContent2" && (
									<div>
										<p className="title3">tabContent2</p>
										<span>.tabmenu .tabmenu-type1 + .tab-content</span>
									</div>
								)}
							</div>
						</div>
						<div className="mt-4">
							<nav className="tabmenu tabmenu-type2">
								<ul className="tab-list">
									<li className={activeTab2 === "tabContent3" ? "on" : ""}>
										<Link to="#tabContent3" onClick={() => handleTab2Click("tabContent3")}>
											<span>tabmenu1</span>
										</Link>
									</li>
									<li className={activeTab2 === "tabContent4" ? "on" : ""}>
										<Link to="#tabContent4" onClick={() => handleTab2Click("tabContent4")}>
											<span>tabmenu2</span>
										</Link>
									</li>
								</ul>
							</nav>
							<div className="tab-content">
								{activeTab2 === "tabContent3" && (
									<div>
										<p className="title3">tabContent1</p>
										<span>.tabmenu .tabmenu-type2 + .tab-content</span>
									</div>
								)}
								{activeTab2 === "tabContent4" && (
									<div>
										<p className="title3">tabContent2</p>
										<span>.tabmenu .tabmenu-type2 + .tab-content</span>
									</div>
								)}
							</div>
						</div>
					</div>
					{/* <div name="component-card">
						<h3 className="title2">card</h3>
						<div className="mt-4">
							<div className="card">.card</div>
						</div>
						<div className="mt-4">
							<div className="card card-full">.card .card-full</div>
						</div>
					</div> */}
					<div name="component-box">
						<h3 className="title2">박스</h3>
						<div className="mt-4">
							<div className="box">.box</div>
						</div>
						<div className="mt-4">
							<div className="box box-point">.box-point</div>
						</div>
						<div className="mt-4">
							<div className="box box-outline">.box-outline</div>
						</div>
					</div>
					<div name="component-table">
						<h3 className="title2">table</h3>
						<div className="mt-4">
							<div className="table">
								<table>
									<caption className="sr-only">최근 작업 목록</caption>
									<colgroup>
										<col />
										<col />
										<col />
									</colgroup>
									<thead>
										<tr>
											<th>테이블 제목</th>
											<th>테이블 제목</th>
											<th>테이블 제목</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>테이블 내용</td>
											<td>테이블 내용</td>
											<td>테이블 내용</td>
										</tr>
										<tr>
											<td>테이블 내용</td>
											<td>테이블 내용</td>
											<td>테이블 내용</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div name="component-paging">
						<h3 className="title2">paging</h3>
						<div className="mt-4">
							<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
							{/* 페이지 내용 렌더링 */}
							<div className="content">
								<h3 className="title3">Page {currentPage}</h3>
								{/* 페이지 내용 */}
							</div>
						</div>
					</div>
					<div name="component-dropzone">
						<h3 className="title2">dropzone</h3>
						<div className="mt-4">
							<DragAndDrop />
						</div>
					</div>
					<div name="component-alerts">
						<h3 className="title2">alerts popup</h3>
						<div className="btn-group">
							<button
								type="button"
								className="btn btn-xl btn-primary"
								onClick={() =>
									window.alert({ title: "Alert", message: "alert 창입니다. 메세지와 타이틀을 수정해주세요" })
								}
							>
								Alert
							</button>
							<button
								type="button"
								className="btn btn-xl btn-outline-gray"
								onClick={() =>
									window.alert({
										title: "Alert",
										// message: "alert 창입니다. 메세지와 타이틀을 수정해주세요",
										messageClassName: "custom-message-class",
										content: `<div class="box">alert 창입니다. 메세지와 타이틀을 수정해주세요</div>`,
										btnClassName: "outline-gray",
										alertBtnText: "닫기"
									})
								}
							>
								Alert
							</button>
						</div>
					</div>
					<div name="component-confirm">
						<h3 className="title2">confirm popup</h3>
						<button
							type="button"
							className="btn btn-xl btn-primary"
							onClick={() =>
								window.confirm({
									title: "Confirm",
									message: "Confirm창 입니다. 메세지와 타이틀을 수정해주세요",
									// content: "<p>수정</p>", // 추가 마크업 없을 경우 미노출
									onConfirm: (result) => console.log(result),
									confirmButtonText: "확인",
									cancelButtonText: "취소"
									// buttonStatus: "invalid" // 없을 경우 기본값
								})
							}
						>
							Confirm
						</button>
					</div>
					<div name="component-toast">
						<h3 className="title2">toast popup</h3>

						<div className="mt-4">
							<div className="btn-group">
								<button className="btn btn-md btn-primary" onClick={handleToastClick1}>
									기본 토스트 팝업/ top/ light
								</button>
								<button className="btn btn-md btn-primary" onClick={handleToastClick2}>
									기본 토스트 팝업/ bottom/ dark/ icon
								</button>
							</div>
						</div>
						<h3 className="title2 mt-4">toast Alert</h3>
						<div className="mt-4">
							<div className="btn-group">
								<button className="btn btn-md btn-primary" onClick={toast1.openToast}>
									토스트 얼럿 팝업/ top/ light
								</button>
								<button className="btn btn-md btn-primary" onClick={toast2.openToast}>
									토스트 얼럿 팝업/ bottom/ dark/ actions
								</button>
							</div>

							{showToast1 && (
								<Toast
									message="기본 토스트 팝업입니다." // 메세지 내용
									onClose={() => {
										setShowToast1(false); // 토스트 팝업이 닫히면서 작동될 로직 없을 경우 	onClose={() => {}} 로 표기
									}}
									position="top" // 토스트 팝업 노출 위치 top, bottom
									theme="light" // 태마 light, dark
								/>
							)}
							{showToast2 && (
								<Toast
									message="기본 토스트 팝업입니다."
									onClose={() => {
										setShowToast2(false);
									}}
									icon={<i className="ico ico-notify-xs"></i>}
									position="bottom"
									theme="dark"
								/>
							)}
							{toast1.showToast && (
								<ToastAlert
									message="아이콘을 포함한 토스트"
									onClose={toast1.closeToast}
									icon={<i className="ico ico-notify-xs"></i>} // 아이콘 추가 방법
									position="top"
									theme="light"
								/>
							)}
							{toast2.showToast && (
								<ToastAlert
									message="액션 버튼이 있는 토스트"
									onClose={toast2.closeToast}
									icon={<i className="ico ico-notify-xs"></i>}
									actions={[
										// 추가 커스텀 버튼이 있는 경우
										<button type="button" className="btn btn-xs btn-primary" key="dismiss">
											커스텀 버튼
										</button>
									]}
									position="bottom"
									theme="dark"
								/>
							)}
						</div>
					</div>
					<div name="component-modal">
						<h3 className="title2">modal popup</h3>
						<div className="btn-group mt-4">
							<Link to="#" className="btn btn-xl btn-primary" onClick={openDefaultPopup}>
								Default
							</Link>
							<Link to="#" className="btn btn-xl btn-primary" onClick={openSmPopup}>
								Sm
							</Link>
							<Link to="#" className="btn btn-xl btn-primary" onClick={openMdPopup}>
								Md
							</Link>
						</div>
					</div>
				</Element>
				<Element className="guide-content">
					<div name="component-sample">
						<h3 className="title2">sample</h3>
						<div className="mt-4"></div>
					</div>
					<div name="component-popup">
						<h3 className="title2">기타 팝업</h3>
						<div className="mt-4">
							<div className="btn-group">
								<button
									type="button"
									className="btn btn-search btn-md btn-outline-gray"
									onClick={(e) => popupSearch.open(e)}
								>
									<span>문서 또는 서비스 검색</span>
									<i className="ico ico-search-gray"></i>
									<span className="sr-only">검색</span>
								</button>
								<button type="button" className="btn btn-lg btn-primary" onClick={(e) => popupPrivacy.open(e)}>
									개인정보처리방침 Popup
								</button>
								<button type="button" className="btn btn-lg btn-primary" onClick={(e) => popupOpenlic.open(e)}>
									오픈라이선스 고지 Popup
								</button>
								<button type="button" className="btn btn-lg btn-primary" onClick={(e) => popupAgreement.open(e)}>
									서비스 이용약관 Popup
								</button>
								<button type="button" className="btn btn-lg btn-primary" onClick={(e) => popupLoginCommon.open(e)}>
									로그인 Popup
								</button>

								<button
									type="button"
									className="btn btn-lg btn-primary"
									onClick={() =>
										window.confirm({
											title: "사용권이 없어요.",
											message: "새로운 사용권을 구매하거나 기간이 유효한 사용권 코드를 입력해주세요.",
											cancelButtonText: "취소",
											confirmButtonText: "사용권 구매하기"
										})
									}
								>
									서비스 사용권 공통 팝업
								</button>

								{/* 개발확인 필요 2024-03-15 : 이미지 업로드 실패 얼럿 팝업 추가 */}
								<button
									type="button"
									className="btn btn-lg btn-primary"
									onClick={() =>
										window.alert({
											title: "이미지 업로드 실패했어요.",
											content: `<p>업로드 한 이미지 파일 형식이 지원되는지,<br> 파일 크기가 너무 크지 않은지 확인해 주세요.</p>
											<span class="sub-message">업로드 가능 이미지 형식 : JPG(JPEG), PNG, GIF, BMP</span>`,
											btnClassName: "primary",
											alertBtnText: "확인"
										})
									}
								>
									이미지 업로드 실패 얼럿 팝업
								</button>

								<button className="btn btn-lg btn-outline-primary" onClick={handleOpenConfirm}>
									<i className="ico ico-tool-md-primary"></i>
									다시 변환하기
								</button>
								{isConfirmOpen && (
									<ModalConfirm
										isOpen={isConfirmOpen}
										title="다시 변환하시겠어요?"
										message="사용권 1장이 차감됩니다."
										onConfirm={handleConfirm}
										onCancel={handleCancel}
										confirmButtonText="변환"
										cancelButtonText="취소"
									/>
								)}
							</div>
						</div>
					</div>
				</Element>
			</div>
			<PopupLayout
				isOpen={isDefaultPopup}
				closePopup={closeDefaultPopup}
				title="팝업 타이틀"
				size="default"
				footerContent={
					// null //  버튼이 없을 경우
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-primary">
								사용자 정의 버튼
							</button>
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeDefaultPopup}>
								닫기
							</button>
						</div>
					</>
				}
			>
				<p>이곳에 원하는 팝업 내용을 넣으세요.</p>
			</PopupLayout>
			<PopupLayout
				isOpen={isSmPopup}
				closePopup={closeSmPopup}
				title="팝업 타이틀"
				size="sm"
				footerContent={
					// null //  버튼이 없을 경우
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-primary">
								사용자 정의 버튼
							</button>
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeSmPopup}>
								닫기
							</button>
						</div>
					</>
				}
			>
				<p>이곳에 원하는 팝업 내용을 넣으세요.</p>
			</PopupLayout>
			<PopupLayout
				isOpen={isMdPopup}
				closePopup={closeMdPopup}
				title="팝업 타이틀"
				size="md"
				footerContent={
					// null //  버튼이 없을 경우
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-primary">
								사용자 정의 버튼
							</button>
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeMdPopup}>
								닫기
							</button>
						</div>
					</>
				}
			>
				<p>이곳에 원하는 팝업 내용을 넣으세요.</p>
			</PopupLayout>
			<PopupSearch isOpen={popupSearch.isOpen} closePopup={popupSearch.close} />
			<PopupPrivacy isOpen={popupPrivacy.isOpen} closePopup={popupPrivacy.close} />
			<PopupOpenlic isOpen={popupOpenlic.isOpen} closePopup={popupOpenlic.close} />
			<PopupAgreement isOpen={popupAgreement.isOpen} closePopup={popupAgreement.close} />
			<PopupLogin isOpen={popupLoginCommon.isOpen} closePopup={popupLoginCommon.close} />;
			{toast3.showToast && (
				<ToastAlert
					message="변환이 완료되었습니다. 틀린 내용이 있는지 확인해주세요!"
					onClose={toast3.closeToast}
					icon={<i className="ico ico-notify-xs"></i>}
					position="top"
					theme="dark"
				/>
			)}
		</>
	);
}
