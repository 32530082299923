import axios from "axios";
import { API_URL2 } from "../url/URL";

const LoginWithGoogle = async (to) => {
	try {
		const encodedState = to ? encodeURIComponent(to) : "";
		const response = await axios.get(`${API_URL2}user/google/login`, {
			params: {
				state: encodedState
			}
		});

		const url = response.data;
		window.location.href = url;
	} catch (err) {
		console.error(err);
	}
};

export default LoginWithGoogle;
