import { IMGBRIGHTNESS_SAVE, IMGBRIGHTNESS_ADD2 } from "../url/URL_Brigthness";

const { default: api } = require("@/services/util/Interceptor");

export const brightnessStepTwo = async (data) => {
	try {
		const resp = await api.post(IMGBRIGHTNESS_ADD2, data);
		return resp;
	} catch (error) {
		return "Fail";
	}
};

export const brightnessSave = async (data) => {
	try {
		const resp = await api.post(IMGBRIGHTNESS_SAVE, data);
		return resp;
	} catch (error) {
		return "Fail";
	}
};
