import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { PROMOTION_LOG } from "../url/URL";
import { setMyLicense } from "@/services/store/action/LicenseAction";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const LicenseExamination = async (dispatch) => {
	try {
		const response = await api.get(PROMOTION_LOG);
		dispatch(setMyLicense(response.data.data));
		return response;
	} catch (error) {
		navigateTo("/error");
		return;
	}
};
