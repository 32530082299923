import { SeoSave } from "@/services/core/seo/SeoSave";
import { setSeoData, updateSeoMetaTag } from "@/services/store/action/seoAction";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function SeoMetaTitle({setIsChangeTag}) {
	const data = useSelector((state) => state.seo.seoData.data.resData.title);
	const choicedata = useSelector((state) => state.seo.seoData.data.choiceData.title);
	const seoData = useSelector((state) => state.seo.seoData);
	const [fristRender,setFristRender]=useState(true)
	const dispatch = useDispatch();

	const [selectedValue, setSelectedValue] = useState(choicedata.title||data[0].content);
	const [selectedTitle, setSelectedTitle] = useState(choicedata||data[0]);
	const handleRadioChange = (event) => {
		setSelectedValue(event.target.value);
		const data = {
			sotId: event.target.id.split("_")[1],
			title: event.target.value
		};
		setSelectedTitle(data);
	};
	useEffect(() => {
		const updatedSeoData = {
			...seoData,
			data: {
				...seoData.data,
				choiceData: {
					...seoData.data.choiceData,
					title: selectedTitle
				},
				isChangeTag: true
			}
		};
		
		if (fristRender){
			setFristRender(false)
		}else{

			setIsChangeTag(true)
		}
		
		dispatch(setSeoData(updatedSeoData));

	}, [selectedTitle]);

	useEffect(() => {

		setSelectedValue(choicedata.title||data[0].content);
		setSelectedTitle(choicedata||data[0]);

	}, [data]);
	return (
		<>
			<div className="title-wrap">
				<div className="wrap-left">
					<h4 className="title3">콘텐츠 제목 추천</h4>
					<span className="guide-text">브라우저 타이틀에 사용됩니다.</span>
				</div>
			</div>
			<div className="seowork-content">
				<ul className="list-metatitle">
					{data.map((item, index) => (
						<li key={index}>
							<span className="check-item-sm">
								<input
									type="radio"
									id={`radio_${item.sotId}`}
									name="metaTitle"
									value={item.content}
									checked={selectedValue === item.content}
									onChange={handleRadioChange}
								/>
								<label htmlFor={`radio_${item.sotId}`}>{item.content}</label>
							</span>
						</li>
					))}
				</ul>
			</div>
		</>
	);
}
