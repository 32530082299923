import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dropdown from "@/components/form/Dropdown";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import PopupLayer from "@/components/popup/PopupLayer";
import Toast from "@/components/toast/Toast";
import nodataImg from "@/assets/images/img-nodata@2x.png";
import previewImg from "@/assets/images/img-example1.png";
import DropdownWork from "@/services/core/workspace/DropdownWork";
import noImg from "@/assets/images/img-noimg.png"; // 이미지 없을 경우 노출되는 이미지

import TaskData from "@/mock/TaskData";
import WorkUsePopup from "./WorkSpacePopup";
import { WorkSpaceChangeName } from "./WorkSpaceChangeName";
import { TrashingIt } from "./TrashingIt";
import { DataCount } from "../mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "./DashBoard";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { TrashRestore } from "./TrashRestore";
import { fetchTrashCanData } from "@/services/store/action/TrashCanAction";
import { TrashDelete } from "./TreashDelete";
import { navigateTo } from "@/services/util/Navigate";
import InputTaskNameWork from "@/components/form/InputTaskNameWork";
import { showToast, updateToastKey } from "@/services/store/action/ToastAction";
import {
	setWorkActive,
	trashSetDesc,
	trashSetOrderBy,
	workSetDesc,
	workSetOrderBy
} from "@/services/store/action/SortOrderAction";
import { WorkSpaceDetail } from "./Workspace_repo";
import { setSeoData } from "@/services/store/action/seoAction";
import LoadingBar from "@/components/loading/LoadingBar";
import LoadingBarFull from "@/components/loading/LoadingBarFull";
import LoadingOverlay from "@/components/loading/LoadingOverlay";
import { handleWorkDetail } from "./WorkspaceDetail";
import { API_URL } from "../url/URL";
import numeral from "numeral";
import { fileSizeDisplay } from "@/services/util/FileSize";

export default function ListTaskCardWork({
	workItems: initialWorkItems,
	itemCount,
	onSelectedItemsCountChange,
	setOrderBy,
	setDesc,
	desc,
	orderBy,
	work,
	onWorkNameChangeSuccess,
	onSelectedItemsChange,
	trashItSuccess,
	onSelectAllCheckedChange,
	setSelectAllChecked,
	cardOrder,
	cardType
}) {
	const tableData = useSelector((state) => state.table?.tableData || []);
	const brightness = useSelector((state) => state);
	const workCardData = useSelector((state) => state.workCardSort || []);
	const trashCardData = useSelector((state) => state.trashCarSort || []);
	const cardDataRowNumber = useSelector((state) => state.workspace?.workSpaceData?.data?.[0]?.rownum - 1 || 0);

	const trashCardDataRowNumber = useSelector((state) => state.trash?.trashCanData?.data?.[0]?.rownum - 1 || 0);

	const html = useSelector((state) => state.html?.htmlData || []);
	useEffect(() => {
		setSelectAllChecked(false); // 신호가 변경될 때마다 실행
		setSelectedItems(new Set());
		const allRows = document.querySelectorAll("ul.card-list li");
		allRows.forEach((row) => {
			row.classList.remove("checked");
		});
		setWorkItems((prevCheckboxes) => prevCheckboxes.map((checkbox) => ({ ...checkbox, checked: false })));

		onSelectedItemsCountChange(0);
	}, [onSelectAllCheckedChange]);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	// 작업 리스트
	const [workItems, setWorkItems] = useState([]);
	const workOrderBy = useSelector((state) => state.workCardSort.orderBy);
	const trashOrderBy = useSelector((state) => state.trashCarSort.orderBy);
	const [selectedOption1, setSelectedOption1] = useState(cardOrder === "trash" ? trashOrderBy : workOrderBy);

	const [selectedOption2, setSelectedOption2] = useState(
		cardOrder === "trash" ? trashCardData.desc : workCardData.desc
	);

	const {
		isOpen: isDefaultPopup,
		open: openDefaultPopup,
		close: closeDefaultPopup,
		selectedId,
		selectedName
	} = WorkUsePopup();
	const [userWorkName, setWorkName] = useState("");

	/// 작업명 변경
	const handleChangeWorkName = async (selectedOption2) => {
		const resp = await WorkSpaceChangeName(selectedId, userWorkName);

		if (resp) {
			onWorkNameChangeSuccess(cardDataRowNumber, selectedOption2.value);
			closeDefaultPopup();
		} else {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};

	// 더보기 팝업
	const moreButtonRef = useRef([]);
	const [popupStates, setPopupStates] = useState({});
	const [showToastRestore, setShowToastRestore] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState(null);
	const [selectedItems, setSelectedItems] = useState(new Set());

	const handleTrashDelete = async (id) => {
		const resp = await TrashDelete(id);

		if (resp === "Success") {
			await refreshData(trashCardDataRowNumber);
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
		}

		if (resp === "Fail") {
			alert({ message: "영구 삭제를 실패하였습니다." });
			return;
		}
	};
	const refreshData = async (after, order, desc) => {
		const refreshParams = {
			after: after,
			count: 10,
			search: "",
			service_type: "",
			order_by: order,
			is_desc: desc
		};
		dispatch(fetchTrashCanData(refreshParams));
		const dataCountResult = await DataCount();
		dispatch(setDataCount(dataCountResult));
	};

	const handleToastRestore = async (id) => {
		const cardOrderby = trashCardData.orderBy;
		const cardDesc = trashCardData.desc;

		try {
			await TrashRestore(id);
			dispatch(showToast());
			dispatch(updateToastKey());
			await refreshData(trashCardDataRowNumber, cardOrderby, cardDesc);
		} catch (error) {
			alert({ message: "복원에 실패하였습니다." });
			return;
		}
	};
	const [options1, setOptions1] = useState(
		cardType === "TRASHPAGE"
			? [
					{ value: 1, label: "휴지통 이동일시" },
					{ value: 2, label: "작업명" },
					{ value: 3, label: "원본 파일 타입" },
					{ value: 4, label: "원본 파일 크기" },
					{ value: 5, label: "영구삭제 예정일시" }
			  ]
			: [
					{ value: 1, label: "마지막 수정 일시" },
					{ value: 2, label: "작업명" },
					{ value: 3, label: "원본 파일 타입" },
					{ value: 4, label: "원본 파일 크기" },
					{ value: 5, label: "생성일시" }
			  ]
	);
	const [option22, setOption22] = useState([
		[
			{ value: true, label: "최신순" },
			{ value: false, label: "오래된순" }
		],
		[
			{ value: true, label: "내림차순" },
			{ value: false, label: "오름차순" }
		],
		[],
		[
			{ value: true, label: "작은순" },
			{ value: false, label: "큰순" }
		],
		[
			{ value: true, label: "최신순" },
			{ value: false, label: "오래된순" }
		]
	]);

	const [options2, setOptions2] = useState(option22[orderBy - 1]);
	const [selectedOptions1, setSelectedOptions1] = useState(options1[orderBy - 1]);
	const [selectedOptions2, setSelectedOptions2] = useState(option22[orderBy - 1][0]);

	// [개발] options1에서 선택한 값에 따라 options2에서 노출되는 옵션이 달라져야 함
	// 마지막 수정 일시, 생성 일시 선택 시 : 최신순 / 오래된 순 옵션 노출
	// 작업명 선택 시 : 내림차순 / 오름차순 옵션 노출
	// 원본 파일 타입 선택 시 :  options2 Dropdown 전체 미노출
	// 원본 파일 크기 선택 시 : 작은 순 / 큰 순 노출

	const handleSelect1 = (option) => {
		if (cardOrder === "trash") {
			dispatch(trashSetDesc(true));
			dispatch(trashSetOrderBy(option.value));
		}

		if (cardOrder === "work") {
			dispatch(workSetDesc(true));
			dispatch(workSetOrderBy(option.value));
		}
		setSelectedOption1(option.value);
		setSelectedOptions1(option);

		setOrderBy(option.value);
		switch (option.value) {
			case 1:
			case 5:
				setOptions2([
					{ value: true, label: "최신순" },
					{ value: false, label: "오래된순" }
				]);
				break;
			case 2:
				setOptions2([
					{ value: true, label: "내림차순" },
					{ value: false, label: "오름차순" }
				]);

				break;
			case 3:
				setOptions2([]);
				break;
			case 4:
				setOptions2([
					{ value: true, label: "작은순" },
					{ value: false, label: "큰순" }
				]);

				break;
			default:
				setOptions2([]);
				break;
		}
	};
	const handleSelect2 = (option) => {
		if (cardOrder === "trash") {
			dispatch(trashSetDesc(option.value));
		}

		if (cardOrder === "work") {
			dispatch(workSetDesc(option.value));
		}

		setSelectedOption2(option.value);
		setSelectedOptions2(option);
	};

	// 리스트 로드 갯수
	useEffect(() => {
		setOrderBy(selectedOption1);
	}, [selectedOption1]);

	useEffect(() => {
		if (desc) {
			var descnum = 0;
		} else {
			var descnum = 1;
		}
		setSelectedOptions2(option22[orderBy - 1][descnum]);
	}, [options2]);

	useEffect(() => {
		if (selectedOption1) setDesc(selectedOption2);
	}, [selectedOption2]);
	// 리스트 로드 갯수
	useEffect(() => {
		setWorkItems(initialWorkItems.slice(0, itemCount)); // itemCount만큼 항목 로드
	}, [itemCount]);
	// 더보기 팝업
	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeMorePopup = (id) => {
		setPopupStates((prevStates) => ({
			...prevStates,
			[id]: false // 특정 작업 항목의 팝업을 닫음
		}));
		setActiveButtonId(null);
	};
	const toggleMorePopup = (id, event) => {
		event.stopPropagation();
		setPopupStates((prevStates) => {
			const newStates = Object.keys(prevStates).reduce((states, itemId) => {
				states[itemId] = false;
				return states;
			}, {});

			newStates[id] = !prevStates[id];
			return newStates;
		});

		setActiveButtonId((prevActiveId) => (prevActiveId === id ? null : id));
	};

	// 체크박스 변경 핸들러
	const handleCheckboxChange = (id, checked) => {
		setWorkItems((currentItems) => currentItems.map((item) => (item.id === id ? { ...item, checked } : item)));
		setWorkItems((currentItems) => {
			const updatedItems = currentItems.map((item) => (item.id === id ? { ...item, checked: checked } : item));

			const selectedIds = updatedItems.filter((item) => item.checked).map((item) => item.id);

			onSelectedItemsChange(new Set(selectedIds));
			onSelectedItemsCountChange(selectedIds.length);

			return updatedItems; // 업데이트된 상태로 설정
		});
	};

	// input 요소의 값이 변경될 때 호출
	const handleWorkNameChange = (event) => {
		setWorkName(event);
	};

	/// 휴지통 보내기
	const handleTrash = async (id, selectedOption2) => {
		const resp = await TrashingIt(id);
		if (resp) {
			trashItSuccess(cardDataRowNumber, selectedOption2);
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
		} else {
			navigateTo("/error");
		}
	};
	const navigate = useNavigate();
	const seoData = useSelector((state) => state.seo.seoData);

	const handleDetail = (workspaceType, workspaceId) => {
		if (workspaceType === "seo") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, seoData);
		} else if (workspaceType === "table") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, tableData);
		} else if (workspaceType === "img2html") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, html);
		} else if (workspaceType === "contrast") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, brightness);
		}

		//TODO 각각 타입에 따라서 처리함
	};

	const link = {
		// display: "flex",
		// "alignItems": "center"
	};

	return (
		<>
			{workItems.length > 0 ? (
				<>
					<div className="workitem-card-wrap">
						<div className="sort-area">
							<DropdownWork
								title="Dropdown"
								options={options2}
								selectedOptions={selectedOptions2}
								onSelect={handleSelect2}
								size="sm"
							/>
							<DropdownWork
								title="Dropdown"
								options={options1}
								selectedOptions={selectedOptions1}
								onSelect={handleSelect1}
								size="sm"
							/>
						</div>

						<ul className="card-list">
							{workItems.map((workitem, index) => (
								<li key={index} className={workitem.checked ? "checked" : ""}>
									<span className="check-item">
										<input
											type="checkbox"
											id={`select-chk${workitem.id}`}
											title={workitem.workName + " 선택"}
											checked={workitem.checked}
											onChange={(e) => handleCheckboxChange(workitem.id, e.target.checked)}
										/>
										<label htmlFor={`select-chk${workitem.id}`}>
											<span className="sr-only">선택</span>
										</label>
									</span>
									<div
										style={link}
										onClick={() => handleDetail(workitem.service.type, workitem.id)}
										tabIndex="0"
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												handleDetail(workitem.service.type, workitem.id);
											}
										}}
									>
										<div className="card-header">
											<h3 className="file-name">
												<span className="file-name">{workitem.workName}</span>
												{workitem.fileShared && (
													<span className="label-shared">
														<i className="ico ico-shared-sm"></i>
														<span className="sr-only">공개된 작업</span>
													</span>
												)}
											</h3>
										</div>
										<div className="card-body">
											<span className={`img ${!workitem.workspaceFilePath ? "no-img" : ""}`}>
												<img
													src={workitem.workspaceFilePath ? `${workitem.workspaceFilePath}` : noImg}
													alt=""
													onError={(e) => {
														e.target.src = noImg;
														e.target.parentElement.classList.add("no-img");
													}}
												/>
											</span>
										</div>
										<div className="card-footer">
											<span className="service-type">
												<i className={`ico ico-${workitem.service.type}-md`}></i>
												<span className="sr-only">{workitem.service.name}</span>
											</span>
											<span className="data-area">
												<span className="file-size">{fileSizeDisplay(workitem.fileSize)}</span>
												<span className="date-time">{workitem.createdDate}</span>
											</span>
										</div>
									</div>
									<div className="more-action-wrap">
										<span className={`more-action ${activeButtonId === workitem.id ? "on" : ""}`}>
											<button
												type="button"
												className="btn-action"
												onMouseDown={handleButtonMouseDown}
												onClick={(e) => toggleMorePopup(workitem.id, e)}
												ref={(el) => (moreButtonRef.current[workitem.id] = el)}
											>
												<i className="ico ico-more-action"></i>
												<span className="sr-only">더보기</span>
											</button>
										</span>
										{/* 더보기 레이어 팝업 */}
										<PopupLayer
											className="more-popup"
											isOpen={popupStates[workitem.id]}
											closePopup={() => closeMorePopup(workitem.id)}
											title="더보기 팝업"
											headerContent={null}
											footerContent={null}
											buttonRef={(el) => (moreButtonRef.current[workitem.id] = el)}
											position="top"
										>
											<div className="info-detail">
												<div className="btn-group">
													{work === "work" ? (
														<div>
															<button
																type="button"
																className="btn btn-md"
																onClick={(e) => {
																	openDefaultPopup(workitem.id, workitem.workName, e);
																	setWorkName(workitem.workName);
																}}
															>
																작업명 변경
															</button>
															<button
																type="button"
																className="btn btn-md"
																onClick={() =>
																	window.confirm({
																		title: "선택한 작업을 휴지통으로 이동하시겠어요?",
																		message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
																		content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
																		onConfirm: (result) => {
																			handleTrash(workitem.id, selectedOption2);
																			closeMorePopup();
																		},
																		confirmButtonText: "휴지통에 버리기",
																		cancelButtonText: "취소"
																	})
																}
															>
																이 작업 휴지통으로 이동
															</button>
														</div>
													) : (
														<div>
															<button
																className="btn btn-md btn-restore"
																onClick={() => handleToastRestore(workitem.id)}
															>
																복원
															</button>
															<button
																type="button"
																className="btn btn-md btn-permanent-del"
																onClick={() =>
																	window.confirm({
																		title: "이 작업을 영구 삭제할까요?",
																		message: "삭제 후 복구할 수 없습니다.",
																		onConfirm: (result) => {
																			handleTrashDelete(workitem.id);
																		},
																		confirmButtonText: "영구 삭제",
																		cancelButtonText: "취소",
																		buttonStatus: "invalid"
																	})
																}
															>
																이 작업 영구 삭제
															</button>
														</div>
													)}
												</div>
											</div>
										</PopupLayer>
									</div>
								</li>
							))}
						</ul>
					</div>
				</>
			) : (
				<div className="default-nodata">
					<div className="nodata-warp">
						<img src={nodataImg} alt="" />
						{work === "work" ? (
							<p>
								작업 공간이 준비되었습니다!<br></br>
								작업을 생성해주세요.
							</p>
						) : (
							<p>휴지통이 비어 있어요. </p>
						)}
					</div>
				</div>
			)}

			<PopupLayout
				isOpen={isDefaultPopup}
				closePopup={closeDefaultPopup}
				title="작업명 변경"
				size="default"
				className="change-work-name-popup"
				footerContent={
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeDefaultPopup}>
								취소
							</button>
							<button
								type="button"
								className="btn btn-xl btn-primary"
								onClick={() => handleChangeWorkName(selectedOptions2)}
							>
								변경
							</button>
						</div>
					</>
				}
			>
				<InputTaskNameWork
					workName={userWorkName}
					onworkNameChange={handleWorkNameChange}
					closeMorePopup={closeMorePopup}
				></InputTaskNameWork>
			</PopupLayout>
		</>
	);
}
