import axios from "axios";
import { getCookie } from "@/services/cookies/UserCookies";
import HttpRequest, { USER_GET_MYPAGE_ME } from "../url/URL";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "@/services/store/action/LoginAction";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

const MyPageGet = async (dispatch) => {
	try {
		const response = await api.get(USER_GET_MYPAGE_ME);
		dispatch(setUserData(response.data));
		return response.data;
	} catch (error) {
		navigateTo("/error");
	}
};

export default MyPageGet;
