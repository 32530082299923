import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ThumbnailImg1 from "@/assets/images/img-thumbnail1.png";
import ThumbnailImg2 from "@/assets/images/img-thumbnail2.png";
import { useSelector } from "react-redux";
import Toast from "@/components/toast/Toast";
import SeoMetaTitle from "./SeoMetaTitle";
import { useEditor } from "@tiptap/react";

export default function SeoMetaImage() {

	const imageData =useSelector((state) => state.seo.seoData.data.resData.sumnail);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");

	const downloadImage = (imageUrl, imageName) => {		
		setToastMessage(`${imageName} 파일을 다운로드 하였습니다.`)
		const link = document.createElement("a");
		link.href = imageUrl;
		link.download = imageName;
		link.click();
		setShowToast(true)
	};

	const handleToastClose = () => {
		setShowToast(false);
		
	};
	return (
		<>
		<a href="/public/seo/sumnail/seo_ec2612cf-4841-4375-8a3e-48a4b23b82cd.jpg"></a>
			<div className="title-wrap">
				<div className="wrap-left">
					<h4 className="title3">썸네일 이미지</h4>
					<span className="guide-text">현재 지원 사이즈: 1024px × 1024px</span>
				</div>
			</div>
			<div className="seowork-content">
				<div className="list-metaimg">
					{imageData.map((image) => (
						<div className="img-item" key={image.ssId}>
							<img src={image.imgPath} alt={image.alt} />
							<Link
								to=""
								className="btn btn-sm"
								onClick={() => downloadImage(image.imgPath, image.alt)}
								title="다운로드"
							>
								<i className="ico ico-download-white-sm"></i>
							</Link>
						</div>
					))}
				</div>

				<p className="comingsoon-text">
					추후 SNS 규격별 이미지를 다운로드 받을 수 있도록 준비중입니다. 조금만 기다려주세요.
				</p>
				{showToast && (
					<Toast
						message={toastMessage}						
						onClose={()=>{
							setShowToast(false);
						}}
						icon={<i className="ico ico-download-light-xs"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
			</div>
		</>
	);
}
