import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "@/services/store/action/LoginAction"; // 로그인 성공 액션 임포트
import PopupLayout from "@/components/popup/PopupLayout";
import LoginForm from "@/components/form/LoginForm";

const PopupLogin = ({ isOpen, closePopup, to, onLoginSuccess }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClosePopup = () => {
		console.log("Popup closed");
		closePopup();
	};

	// PopupLogin 컴포넌트
	const handleLoginSuccess = (userData) => {
		console.log("Login success", userData);
		handleClosePopup(); // 로그인 팝업 먼저 닫기

		if (onLoginSuccess) {
			onLoginSuccess(userData); // EventCodeAlert의 handleLoginSuccess 호출
		} else if (to) {
			navigate(to);
		} else {
			navigate("/dashboard");
		}
	};

	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={closePopup}
			title="로그인"
			size="sm"
			className="login-popup-page"
			footerContent={null}
		>
			<LoginForm className="login-popup-area" onClose={handleClosePopup} to={to} onLoginSuccess={handleLoginSuccess} />
		</PopupLayout>
	);
};

export default PopupLogin;
