import React, { useState, useEffect } from "react";

function InputTaskName({ workName, onworkNameChange }) {
	const [localworkName, setLocalworkName] = useState(workName);

	useEffect(() => {
		setLocalworkName(workName);
	}, [workName]);

	const handleInputChange = (event) => {
		const inputValue = event.target.value;
		if (inputValue.length <= 50) {
			setLocalworkName(inputValue);
			onworkNameChange(inputValue);
		}
	};

	return (
		<div className="inner">
			<input
				type="text"
				id="InputTaskName"
				value={localworkName}
				className="form-control"
				onChange={handleInputChange}
				placeholder="작업명을 입력해주세요."
				maxLength="50"
				title="작업명 변경"
			/>
			<span className="text-count">{localworkName.length}/50 자</span>
		</div>
	);
}

export default InputTaskName;
