import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SeoCreate } from "@/services/core/seo/SeoCreate";
import { useDispatch, useSelector } from "react-redux";
import { setSeoData } from "@/services/store/action/seoAction";
import { setUserData } from "@/services/store/action/LoginAction";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DataCount } from "../mypage/MyDataCount";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { DashBoard } from "../workspace/DashBoard";
import { getCookie } from "@/services/cookies/UserCookies";
import createMetaTag from "@/services/util/seoMeta";
import { isValidUrl } from "@/services/util/utils";

export const useHandleSeoCreate = (textAreaValue, urlInputValue) => {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const seoData = useSelector((state) => state.seo.seoData);
	const userData = useSelector((state) => state.user.userData);
	const allCount = userData?.data.count || null;
	const dispatch = useDispatch();


	const handleSeoCheck = async (isRebuild=false) => {
		// [개발] 로딩을 시뮬레이션하기 위한 지연시간입니다. => 수정 및 변경 필요

		if (!urlInputValue){
			if(isValidUrl(textAreaValue.trim())){
				alert({
					title:"콘텐츠 입력을 다시 확인해 주세요.",
					message:"링크는 링크 입력필드에 입력해야 합니다."
				})
				return false
			}
		}
		let title
		if (isRebuild){
			title = "다시 변환하시겠어요?"
		}else{
			title="SEO 메타태그를 생성하시겠어요?"
		}

		if (allCount > 0) {
			window.confirm({
				title: title,
				content: `사용권 1장이 차감됩니다.`,
				confirmButtonText: "생성",
				cancelButtonText: "취소",
				onConfirm: () => handleSeoGoCreate()
			});
		} else {
			window.confirm({
				title: "사용권이 없어요.",
				content: "새로운 사용권을 구매하거나 기간이 유효한 사용권코드를 입력해주세요.",
				confirmButtonText: "사용권 구매하기",
				cancelButtonText: "취소",
				onConfirm: () => handleBuyUsage()
			});
			setIsLoading(false);
		}

		// [개발] 사용권 사용컴펌 팝업 노출
	};
	const handleBuyUsage = () => {
		setIsLoading(false);
		navigate("/setting/license");
	};
	const handleSeoGoCreate = async () => {
		setIsLoading(true);
		var url = "";
		var html = "";
		var data=""

		if (!urlInputValue) {
			html = textAreaValue;
			data = {
				html: textAreaValue
			};
		} else if (!textAreaValue) {
			url = urlInputValue;
			data = {
				url: urlInputValue
			};
		}
		if (data==""){
			data ={
				html : seoData.data.html
			}
		}
		const result = await SeoCreate(data);
		try {
			if (result.status === "Success") {
				const keywords = result.data.data.keyword.map((item) => ({ skid: item.skId, keyword: item.text }));
				var keyword = "";
				const skIds = [];
				
				result.data.data.keyword.forEach((key, index, array) => {
					
					// 배열의 마지막 요소인 경우의 처리
					if (index === array.length - 1) {
						// 여기서 원하는 작업을 수행하세요.
						// 예: 마지막 요소임을 확인하거나, 마지막에 특별한 처리를 하고 싶은 경우
						keyword += `${key.text}`;
					} else {
						keyword += `${key.text}, `;
					}
				});
				const metaTag=createMetaTag(result.data.data.title[0].content,result.data.data.description.text,keyword,url,result.data.data.siteName)
				const updatedSeoData = {
					...seoData,
					data: {
						...seoData.data,
						url: url,
						html: html,
						resData: result.data.data,
						choiceData: {
							description: result.data.data.description,
							title: { title: result.data.data.title[0].content, sotId: result.data.data.title[0].sotId },
							keywords: keywords,
							sumnail: {
								ssId: ""
							}
						},
						isChangeTag: true,
						metaTag:metaTag
					}
				};



				dispatch(setSeoData(updatedSeoData));

				const updatedUserData = {
					...userData,
					data: {
						...userData.data,
						count: userData.data.count - 1
					}
				};

				dispatch(setUserData(updatedUserData));
				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));
			
				const workId = result.data.data.workspaceId;
				setIsLoading(false);
		
				navigate(`/tool/seo/work-detail/${encodeURIComponent(workId)}`);
			}else if (result.status === "ERR_CANCELED"){
				
				
			}else {
				setIsLoading(false);
				window.alert({
					title: "데이터를 다시 입력해주세요.",
					content: `<p class="mb-2">메타태그 생성이 어려운 데이터 입니다.</p><p>콘텐츠가 포함되어 있는 텍스트 기반의 데이터를 입력해주세요.</p>`
				});
			}
		} catch(error) {
			console.error(error)
			setIsLoading(false);
			window.alert({
				title: "데이터를 다시 입력해주세요.",
				content: `<p class="mb-2">메타태그 생성이 어려운 데이터 입니다.</p><p>콘텐츠가 포함되어 있는 텍스트 기반의 데이터를 입력해주세요.</p>`
			});
		}
	};

	return {
		isLoading,
		handleSeoCheck
	};
};
