// PROMOTION_CODE

// import { getCookie } from "@/services/cookies/UserCookies";
// import axios from "axios";
import { PROMOTION_CODE } from "../url/URL";
import api from "@/services/util/Interceptor";

export const LicenseRegistration = async (inputcode) => {
	try {
		const response = await api.get(PROMOTION_CODE, {
			params: {
				code: inputcode
			}
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			if (status === 400) {
				if (data.message === "잘못된 프로모션 코드") {
					return 1;
				}
				if (data.message === "프로모션 코드 한도 초과") {
					return 1;
				}
				if (data.message === "만료된 프로모션 코드") {
					return 2;
				}
				if (data.message === "준비 중인 프로모션 코드") {
					return 2;
				}
				if (data.message === "이미 한번 등록한 코드") {
					return 1;
				}
				return 1;
			}
			return 1;
		}
		return;
	}
};
