import React from "react";

const ArticleItem = ({ article, handleTagFilter, handleToolFilter, tags, showInfo = true, size = "md" }) => {
	const maskName = (name) => {
		if (!name || name.trim().length === 0) {
			return "익명의 앨리스";
		}
		if (name.length > 1) {
			return name[0] + "*".repeat(name.length - 1);
		}
		return name;
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const now = new Date();
		const diff = now - date;

		const seconds = Math.floor(diff / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		const weeks = Math.floor(days / 7);
		const months = Math.floor(days / 30);
		const years = Math.floor(days / 365);

		if (seconds < 60) {
			return "방금 전";
		} else if (minutes < 60) {
			return `${minutes}분 전`;
		} else if (hours < 24) {
			return `${hours}시간 전`;
		} else if (days < 2) {
			return `어제`;
		} else if (days < 7) {
			return `${days}일 전`;
		} else if (weeks < 5) {
			return `${weeks}주 전`;
		} else if (months < 12) {
			return `${months}개월 전`;
		} else {
			return `${years}년 전`;
		}
	};

	const getToolIcon = (toolKeyword) => {
		const allyReviewTag = tags.find((tag) => tag.text === "앨리후기");
		const tool = allyReviewTag.tools.find((tool) => tool.keyword === toolKeyword);
		return tool ? <i className={`ico ico-${tool.keyword}-md`}></i> : null;
	};

	const getToolText = (toolKeyword) => {
		const allyReviewTag = tags.find((tag) => tag.text === "앨리후기");
		const tool = allyReviewTag.tools.find((tool) => tool.keyword === toolKeyword);
		return tool ? tool.text : toolKeyword;
	};

	const getTagEmoji = (tag) => {
		const matchedTag = tags.find((t) => t.text === tag);
		return matchedTag ? matchedTag.emoji : "🔖";
	};

	const toolKeywordClass = article.tool ? `tool-${article.tool}` : "";
	const sizeClass = `article-item-${size}`;

	return (
		<dl className={`article-item ${toolKeywordClass} ${sizeClass}`}>
			<dt>
				<div className="emoji">{getTagEmoji(article.tags[0])}</div>
				<span className="name">{maskName(article.name)}</span>
				<span className="date">{formatDate(article.date)}</span>
			</dt>
			<dd className="content">{article.content}</dd>
			{showInfo && (
				<dd className="info">
					<ul className="tag-list">
						{article.tags.map((tag, tagIndex) => (
							<li key={tagIndex}>
								<button onClick={() => handleTagFilter(tag)}>#{tag}</button>
							</li>
						))}
					</ul>
					{article.tool && (
						<button className="tool-name" onClick={() => handleToolFilter(article.tool)}>
							{getToolIcon(article.tool)} {getToolText(article.tool)}
						</button>
					)}
				</dd>
			)}
		</dl>
	);
};

export default ArticleItem;
