import React, { useEffect, useRef, forwardRef } from "react";

const PopupLayer = forwardRef(
	(
		{
			className,
			isOpen,
			closePopup,
			title,
			children,
			headerContent,
			footerContent,
			returnFocusRef,
			buttonRef,
			position
		},
		ref
	) => {
		const popupRef = useRef(null);

		useEffect(() => {
			if (isOpen && buttonRef && buttonRef.current && popupRef.current) {
				const buttonRect = buttonRef.current.getBoundingClientRect();
				const popupStyle = popupRef.current.style;

				switch (position) {
					case "right":
						popupStyle.top = `${buttonRect.top}px`;
						popupStyle.left = `${buttonRect.right}px`;
						break;
					case "left":
						popupStyle.top = `${buttonRect.top}px`;
						popupStyle.right = `${window.innerWidth - buttonRect.left}px`;
						break;
					case "top":
						popupStyle.left = `${buttonRect.left}px`;
						popupStyle.bottom = `${window.innerHeight - buttonRect.top}px`;
						break;
					case "bottom":
						popupStyle.top = `${buttonRect.bottom}px`;
						popupStyle.left = `${buttonRect.left}px`;
						break;
					default:
						break;
				}
			}
		}, [isOpen, buttonRef, position]);

		useEffect(() => {
			const handleClickOutside = (event) => {
				if (popupRef.current && !popupRef.current.contains(event.target)) {
					closePopup();
				}
			};

			if (isOpen) {
				document.addEventListener("mousedown", handleClickOutside);
			}
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [isOpen, closePopup]);

		useEffect(() => {
			if (isOpen) {
				// 포커스 기능
				setTimeout(() => {
					const firstFocusableElement = popupRef.current.querySelector(
						"button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
					);
					firstFocusableElement?.focus();
				}, 0);
			} else if (returnFocusRef && returnFocusRef.current) {
				returnFocusRef.current.focus();
			}
		}, [isOpen, returnFocusRef]);

		const handleKeyDown = (event) => {
			if (event.key === "Tab") {
				const focusableElements = popupRef.current.querySelectorAll(
					"button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
				);
				const firstElement = focusableElements[0];
				const lastElement = focusableElements[focusableElements.length - 1];

				if (!event.shiftKey && document.activeElement === lastElement) {
					event.preventDefault(); // 먼저 기본 동작을 방지합니다.

					// 팝업을 닫습니다.
					closePopup();

					// setTimeout을 사용하여 팝업 닫힘 처리가 완료된 후에 포커스를 이동합니다.
					setTimeout(() => {
						// 버튼 뒤의 다음 포커스 가능한 요소를 찾아 포커스를 이동시킵니다.
						const allFocusableElements = document.querySelectorAll(
							"button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
						);
						const elementsArray = Array.prototype.slice.call(allFocusableElements);
						const currentButtonIndex = elementsArray.indexOf(buttonRef.current);
						const nextFocusableElement = elementsArray[currentButtonIndex + 1];

						// 다음 포커스 가능한 요소가 있으면 해당 요소에 포커스를 설정합니다.
						if (nextFocusableElement) {
							nextFocusableElement.focus();
						}
					}, 0);
				} else if (event.shiftKey && document.activeElement === firstElement) {
					// 첫 번째 요소에서 Shift+Tab을 누르면 포커스를 마지막 요소로 이동
					lastElement.focus();
					event.preventDefault();
				}
			}
		};
		const popupStyle = {};

		if (!isOpen) return null;

		return (
			<div
				className={`layer-popup ${className}`}
				id="layer-popup"
				tabIndex="-1"
				ref={popupRef}
				onKeyDown={handleKeyDown}
				style={popupStyle}
			>
				<div className="popup-inner">
					{headerContent && (
						<div className="popup-header">
							<h2 className="popup-title">{title}</h2>
							<button onClick={closePopup}>닫기</button>
						</div>
					)}
					<div className="popup-body">{children}</div>
					{footerContent && (
						<div className="popup-footer">
							<div className="btn-group">{footerContent}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
);

export default PopupLayer;
