import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PopupLayout from "@/components/popup/PopupLayout";

import TaskData from "@/mock/TaskData";
import { useDispatch, useSelector } from "react-redux";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import LoadingBar from "@/components/loading/LoadingBar";
import { formatDate } from "@/services/util/TransForm";
import { MyTrashCan } from "@/services/core/workspace/MyTrashCan";
import { SidebarSearchCount } from "@/services/util/const";
import { handleWorkDetail } from "@/services/core/workspace/WorkspaceDetail";
import { debounce } from "@/services/util/Debouns";

const PopupSearch = ({ isOpen, closePopup, extraValue, search = "", setSearch = "" }) => {
	// 상태 및 함수 정의...
	const [isLoading, setIsLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState(search);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);

	const [searchResults, setSearchResults] = useState([]);
	const [searchPerformed, setSearchPerformed] = useState(false);
	const listResultRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		const timerId = setTimeout(() => {
			debounce(searchTerm);
			fetchSearchData(searchTerm);
		}, 300);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchTerm]);

	const handleSearchInputChange = (event) => {
		setSearchPerformed(false);
		const value = event.target.value;
		setSearchTerm(value);
	};

	const seoData = useSelector((state) => state.seo.seoData);
	const tableData = useSelector((state) => state.table?.tableData || []);
	const brightness = useSelector((state) => state);

	const html = useSelector((state) => state.html?.htmlData || []);

	// 검색 : 필터 '작업' 검색시  예시 데이터 검색가능
	const handleSearch = async (event) => {
		event.preventDefault();
		fetchSearchData(searchTerm);
	};

	const fetchSearchData = async () => {
		if ((searchTerm.trim() !== "") | searchTerm.trim()) {
			setSearchPerformed(true);
			setIsLoading(true);

			let data;
			try {
				if (extraValue === "Trash") {
					data = await MyTrashCan(null, SidebarSearchCount, searchTerm, null, 1, true);
				} else {
					data = await DashBoard(null, SidebarSearchCount, searchTerm, null, 1, true);
				}

				const typeTransformations = {
					img_html: "img2html",
					img_improve: "contrast",
					web_standard: "table"
				};
				const transformedData = data.data
					.map((item) => {
						const transformedType = typeTransformations[item.workspaceType] || item.workspaceType;
						return {
							id: item.workspaceId,
							service: {
								name: transformedType,
								type: transformedType
							},
							workName: item.workspaceName,
							fileShared: false,
							fileType: item.workspaceFileType,
							fileSize: item.workspaceFileSpace, // workspaceFileSpace 사용 또는 기본값 할당
							lastModified: formatDate(item.updateAt || item.createAt), // 임시 값
							createdDate: formatDate(item.createAt), // 임시 값
							delete: false
						};
					})
					.filter(Boolean);

				const results = transformedData.filter((item) =>
					item.workName.toLowerCase().includes(searchTerm.toLowerCase())
				);
				setSearchResults(results);
			} catch (error) {
				setSearchResults([]);
			} finally {
				setIsLoading(false);
			}
		} else {
			setSearchPerformed(false);
		}
	};
	const handleClosePopup = () => {
		setSearchResults([]);
		setSearchPerformed(false);
		setSearchTerm("");
		closePopup();
	};
	useEffect(() => {
		setSearchTerm(search);
	}, [search]);
	useEffect(() => {
		if (isOpen) {
			if (search) {
				fetchSearchData();
			}
		} else {
			if (setSearch !== "") {
				setSearch("");
			}
		}
	}, [isOpen]);

	const handleDetail = async (workspaceType, workspaceId) => {
		try {
			let data;
			setIsLoading(true);
			<LoadingBar />;
			switch (workspaceType) {
				case "seo":
					data = await handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, seoData);
					break;
				case "table":
					data = await handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, tableData);
					break;
				case "img2html":
					data = await handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, html);
					break;
				case "contrast":
					data = await handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, brightness);
					break;
				default:
					throw new Error("서비스 4종 조회 실패");
			}
			setIsLoading(false);
			closePopup();
		} catch (error) {
			console.error("서비스 4종 조회 실패", error);
			alert({ content: "잠시 후 다시 시도해주세요." });
			return;
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" || event.keyCode === 13) {
			fetchSearchData(searchTerm);
			event.preventDefault();
		}
	};
	const link = {
		display: "flex",
		alignItems: "center"
	};
	return (
		<PopupLayout
			className="search-popup"
			isOpen={isOpen}
			closePopup={handleClosePopup}
			title="통합검색"
			size="md"
			footerContent={null}
		>
			<div className="search-bar">
				<form action="#">
					<input
						type="text"
						className="form-control-md"
						placeholder="작업명을 검색해주세요."
						title="작업명을 검색해주세요."
						value={searchTerm}
						onChange={handleSearchInputChange}
						onKeyDown={handleKeyDown}
					/>
					<button type="submit" className="btn-search" onClick={handleSearch}>
						<i className="ico ico-search-lg"></i>
						<span className="sr-only">검색</span>
					</button>
				</form>
			</div>
			<div className="search-result">
				{isLoading ? (
					<div className="loading-area">
						<br />
						<br />
						<br />
						<LoadingBar />
					</div>
				) : searchPerformed ? (
					searchResults.length > 0 ? (
						<>
							<div className="result-data">
								<div className="result-header">
									<h2 className="title3">작업</h2>
									<span className="guide-text">마지막 수정 일시 순으로 나열됩니다.</span>
								</div>
								<ul className="list-result">
									{searchResults.map((result, index) => (
										<li key={index}>
											<Link style={link} onClick={() => handleDetail(result.service.type, result.id)}>
												<span className="service-type">
													<i className={`ico ico-${result.service.type}-md`}></i>
													<span className="sr-only">{result.service.name}</span>
												</span>
												<h3 className="wrok-name">
													<span className="file-name">{result.workName}</span>
													{result.fileShared && (
														<span className="label-shared">
															<i className="ico ico-shared-md"></i>
															<span>공개된 작업</span>
														</span>
													)}
												</h3>

												<span className="date-time">{result.createdDate}</span>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</>
					) : (
						<p className="result-nodata">
							<span className="nodata-warp">
								<span className="search-data">“{searchTerm}”</span>
								<span>에 대한 결과가 없습니다.</span>
							</span>
						</p>
					)
				) : (
					<p className="default-nodata">
						<span className="nodata-warp">작업을 빠르게 검색해보세요.</span>
					</p>
				)}
			</div>
		</PopupLayout>
	);
};

export default PopupSearch;
