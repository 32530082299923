function Timer({ timeLeft }) {
	const formatTimeLeft = () => {
		const minutes = Math.floor(timeLeft / 60);
		const seconds = timeLeft % 60;
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};

	return <span>유효시간 {formatTimeLeft()}</span>;
}

export default Timer;
