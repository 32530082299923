// src/services/store/reducer/UserReducer.js
import { RESET_STATE } from "../action/ResetAction";

// 레벨 매칭 테이블
export const levelMappings = {
	1: { levelName: "새싹", symbol: "🌱", message: "새싹이 트기 시작했어요!" },
	2: { levelName: "클로버", symbol: "☘️", message: "행복을 상징하는 클로버가 자라났어요." },
	3: { levelName: "네잎클로버", symbol: "🍀", message: "행운의 네잎클로버가 나타났어요!" },
	4: { levelName: "잎사귀", symbol: "🍃", message: "잎사귀가 푸르게 돋아났어요." },
	5: { levelName: "풀잎", symbol: "🌿", message: "풀잎이 무성해지고 있어요!" },
	6: { levelName: "작은나무", symbol: "🌲", message: "작은 나무로 자라났어요!" },
	7: { levelName: "튼튼한나무", symbol: "🌳", message: "더욱 커다란 나무가 되었어요!" },
	8: { levelName: "분홍꽃", symbol: "🌺", message: "아름다운 꽃이 활짝 피었네요!" },
	9: { levelName: "단풍잎", symbol: "🍁", message: "단풍잎이 빨갛게 물들었어요!" },
	10: { levelName: "산", symbol: "🏔️", message: "웅장한 산이 형성되었어요!" }
};

// 기본 사용자 상태
const defaultUserStatus = { type: "일반회원", level: 1 };

// 사용자 유형 조건 배열
const conditions = [
	{
		type: "일반회원",
		condition: (licenseTitles) => licenseTitles.length === 0,
		level: 1
	},
	{
		type: "베타테스터",
		condition: (licenseTitles) => licenseTitles.includes("2024 앨리 베타테스터 모집 프로모션"),
		level: 2
	},
	{
		type: "유료회원",
		condition: (licenseTitles) => licenseTitles.includes("유료 라이선스 타이틀 예시"),
		level: 5
	},
	{
		type: "관리자",
		condition: (licenseTitles) => licenseTitles.includes("관리자 라이선스 타이틀 예시"),
		level: 10
	}
	// 필요한 경우 다른 조건 추가
];

const initialState = {
	isAuthenticated: false, // 로그인 여부 상태 추가
	userData: {
		...defaultUserStatus,
		...levelMappings[defaultUserStatus.level]
		// ... 기타 사용자 정보
	},
	myLicense: {
		data: []
	}
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return {
				...state,
				isAuthenticated: true,
				userData: {
					...state.userData,
					...action.payload
				}
			};
		case "LOGOUT":
			return {
				...state,
				isAuthenticated: false,
				userData: {
					...defaultUserStatus,
					...levelMappings[defaultUserStatus.level]
				}
			};
		case "SET_USER_DATA":
			const userStatus = checkUserType(action.payload.myLicense?.data || state.myLicense.data || []);
			return {
				...state,
				userData: {
					...state.userData,
					...action.payload,
					...userStatus
				},
				myLicense: {
					...state.myLicense,
					data: action.payload.myLicense?.data || [] // 기본값 설정
				}
			};
		case "SET_MY_LICENSE":
			const updatedLicenseData = action.payload.append
				? [action.payload.newLicenseData, ...state.myLicense.data]
				: Array.isArray(action.payload.newLicenseData)
				? action.payload.newLicenseData
				: [action.payload.newLicenseData];

			const newUserStatus = checkUserType(updatedLicenseData);

			return {
				...state,
				myLicense: {
					...state.myLicense,
					data: updatedLicenseData
				},
				userData: {
					...state.userData,
					...newUserStatus
				}
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

// 헬퍼 함수: 사용자 유형 및 레벨을 확인하는 함수
const checkUserType = (licenseData = []) => {
	const oneYearAgo = new Date();
	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

	const recentLicenseTitles = licenseData
		.filter((item) => new Date(item.createAt) >= oneYearAgo)
		.map((item) => item.title);

	for (const condition of conditions) {
		if (condition.condition(recentLicenseTitles)) {
			const level = condition.level || defaultUserStatus.level;
			const { levelName, symbol, message } = levelMappings[level];
			return { type: condition.type, level, levelName, symbol, message };
		}
	}

	const { level, type } = defaultUserStatus;
	const { levelName, symbol, message } = levelMappings[level];
	return { type, level, levelName, symbol, message };
};

export default userReducer;
