import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { SEO_CREATE } from "../url/URL_Seo";
import api from "@/services/util/Interceptor";

export const SeoCreate = async (data) => {
	const token = getCookie("token");
	try {
		const response = await api.post(SEO_CREATE, data, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		
		if (error.code=="ERR_CANCELED"){
			return {
				status: "ERR_CANCELED"
				
			};
		}else{
			return null;
		}

		
	}
};
