// import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "@/assets/images/logo@2x.png";
// import Error from "@/assets/images/ico-error@2x.png";

export default function ErrorPage() {
	return (
		<>
			<div className="error-page">
				<div className="error-inner">
					<h1 className="logo">
						<Link to="/">
							<img src={Logo} alt="A11Y" />
						</Link>
					</h1>
					<div className="error-area">
						{/* <img src={Error} alt="Error" /> */}
						<p className="message">잠시만 기다려주세요.</p>
						<p className="desc">곧 호출한 페이지로 이동합니다.</p>
						{/* <div className="btn-group">
							<Link to="/" className="btn btn-xl btn-outline-gray pl-6 pr-6">
								이전으로
							</Link>
							<Link to="/" className="btn btn-xl btn-primary pl-6 pr-6">
								메인으로
							</Link>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}
