import LoadingBar from "@/components/loading/LoadingBar";
import { Link } from "react-router-dom";
import Logo from "@/assets/images/logo@2x.png";
import Error from "@/assets/images/ico-error@2x.png";

export default function LoadingPage() {
	return (
		<>
			<div className="error-page">
				<div className="error-inner">
					<h1 className="logo">
						<Link to="/">
							<img src={Logo} alt="A11Y beta" />
						</Link>
					</h1>
					<LoadingBar />
				</div>
			</div>
		</>
	);
}
