import pretty from 'pretty';
const createMetaTag = (title,description,keywords,url,sitename)=> {
let html
if (sitename===null){
     html = 
`<meta charset="UTF-8">
<meta name="robots" content="index, follow">

<!-- Primary Meta Tags -->
<title>${title} | <!--// TODO : 사이트명을 입력해주세요.--></title> 
<meta name="description" content="${description}">
<meta name="keywords" content="${keywords}">

<!-- Open Graph / Facebook, KaKaoTalk -->
<meta property="og:url" content="${url}">
<meta property="og:title" content="${title} | <!--// TODO : 사이트명을 입력해주세요.-->">
<meta property="og:description" content="${description}">
<meta property="og:type" content="website"><!-- // TODO : 웹사이트 콘텐츠 성격에 맞는 Open Graph type을 선택하세요. 기본은 website, 보다 구체적 카테고리는 article, video.movie, music 등을 사용합니다. 커스텀 type이 있으면 그것을 사용해도 좋습니다. -->
<meta property="og:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 --> 

<!-- Twitter -->
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="${title} | <!--// TODO : 사이트명을 입력해주세요.-->">
<meta name="twitter:description" content="${description}">
<meta name="twitter:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 -->`
}else{
     html = 
    `<meta charset="UTF-8">
    <meta name="robots" content="index, follow">
    
    <!-- Primary Meta Tags -->
    <title>${title} | ${sitename}</title>
    <meta name="description" content="${description}">
    <meta name="keywords" content="${keywords}">
    
    <!-- Open Graph / Facebook, KaKaoTalk -->
    <meta property="og:url" content="${url}">
    <meta property="og:title" content="${title} | ${sitename}">
    <meta property="og:description" content="${description}">
    <meta property="og:type" content="website"><!-- // TODO : 웹사이트 콘텐츠 성격에 맞는 Open Graph type을 선택하세요. 기본은 website, 보다 구체적 카테고리는 article, video.movie, music 등을 사용합니다. 커스텀 type이 있으면 그것을 사용해도 좋습니다. -->
    <meta property="og:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 --> 
    
    <!-- Twitter -->
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content="${title} | ${sitename}">
    <meta name="twitter:description" content="${description}">
    <meta name="twitter:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 -->`
}


html=pretty(html, {ocd: true})
return html

}
export default createMetaTag