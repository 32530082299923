import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (name, value, Option) => {
	return cookies.set(name, value, { ...Option });
};

export const getCookie = (name) => {
	return cookies.get(name);
};

export const removeCookie = (name, Option) => {
	
	
	return cookies.remove(name, {...Option});
};
