import {
	BrightnessHeight,
	BrightnessMB,
	BrightnessWidth,
	Img2Height,
	Img2MB,
	Img2Width,
	ImgDefaultHeight,
	ImgDefaultMB,
	ImgDefaultWidth,
	SeoMB,
	SeoWidth,
	SeoHeight
} from "@/services/util/const";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({
	showDeleteButton,
	hideGuideText,
	onFileUploaded,
	onFileRemoved,
	service,
	disabled,
	textAreaValue
}) => {
	const [acceptedFiles, setAcceptedFiles] = useState([]);
	const [showGuideText, setShowGuideText] = useState(true);

	const config = {
		default: {
			maxSizeInMB: ImgDefaultMB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: ImgDefaultWidth, height: ImgDefaultHeight }
		},
		IMG2: {
			maxSizeInMB: Img2MB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: Img2Width, height: Img2Height }
		},
		brightness: {
			maxSizeInMB: BrightnessMB,
			acceptedFormats: [".jpg", ".jpeg", ".png", ".gif", ".bmp"],
			maxDimensions: { width: BrightnessWidth, height: BrightnessHeight }
		},
		SEO: {
			maxSizeInMB: SeoMB,
			acceptedFormats: [".pdf"],
			maxDimensions: null // PDF는 dimensions 체크 안함
		}
	};

	const guideMessages = {
		default: {
			messages: [
				"Ctrl + V로 클립보드 이미지를 붙여넣으세요.",
				"이 영역에 마우스로 끌어 올리거나 파일을 직접 선택할 수도 있습니다.",
				"최대 20MB 이하 크기의 이미지 파일 등록 가능"
			]
		},
		SEO: {
			messages: [
				"PDF 파일을 업로드해주세요.",
				"이 영역에 마우스로 끌어 올리거나 파일을 직접 선택할 수도 있습니다.",
				"최대 20MB 이하 크기의 PDF 파일 등록 가능"
			]
		}
	};

	const { maxSizeInMB, acceptedFormats, maxDimensions } = config[service] || config["default"];

	useEffect(() => {}, [acceptedFiles]);

	const handlePaste = useCallback((event) => {
		const items = event.clipboardData.items;
		for (let i = 0; i < items.length; i++) {
			if (items[i].type.indexOf("image") !== -1) {
				const blob = items[i].getAsFile();
				handleFile(blob);
				event.preventDefault();
				break;
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("paste", handlePaste);
		return () => {
			window.removeEventListener("paste", handlePaste);
		};
	}, [handlePaste]);

	const handleFile = useCallback(
		(file) => {
			const fileSizeInMB = file.size / (1024 * 1024);
			if (fileSizeInMB > maxSizeInMB) {
				alertInvalidFile();
				return;
			}

			const isValidFileType = acceptedFormats.some((ext) => file.name.toLowerCase().endsWith(ext));
			if (!isValidFileType) {
				alertInvalidFile();
				return;
			}

			// PDF 파일인 경우
			if (file.type === "application/pdf") {
				setAcceptedFiles([file]);
				if (hideGuideText) {
					setShowGuideText(false);
				}
				if (onFileUploaded) {
					onFileUploaded(file);
				}
				return;
			}

			// 이미지 파일인 경우 기존 로직 유지
			const reader = new FileReader();
			reader.onload = (e) => {
				const img = new Image();
				img.onload = () => {
					const { width, height } = img;
					if (width <= maxDimensions.width && height <= maxDimensions.height) {
						const fileSizeInMB = file.size / (1024 * 1024);
						if (fileSizeInMB <= maxSizeInMB) {
							const isValidFileType = acceptedFormats.some((ext) =>
								file.name ? file.name.toLowerCase().endsWith(ext) : file.type.startsWith("image/")
							);
							if (isValidFileType) {
								setAcceptedFiles([file]);
								if (hideGuideText) {
									setShowGuideText(false);
								}
								if (onFileUploaded) {
									onFileUploaded(file);
								}
							} else {
								alertInvalidFile();
							}
						} else {
							alertInvalidFile();
						}
					} else {
						alertInvalidFile();
					}
				};
				img.onerror = () => {
					console.error("이미지 에러");
				};
				img.src = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		[maxDimensions, maxSizeInMB, acceptedFormats, hideGuideText, onFileUploaded]
	);

	const onDrop = useCallback(
		(files) => {
			if (files[0]) {
				handleFile(files[0]);
			}
		},
		[handleFile]
	);

	const alertInvalidFile = () => {
		window.alert({
			title: "이미지 업로드 실패했어요.",
			content: `<p>업로드 한 이미지 파일 형식이 지원되는지,<br> 파일 크기가 너무 크지 않은지 확인해 주세요.</p>
						<span class="sub-message">업로드 가능 이미지 형식 : JPG(JPEG), PNG, GIF, BMP</span>`,
			btnClassName: "primary",
			alertBtnText: "확인"
		});
	};

	const removeFile = () => {
		setAcceptedFiles([]);
		setShowGuideText(true);

		if (onFileRemoved) {
			onFileRemoved();
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept:
			service === "SEO"
				? { "application/pdf": [".pdf"] }
				: {
						"image/jpeg": [".jpeg", ".jpg"],
						"image/png": [".png"],
						"image/gif": [".gif"],
						"image/bmp": [".bmp"]
				  },
		noClick: true
	});

	const handleButtonClick = () => {
		document.querySelector('input[type="file"]').click();
	};

	const guideText =
		service === "SEO"
			? "PDF 파일을 이 영역에 드래그하거나 파일을 직접 선택하세요."
			: "Ctrl + V로 클립보드 이미지를 붙여넣으세요.";

	return (
		<div className={`file-upload ${disabled ? "disabled" : ""}`}>
			{acceptedFiles.map((file, index) => (
				<div key={index} className="file-item">
					<div className="preview">
						{file.type === "application/pdf" ? (
							<div className="pdf-preview">
								{/* <i className="ico ico-pdf"></i> */}
								<div className="pdf-info">
									<span className="filename">{file.name}</span>
									{textAreaValue && <p className="preview-text">{textAreaValue.slice(0, 100)}...</p>}
								</div>
							</div>
						) : (
							<img src={URL.createObjectURL(file)} alt={file.name} className="preview-img" />
						)}
					</div>
					{showDeleteButton && (
						<button type="button" className="btn-delete" onClick={removeFile}>
							<i className="ico ico-cancel-md"></i>
							<span className="sr-only">삭제</span>
						</button>
					)}
				</div>
			))}
			<div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""} ${disabled ? "disabled" : ""}`}>
				<input {...getInputProps()} />
				<div className="upload-area">
					{showGuideText && (
						<div className="guide-text">
							<p className="text-md">
								{(service && guideMessages[service]?.messages[0]) || guideMessages.default.messages[0]}
							</p>
							<p className="text-md mt-1">
								이 영역에 마우스로 끌어 올리거나 <br className="d-down-sm" />
								<button type="button" className="btn btn-file" onClick={handleButtonClick} disabled={disabled}>
									파일을 직접 선택
								</button>
								할 수도 있습니다.
							</p>
							<p className="text-md mt-2">
								<span>{(service && guideMessages[service]?.messages[2]) || guideMessages.default.messages[2]}</span>
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Dropzone;
