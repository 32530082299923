import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCookie } from "@/services/cookies/UserCookies";
import MyPageGet from "../mypage/MyPageGet";
import { LicenseExamination } from "../mypage/LicenseExamination";
import { DataCount } from "../mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "../workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { loginSuccess } from "@/services/store/action/LoginAction"; // 로그인 성공 액션 추가
import LoadingPage from "@/services/util/LoadingPage";
import api from "@/services/util/Interceptor";
import { API_URL2 } from "../url/URL";
import { CookiesMaxAge } from "@/services/util/const";
import { alertFormatDate } from "@/services/util/TransForm";

const GoogleRedirectURL = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchAuthData = async () => {
			setIsLoading(true);
			const urlParams = new URLSearchParams(window.location.search);
			const error = urlParams.get("error");

			if (error) {
				setIsLoading(false);
				navigate("/login");
				return;
			}

			try {
				const code = urlParams.get("code");
				const state = urlParams.get("state");

				console.log("Received state:", state); // 디버깅용

				const response = await api.get(`${API_URL2}user/google/callback`, {
					params: {
						code,
						state: state || ""
					}
				});

				const data = response.data;

				if (response.status === 200) {
					// 토큰 저장
					setCookie("token", data.access_token, { path: "/", maxAge: CookiesMaxAge });
					dispatch(loginSuccess(data.user));

					// 필요한 데이터 로드
					await Promise.all([
						MyPageGet(dispatch),
						LicenseExamination(dispatch),
						(async () => {
							const dataCountResult = await DataCount();
							dispatch(setDataCount(dataCountResult));
						})(),
						(async () => {
							const dashboardData = await DashBoard(null, 10, "", null, 1, true);
							dispatch(setDashboardData(dashboardData));
						})()
					]);

					// state 처리 및 리다이렉션
					if (state) {
						try {
							const decodedState = decodeURIComponent(state);
							if (decodedState.startsWith("/")) {
								// 내부 경로인지 확인
								navigate(decodedState);
							} else {
								navigate("/dashboard");
							}
						} catch (decodeError) {
							console.error("Redirect error:", decodeError);
							navigate("/dashboard");
						}
					} else {
						navigate("/dashboard");
					}
				} else if (response.status === 201) {
					navigate("/join", { state: { fromAuth: true, userData: data } });
				}
			} catch (err) {
				console.error("Auth error:", err);
				setError(err);

				if (err.response?.data?.message?.msg === "탈퇴한 회원입니다.") {
					const dateString = err.response.data.message.date;
					const formattedDate = alertFormatDate(dateString);
					alert({
						title: `[탈퇴] 탈퇴한 사용자입니다.`,
						message: `${formattedDate} 이후에 가입이 가능합니다.`
					});
					navigate("/");
					return;
				}

				if (err.response?.data?.message === "차단한 회원입니다.") {
					alert({ message: `[블럭] 계정이 잠겼습니다. 운영팀에 문의하세요.` });
					navigate("/");
					return;
				}

				navigate("/error");
			} finally {
				setIsLoading(false);
			}
		};

		fetchAuthData();
	}, [navigate, dispatch]);

	return (
		<div>
			<LoadingPage />
		</div>
	);
};

export default GoogleRedirectURL;
