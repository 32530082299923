import React, { useEffect, useRef, useState } from "react";
import Toast from "@/components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { updateTableCaptionChoice } from "@/services/store/action/tableAction";
import { TableCaptionSave } from "@/services/core/table/TableApi";

const TableCaptionChecker = ({
	htmlContent,
	onValidation,
	tableIndex,
	tableCaptions,
	onCaptionSelected,
	setIsChangeTag
}) => {
	const prevhtmlContent = useRef(htmlContent);
	const [showToastCaptionCopy, setShowCaptionCopy] = useState(false);
	const [, setSelectedCaption] = useState("");
	const hasMounted = useRef(false); // 컴포넌트 마운트 상태를 추적하는 ref
	const tableData = useSelector((state) => state.table.tableData.data.transformedData[tableIndex]);
	const dispatch = useDispatch();
	useEffect(() => {
		const validateHtml = async () => {
			const errors = [];

			if (htmlContent !== prevhtmlContent.current) {
				if (typeof onValidation === "function") {
					onValidation(errors);
				} else {
					console.error("onValidation is not a function");
				}
			}

			prevhtmlContent.current = htmlContent;
		};

		validateHtml();
	}, [htmlContent, onValidation]);

	const handleToastCaptionCopy = (event) => {
		const parentDD = event.currentTarget.parentElement;
		const labelElement = parentDD.querySelector("label");
		const captionText = labelElement.textContent;
		setShowCaptionCopy(true);
		try {
			navigator.clipboard.writeText(captionText);
		} catch (error) {
			console.error("텍스트를 복사하는 동안 오류가 발생했습니다:", error);
		}
	};

	useEffect(() => {
		if (!hasMounted.current) {
			// 컴포넌트가 처음 마운트 될 때만 실행되는 로직
	
			let defaultSelectedCaption;
			if (tableData.choice) {
				let idx;
				if (tableData.choice) {
					idx = 1;
					defaultSelectedCaption = {
						caption: tableCaptions.recommendedCaptions[1],
						type: "자연스러운 서술형"
					};
				} else {
					defaultSelectedCaption = {
						caption: tableCaptions.recommendedCaptions[0],
						type: "요약 극대화 명사형"
					};
				}


			}else if (tableData.choice===false){
				defaultSelectedCaption = {
					caption: tableCaptions.recommendedCaptions[0],
					type: "요약 극대화 명사형"
				};
			}
			setSelectedCaption(defaultSelectedCaption);
			onCaptionSelected(tableIndex, JSON.stringify(defaultSelectedCaption), defaultSelectedCaption.type);

			hasMounted.current = true; // 초기 마운트 로직 실행 후, 마운트 상태를 true로 변경
		}
	}, [onCaptionSelected, tableCaptions.recommendedCaptions, tableIndex,tableData]);

	const handleRadioChange = async (event) => {
		const selectedValue = JSON.parse(event.target.value); // 문자열을 객체로 변환
		const selectedCaption = event.target.value;
		const style = selectedValue.type;
		
		let choice;
		if (style == "요약 극대화 명사형") {
			choice = false;
		} else {
			choice = true;
		}

		setSelectedCaption(selectedValue); // 상태 업데이트
		onCaptionSelected(tableIndex, selectedCaption, style);
		dispatch(updateTableCaptionChoice(tableIndex, choice));
		const data = {
			toId: tableData.id,
			choice: choice
		};
		setIsChangeTag(true);
	};

	return (
		<>
			<div className="table-caption-checker">
				<div className="caption-info">
					<h3 className="title">기존 &lt;caption&gt; 정보</h3>
					<div>
						<div className="box box-outline">
							{/* [개발] caption 태그가 없을 경우 노출 */}
							{tableCaptions.status === "none" && (
								<>
									<span className="ico-area">
										<i className="ico ico-caption-tag-no"></i>
									</span>
									<p className="caption">
										<code className="code">&lt;caption&gt;</code> 태그가 없었습니다.
									</p>
								</>
							)}
							{/* [개발] caption 태그가 존재하지만 내용이 비어있는 경우 */}
							{tableCaptions.status === "empty" && (
								<>
									<span className="ico-area">
										<i className="ico ico-caption-tag-yes"></i>
									</span>
									<p className="caption">
										<code className="code">&lt;caption&gt;</code> 내용이 비어있었습니다.
									</p>
								</>
							)}
							{/* [개발] caption이 있을 경우 노출 */}
							{tableCaptions.status === "present" && (
								<>
									<span className="ico-area">
										<i className="ico ico-caption-exist"></i>
									</span>
									<p className="caption">
										<span className="caption-cancel">{tableCaptions.text}</span>
									</p>
								</>
							)}
						</div>
					</div>
				</div>
				<div className="caption-suggestions">
					<h3 className="title">
						<i className="ico ico-tool-md-primary"></i>
						앨리 추천 &lt;caption&gt; <span>선호하는 문체를 선택해주세요.</span>
					</h3>
					<div>
						<dl>
							<dt>
								요약 극대화<span className="text-gray">듣는 시간을 1초 더 아껴주는</span>
							</dt>
							<dd>
								<span className="check-item-sm">
									{tableData.choice ? (
										<input
											type="radio"
											data-style="noun" // 명사형
											id={`table${tableIndex}-caption1`}
											name={`table${tableIndex}-caption`}
											value={JSON.stringify({
												caption: tableCaptions.recommendedCaptions[0],
												type: "요약 극대화 명사형"
											})}
											onChange={handleRadioChange}
										/>
									) : (
										<input
											type="radio"
											data-style="noun" // 명사형
											id={`table${tableIndex}-caption1`}
											name={`table${tableIndex}-caption`}
											value={JSON.stringify({
												caption: tableCaptions.recommendedCaptions[0],
												type: "요약 극대화 명사형"
											})}
											defaultChecked
											onChange={handleRadioChange}
										/>
									)}

									<label htmlFor={`table${tableIndex}-caption1`}>{tableCaptions.recommendedCaptions[0]}</label>
								</span>
								<button type="button" className="btn btn-copy" onClick={handleToastCaptionCopy}>
									<i className="ico ico-copy-xs"></i>caption 복사
								</button>
							</dd>
						</dl>
						<dl>
							<dt>
								자연스럽게<span className="text-gray">듣기 편안한 서술형</span>
							</dt>
							<dd>
								<span className="check-item-sm">
									{tableData.choice ? (
										<input
											type="radio"
											data-style="descriptive" // 서술형
											id={`table${tableIndex}-caption2`}
											name={`table${tableIndex}-caption`}
											onChange={handleRadioChange}
											defaultChecked
											value={JSON.stringify({
												caption: tableCaptions.recommendedCaptions[1],
												type: "자연스러운 서술형"
											})}
										/>
									) : (
										<input
											type="radio"
											data-style="descriptive" // 서술형
											id={`table${tableIndex}-caption2`}
											name={`table${tableIndex}-caption`}
											onChange={handleRadioChange}
											value={JSON.stringify({
												caption: tableCaptions.recommendedCaptions[1],
												type: "자연스러운 서술형"
											})}
										/>
									)}

									<label htmlFor={`table${tableIndex}-caption2`}>{tableCaptions.recommendedCaptions[1]}</label>
								</span>
								<button type="button" className="btn btn-copy" onClick={handleToastCaptionCopy}>
									<i className="ico ico-copy-xs"></i>caption 복사
								</button>
							</dd>
						</dl>
					</div>
					{showToastCaptionCopy && (
						<Toast
							message="caption이 복사되었습니다."
							onClose={() => {
								setShowCaptionCopy(false);
							}}
							icon={<i className="ico ico-copy-white-sm"></i>}
							position="bottom"
							theme="dark"
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default TableCaptionChecker;
