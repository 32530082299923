export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const SET_IMG_WORK_DATA = "SET_IMG_WORK_DATA";
export const UPDATE_IMAGE_BRIGHTNESS_SAVE_ITEM = "UPDATE_IMAGE_BRIGHTNESS_SAVE_ITEM";
export const REFRESH_SAVE_DATA = "REFRESH_SAVE_DATA";
export const UPDATE_IMAGE_OUTPUT = "UPDATE_IMAGE_OUTPUT";
export const SAVE_IMAGE_OUTPUT = "SAVE_IMAGE_OUTPUT";
export const RESET_IMAGE_OUTPUT = "RESET_IMAGE_OUTPUT";
export const SET_BRIGHTNESS = "SET_BRIGHTNESS";
export const SET_BRIGHTNESS_STEP = "SET_BRIGHTNESS_STEP";
export const RESET_BRIGHTNESS_RESET_TIME = "RESET_BRIGHTNESS_RESET_TIME";

export const resetBrightnessTime = () => ({
	type: RESET_BRIGHTNESS_RESET_TIME
})
export const setBrightnessStep = (step) => ({
	type: SET_BRIGHTNESS_STEP,
	payload: step
});

export const setBrightnessData = (brightness) => ({
	type: SET_BRIGHTNESS,
	payload: brightness
});

export const setImageData = (data) => ({
	type: SET_IMAGE_DATA,
	payload: data
});

export const updateImageBrigthtnessSaveTime = () => {
	return {
		type: UPDATE_IMAGE_BRIGHTNESS_SAVE_ITEM
	};
};

export const refreshSaveData = (data) => ({
	type: REFRESH_SAVE_DATA,
	payload: data
});

export const outPutSaveData = (newOutput) => {
	return {
		type: UPDATE_IMAGE_OUTPUT,
		payload: newOutput
	};
};

export const outPutImgData = (data) => {
	return {
		type: SAVE_IMAGE_OUTPUT,
		payload: data
	};
};
export const resetImageOutput = () => ({
	type: RESET_IMAGE_OUTPUT
});
