import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginForm from "@/components/form/LoginForm";
import { getCookie } from "@/services/cookies/UserCookies";

export default function LoginPage() {
	const navigate = useNavigate();
	const location = useLocation();

	// 로그인 상태 유지시 리다이렉트 처리
	useEffect(() => {
		const token = getCookie("token");

		// 쿼리 파라미터에서 redirect 경로 추출
		const queryParams = new URLSearchParams(location.search);
		const redirectTo = queryParams.get("redirect") || "/dashboard"; // 기본값은 "/dashboard"

		// 1. 로그인 상태 유지시 (이미 로그인된 경우) 리다이렉트
		if (token) {
			navigate(redirectTo);
		}
	}, [navigate, location.search]);

	// 2. 새로 로그인 성공 시 처리
	const handleLoginSuccess = () => {
		const queryParams = new URLSearchParams(location.search);
		const redirectTo = queryParams.get("redirect") || "/dashboard"; // 로그인 성공 시 리다이렉트 경로
		navigate(redirectTo);
	};

	return (
		<div className="container account-page">
			<div className="account-header">
				<Link to="/" className="btn btn-xs btn-back">
					<i className="ico ico-arrow-left-gray"></i>돌아가기
				</Link>
				<h2 className="title1">로그인</h2>
			</div>
			{/* 로그인 성공 시 handleLoginSuccess 호출 */}
			<LoginForm onLoginSuccess={handleLoginSuccess} />
		</div>
	);
}
