import axios from "axios";
import { getCookie } from "@/services/cookies/UserCookies";
import { MY_CHANGE_PASSWORD } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const MyChangePassword = async (currentPassword, changePassword) => {
	try {
		const response = await api.post(MY_CHANGE_PASSWORD, {
			password: currentPassword,
			newPassword: changePassword
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			if (status === 400) {
				if (data.message === "잘못된 비밀번호") {
					alert({ message: "잘못된 비밀번호 입니다." });
					return "fail";
				}
			} else {
				navigateTo("/error");
				return "fail";
			}
		}
		return "fail";
	}
};
