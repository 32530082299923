// SettingTabs.js
import React from "react";
import { Link, useLocation } from "react-router-dom";

const SettingTabs = () => {
	const location = useLocation();

	const tabs = [
		{ path: "/setting/activity", label: "앨리지수" },
		{ path: "/setting/license", label: "사용권" },
		{ path: "/setting/account", label: "내 계정" }
	];

	const activeTab = tabs.find((tab) => location.pathname.includes(tab.path)) || tabs[0];

	const getTitle = (path) => (path === activeTab.path ? "선택됨" : "");

	return (
		<nav className="tab-menu tabmenu-type2">
			<ul className="tab-list">
				{tabs.map((tab) => (
					<li key={tab.path} className={tab.path === activeTab.path ? "on" : ""}>
						<Link to={tab.path} title={getTitle(tab.path)}>
							<span>{tab.label}</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default SettingTabs;
