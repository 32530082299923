import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import usePopup from "@/hooks/usePopup";
import PopupLogin from "@/pages/popup/PopupLogin";
import PopupLayer from "@/components/popup/PopupLayer";
import Logo from "@/assets/images/logo@2x.png";
import { getCookie } from "@/services/cookies/UserCookies";
import { useDispatch, useSelector } from "react-redux";
import imgProfile from "@/assets/images/img-profile-sm@2x.png";
import LogoutButton3 from "@/services/util/LogoutButton3";
import { selectSid } from "@/services/store/action/SelectAction";
import { setUserData } from "@/services/store/action/LoginAction";
// import TopBanner from "@/components/layouts/TopBanner";
import MyPageGet from "@/services/core/mypage/MyPageGet";

export default function HeaderHome({ setIsAuthenticated, isAuthenticated = false }) {
	const dispatch = useDispatch();
	const popupLoginCommon = usePopup();
	const myUserData = useSelector((state) => state.user.userData);
	const navigate = useNavigate();
	const [redirectPath, setRedirectPath] = useState(null);

	const gnbNavItems = [
		{
			to: "#",
			text: "서비스",
			requiresAuth: true,
			subItems: [
				{
					to: "#",
					text: "AI 서비스",
					label: "무료",
					labelClassName: "free",
					requiresAuth: true,
					subItems: [
						{
							to: "/tool/img2html/add",
							iconClass: "ico ico-img2html-md",
							text: "이미지 HTML 변환기",
							requiresAuth: true
						},
						{
							to: "/tool/contrast/add",
							iconClass: "ico ico-contrast-md",
							text: "이미지 명도대비 최적화",
							requiresAuth: true
						},
						{
							to: "/tool/seo/add",
							iconClass: "ico ico-seo-md",
							text: "SEO 메타태그 생성기",
							requiresAuth: true
						},
						{
							to: "/tool/table/add",
							iconClass: "ico ico-table-md",
							text: "표 접근성 최적화",
							requiresAuth: true
						}
					]
				},
				{
					to: "#",
					text: "프리미엄",
					label: "모집중",
					labelClassName: "comingsoon",
					subItems: [
						{ to: "/plugin", text: "앨리 API 플러그인 신청하기" }
						// { to: "/enterprise", text: "앨리 엔터프라이즈" }
					]
				}
			]
		},
		{ to: "/community", text: "커뮤니티" },
		{ to: "/resource", text: "리소스" },
		{
			to: "#",
			text: "소식·이벤트",
			subItems: [
				{ to: "/contents?to=release-note&title=업데이트+소식", text: "업데이트 소식" },
				{ to: "/contents?to=events&title=이벤트+당첨자발표", text: "이벤트·당첨자발표" }
			]
		}
	];

	const GnbNavMenu = ({ items }) => {
		const location = useLocation();

		const isMenuItemActive = (item) => {
			if (item.to === "#") {
				if (item.subItems) {
					return item.subItems.some((subItem) => isMenuItemActive(subItem));
				}
				return false;
			}

			const itemUrl = new URL(item.to, window.location.origin);
			const currentUrl = new URL(location.pathname + location.search, window.location.origin);

			if (itemUrl.pathname !== currentUrl.pathname) {
				if (item.subItems) {
					return item.subItems.some((subItem) => isMenuItemActive(subItem));
				}
				return false;
			}

			const itemParams = itemUrl.searchParams;
			const currentParams = currentUrl.searchParams;

			for (let [key, value] of itemParams.entries()) {
				if (currentParams.get(key) !== value) {
					return false;
				}
			}

			return true;
		};

		const handleMenuItemClick = (e, item, parentItem = {}) => {
			const isComingSoon = item.labelClassName === "comingsoon" || parentItem.labelClassName === "comingsoon";
			const exceptUrls = ["/plugin"]; // 예외 URL 목록

			// 예외 URL이면서 comingsoon인 경우는 얼럿 표시하지 않음
			if (isComingSoon && !exceptUrls.includes(item.to)) {
				e.preventDefault();
				window.alert({
					title: "⏳ 출시 준비중 입니다.",
					content: `<div class="box"><p>빠르게 소식을 받고 싶다면?</p><a href="https://open.kakao.com/o/g9jkTvug" class="text-primary text-medium link" target="_blank" title="새창">앨리 사용자 오픈카톡 입장하기</a></div>`,
					btnClassName: "outline-gray",
					alertBtnText: "확인"
				});
			} else if (item.to === "#") {
				e.preventDefault();
			} else if (item.requiresAuth && !isAuthenticated) {
				e.preventDefault();
				setRedirectPath(item.to);
				popupLoginCommon.open(e);
			}
		};

		const renderMenuItem = (item, parentItem = {}) => {
			const content = (
				<>
					{item.iconClass && <i className={item.iconClass}></i>}
					{item.text}
					{item.label && <span className={`label ${item.labelClassName}`}>{item.label}</span>}
				</>
			);

			if (item.to === "#") {
				return (
					<button className="menu" onClick={(e) => handleMenuItemClick(e, item, parentItem)}>
						{content}
					</button>
				);
			} else {
				return (
					<Link to={item.to} onClick={(e) => handleMenuItemClick(e, item, parentItem)}>
						{content}
					</Link>
				);
			}
		};

		return (
			<ul className="gnb-nav-list">
				<li className="d-down-md">
					<Link to="/">홈</Link>
				</li>
				{items.map((item, index) => (
					<li key={index} className={`${item.subItems ? "has-submenu" : ""} ${isMenuItemActive(item) ? "active" : ""}`}>
						{renderMenuItem(item)}
						{item.subItems && (
							<div className="lnb-area">
								{item.subItems.map((subItem, subIndex) => {
									if (subItem.subItems && subItem.subItems.length > 0) {
										return (
											<dl key={subIndex} className="submenu-group">
												<dt>
													<span className="text">{subItem.text}</span>
													{subItem.label && <span className={`label ${subItem.labelClassName}`}>{subItem.label}</span>}
												</dt>

												{subItem.subItems.map((subSubItem, subSubIndex) => {
													const isActive = isMenuItemActive(subSubItem);
													return (
														<dd key={subSubIndex} className={isActive ? "active" : ""}>
															{renderMenuItem(subSubItem, subItem)}
														</dd>
													);
												})}
											</dl>
										);
									} else {
										const subItemsWithoutSubItems = item.subItems.filter(
											(subItem) => !subItem.subItems || subItem.subItems.length === 0
										);

										if (subIndex === 0) {
											return (
												<ul key="subItemsWithoutSubItems" className="submenu-list">
													{subItemsWithoutSubItems.map((subItem, idx) => {
														const isActive = isMenuItemActive(subItem);
														return (
															<li key={idx} className={isActive ? "active" : ""}>
																{renderMenuItem(subItem, item)}
															</li>
														);
													})}
												</ul>
											);
										} else {
											return null;
										}
									}
								})}
							</div>
						)}
					</li>
				))}
			</ul>
		);
	};

	const [isProfileMorePopupOpen, setIsProfileMorePopupOpen] = useState(false);
	const profileMoreButtonRef = useRef(null);
	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeProfileMorePopup = () => {
		setIsProfileMorePopupOpen(false);
	};
	const toggleProfileMorePopup = () => {
		setIsProfileMorePopupOpen(!isProfileMorePopupOpen);
	};

	useEffect(() => {
		const token = getCookie("token");
		setIsAuthenticated(!!token);

		if (token && (!myUserData || !myUserData.data || !myUserData.data.name)) {
			(async () => {
				try {
					const userData = await MyPageGet(dispatch);
					dispatch(setUserData(userData));
				} catch (error) {
					console.error("Failed to fetch user data:", error);
				}
			})();
		}
	}, [setIsAuthenticated, myUserData, dispatch]);

	const onLoginSuccess = (userData) => {
		const updatedUserData = {
			...userData,
			myLicense: userData.myLicense || { data: [] }
		};

		setIsAuthenticated(true);
		dispatch(setUserData(updatedUserData));

		if (redirectPath) {
			navigate(redirectPath);
			setRedirectPath(null);
		} else {
			navigate("/dashboard");
		}
	};

	const handleLogoClick = (e) => {
		e.preventDefault();
		navigate("/");
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handlebar = () => {
		dispatch(selectSid("대시보드"));
	};

	const toggleAllMenu = () => {
		document.body.classList.toggle("allmenu-opened");
	};

	return (
		<>
			<nav id="skip">
				<ul>
					<li>
						<a href="#content">콘텐츠 바로가기</a>
					</li>
					<li>
						<a href="#gnb">주메뉴 바로가기</a>
					</li>
					<li>
						<a href="#footer">푸터 바로가기</a>
					</li>
				</ul>
			</nav>
			<header id="header" className="header-full">
				{/* <TopBanner isAuthenticated={isAuthenticated} useToggle={false} /> */}
				{/* <TopBanner closeButtonVisible={false} useToggle={false} /> */}
				<div className="header-container">
					<h1 id="logo">
						<Link to="/" onClick={handleLogoClick} style={{ cursor: "pointer" }}>
							<img src={Logo} alt="A11Y beta" />
						</Link>
					</h1>
					<nav id="gnb">
						<GnbNavMenu items={gnbNavItems} />
					</nav>

					<div className="right">
						{!isAuthenticated ? (
							<div className="btn-wrap">
								<Link className="btn btn-md btn-outline-gray" to="/login">
									<span>로그인</span>
								</Link>
								<Link
									to="https://tally.so/r/w4kBDO"
									target="_blank"
									title="새창"
									className="btn btn-md btn-primary d-up-md"
								>
									<span>제휴문의</span>
								</Link>
							</div>
						) : (
							<div className="profile-wrap">
								<button
									type="button"
									className="profile"
									onClick={toggleProfileMorePopup}
									onMouseDown={handleButtonMouseDown}
									ref={profileMoreButtonRef}
								>
									<span className="profile-thumb">
										{myUserData && myUserData.data && myUserData.data.profileImg ? (
											<img src={`${myUserData.data.profileImg}`} alt={myUserData.data.name + " 프로필 이미지"} />
										) : (
											<img src={imgProfile} alt={" 프로필 이미지"} />
										)}
									</span>
									<span className="profile-name">
										{myUserData && myUserData.data && myUserData.data.name ? (
											<span className="name">{myUserData.data.name}</span>
										) : (
											<span className="name">로딩중</span>
										)}
									</span>
									<span className="more-info">
										<i className={isProfileMorePopupOpen ? "ico ico-arrow-up-sm" : "ico ico-arrow-down-sm"}></i>
										<span className="sr-only">더보기</span>
									</span>
								</button>
							</div>
						)}

						<button id="btn-allmenu" className="btn btn-icon d-down-md" onClick={toggleAllMenu}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
								<title>전체메뉴</title>
								<path d="M3 5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3 12H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3 19H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</button>
					</div>
				</div>
			</header>

			<PopupLogin
				isOpen={popupLoginCommon.isOpen}
				closePopup={popupLoginCommon.close}
				to={redirectPath}
				onLoginSuccess={onLoginSuccess}
			/>

			<PopupLayer
				className="profile-more-popup"
				isOpen={isProfileMorePopupOpen}
				closePopup={closeProfileMorePopup}
				title="프로필 더보기 팝업"
				headerContent={null}
				footerContent={null}
				buttonRef={profileMoreButtonRef}
				position="bottom"
			>
				<div className="info-detail">
					<div className="btn-group">
						<Link to="/dashboard" className="btn btn-sm" onClick={handlebar}>
							대시보드
						</Link>
						<Link to="/settings" className="btn btn-sm">
							설정
						</Link>
						<LogoutButton3 setAuth={setIsAuthenticated} setIsProfileMorePopupOpen={setIsProfileMorePopupOpen} />
					</div>
				</div>
			</PopupLayer>
		</>
	);
}
