// 디폴트 콜백 함수
const defaultOnFocus = (selector) => {
	const element = document.querySelector(`${selector}`);
	if (element) {
		element.focus();
		const range = document.createRange();
		const sel = window.getSelection();
		range.selectNodeContents(element);
		range.collapse(false);
		sel.removeAllRanges();
		sel.addRange(range);
		element.scrollIntoView({ behavior: "smooth", block: "center" });
	}
};

// 디폴트 구조 정의
const defaultAction = {
	icon: "❓", // 디폴트 아이콘
	buttonText: "위치로 이동", // 디폴트 버튼 텍스트
	onPreview: defaultOnFocus // 디폴트 콜백 함수
};

// 오류 유형별 아이콘, 버튼 텍스트, 콜백 함수 관리
const AxeCoreViolationActions = {
	"empty-table-header": {
		// description: "테이블 헤더가 인식 가능한 텍스트를 가지고 있는지 확인하세요.",
		// help: "테이블 헤더 텍스트는 반드시 비어있지 않아야 합니다.",
		description: "빈 제목 셀에 내용을 입력하세요.",
		help: "테이블 헤더는 인식 가능한 텍스트를 포함해야 합니다.",
		icon: <i className="ico ico-rowtitle-sm"></i>,
		buttonText: "빈 <th> 내용 채우기",
		placeholder: "빈 제목 셀에 내용을 입력하세요",
		init: (selector) => {
			const elements = document.querySelectorAll(selector);
			elements.forEach((element) => {
				element.classList.add("a11y-warning");
				element.contentEditable = "true";
			});
		},
		onClick: (selector) => {
			const th = document.querySelector(selector);
			if (th) {
				// th.classList.add("a11y-editing");
				th.contentEditable = "true";
				th.focus();
				const range = document.createRange();
				const sel = window.getSelection();
				range.selectNodeContents(th);
				range.collapse(false);
				sel.removeAllRanges();
				sel.addRange(range);
				th.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		},
		onFocus: (selector) => {
			const th = document.querySelector(selector);
			if (th) {
				th.classList.add("a11y-editing");
				th.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		},
		onBlur: (selector) => {
			const th = document.querySelector(selector);
			if (th) {
				th.classList.remove("a11y-editing");
			}
		},
		onChange: (event, selector, onInputChangeCallback) => {
			const value = event.target.value; // 사용자가 입력한 값
			const th = document.querySelector(selector);
			if (th) {
				onInputChangeCallback(th, value);
			} else {
				console.error(selector + " : 해당 selector에 해당하는 테이블을 찾을 수 없습니다.");
			}
		}
	},
	"table-fake-caption": {
		// description: "캡션이 있는 테이블이 <caption> 엘리먼트를 사용하고 있는지 확인하세요.",
		// help: "데이터 테이블에 캡션을 제공하는데 데이터 셀이나 헤더 셀이 반드시 사용되지 않아야 합니다.",
		description: "표의 내용을 요약한 정보를 <caption>에 입력해야 합니다.",
		help: "테이블 캡션은 데이터의 컨텍스트와 목적을 설명해 사용자가 내용을 이해하는 데 도움을 줍니다. <caption> 요소를 사용하여 구현하세요.",
		a11y: "표의 제목이 없어요.",
		icon: "🎭",
		buttonText: "가짜 테이블 캡션 편집하기",
		onFocus: defaultOnFocus
	},
	"td-headers-attr": {
		// description: "headers를 사용하는 테이블의 각 셀이 그 테이블의 다른 셀을 참조하고 있는지 확인하세요.",
		// help: "headers 어트리뷰트를 사용하는 table 엘리먼트의 모든 셀은 반드시 그 동일한 테이블의 다른 셀만 참조해야 합니다.",
		description: "테이블 셀의 headers 속성을 사용하여 셀 간의 관계를 명확하게 정의합니다.",
		help: "<td> 요소의 headers 속성은 해당 셀과 관련된 테이블 헤더의 ID를 참조해야 합니다. 이는 셀의 데이터가 어떤 헤더와 관련 있는지를 명확히 합니다.",
		a11y: "header 속성이 들어있는 th를 확인하세요",
		icon: "🧩",
		buttonText: "headers 편집하기",
		onFocus: defaultOnFocus
	},
	"th-has-data-cells": {
		// description: "데이터 테이블의 각 테이블 헤더가 데이터 셀을 참조하고 있는지 확인하세요.",
		// help: "모든 th 엘리먼트와 role=columnheader/rowheader를 가진 엘리먼트는 반드시 그것들이 설명하는 데이터 셀을 가져야 합니다.",
		description: "각 테이블 헤더는 하나 이상의 데이터 셀과 연결되어 있어야 합니다.",
		help: "<th> 요소와 role 속성이 columnheader 또는 rowheader인 요소는 해당하는 데이터 셀을 참조해야 합니다. 이는 테이블 구조의 명확성을 보장합니다.",
		a11y: "headers",
		icon: "🔗",
		buttonText: "test 편집하기",
		onFocus: defaultOnFocus
	},
	"table-duplicate-name": {
		// description: "테이블이 동일한 summary와 caption을 가지지 않게 하세요.",
		// help: "<caption> 엘리먼트는 summary 어트리뷰트와 동일한 텍스트를 포함하지 않아야 합니다.",
		description: "테이블의 summary와 caption이 중복되지 않도록 주의하세요.",
		help: "테이블의 <caption>은 테이블의 내용을 요약하는 고유한 텍스트를 포함해야 하며, summary 속성과 동일한 정보를 반복해서는 안 됩니다.",
		a11y: "표 요약문 내용이 중복됐어요.",
		icon: <i className="ico ico-no-repeat-sm"></i>,
		buttonText: "caption을 summary와 다른 값으로 변경하기",
		init: (selector) => {
			const table = document.querySelector(selector);
			const caption = table.querySelector("caption");
			caption.classList.add("a11y-warning");
			caption.contentEditable = "true";
		},
		onClick: (selector) => {
			const table = document.querySelector(selector);
			const caption = table.querySelector("caption");
			if (caption) {
				caption.contentEditable = "true";
				caption.focus();
				const range = document.createRange();
				const sel = window.getSelection();
				range.selectNodeContents(caption);
				sel.removeAllRanges();
				sel.addRange(range);
				caption.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		},
		onFocus: (selector) => {
			const table = document.querySelector(selector);
			const caption = table.querySelector("caption");
			if (caption) {
				caption.classList.add("a11y-editing");
				caption.scrollIntoView({ behavior: "smooth", block: "nearest" });
			}
		},
		onBlur: (selector) => {
			const table = document.querySelector(selector);
			if (table) {
				const caption = table.querySelector("caption");
				if (caption) {
					caption.classList.remove("a11y-editing");
				}
			}
		},
		onChange: (event, selector, onInputChangeCallback) => {
			const value = event.target.value; // 사용자가 입력한 값
			const table = document.querySelector(selector);
			if (table) {
				const caption = table.querySelector("caption");
				if (caption) {
					onInputChangeCallback(caption, value);
				} else {
					// 테이블 내에 caption 요소가 없다면, 새로운 caption을 생성하거나 다른 처리를 수행합니다.
					console.error("Caption 요소가 테이블 내에 존재하지 않습니다.");
				}
			} else {
				console.error("해당 selector에 해당하는 테이블을 찾을 수 없습니다.");
			}
		}
	},
	"td-has-header": {
		// description: "큰 테이블의 비어 있지 않은 각 데이터 셀이 하나 또는 그 이상의 테이블 헤더를 가지고 있는지 확인하세요.",
		// help: "3x3보다 큰 테이블에서 모든 비어있지 않은 td 엘리먼트는 반드시 연관된 테이블 헤더를 가져야 합니다.",
		description: "제목 셀와 내용 셀을 headers로 연결해보세요.",
		help: "3x3보다 큰 테이블에서 모든 데이터 셀(<td>)은 하나 이상의 테이블 헤더와 명확하게 연결되어야 합니다. 이는 테이블 데이터의 구조와 관계를 이해하는 데 필수적입니다.",
		a11y: "이 표는 복잡해요. ",
		icon: "🗺️",
		buttonText: "headers와 id 추가하기",
		onFocus: defaultOnFocus
	},
	default: defaultAction
};

export default AxeCoreViolationActions;
