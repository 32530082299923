import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
// import porofileImg from "@/assets/images/img-profile@2x.png";

import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";
import LoadingBar from "@/components/loading/LoadingBar";
import ImageViewer from "@/components/plugins/ImageViewer";
import Img2htmlEditor from "./Img2htmlWorkDetailComponents/Img2htmlEditor";

import Toast from "@/components/toast/Toast";
import ToastAlert from "@/components/toast/ToastAlert";
import useToastAlert from "@/hooks/useToastAlert";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import ModalConfirm from "@/components/modal/ModalConfirm";
import { useDispatch, useSelector } from "react-redux";
// import { htmlAutoSave, htmlDownLoad, htmlReCreate, htmlShare } from "@/services/core/Img2html/HtmlCreate";
import { htmlAutoSave, htmlReCreate, htmlShare } from "@/services/core/Img2html/HtmlCreate";
import {
	autoSaveHtml,
	createHtmlSuccess,
	resetSaveTime,
	// shareResetState,
	updateIsAddress,
	updateIsShare
} from "@/services/store/action/Img2HtmlAction";
import LoadingOverlay from "@/components/loading/LoadingOverlay";
// import LoadingBarFull from "@/components/loading/LoadingBarFull";
import JSZip from "jszip";
// import { DefaultAutoSaveTime, ImgPath, defaultProfileImage, sharePath } from "@/services/util/const";
import { DefaultAutoSaveTime } from "@/services/util/const";
import { API_URL } from "@/services/core/url/URL";
import { setUserData } from "@/services/store/action/LoginAction";
import { DataCount } from "@/services/core/mypage/MyDataCount";
// import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
// import { setDashboardData } from "@/services/store/action/DashBoard";

export default function Img2htmlWorkDetailPage(props) {
	useEffect(() => {
		// URL에서 쿼리 파라미터 제거
		const urlWithoutQueryParams = window.location.pathname;

		// History API를 사용하여 쿼리 파라미터 없이 현재 URL을 대체
		window.history.replaceState({}, "", urlWithoutQueryParams);
	}, []);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const extraValue = queryParams.get("extra");
	const [imageData, setImageData] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);
	const [isWorkName, setWorkName] = useState("");
	// [개발] UI 테스트용 임시 로직 : 개발시 삭제
	// const [data, setData] = useState(null);
	const [showToast1, setShowToast1] = useState(false);
	const [showToast2, setShowToast2] = useState(false);
	const [showToast3, setShowToast3] = useState(false);
	const [showToast4, setShowToast4] = useState(false);
	const [showToast5, setShowToast5] = useState(false);
	const [showToast6, setShowToast6] = useState(false);
	const [showToast7, setShowToast7] = useState(false);
	const toast1 = useToastAlert();
	const { isOpen: isSharePopup, open: openSharePopup, close: closeSharePopup } = usePopup();

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	/// 로직
	const handleWorkNameUpdate = (newWorkName) => {
		setWorkName(newWorkName);
	};

	const timeSave = useSelector((state) => state.html?.saveTime || "");
	const saveTime = useSelector((state) => state.html.htmlData.response.data.data.saveSecond || DefaultAutoSaveTime);
	const Img2Data = useSelector((state) => state.html?.htmlData || []);

	const userData = useSelector((state) => state.user?.userData || []);
	const allCount = userData?.data?.count || null;
	const share = Img2Data?.response?.data?.data?.isShare || false;
	const address = Img2Data?.response?.data?.data?.address || "";
	const [isShare, setIsShare] = useState(share || "");
	const dispatch = useDispatch();
	const Data = useSelector((state) => state.html?.htmlData || []);
	const idId = Img2Data.response?.data?.data?.ihId || [];
	const workName = Img2Data.response?.data?.data?.workspaceName || [];
	// userName, profile, count
	const userName = Img2Data.response?.data?.data?.name || [];
	const profile = Img2Data.response?.data?.data?.profileImg || [];
	const count = Img2Data.response?.data?.data?.count || [];
	const orfileName = Img2Data.response?.data?.data?.oriFilename || "";
	const fileName = orfileName.split(".").slice(0, -1).join(".");
	const formattedData = {
		src: Data?.response?.data?.data?.imgPath || [],
		html: Data?.response?.data?.data?.html || []
	};

	// const mountedRef = useRef(false); // 컴포넌트가 마운트되었는지 추적

	const idIdRef = useRef();
	idIdRef.current = Img2Data.response?.data?.data?.ihId;
	useEffect(() => {
		const updatedFormattedData = {
			src: Img2Data.response?.data?.data?.imgPath || [],
			html: Img2Data.response?.data?.data?.html || []
		};
		setData(updatedFormattedData);
		setImageData(updatedFormattedData);
	}, [Img2Data, isShare]); // Img2Data가 업데이트될 때마다 실행

	const shareHandle = async (share, ihid) => {
		try {
			if (share === true) {
				setIsShare(address);
				await handleAutoSave(idIdRef.current, htmlContentRef.current);
				openSharePopup();
			} else {
				await handleAutoSave(idIdRef.current, htmlContentRef.current);
				const resp = await htmlShare(ihid);
				setIsShare(resp.response.data.data);
				dispatch(updateIsAddress(resp.response.data.data));

				dispatch(updateIsShare(true));
				openSharePopup();
			}
		} catch (error) {
			console.error("[Error]공유하기 실패");
			alert({ message: "잠시 후 다시 시도해주세요." });
			return;
		}
	};

	// ModalConfirm 커스텀 함수
	const handleOpenConfirm = async () => {
		if (allCount > 0) {
			setIsConfirmOpen(true);
		} else {
			window.confirm({
				title: "사용권이 없어요.",
				content: "새로운 사용권을 구매하거나 기간이 유효한 사용권코드를 입력해주세요.",
				confirmButtonText: "사용권 구매하기",
				cancelButtonText: "취소",
				onConfirm: () => handleBuyUsage()
			});
			return;
		}
	};

	const handleBuyUsage = () => {
		setIsLoaded(false);
		navigate("/setting/license");
	};
	const handleCancel = () => {
		setIsConfirmOpen(false);
	};

	// ModalConfirm에서 '변환' 버튼 클릭 시 : toast Alert 추가
	const handleConfirm = async (ihId) => {
		setIsLoading(true);
		setIsConfirmOpen(false);
		try {
			await handleAutoSave(idIdRef.current, htmlContentRef.current);

			const resp = await htmlReCreate(ihId);
			if (resp.message === "Fail") {
				alert({ message: "잠시 후 다시 시도해주세요." });
				setIsLoading(false);
				return;
			}

			dispatch(createHtmlSuccess(resp));

			setData(formattedData);
			// setImageData(data.images[0]);
			setImageData(formattedData);
			const updatedUserData = {
				...userData,
				data: {
					...userData.data,
					count: userData.data.count - 1
				}
			};
			dispatch(setUserData(updatedUserData));
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));

			const Success = "Success";
			const workId = resp.response.data.data.workspaceId;
			setIsLoading(false);

			navigate(`/tool/img2html/work-detail/${encodeURIComponent(workId)}?extra=${encodeURIComponent(Success)}`);
		} catch (error) {
			console.error("[ERROR]이미지 다시 변환하기 실패", error);
			alert({ message: "잠시 후 다시 시도해주세요." });
			return;
		}

		toast1.openToast();
		// setIsConfirmOpen(false);
	};
	const navigate = useNavigate();
	const handleBack = async () => {
		navigate("/tool/img2html/add");
	};

	// [개발] 공유 링크 복사 버튼 기능 : 공유 링크 복사기능 추가
	const linkShare = (isShare) => {
		const url = `${API_URL}share/${isShare}`;
		const shareLink = `${url}`;
		navigator.clipboard
			.writeText(shareLink)
			.then(() => {
				setShowToast6(true);
				closeSharePopup();
			})
			.catch((err) => {
				setShowToast7(true);
			});
	};

	// const [subtitle] = useState("새 작업_230928"); // [개발] 동일한 작업명을 위한 임시코드입니다. 수정이 필요합니다.

	const [htmlContent, setHtmlContent] = useState(""); // [개발] HTML 변수명 : htmlContent
	const [markdownContent, setMarkdownContent] = useState(""); // [개발] 마크다운 변수명 : markdownContent
	const htmlContentRef = useRef(htmlContent);

	useEffect(() => {
		htmlContentRef.current = htmlContent;
	}, [htmlContent]);

	useEffect(() => {
		let saveTime = timeSave.saveSecond * 1000;
		if (!saveTime) {
			saveTime = DefaultAutoSaveTime;
		}
		const intervalId = setInterval(() => {
			// 자동 저장 기능
			handleAutoSave(idIdRef.current, htmlContentRef.current);
		}, saveTime);
		return () => clearInterval(intervalId); // 컴포넌트가 unmount될 때 interval 제거
	}, []);

	const handleAutoSave = async (idId, htmlContent) => {
		const resp = await htmlAutoSave(idId, htmlContent);
		if (resp === "Fail") {
		} else {
			dispatch(autoSaveHtml());
		}
	};

	useEffect(() => {
		const handleBeforeClose = async () => {
			const resp = await htmlAutoSave(idIdRef.current, htmlContent);
		};

		window.addEventListener("beforeunload", handleBeforeClose);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeClose);
		};
	}, []);

	useEffect(() => {
		const routerImg2Data = async () => {
			const resp = await htmlAutoSave(idIdRef.current, htmlContentRef.current);
			// dispatch(resetSaveTime());
		};
		return () => {
			dispatch(resetSaveTime());
			routerImg2Data();
		};
	}, [location]);

	useEffect(() => {
		if (extraValue === "Success") {
			toast1.openToast();
		}
	}, [extraValue]);

	const extractH1Text = (htmlContent) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, "text/html");
		const h1 = doc.querySelector("h1");
		return h1 ? h1.textContent : "";
	};

	const createContentValue = (htmlContent) => {
		const h1Text = extractH1Text(htmlContent);
		return h1Text ? `${h1Text} (하단 설명)` : "기본 이미지 대체텍스트";
	};

	const createFigureHtml = (imageData, userName, profile, count, imagePath, profileImgPath) => {
		// [개발] 이미지 경로를 상대 경로로 변경 (구조에 따라 조정 필요)
		// [개발] 이미지 대체텍스트의 경우 : "htmlContent의 제목요소(h1)의 텍스트 + (하단 설명)"
		const altContent = createContentValue(htmlContent);
		const h1Text = extractH1Text(htmlContent) || "웹 접근성 AI 마법사, 앨리";

		return `
		<!DOCTYPE html>
		<html lang="ko">
		<head>
			<link rel="icon" type="image/png" href="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/images/favicon.ico" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
			<meta name="keywords" content="웹접근성, 웹 접근성, a11y, 인시퀀스, 앨리, AI, 인공지능, SEO" />
			<meta property="og:title" content="${h1Text}" />
			<meta property="og:description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
			<meta property="og:image" content="${imagePath}" />
			<meta property="og:type" content="website" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="${h1Text}" />
			<meta name="twitter:description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
			<meta name="twitter:image" content="${imagePath}" />
			<title>[a11y.co.kr] ${h1Text}</title>
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/SharePage.css" />
		</head>
		<body>
			<div class="a11y-container">
				<header>
					<h1 class="logo-a11y">
						<a href="${API_URL}" target="_blank" title="새창">
							<img src="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/images/logo@2x.png" alt="A11Y" />
						</a>
					</h1>
					<h2 class="a11y-title">이미지 HTML 변환 결과물</h2>
					<p class="a11y-info">
						<span class="a11y-profile-thumb">
							<img src="${profileImgPath}" alt="프로필 이미지" />
						</span>
						<span class="a11y-text">
							<strong class="a11y-user">${userName}</strong>님이 앨리와 함께<strong class="a11y-count">${count}</strong>번째 웹 접근성 개선에 기여했어요.
						</span>
					</p>
					<div class="box">
						<i class="ico ico-notify"></i>
						<a href="https://a11y.co.kr/help/img2html-output" target="_blank" title="새창" class="a11y-link">이미지에 대체 텍스트 적용 방법(IR 기법) 자세히 보기</a>
					</div>
				</header>
				<article>
					<figure>
						<img src="${imagePath}" alt="${altContent}">
						<figcaption class="a11y-sr-only html-preview">
							${htmlContent}
						</figcaption>
					</figure>
				</article>
				<footer>
					<div class="box box-flex">
						<p class="a11y-producer">
							<img src="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/images/logo-a11y-sm@2x.png" alt="A11Y" class="logo-a11y-sm" />
							앨리에서 제작되었습니다.
						</p>
	
						<!-- [개발] 실제 a11y 링크로 추후 변경 예정 : https://a11y.co.kr/ -->
						<a href="${API_URL.slice(0, -1)}" target="_blank" title="새창" class="btn btn-a11y">
							<i class="ico ico-link-sm-white"></i>
							앨리 바로가기
						</a>
					</div>
				</footer>
			</div>
		</body>
	</html>
		`;
	};
	const createCopyFigureHtml = (imageData) => {
		// [개발] 이미지 경로를 상대 경로로 변경 (구조에 따라 조정 필요)

		const relativeImagePath = `${imageData.src.replace("/mock/", "./")}`;
		// [개발] 이미지 대체텍스트의 경우 : "htmlContent의 제목요소(h1)의 텍스트 + (하단 설명)"
		const altContent = createContentValue(htmlContent);

		return `<figure>
			<img src="${relativeImagePath}" alt="${altContent}">
			<figcaption class="ally-sr-only html-preview">
				${htmlContent}
			</figcaption>
		</figure>`;
	};

	useEffect(() => {
		setData(formattedData);
		setImageData(formattedData);
		setIsLoaded(true);
	}, [isLoading]);

	if (!isLoaded) {
		return (
			<div className="loading-area">
				<LoadingBar />
			</div>
		);
	}

	const handleHtmlChange = (htmlContent) => {
		setHtmlContent(htmlContent);
	};
	const handleMarkdownChange = (markdownContent) => {
		setMarkdownContent(markdownContent);
	};

	// [개발] 내려받기 할 경우 : zip로 다운로드 되어야 합니다. => 추가 전달드린 zip 파일 및 노션 참고
	// [개발] 압축파일로 내보내기 로직 : 현재 htmlContent파일을 html로만 다운로드 되게 작업 되어 있습니다.

	const downloadImage = async (imageSrc) => {
		try {
			const response = await fetch(imageSrc);

			const blob = await response.blob();
			return blob;
		} catch (error) {
			return "Fail";
		}
	};

	const exportToZIP = async (
		ihid,
		workSpaceName,
		htmlContent,
		imageData,
		userName,
		profile,
		count,
		fileName,
		isWorkName
	) => {
		try {
			await handleAutoSave(idIdRef.current, htmlContentRef.current);

			const userCount = count || 0;

			const folderName = isWorkName && isWorkName.trim() !== "" ? isWorkName : workSpaceName;

			const zip = new JSZip();
			// HTML 파일 생성
			const h1Text = extractH1Text(htmlContent) || "";
			const htmlFileName = `[a11y.co.kr] ${h1Text}.html`;
			const imageExtension = orfileName.split(".").pop();
			const imagePath = `${fileName}.${imageExtension}`;
			const profileImgPath = "profile.jpg";
			const profileImg = `${profile}`;
			const defaultProfileImage = "https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/images/ico-profile-sm@2x.png";

			const profileImage = profile && profile.length > 0 ? profileImg : defaultProfileImage;

			const figureHtml = createFigureHtml(imageData, userName, profileImage, userCount, imagePath, profileImgPath);
			zip.file(htmlFileName, new Blob([figureHtml], { type: "text/html;charset=utf-8" }));
			const imageBlob = await downloadImage(`${imageData.src.replace("/mock/", "./")}`);
			const profileBlob = await downloadImage(profileImage);
			if (imageBlob === "Fail") {
				alert({ message: "잠시 후 다시 시도해주세요." });
				return;
			}
			if (profileBlob === "Fail") {
				alert({ message: "잠시 후 다시 시도해주세요." });
				return;
			}

			zip.file(imagePath, imageBlob, { binary: true });
			zip.file(profileImgPath, profileBlob, { binary: true });
			// ZIP 파일 생성 및 다운로드
			zip
				.generateAsync({ type: "blob" })
				.then((content) => {
					saveAs(content, `[a11y.co.kr]${folderName}.zip`);
					setShowToast3(true); // 다운로드 성공 알림
				})
				.catch((error) => {
					console.error("ZIP 파일 생성 실패:", error);
					return;
				});
		} catch (error) {
			console.error("압축파일로 내보내기 오류", error);
			alert({ message: "잠시 후 다시 시도해주세요." });
			return;
		}
	};
	// HTML 복사 로직
	const copyToHTML = async () => {
		// [개발] HTML 복사 변수명 : htmlContent

		const figureHtml = createCopyFigureHtml(imageData);
		try {
			await navigator.clipboard.writeText(figureHtml);
			setShowToast2(true);
		} catch (error) {
			setShowToast5(true);
		}
	};

	// 마크다운 복사 로직
	const copyToMarkdown = async () => {
		// [개발] 마크다운 복사 변수명 : markdownContent

		try {
			await navigator.clipboard.writeText(markdownContent);
			setShowToast1(true);
		} catch (error) {
			setShowToast4(true);
		}
	};

	return (
		<>
			<div className="container img2html-work-detail-page">
				<HeaderDetails
					onWorkNameChange={handleWorkNameUpdate}
					className="service-img2html"
					subTitle={Img2Data?.response?.data?.data?.workspaceName}
					workspaceId={Img2Data?.response?.data?.data?.workspaceId || []}
					currentTime2={timeSave}
					saveTime={saveTime}
				/>

				<div className="body-details">
					{isLoading === true && <LoadingOverlay />}
					{isLoading === false && (
						<div className="img2html-container">
							<div className="col col-viewer">
								<ImageViewer imageData={imageData} />
							</div>
							<div className="handle"></div>
							<div className="col col-editor">
								<Img2htmlEditor
									imageData={imageData}
									onMarkdownChange={handleMarkdownChange}
									onHtmlChange={handleHtmlChange}
								/>
							</div>
						</div>
					)}
				</div>
				<FooterDetails>
					<div className="wrap-left">
						<div className="btn-group">
							<button type="button" className="btn btn-lg btn-gray" onClick={handleBack}>
								<i className="ico ico-arrow-left-white"></i>
								처음으로
							</button>
							<button className="btn btn-lg btn-outline-primary" onClick={handleOpenConfirm} disabled={isLoading}>
								<i className="ico ico-tool-md-primary"></i>
								다시 변환하기
							</button>
						</div>

						{/* [개발] UI 테스트용 임시 로직 : 개발시 삭제 */}
					</div>
					<div className="wrap-right">
						<button type="button" className="btn btn-lg btn-outline-gray" onClick={copyToMarkdown} disabled={isLoading}>
							<i className="ico ico-copy-sm">복사</i> 마크다운
						</button>
						<button type="button" className="btn btn-lg btn-outline-gray" onClick={copyToHTML} disabled={isLoading}>
							<i className="ico ico-copy-sm">복사</i> HTML
						</button>
						<button
							type="button"
							className="btn btn-lg btn-outline-primary"
							onClick={() =>
								exportToZIP(idId, workName, htmlContent, imageData, userName, profile, count, fileName, isWorkName)
							}
							disabled={isLoading}
						>
							<i className="ico ico-download-primary-sm">다운로드</i> 압축파일
						</button>
						<button
							type="button"
							className="btn btn-lg btn-primary"
							onClick={() => shareHandle(share, idId)}
							disabled={isLoading}
						>
							<i className="ico ico-share-white-sm">링크</i> 공유하기
						</button>
					</div>
				</FooterDetails>

				{/* 마크업 수정 2024-03-21 : 컴펌 팝업 마크업 위치 변경 (퍼블코드 위치만 변경)*/}
				{isConfirmOpen && (
					<ModalConfirm
						isOpen={isConfirmOpen}
						title="다시 변환하시겠어요?"
						message="사용권 1장이 차감됩니다."
						onConfirm={() => handleConfirm(idId, userName, profile, count)}
						onCancel={handleCancel}
						confirmButtonText="변환"
						cancelButtonText="취소"
					/>
				)}
				{showToast1 && (
					<Toast
						message="마크다운이 복사되었습니다."
						onClose={() => {
							setShowToast1(false);
						}}
						icon={<i className="ico ico-copy-white-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast2 && (
					<Toast
						message="HTML이 복사되었습니다."
						onClose={() => {
							setShowToast2(false);
						}}
						icon={<i className="ico ico-copy-white-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast3 && (
					/// 여기
					<Toast
						// message="새 작업_230928.zip 파일을 다운로드 하였습니다." // [개발] 파일명의 경우 작업명으로 변경 필요
						message={`${isWorkName || workName}.zip 파일을 다운로드 하였습니다.`}
						onClose={() => {
							setShowToast3(false);
						}}
						icon={<i className="ico ico-download-light-xs"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast4 && (
					<Toast
						message="마크다운을 복사하는데 실패하였습니다."
						onClose={() => {
							setShowToast4(false);
						}}
						icon={<i className="ico ico-warning-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast5 && (
					<Toast
						message="HTML을 복사하는데 실패하였습니다."
						onClose={() => {
							setShowToast5(false);
						}}
						icon={<i className="ico ico-warning-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast6 && (
					<Toast
						message="작업 링크가 복사되었습니다."
						onClose={() => {
							setShowToast6(false);
						}}
						icon={<i className="ico ico-linkclip-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{showToast7 && (
					<Toast
						message="공유 링크 복사에 실패하였습니다."
						onClose={() => {
							setShowToast7(false);
						}}
						icon={<i className="ico ico-linkclip-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				{toast1.showToast && (
					<ToastAlert
						message="변환 완료! 틀린 글자를 확인해주세요."
						onClose={toast1.closeToast}
						icon={<i className="ico ico-notify-xs"></i>}
						position="top"
						theme="dark"
					/>
				)}
				<PopupLayout
					isOpen={isSharePopup}
					closePopup={closeSharePopup}
					title="공유하기"
					size="sm"
					className={"share-popup"}
					footerContent={
						<>
							<div className="btn-group">
								{/* [개발] 버튼 기능 : 공유 링크 복사기능 추가 */}
								<button type="button" className="btn btn-xl btn-primary" onClick={() => linkShare(isShare)}>
									<i className="ico ico-linkclip-sm"></i>
									공유 링크 복사
								</button>
							</div>
						</>
					}
				>
					<div className="box">
						<i className="ico ico-img2html-md"></i>
						<span className="share-file">{isWorkName || workName}</span>
					</div>
					<p>URL이 있는 사람은 누구나 이 작업 결과를 볼 수 있습니다.</p>
				</PopupLayout>
			</div>
		</>
	);
}
