import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import ArticleItem from "@/components/community/ArticleItem";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import nodataImg from "@/assets/images/img-nodata@2x.png";
import { fetchGoogleSheetData } from "@/services/util/GoogleSheet";
import { tagData } from "@/data/communityFilter"; // 공통 데이터 임포트

const ArticleContainer = forwardRef(
	({ filterTag, filterTool, limit, sort, showInfo = true, handleTagFilter, handleToolFilter }, ref) => {
		const [loading, setLoading] = useState(true);
		const [filteredArticles, setFilteredArticles] = useState([]);

		const randomSort = (array) => {
			return array.sort(() => Math.random() - 0.5);
		};

		const parseDate = (dateString) => {
			const parts = dateString.split(/[-\/ :]/); // 하이픈(-), 슬래시(/), 콜론(:), 공백으로 구분
			return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4]);
		};

		const getRelativeTime = (date) => {
			const now = new Date();
			const diffTime = now - date;
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			if (diffDays < 30) {
				return `${diffDays}일 전`;
			} else if (diffDays < 365) {
				const diffMonths = Math.floor(diffDays / 30);
				return `${diffMonths}개월 전`;
			} else {
				const diffYears = Math.floor(diffDays / 365);
				return `${diffYears}년 전`;
			}
		};

		const fetchData = async () => {
			setLoading(true);
			try {
				const data = await fetchGoogleSheetData("게시판");
				const articlesData = data.map((row) => ({
					date: parseDate(row[0]), // 작성일시
					name: row[1], // 이름
					email: row[2], // 이메일
					content: row[3], // 의견
					tags: (row[4] || "").split(",").map((tag) => tag.trim()), // 태그
					tool: row[5] || "" // 서비스
				}));

				const filtered = articlesData.filter((article) => {
					const matchTags = filterTag ? article.tags.includes(filterTag) : true;
					const matchTool = filterTool ? article.tool === filterTool : true;
					return matchTags && matchTool;
				});

				let sortedArticles;
				if (sort === "reverse") {
					sortedArticles = [...filtered].reverse();
				} else if (sort === "random") {
					sortedArticles = randomSort([...filtered]);
				} else {
					sortedArticles = filtered.sort((a, b) => b.date - a.date); // 최신순 정렬
				}

				setFilteredArticles(sortedArticles.slice(0, limit ?? sortedArticles.length));
			} catch (error) {
				console.error("Error fetching articles:", error);
			} finally {
				setLoading(false);
			}
		};

		useImperativeHandle(ref, () => ({
			refresh: fetchData
		}));

		useEffect(() => {
			fetchData();
		}, [filterTag, filterTool, limit, sort]);

		return (
			<div className="article-container">
				{loading ? (
					<div className="default-nodata">
						<LoadingSpinner message="데이터를 불러오고 있어요" />
					</div>
				) : filteredArticles.length === 0 ? (
					<div className="default-nodata">
						<div className="nodata-warp">
							<img src={nodataImg} alt="No data" />
							<p>데이터가 없어요.</p>
						</div>
					</div>
				) : (
					<div className="article-list">
						{filteredArticles.map((article, index) => (
							<ArticleItem
								key={index}
								article={{ ...article, relativeDate: getRelativeTime(article.date) }}
								tags={tagData} // tags 전달
								tools={tagData.find((tag) => tag.text === "앨리후기").tools} // tools 전달
								handleTagFilter={handleTagFilter} // 필터링 함수 전달
								handleToolFilter={handleToolFilter} // 필터링 함수 전달
								showInfo={showInfo} // 인포 영역 표시 여부 전달
							/>
						))}
					</div>
				)}
			</div>
		);
	}
);

export default ArticleContainer;
