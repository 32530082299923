import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

const ContrastAanalysis = ({
	contrastRatio,
	imageData,
	currentStep,
	selectedPinId,
	onClose,
	onFindPinClick,
	onSelectedColors,
}) => {
	const [improvedContrasts, setImprovedContrasts] = useState({});
	const ContrastAanalysisRef = useRef(null);
	const select = useSelector((state) => state.brightnessColor || []);
	const allData = useSelector((state) => state);

	const initializeSelectedColors = () => {
		if (!imageData || !select || select.length === 0) return {};

		return imageData.pins.reduce((acc, pin, index) => {
			const selectedColor = select.selectedColor[index + 1];

			const colorIndex = pin.suggestedColor.indexOf(selectedColor);
			acc[pin.id] = colorIndex >= 0 ? pin.suggestedColor[colorIndex] : pin.suggestedColor[0] || "기본 색상";
			return acc;
		}, {});
	};

	const [selectedColors, setSelectedColors] = useState(initializeSelectedColors);
	const handleFindPin = (pinId) => {
		onFindPinClick(pinId);
	};

	useEffect(() => {
		if (onSelectedColors) {
			onSelectedColors(selectedColors);
		}
	}, [selectedColors, onSelectedColors]);

	useEffect(() => {
		if (selectedPinId && ContrastAanalysisRef.current) {
			const selectedElement = ContrastAanalysisRef.current.querySelector(
				`.contrast-info-item[data-pin-id="${selectedPinId}"]`
			);
			if (selectedElement) {
				selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
			}
		}
	}, [selectedPinId]);

	useEffect(() => {
		const newContrasts = imageData.pins.reduce((acc, pin) => {
			const improvedContrast = getContrastRatio(selectedColors[pin.id], pin.bgColor).toFixed(1);
			acc[pin.id] = improvedContrast;
			return acc;
		}, {});

		setImprovedContrasts(newContrasts);
	}, [selectedColors, imageData.pins]);

	if (!imageData) {
		return <div>이미지 데이터가 없습니다.</div>;
	}

	const handleColorChange = (pinId, color) => {

		setSelectedColors((prevColors) => ({
			...prevColors,
			[pinId]: color
		}));
	};

	function getContrastRatio(foreground, background) {
		const luminance = (color) => {
			const a = color.map((v) => {
				v /= 255;
				return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
			});
			return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
		};

		const rgb = (color) => {
			if (!color || typeof color !== "string" || !color.match(/\w\w/g)) {
				return [0, 0, 0]; // 잘못된 값에 대한 기본 처리 (예: 검은색)
			}
			return color.match(/\w\w/g).map((c) => parseInt(c, 16));
		};

		const lum1 = luminance(rgb(foreground));
		const lum2 = luminance(rgb(background));
		const brightest = Math.max(lum1, lum2);
		const darkest = Math.min(lum1, lum2);
		return (brightest + 0.05) / (darkest + 0.05);
	}

	function calculateContrastText(pin) {
		const originalContrast = getContrastRatio(pin.fontColor, pin.bgColor).toFixed(2);
		return originalContrast;
	}

	return (
		<div className={classNames("contrast-analysis", { "step-hide": currentStep === 2 })} ref={ContrastAanalysisRef}>
			<div className="title-area">
				<div className="left-area">
					<h3 className="title3">색상정보 분석</h3>
					<p className="analysis-info">
						{/* [개발] ContrastAddPage에서 선택한 값에 따라 클래스 추가 필요
								AA 4.5:1 선택 시 : level-aa / A 3:1 선택 시 : level-a */}
						{contrastRatio === 4.5 && (
							<span className="level level-aa">
								<span>AA</span>
								<span>{contrastRatio}:1</span>
							</span>
						)}
						{contrastRatio === 3 && (
							<span className="level level-a">
								<span>A</span>
								<span>{contrastRatio}:1</span>
							</span>
						)}
						<span>권장</span> 기준
					</p>
				</div>
				<div className="right-area">
					<ol className="step-area">
						<li className="current" aria-current="step">
							<span className="num">1</span>
							<span className="txt">추천 색상 선택</span>
						</li>
						<li>
							<span className="num">2</span>
							<span className="txt">이미지 변환 결과</span>
						</li>
					</ol>
				</div>
			</div>
			{imageData.pins.length === 0 ? (
				<div className="no-result">
					<i className="ico ico-pass mb-3"></i>
					<p>
						훌륭합니다!
						<br />
						기준을 통과했어요.
					</p>
				</div>
			) : (
				<>
					<div className="contrast-info-container">
						{imageData.pins.map((pin, index) => {
							const originalContrast = calculateContrastText(pin);
							const isDisabled = currentStep === 2;
							return (
								<dl
									key={pin.id}
									data-pin-id={pin.id}
									className={classNames("contrast-info-item", {
										active: pin.id === selectedPinId
									})}
									onClick={() => handleFindPin(pin.id)}
								>
									<dt>
										<span className="num">{index + 1}</span>기준 미달 요소
										{pin.id === selectedPinId && (
											<span className="active checking-area">
												<i className="ico ico-checking-red"></i>확인중
											</span>
										)}
									
									</dt>
									<dd>
										<div className="info-item info-item-before">
											<strong className="title">기존 색상 분석</strong>
											<div className="preview-pannel" style={{ backgroundColor: pin.bgColor }}>
												<p className="text" style={{ color: pin.fontColor }}>
													기존 글자색은
													<br />
													{pin.fontColor} 입니다.
												</p>
												<p className="contrast-ratio">
													<span>명암비</span>
													<strong>
														{originalContrast}:1
														{originalContrast < contrastRatio ? (
															<i className="ico ico-fail-sm"></i>
														) : (
															<i className="ico ico-pass-sm"></i>
														)}
													</strong>
												</p>
											</div>
											<div className="color-info">
												<dl className="color-info-item">
													<dt className="label">
														배경 색상 <small>기준 색상(고정)</small>
													</dt>
													<dd>
														<div className="chip-item">
															<div className="color-value">
																<i className="ico ico-background-sm"></i>
																{pin.bgColor}
															</div>
															<div className="chip" style={{ backgroundColor: pin.bgColor }}></div>
														</div>
													</dd>
												</dl>
												<dl className="color-info-item">
													<dt className="label">글자 색상</dt>
													<dd>
														<div className="chip-item">
															<div className="color-value">
																<i className="ico ico-text-color-sm"></i>
																{pin.fontColor}
															</div>
															<div className="chip" style={{ backgroundColor: pin.fontColor }}></div>
														</div>
													</dd>
												</dl>
											</div>
										</div>
										<div className="info-item info-item-after">
											<strong className="title">
												<i className="ico ico-tool-md-primary"></i>앨리 추천 색상
												<span className="sub-ject">글자 추천 색상을 선택해주세요.</span>
											</strong>
											<div className="preview-pannel" style={{ backgroundColor: pin.bgColor }}>
												<p className="text" style={{ color: selectedColors[pin.id] }}>
													{currentStep === 1 && <>지금 선택된</>}
													{currentStep === 2 && <>변환에 적용한</>} 글자색은
													<br />
													{selectedColors[pin.id]} 입니다.
												</p>
												<p className="contrast-ratio">
													<span>명암비</span>
													<strong>
														{improvedContrasts[pin.id]}:1
														{improvedContrasts[pin.id] < contrastRatio ? (
															<i className="ico ico-fail-sm"></i>
														) : (
															<i className="ico ico-pass-sm"></i>
														)}
													</strong>
												</p>
											</div>
											<div className="color-info">
												<dl className="color-info-item">
													<dt className="label">
														최적화 색상
														{contrastRatio === 4.5 && (
														<span className="level level-aa">
														<span>AA</span>
														<span>{contrastRatio}:1</span>
													</span>
														)}
													 {contrastRatio === 3 && (
															<span className="level level-a">
																		<span>A</span>
																		<span>{contrastRatio}:1</span>
																		</span>
													 )}
													</dt>
													<dd>
														<label
															className={classNames("chip-item", "check-item-xs", {
																checked: selectedColors[pin.id] === pin.suggestedColor[0],
																disabled: isDisabled,
																readonly:
																	pin.suggestedColor.length === 1 || pin.suggestedColor[0] === pin.suggestedColor[1]
															})}
														>
															{/* 같은색상찾기 */}
															<input
																type="radio"
																name={`pick-${pin.id}`}
																checked={selectedColors[pin.id] === pin.suggestedColor[0]}
																onChange={() => handleColorChange(pin.id, pin.suggestedColor[0])}
																disabled={isDisabled}
																readOnly={
																	pin.suggestedColor.length === 1 || pin.suggestedColor[0] === pin.suggestedColor[1]
																}
															/>
															<span className="color-value">{pin.suggestedColor[0]}</span>
															<div className="chip" style={{ backgroundColor: pin.suggestedColor[0] }}></div>
														</label>
													</dd>
												</dl>
												{pin.suggestedColor.length > 1 && pin.suggestedColor[0] !== pin.suggestedColor[1] && (
													<dl className="color-info-item">
														<dt className="label">
															명도 극대화 색상
															{contrastRatio === 4.5 && (
															<span className="level level-aaa">
															<span>AAA</span>
															
															<span>7:1</span>
														</span>
						
															)} 
													{contrastRatio === 3 && (
														<span className="level level-aa">
															<span>AA</span>
															
															<span>4.5:1</span>
														</span>
													)} 
														</dt>
														<dd>
															<label
																className={`chip-item check-item-xs ${
																	selectedColors[pin.id] === pin.suggestedColor[1] ? "checked" : ""
																} ${isDisabled ? "disabled" : ""}`}
															>
																<input
																	type="radio"
																	name={`pick-${pin.id}`}
																	checked={selectedColors[pin.id] === pin.suggestedColor[1]}
																	onChange={() => handleColorChange(pin.id, pin.suggestedColor[1])}
																	disabled={isDisabled}
																/>
																<span className="color-value">{pin.suggestedColor[1]}</span>
																<div className="chip" style={{ backgroundColor: pin.suggestedColor[1] }}></div>
															</label>
														</dd>
													</dl>
												)}
											</div>
											{pin.suggestedColor.length === 1 || pin.suggestedColor[0] === pin.suggestedColor[1] ? (
												<button
													type="button"
													className="btn-question"
													onClick={() =>
														window.alert({
															title: "왜 하나의 색상만 추천해주나요?",
															content: `<p className="text-left">특정 상황에서는 최적의 접근성을 위해 가장 적합한 단일 색상만 제공됩니다.</p>`
														})
													}
												>
													<i className="ico ico-question-md">
														<span className="sr-only">도움말</span>
													</i>
												</button>
											) : null}
										</div>
									</dd>
								</dl>
							);
						})}
					</div>
					{currentStep === 2 && (
						<button type="button" className="btn-close" onClick={onClose}>
							<i className="ico ico-close-black-md"></i>
							<span className="sr-only">닫기</span>
						</button>
					)}
				</>
			)}
		</div>
	);
};
export default ContrastAanalysis;
