import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import LoadingBarFullDimed from "@/components/loading/LoadingBarFullDimed";

import { useDispatch, useSelector } from "react-redux";

import { goDetail, tableDetail } from "@/services/core/workspace/WorkspaceDetail";
import TableWorkDetailPage from "./TableWorkDetailPage";

export default function TableWorkDetailMiddlePage() {
	// popup

	const datas = useSelector((state) => state.table.tableData?.data || []);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { workId } = useParams();
	const queryParams = new URLSearchParams(location.search);
	const isLoad = queryParams.get("isLoad");
	const [isLoading, setIsLoading] = useState(true);
	const handleDetail = async () => {
		setIsLoading(true);
		const response = await goDetail(workId, navigate);
		tableDetail(dispatch, response);
		navigate("/tool/table/work-detail/" + workId + "?isLoad=true");
	};

	useEffect(() => {
		if ((datas?.resData?.workspaceId != workId) & !isLoad) {
			handleDetail();
			setIsLoading(false);
		}
	}, []);
	if (datas?.resData?.workspaceId == workId) {
		return <TableWorkDetailPage></TableWorkDetailPage>;
	} else if (!isLoad) {
		return (
			<>
				<div className="container table-work-detail-page table-work-detail-step1-page">
					<LoadingBarFullDimed isLoading={isLoading} />
				</div>
			</>
		);
	} else {
		return <TableWorkDetailPage></TableWorkDetailPage>;
	}
}
