import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@/components/button/Button";
import { tagData } from "@/data/communityFilter";
import Toast from "../toast/Toast";

const GOOGLE_SHEET_URL =
	"https://script.google.com/macros/s/AKfycbxs2v7dEAdJvU7L-QamKtwL6bguyCQa8HhQ-uSTDnuiirEjIdTsWNivtpUhWIDS92On/exec";

const CommunityInput = ({ placeholder, placeholderEmoji, filterTag, filterTool, onSubmitSuccess }) => {
	const [content, setContent] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);
	const maxCharCount = 10;

	// Redux에서 사용자 데이터 가져오기
	const userData = useSelector((state) => state.user?.userData);
	const userEmail = userData?.data?.email || "";
	const userName = userData?.data?.name || "";

	const submitArticle = async (article) => {
		try {
			const response = await fetch(GOOGLE_SHEET_URL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({ sheetName: "게시판", ...article }),
				mode: "no-cors" // CORS 문제를 피하기 위해 사용, 실제 응답을 처리하지 않음
			});
			return "Success";
		} catch (error) {
			console.error("Error submitting article:", error);
			return "Fail";
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			// 플레이스홀더에 연동된 태그값을 저장
			const associatedTag = tagData.find((tag) => tag.placeholder === placeholder)?.text || filterTag;
			const newArticle = {
				userName: userName,
				userId: userEmail,
				content: content,
				tags: associatedTag ? [associatedTag] : [],
				tool: filterTool
			};
			const result = await submitArticle(newArticle);
			if (result === "Success") {
				setToastMessage("글이 등록되었습니다.");
				setContent(""); // 입력란 초기화
				if (onSubmitSuccess && typeof onSubmitSuccess === "function") {
					onSubmitSuccess(); // 목록 갱신을 트리거
				}
			} else {
				throw new Error("Failed to submit article");
			}
		} catch (error) {
			console.error("Error submitting article:", error);
			setToastMessage("등록에 실패했어요.");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{toastMessage && (
				<Toast
					message={toastMessage}
					duration={3500}
					onClose={() => setToastMessage(null)}
					icon={<i className="ico ico-add-sm"></i>}
					position="bottom"
					theme="dark"
				/>
			)}
			<form className="community-input" onSubmit={handleSubmit}>
				<fieldset>
					<legend className="sr-only">의견 남기기</legend>
					<div className="emoji">{placeholderEmoji}</div>
					<input
						type="text"
						className="form-control"
						placeholder={placeholder}
						title={placeholder}
						value={content}
						onChange={(e) => setContent(e.target.value)}
						required
					/>
					<Button
						type="submit"
						text={
							content.length === 0
								? "등록"
								: content.length >= maxCharCount
								? "등록"
								: `${content.length}/${maxCharCount}`
						}
						isLoading={isLoading}
						size="md"
						color="primary"
						disabled={isLoading || content.length < maxCharCount}
					/>
				</fieldset>
			</form>
		</>
	);
};

export default CommunityInput;
