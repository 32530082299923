import userEvent from "@testing-library/user-event";
import React, { useEffect, forwardRef, useState, useImperativeHandle } from "react";

const HtmlPreview = forwardRef(
	({ initialCode, onContentChange, focusedSelector, userInput, tableCaption, onRendered }, ref) => {
		// const [htmlCode, setHtmlCode] = useState(initialCode); 
		
		useEffect(() => {
			// 초기 렌더링
			
			if (ref.current) {
				ref.current.innerHTML = initialCode;

				// onRendered가 함수인지 확인 후 호출
				if (typeof onRendered === "function") {
					onRendered(true);
				}
			}
			
			// 선택된 캡션으로 <caption> 태그의 내용을 업데이트
			if (ref.current && tableCaption) {
				const captionElement = ref.current.querySelector("caption");
				if (captionElement) {
					captionElement.textContent = tableCaption;
				} else {
					// <caption> 태그가 없는 경우, 생성 추가
					const table = ref.current.querySelector("table");
					const newCaptionElement = document.createElement("caption");
					newCaptionElement.textContent = tableCaption;
					table.prepend(newCaptionElement); // 테이블의 첫 번째 자식으로 캡션 추가
				}
			}

			// `userInput` 객체를 순회하여 DOM 업데이트
			if (ref.current && userInput) {
				Object.entries(userInput).forEach(([selector, value]) => {
					const element = ref.current.querySelector(selector);
					if (element) {
						element.textContent = value; // 또는 innerHTML 등 적절한 속성 사용
					}
				});
			}

			// 부모 컴포넌트에 변화된 HTML 코드 전달
			if (typeof onContentChange === "function") {
				onContentChange(ref.current.innerHTML);
			}

		}, [initialCode, onContentChange, userInput, tableCaption, onRendered, ref]);

		useEffect(() => {
			// 포커스된 요소의 셀렉터를 사용하여 해당 요소를 찾고, 클래스를 추가하거나 제거
			const focusedElement = document.querySelector(focusedSelector);
			if (focusedElement) {
				focusedElement.classList.add("a11y-warning");

				// onBlur 이벤트나 다른 방법을 사용하여 포커스가 벗어났을 때 클래스 제거
				return () => {
					focusedElement.classList.remove("a11y-warning");
				};
			}
		}, [focusedSelector, ref]);


		return <div className="html-preview" id="html-preview" ref={ref}></div>;
	}
);

export default HtmlPreview;
