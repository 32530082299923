import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "@/components/loading/LoadingSpinner";

const TallyIframe = ({ dataTallyOpen, frameTitle, className, userData }) => {
	const iframeRef = useRef(null);
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const [isThankYouPage, setIsThankYouPage] = useState(false); // 감사 페이지 상태 추가

	// 기본 Tally URL
	const baseTallyUrl = `https://tally.so/embed/${dataTallyOpen}`;

	// userData 객체를 쿼리 스트링으로 변환
	const queryString = userData
		? Object.entries(userData)
				.filter(([_, value]) => value !== undefined && value !== null)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join("&")
		: "";

	const tallyUrl = `${baseTallyUrl}${
		queryString ? `?${queryString}&` : "?"
	}alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`;

	// // 로그 추가: URL이 제대로 생성되었는지 확인
	// useEffect(() => {
	// 	console.log("Generated Tally URL:", tallyUrl);
	// }, [tallyUrl]);

	useEffect(() => {
		const widgetScriptSrc = "https://tally.so/widgets/embed.js";
		const loadTallyEmbeds = () => {
			if (typeof window.Tally !== "undefined") {
				window.Tally.loadEmbeds();
			}
		};

		if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
			const script = document.createElement("script");
			script.src = widgetScriptSrc;
			script.onload = loadTallyEmbeds;
			script.onerror = loadTallyEmbeds;
			document.body.appendChild(script);
		} else {
			loadTallyEmbeds();
		}

		let initialLoad = true;
		const observer = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.attributeName === "style") {
					const iframe = mutation.target;
					if (iframe.offsetHeight > 0 && !iframeLoaded) {
						setIframeLoaded(true);
					}
					if (!initialLoad && iframe.offsetHeight < 390) {
						setIsThankYouPage(true);
					} else {
						setIsThankYouPage(false);
					}
				}
			}
			initialLoad = false;
		});

		if (iframeRef.current) {
			observer.observe(iframeRef.current, { attributes: true });
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<div className={`tally-iframe ${className || ""} ${isThankYouPage ? "thank-you-page" : ""}`}>
			{!iframeLoaded && (
				<div className="loading-area">
					<LoadingSpinner />
				</div>
			)}
			<iframe
				className={iframeLoaded ? "loaded" : ""} // iframeLoaded가 true이면 'loaded' 클래스 추가
				ref={iframeRef}
				data-tally-src={tallyUrl}
				loading="lazy"
				title={frameTitle}
			></iframe>
		</div>
	);
};

export default TallyIframe;
