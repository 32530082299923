import api from "@/services/util/Interceptor";
import { IMG2_AUTO_SAVE, IMG2_HTML_DOWNLOAD, IMG2_SHARE, UMG2_HTML_CREATE, UMG2_HTML_RECREATE } from "../url/URL";

export const htmlCreate = async (fileName, filyType, file) => {

	try {
		const formData = new FormData();
		formData.append("img", file);

		const queryParams = new URLSearchParams({
			file_type: filyType,
			file_name: fileName
		}).toString();

		const url = `${UMG2_HTML_CREATE}?${queryParams}`; // 최종 URL 생성

		const resp = await api.post(url, formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		});

		return { response: resp, message: "Success" };
	} catch (error) {
		if (error.code=="ERR_CANCELED"){
			return {
				message: "ERR_CANCELED"
				
			};
		}else{
			console.error("이미지2 HTML 생성 실패");
			alert({ message: "잠시 후 다시 시도해주세요." });
			return { response: error, message: "Fail" };;	
		}
		
	}

	return;
};

export const htmlReCreate = async (ihId) => {
	try {
		const resp = await api.post(UMG2_HTML_RECREATE, {
			ihId: ihId
		});
		return { response: resp, message: "Success" };
	} catch (error) {
		console.error("이미지 새로만들기 실패", error);
		alert({ message: "잠시 후 다시 시도해주세요." });
		return { response: error, message: "Fail" };
	}
};

export const htmlDownLoad = async (ihId, workspace_name) => {
	try {
		const url = `${IMG2_HTML_DOWNLOAD}${ihId}?workspace_name=${encodeURIComponent(workspace_name)}`;
		const resp = await api.get(url);
		return resp;
	} catch (error) {
		console.error("다운로드 실패:", error);
		alert({ message: "잠시 후 다시 시도해주세요." });
		return;
	}
};

export const htmlShare = async (ihId) => {
	try {
		const resp = await api.post(
			IMG2_SHARE,
			{},
			{
				// 요청 본문을 빈 객체로 설정
				params: {
					ih_id: ihId
				}
			}
		);
		return { response: resp, message: "Success" };
	} catch (error) {
		console.error("공유하기 실패", error)
		alert({ message: "잠시 후 다시 시도해주세요." });
		return;
	}
};

export const htmlAutoSave = async (ihId, html) => {
	try {
		const resp = await api.post(IMG2_AUTO_SAVE, {
			ihId: ihId,
			html: html
		});
		return resp;
	} catch (error) {
		console.error("AutoSave Fail", error);
		return "Fail";
	}
};
