import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ToastAlert = ({ message, actions, onClose, icon, theme, position }) => {
	const [showToast, setShowToast] = useState(true);
	// let lastFocusedElement = null;

	const toastRef = useRef(null);

	useEffect(() => {
		if (toastRef.current) {
			toastRef.current.classList.add(`open-${position}`);
			toastRef.current.tabIndex = -1;
			toastRef.current.focus();
		}

		return () => {};
	}, [position]);

	const handleCloseClick = () => {
		if (toastRef.current) {
			toastRef.current.classList.add(`close-${position}`);
		}

		setTimeout(() => {
			onClose();
			setShowToast(false);
		}, 500);
	};

	const getToastClass = () => {
		let toastClass = `toast open-${position}`;

		if (theme === "dark") {
			toastClass += " dark-theme";
		}
		return toastClass;
	};

	return (
		showToast && (
			<div ref={toastRef} className={getToastClass()}>
				{icon && <span className="toast-icon">{icon}</span>}
				<p className="message">{message}</p>
				{actions && actions.length > 0 && <div className="toast-actions">{actions}</div>}
				<button type="button" className="btn-toast-close" onClick={handleCloseClick}>
					<i className="ico ico-close-xs"></i>
					<span className="sr-only">닫기</span>
				</button>
			</div>
		)
	);
};

ToastAlert.propTypes = {
	message: PropTypes.string.isRequired,
	actions: PropTypes.arrayOf(PropTypes.element),
	onClose: PropTypes.func.isRequired,
	icon: PropTypes.element,
	theme: PropTypes.oneOf(["light", "dark"]), // 밝은 테마 또는 어두운 테마
	position: PropTypes.oneOf(["top", "bottom"]) // 상단 (top) 또는 하단 (bottom)
};

export default ToastAlert;
