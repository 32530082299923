import React, { useState } from "react";
import { Link } from "react-router-dom";

import PopupPrivacy from "@/pages/popup/PopupPrivacy";
import PopupOpenlic from "@/pages/popup/PopupOpenlic";
import PopupAgreement from "@/pages/popup/PopupAgreement";

const menuItems = [
	{
		name: "회원",
		subItems: [
			{ name: "로그인", link: "/login" },
			// { name: "계정찾기", link: "/findid" },
			// { name: "계정찾기 결과", link: "/findid/result" },
			{ name: "회원가입", link: "/join" },
			{ name: "비밀번호재설정", link: "/findpw" }
		]
	},
	{
		name: "내 작업",
		subItems: [
			{ name: "대시보드", link: "/dashboard" },
			{ name: "내 작업공간", link: "/workspace" },
			{ name: "휴지통", link: "/delete" }
		]
	},
	{
		name: "서비스",
		subItems: [
			{ name: "이미지 HTML 변환기", link: "/tool/img2html/add" },
			{ name: "이미지 HTML 결과", link: "/tool/img2html/work-detail" },
			{ name: "이미지 명도대비 최적화", link: "/tool/contrast/add" },
			{ name: "이미지 명도대비 결과", link: "/tool/contrast/work-detail" },
			{ name: "SEO 메타태그 생성", link: "/tool/seo/add" },
			{ name: "SEO 메타태그 결과", link: "/tool/seo/work-detail" },
			{ name: "표 접근성 최적화 입력", link: "/tool/table/add" },
			{ name: "표 접근성 최적화 결과", link: "/tool/table/work-detail" },
			{ name: "웹 접근성 문법 검사기", link: "/tool/linter/work-detail" }
		]
	},
	{
		name: "설정",
		subItems: [
			{ name: "계정 정보", link: "/setting/account" },
			{ name: "사용권", link: "/setting/license" }
		]
	},
	{
		name: "기타",
		subItems: [
			{ name: "개인정보보호정책", link: "/privacy", popupId: "privacyPopup" },
			{ name: "이용약관", link: "/agreement", popupId: "agreementPopup" },
			{ name: "오픈소스 라이선스 고지", link: "/openlic", popupId: "openlicPopup" },
			{ name: "오류 페이지", link: "/error" }
		]
	},
	{
		name: "이메일 템플릿",
		subItems: [
			{
				name: "이메일 템플릿 페이지",
				link: "http://pms.inseq.co.kr/a11y/a11y-front/files/develop/public/email/EmailTemplatePage.html"
			},
			{
				name: "이메일 회원가입 완료",
				link: "http://pms.inseq.co.kr/a11y/a11y-front/files/develop/public/email/EmailJoinPage.html"
			},
			{
				name: "이메일 회원가입 인증",
				link: "http://pms.inseq.co.kr/a11y/a11y-front/files/develop/public/email/EmailJoinAccreditPage.html"
			},
			{
				name: "이메일 비밀번호 인증",
				link: "http://pms.inseq.co.kr/a11y/a11y-front/files/develop/public/email/EmailPwAccreditPage.html"
			}
		]
	},
	{
		name: "서비스 결과 공유하기",
		subItems: [{ name: "이미지 HTML 결과 공유하기 페이지", link: "/share/sample" }]
	}
];

const GuideHeader = () => {
	// 팝업 상태를 관리하는 상태 변수들
	const [isPopupPrivacyOpen, setIsPopupPrivacyOpen] = useState(false);
	const [isPopupOpenlicOpen, setIsPopupOpenlicOpen] = useState(false);
	const [isPopupAgreementOpen, setIsPopupAgreementOpen] = useState(false);

	// 팝업을 열기 위한 함수들
	// 개인정보처리방침
	const openPopupPrivacy = () => setIsPopupPrivacyOpen(true);
	const closePopupPrivacy = () => setIsPopupPrivacyOpen(false);

	// 오픈소스라이선스 고지
	const openPopupOpenlic = () => setIsPopupOpenlicOpen(true);
	const closePopupOpenlic = () => setIsPopupOpenlicOpen(false);

	// 서비스 이용약관
	const openPopupAgreement = () => setIsPopupAgreementOpen(true);
	const closePopupAgreement = () => setIsPopupAgreementOpen(false);

	const popupFunctions = {
		privacyPopup: openPopupPrivacy,
		agreementPopup: openPopupAgreement,
		openlicPopup: openPopupOpenlic
	};

	return (
		<>
			<ul className="gnb-list">
				{menuItems.map((item) => (
					<li key={item.name}>
						{item.link ? <Link to={item.link}>{item.name}</Link> : <strong>{item.name}</strong>}
						{item.subItems && (
							<ul>
								{item.subItems.map((subItem) => (
									<li key={subItem.name}>
										{subItem.popupId ? (
											<Link
												to="#"
												onClick={() => {
													const popupFunction = popupFunctions[subItem.popupId];
													if (popupFunction) {
														popupFunction();
													}
												}}
											>
												{subItem.name}
											</Link>
										) : (
											<Link to={subItem.link}>{subItem.name}</Link>
										)}
									</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
			<PopupPrivacy isOpen={isPopupPrivacyOpen} closePopup={closePopupPrivacy} />
			<PopupOpenlic isOpen={isPopupOpenlicOpen} closePopup={closePopupOpenlic} />
			<PopupAgreement isOpen={isPopupAgreementOpen} closePopup={closePopupAgreement} />
		</>
	);
};

export default GuideHeader;
