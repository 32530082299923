import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ListTask from "@/components/list/ListTask";
import ListTaskCard from "@/components/list/ListTaskCard";
import Dropdown from "@/components/form/Dropdown";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import Pagination from "@/components/navigation/Pagination";
import PopupSearch from "@/pages/popup/PopupSearch";
import { useDispatch, useSelector } from "react-redux";
import { workSpaceData, workSpaceLoaded, workSpaceLoading } from "@/services/store/action/WorkSpaceAction";
import { DashBoard } from "@/services/core/workspace/DashBoard";

import ListTaskCardWork from "@/services/core/workspace/ListTaskCardWork";
import { TrashingIt, TrashingListIt } from "@/services/core/workspace/TrashingIt";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { setDashboardData } from "@/services/store/action/DashBoard";
import ListTaskWork from "@/services/core/workspace/ListTaskWork";
import { formatDate } from "@/services/util/TransForm";
import { navigateTo } from "@/services/util/Navigate";
import { getCookie } from "@/services/cookies/UserCookies";
import { setWorkActive, setWorkOrderBy } from "@/services/store/action/SortOrderAction";

export default function WorkspacePage({ hasNavigation = true }) {
	const dispatch = useDispatch();
	const workListData = useSelector((state) => state.sort || []);
	const workCardData = useSelector((state) => state.workCardSort || []);

	// evaluateDesc 함수를 호출하여 결과를 출력
	const navigate = useNavigate();

	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [selectedItems, setSelectedItems] = useState(new Set());

	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const data = useSelector((state) => state.workspace?.workSpaceData?.data || []);
	const listDataRowNumber = useSelector((state) => state.workspace?.workSpaceData?.data?.[0]?.rownum - 1 || 0);

	const [count, setCount] = useState(15);
	const [after, setAfter] = useState(null);
	const [orderBy, setOrderBy] = useState(workListData.orderBy);
	const [desc, setDesc] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [stateWork, setStateWork] = useState("work");
	const [serviceType, setServiceType] = useState(null);
	const isLoading = useSelector((state) => state.workspace.isLoading);
	const meta = useSelector((state) => state.workspace.workSpaceData.meta || []);
	const [resetSignal, setResetSignal] = useState(0); // 초기값은 0
	const descState = useSelector((state) => state.sort.desc);
	const descValue = Object.values(descState).find((value) => value !== null);
	const [specialCondition, setSpecialCondition] = useState(true);
	const [newServiceType, setNewServiceType] = useState(null);
	const [firstState, setFirstState] = useState(true);
	const handleResetClick = () => {
		setSelectedItems(new Set());
		setSelectAllChecked(false);
		setSelectedItemsCount(0);
		setResetSignal((prev) => prev + 1); // 버튼 클릭시 값 증가
	};
	const [totalPages, setTotalPages] = useState(0);
	const fetchData = async (after, count = 15, search = "", service_type = serviceType, order_by = orderBy, desc) => {
		if (newServiceType === serviceType) {
			try {
				dispatch(workSpaceLoading());

				const res_data = await DashBoard(after, count, search, service_type, order_by, desc);
				dispatch(workSpaceData(res_data));

				if (res_data && res_data.meta && res_data.meta.totalCount) {
					setTotalPages(Math.ceil(res_data.meta.totalCount / count));
				} else {
					setTotalPages(0);
					setCurrentPage(1);
				}
			} catch (error) {
				navigateTo("/error");
			} finally {
				dispatch(workSpaceLoaded());
			}
		} else {
			try {
				const toAfter = 0;

				dispatch(workSpaceLoading());

				const res_data = await DashBoard(toAfter, count, search, service_type, order_by, desc);
				dispatch(workSpaceData(res_data));
				if (res_data && res_data.meta && res_data.meta.totalCount) {
					setTotalPages(Math.ceil(res_data.meta.totalCount / count));
					setNewServiceType(serviceType);
					setAfter(0);
				} else {
					setTotalPages(0);
					setCurrentPage(1);
				}
			} catch (error) {
				navigateTo("/error");
			} finally {
				dispatch(workSpaceLoaded());
			}
		}
	};
	const handlePageChange = async (page) => {
		setCurrentPage(page);
		setAfter((page - 1) * count);
	};

	useEffect(() => {
		if (currentPage > totalPages) {
			if (specialCondition) {
				fetchData(0, count, "", serviceType, orderBy, descValue);
			} else {
				fetchData(0, count, "", serviceType, orderBy, desc);
			}
		} else {
			if (specialCondition) {
				fetchData(after, count, "", serviceType, orderBy, descValue);
			} else {
				fetchData(after, count, "", serviceType, orderBy, desc);
			}
		}
	}, [after, count, orderBy, desc, descValue, serviceType]);

	const typeTransformations = {
		img_html: "img2html",
		img_improve: "contrast",
		web_standard: "table"
	};
	const transformedData = !isLoading
		? data.map((item) => {
				const transformedType = typeTransformations[item.workspaceType] || item.workspaceType;
				return {
					id: item.workspaceId,
					service: {
						name: transformedType,
						type: transformedType
					},
					workName: item.workspaceName,
					fileShared: item.workspaceIsShare,
					workspaceFilePath: item.workspaceFilePath,
					fileType: item.workspaceFileType,
					fileSize: item.workspaceFileSpace,
					lastModified: formatDate(item.updateAt) || formatDate(item.createAt),
					createdDate: formatDate(item.createAt),
					delete: false
				};
		  })
		: [];
	// 검색 팝업
	const [isPopupSearchOpen, setIsPopupSearchOpen] = useState(false);
	const openPopupSearch = () => setIsPopupSearchOpen(true);
	const closePopupSearch = () => setIsPopupSearchOpen(false);

	// tab 메뉴
	const [activeTab, setActiveTab] = useState(() => {
		// 저장된 탭 상태 불러오기, 없으면 listType이 기본값
		return localStorage.getItem("workspaceActiveTab") || "listType";
	});

	// 체크항목 개수
	const [selectedItemsCount, setSelectedItemsCount] = useState(0);
	const handleSelectedItemsCountChange = (count) => {
		setSelectedItemsCount(count);
		// setIsInitialLoad(true);
	};

	// 아래 코드로 변경
	const handleTabClick = (tabName) => {
		setSelectedItems(new Set());
		setSelectAllChecked(false);
		setSelectedItemsCount(0);

		if (tabName === "listType") {
			setOrderBy(workListData.orderBy);
			setDesc(workListData.isActive);
			setSpecialCondition(true);
		}

		if (tabName === "cardTpe") {
			setOrderBy(workCardData.orderBy);
			setDesc(workCardData.desc);
			setSpecialCondition(false);
		}

		// localStorage에 현재 선택된 탭 저장
		localStorage.setItem("workspaceActiveTab", tabName);
		setActiveTab(tabName);
	};

	const options = [
		{ value: null, label: "서비스 유형" },
		{ value: "img2html", label: "이미지 HTML 변환기", iconClass: "ico-img2html-sm" },
		{ value: "contrast", label: "이미지 명도대비 최적화", iconClass: "ico-contrast-sm" },
		{ value: "seo", label: "SEO 메타태그 생성기", iconClass: "ico-seo-sm" },
		{ value: "table", label: "표 접근성 최적화", iconClass: "ico-table-sm" }
	];
	const [selectOptionNum, setSelectOptionNum] = useState(0);

	function transformServiceType(value) {
		switch (value) {
			case "img2html":
				return "img_html";
			case "contrast":
				return "img_improve";
			case "table":
				return "web_standard";
			case "seo":
				return "seo";
			default:
				return null; // 매핑되지 않는 경우 원본 값을 그대로 반환
		}
	}
	/// 여기
	const handleSelect = async (option) => {
		const newServiceType = transformServiceType(option ? option.value : null);
		setServiceType(newServiceType);
		// setAfter(0);
		setCurrentPage(1);

		if (option !== null) {
			if (option.value === "img2html") {
				setSelectOptionNum(1);
			}
			if (option.value === "contrast") {
				setSelectOptionNum(2);
			}
			if (option.value === "seo") {
				setSelectOptionNum(3);
			}
			if (option.value === "table") {
				setSelectOptionNum(4);
			}
		} else {
			setSelectOptionNum(0);
		}

		return;
	};

	const handleSelectAllCheckedChange = () => {
		setSelectAllChecked(false);
	};

	const handleSelectedItemsChange = (items) => {
		setSelectedItems(items);
	};

	/// 휴지통 보내기
	const handleTrash = async (id) => {
		const idList = Array.from(id);
		const resp = await TrashingListIt(idList);

		if (resp) {
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));

			fetchData(listDataRowNumber);
			setSelectedItemsCount(0);
			setSelectAllChecked(false);
			setSelectedItems(new Set());
		} else {
			navigateTo("/error");
		}
	};

	return (
		<>
			<div className="container workspace-page">
				<div className="content-box">
					<div className="workitem-wrap">
						<div className="workitem-header">
							<div className="title-area">
								<h3 className="title1">내 작업공간</h3>
								<div className="status-area">
									{/* 체크된 항목이 없을 때만 안내 문구 노출 */}
									{selectedItemsCount === 0 && (
										<div className="dropdown-warp">
											<Dropdown
												title="Dropdown"
												options={options}
												onSelect={handleSelect}
												currentOption={selectOptionNum}
											/>
										</div>
									)}

									{/* 체크된 항목이 1개 이상일 경우 버튼 영역 노출 */}
									{selectedItemsCount > 0 && (
										<div className="checked-btn-area">
											<div className="checked-num">
												<span>{selectedItemsCount}</span>개 선택됨
											</div>
											<button
												type="button"
												onClick={() => handleResetClick()}
												className="btn btn-sm btn-outline-gray pl-3 pr-3"
											>
												<i className="ico ico-cancel-black-xs"></i>
												<span>선택 취소</span>
											</button>
											<button
												type="button"
												className="btn btn-sm btn-outline-gray pl-3 pr-3"
												onClick={() =>
													window.confirm({
														title: "선택한 작업을 휴지통으로 이동하시겠어요?",
														message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
														content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
														onConfirm: (result) => {
															handleTrash(selectedItems);
															// closeMorePopup();
														},
														confirmButtonText: "휴지통에 버리기",
														cancelButtonText: "취소"
													})
												}
											>
												<i className="ico ico-delete-xs"></i>
												<span>휴지통으로 이동</span>
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="btn-area">
								<button type="button" className="btn btn-search" onClick={openPopupSearch}>
									<i className="ico ico-search-lg"></i>
									<span className="sr-only">문서 또는 서비스 검색</span>
								</button>
								{hasNavigation && (
									<nav className="tabmenu tabmenu-type3">
										<ul className="tab-list">
											<li className={activeTab === "listType" ? "on" : ""}>
												<Link to="#listType" onClick={() => handleTabClick("listType")}>
													<i className="ico ico-listview"></i>
													<span className="sr-only">리스트 형식</span>
												</Link>
											</li>
											<li className={activeTab === "cardTpe" ? "on" : ""}>
												<Link to="#cardTpe" onClick={() => handleTabClick("cardTpe")}>
													<i className="ico ico-cardview"></i>
													<span className="sr-only">카드 형식</span>
												</Link>
											</li>
										</ul>
									</nav>
								)}
							</div>
						</div>

						<div className="tab-content workitem-body">
							{/* [개발] 영역 리스트 로딩시 로딩바 적용 */}
							{activeTab === "listType" && (
								<>
									{isLoading ? (
										<div className="loading-area">
											<LoadingSpinner />
										</div>
									) : (
										<ListTaskWork
											reduxSort={"MYPAGE"}
											workItems={transformedData}
											itemCount={transformedData.length}
											onSelectedItemsCountChange={handleSelectedItemsCountChange}
											setOrderBy={setOrderBy}
											desc={desc}
											orderBy={orderBy}
											work={stateWork}
											onWorkNameChangeSuccess={(after, desc) => fetchData(after, 15, "", serviceType, orderBy, desc)}
											trashItSuccess={(after, desc) => fetchData(after, 15, "", serviceType, orderBy, desc)}
											onSelectAllCheckedChange={resetSignal}
											onSelectedItemsChange={handleSelectedItemsChange}
										/>
									)}
									{!isLoading && totalPages > 0 && (
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
									)}
									{/* {isLoading ? (
										<div></div>
									) : (
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
									)} */}
								</>
							)}

							{activeTab === "cardTpe" && (
								<>
									{isLoading ? (
										<div className="loading-area">
											<LoadingSpinner />
										</div>
									) : (
										<ListTaskCardWork
											workItems={transformedData}
											itemCount={transformedData.length}
											onSelectedItemsCountChange={handleSelectedItemsCountChange}
											setOrderBy={setOrderBy}
											setDesc={setDesc}
											desc={desc}
											orderBy={orderBy}
											work={stateWork}
											trashItSuccess={(after, desc) => fetchData(after, 15, "", serviceType, orderBy, desc)}
											onSelectAllCheckedChange={resetSignal}
											// onWorkNameChangeSuccess={(after, desc) => fetchData(after)}
											onWorkNameChangeSuccess={(after, desc) => fetchData(after, 15, "", serviceType, orderBy, desc)}
											onSelectedItemsChange={handleSelectedItemsChange}
											setSelectAllChecked={setSelectAllChecked}
											cardOrder={"work"}
											// setSelectAllChecked={setSelectAllChecked}
										/>
									)}

									{!isLoading && totalPages > 0 && (
										<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* 검색 모달 팝업 */}
			<PopupSearch isOpen={isPopupSearchOpen} closePopup={closePopupSearch} />
		</>
	);
}
