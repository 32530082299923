import api from "@/services/util/Interceptor";
import { USER_WITHDRAWAL } from "../url/URL";
import { sendToGoogleSheet } from "@/services/util/sendToGoogleSheet";

export const Withdrawal = async (userData, reasons) => {
	try {
		// 탈퇴 API 호출
		await api.post(USER_WITHDRAWAL, {});

		// 탈퇴 사유 수집 Google Sheets API 호출 (비동기 처리, 콜백 없이 진행)
		sendToGoogleSheet("탈퇴사유", {
			userId: userData.userEmailId,
			userName: userData.userName,
			reasons: reasons
		});

		return "Success";
	} catch (error) {
		console.error("회원탈퇴 실패", error);
		return "Fail";
	}
};
