import React, { useEffect } from "react";

// scrollToTop HOC 정의
export default function scrollToTop(WrappedComponent) {
  return function (props) {
    useEffect(() => {
      const contentElement = document.getElementById("content");
      if (contentElement) {
        contentElement.scrollTop = 0;
      }
    }, []);

    // 원래 컴포넌트에 props를 그대로 전달하여 렌더링합니다.
    return <WrappedComponent {...props} />;
  };
}
