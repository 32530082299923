// 전화번호에서 하이픈을 제거하는 함수
export const sanitizePhoneNumber = (phone) => {
	return phone.replace(/-/g, "");
};

// url 체크 정규식
function ensureProtocol2(url) {

    return !/^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//.test(url)
}

function isAbsoluteUrl(urlString) {
    // 절대 URL 패턴: 프로토콜을 시작으로 하는지 확인
    const pattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
    return pattern.test(urlString);
}
function ensureProtocol(urlString) {
    // URL이 프로토콜을 포함하고 있는지 확인하고, 없다면 http://를 추가
    if (!/^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//.test(urlString)) {
        urlString = 'http://' + urlString;  // 기본 프로토콜로 http를 추가
    }
    return urlString;
}
function isValidInteger(input) {
    // 입력된 값이 숫자만 포함하는지 정규 표현식을 사용하여 검사
    return /^\d+$/.test(input);
}

const checkDot=(string)=>{
    const regex = /[a-zA-Zㄱ-ㅎ가-힣0-9]+\.$/g;
    return regex.test(string)
}

const spaceCheck =(string)=>{
    const regex = /\s/;
    return regex.test(string)
}
export const isValidUrl = (urlString) => {

	if (isValidInteger(urlString)){
		return false
	}
    console.log(checkDot(urlString),urlString)
    if(checkDot(urlString)){
        
		return false
	}
    if(spaceCheck(urlString)){
        return false
    }

	try {
		const myURL = new URL(ensureProtocol(urlString));
		console.log(myURL)
		const urlSplit = myURL.hostname.split(".")
		console.log(urlSplit)
		return urlSplit.length>1
	} catch (error) {
		
		return false
	}
	
};

