import { PasswordEmailVerification } from "@/services/core/singIn/ChangePassword";
import { EmailAuthenticationCode, EmailCodeValidation } from "@/services/core/singIn/EmailSingIn";
import Timer from "@/services/util/Timer";
import { emailRegex, passwordRegex } from "@/services/util/const";
import { useEffect, useState } from "react";

//[개발] step 1. 이메일 아이디 입력 시 노출 (step2 미노출)
export const FindpwStep1 = ({ status, setEmail, setAuthCode, setIsEvId }) => {
	const [emailError, setEmailError] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isRequestingAuth, setIsRequestingAuth] = useState(false);
	const [isCertiNumberAreaVisible, setIsCertiNumberAreaVisible] = useState(false);
	const [certiNumber, setCertiNumber] = useState("");
	const [isResendDisabled, setIsResendDisabled] = useState(false);
	const [isBtnFormInlineVisible2, setIsBtnFormInlineVisible] = useState(false);
	const [emailDisabled, setEmailDisabled] = useState(false);

	/// 타이머
	const [timeLeft, setTimeLeft] = useState(null);
	const [authStatus, setAuthStatus] = useState("");

	// 비밀번호변경 sns계정 체크
	const [isSns, setIsSns] = useState(false);

	// 인증 요청 버튼
	const handleEmailConfirmation = async () => {
		if (isRequestingAuth) {
			setIsResendDisabled(true);
			setTimeout(() => {
				setIsResendDisabled(false);
			}, 2000);
		}
		// 정규식을 사용하여 이메일 형식 검사
		const isEmailValid = emailRegex.test(userEmail);

		// 이메일이 유효하지 않으면 에러를 표시하고 함수 종료
		if (!isEmailValid) {
			setEmailError(true);
			return;
		}

		// 이메일이 유효하면 에러를 숨기고 인증 요청 로직 실행
		setEmailError(false);

		const verificationResult = await PasswordEmailVerification(userEmail, false);
		if (verificationResult === 0) {
			const authCodeResult = await EmailAuthenticationCode(userEmail, false);
			setEmailDisabled(true);
			if (authCodeResult === true) {
				setIsRequestingAuth(true);
				setIsCertiNumberAreaVisible(true);
				setTimeLeft(300);
				setAuthStatus("");
			}
		} else if (verificationResult === 3) {
			setIsSns(true);
		} else {
			setEmailError(true);
		}
	};

	// 인증번호 입력 확인
	const handleCertiNumberChange = (event) => {
		const number = event.target.value;

		if (/^\d*$/.test(number)) {
			setCertiNumber(number);
			setIsBtnFormInlineVisible(!!number);

			const inputElement = document.getElementById("user-certi-number");
			if (inputElement) {
				inputElement.classList.add("padding-right-100");

				if (!number) {
					inputElement.classList.remove("padding-right-100");
				}
			}
		}
	};
	useEffect(() => {
		let interval = null;
		if (timeLeft > 0) {
			interval = setInterval(() => {
				setTimeLeft(timeLeft - 1);
			}, 1000);
		} else if (timeLeft === 0) {
			clearInterval(interval);
			// 인증번호 요청이 이루어진 상태에서만 expired 상태로 설정
			if (isRequestingAuth) {
				setAuthStatus("expired");
			}
		}

		return () => clearInterval(interval);
	}, [timeLeft, isRequestingAuth]);

	useEffect(() => {
		const isValid = emailRegex.test(userEmail);
		setIsEmailValid(isValid);
	}, [userEmail]);
	useEffect(() => {
		const isInitialEmailValid = emailRegex.test(userEmail);
		setIsEmailValid(isInitialEmailValid);
	}, [userEmail]);

	//인증하기 버튼이후 호출
	const handleVerifyCode = async () => {
		const result = await EmailCodeValidation(userEmail, certiNumber);
		if (result.status === "Success") {
			status("Success");
			setAuthStatus("Success");
			setTimeLeft(null);
			setAuthCode(certiNumber);
			setIsEvId(result.data.data);
		} else {
			status("fail");
			setAuthStatus("fail");
		}
	};

	return (
		<dl className="form-item">
			<dt>
				<label htmlFor="user-email">이메일 아이디</label>
			</dt>
			<dd>
				<div className="input-group">
					<input
						type="text"
						id="user-email"
						className={`form-control ${emailError ? "error" : ""}`}
						placeholder="예) a11y@domain.com"
						onChange={(e) => {
							const newEmail = e.target.value;
							setUserEmail(newEmail);
							setEmail(newEmail);
							const isValid = emailRegex.test(e.target.value);
							setEmailError(!isValid);
							setIsEmailValid(isValid);
							setIsSns(false);
						}}
						disabled={emailDisabled}
					/>
					{/* || !isEmailValid */}

					<span className="input-addon">
						<button
							type="button"
							className={`btn btn-xl btn-gray`}
							disabled={isResendDisabled || !isEmailValid}
							onClick={handleEmailConfirmation}
						>
							{isRequestingAuth ? "인증번호 재전송" : "인증요청"}
						</button>
					</span>
				</div>
				{/* 이메일 잘 못 입력했을 경우 노출 */}
				{userEmail !== "" && isSns && (
					<p className="error-text">
						SNS 계정으로 가입된 경우 비밀번호 재설정이 불가합니다. <br></br>해당 계정으로 다시 로그인 해주세요.
					</p>
				)}
				{userEmail !== "" && emailError && <p className="error-text">올바른 이메일을 입력해주세요.</p>}

				{/* 인증번호 정상적으로 입력 시 해당 input disabled 처리 및 인증하기  버튼 미노출 필요 */}

				<div className="input-btn-group" id="certiNumberArea">
					<input
						type="text"
						id="user-certi-number"
						className="form-control"
						title="메일로 수신된 인증번호 4자리"
						placeholder="메일로 수신된 인증번호 4자리"
						onChange={handleCertiNumberChange}
						value={certiNumber}
						disabled={!isCertiNumberAreaVisible || authStatus === "Success"}
					/>
					{authStatus !== "Success" && isBtnFormInlineVisible2 && (
						<button type="button" className="btn btn-sm btn-primary btn-form-inline" onClick={handleVerifyCode}>
							인증하기
						</button>
					)}
				</div>

				{/* 인증번호 요청 시 노출 */}
				{isCertiNumberAreaVisible && authStatus === "" && timeLeft !== null && (
					<div className="certi-number-area">
						<span>인증번호가 요청되었습니다.</span>
						<span>
							<Timer timeLeft={timeLeft} />
						</span>
					</div>
				)}

				{/* 인증번호가 정상적으로 입력되었을 경우 노출 */}
				{authStatus === "Success" && (
					<div className="certi-number-area waiting">
						<p>인증번호가 확인되었습니다.</p>
					</div>
				)}

				{/* 입력한 인증번호가 잘못된 경우 노출 */}
				{authStatus === "fail" && timeLeft !== null && (
					<div className="certi-number-area invalid">
						<span>올바르지 않은 인증번호입니다.</span>
						<span>
							<Timer timeLeft={timeLeft} />
						</span>
					</div>
				)}

				{/* 인증번호 유효시간 만료된 경우 노출 */}
				{authStatus === "expired" && (
					<div className="certi-number-area invalid">
						<p>인증번호 유효시간이 만료되었습니다. 재전송 버튼을 눌러주세요.</p>
					</div>
				)}
			</dd>
		</dl>
	);
};

//step 2. 비밀번호 재설정 시 노출
export const FindpwStep2 = ({ setPassword, setCheckPassword, isSamePassword, setIsSamePassword }) => {
	const [inputTopPassword, setInputTopPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [isValid, setIsValid] = useState(null);
	const [isConfirmValid, setIsConfirmValid] = useState(null);
	const regex = passwordRegex;

	const handlePasswordChange = (e) => {
		const inputPassword = e.target.value;
		setInputTopPassword(inputPassword);
		setIsSamePassword(false);
		setIsValid(regex.test(inputPassword));
		setPassword(inputPassword);
	};

	const handleConfirmPasswordChange = (e) => {
		const inputConfirmPassword = e.target.value;
		setConfirmPassword(inputConfirmPassword);
		// 비밀번호 일치 여부 업데이트
		if (inputConfirmPassword) {
			setIsConfirmValid(inputTopPassword === inputConfirmPassword);
		}
	};
	useEffect(() => {
		if (confirmPassword !== "") {
			setIsConfirmValid(inputTopPassword === confirmPassword);
		}
	}, [inputTopPassword]);
	useEffect(() => {
		if (isConfirmValid && regex.test(confirmPassword)) {
			setCheckPassword(confirmPassword);
		}
	}, [confirmPassword, isConfirmValid, regex, setCheckPassword, isSamePassword]);
	return (
		<dl className="form-item">
			<dt>
				<label htmlFor="user-new-pw">새 비밀번호</label>
			</dt>
			<dd>
				<div className="input-group-form">
					<input
						type="password"
						id="user-new-pw"
						className="form-control"
						placeholder="새 비밀번호를 입력해주세요.."
						onChange={handlePasswordChange}
					/>
					{/* 조건을 달성하지 못한 경우 : error 클래스 추가 */}

					{isSamePassword ? (
						<div className="certi-number-area mb-3 invalid">
							<p>이전 비밀번호와 동일합니다. 변경된 비밀번호를 입력해주세요.</p>
						</div>
					) : isValid !== false ? (
						<p className="guide-text">
							영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.
						</p>
					) : (
						<div className="certi-number-area mb-3 invalid">
							<p>영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.</p>
						</div>
					)}

					<input
						type="password"
						id="user-new-pw-check"
						title="새 비밀번호를 다시 입력해주세요."
						className="form-control pw-chk"
						placeholder="새 비밀번호를 다시 입력해주세요."
						onChange={handleConfirmPasswordChange}
					/>
					{
						isConfirmValid === false ? (
							<p className="error-text">비밀번호가 일치하지 않습니다.</p>
						) : isConfirmValid === true && isSamePassword === false ? (
							<p className="waiting-text">비밀번호가 일치합니다.</p>
						) : null /* 아무것도 입력되지 않았을 때는 메시지를 표시하지 않음 */
					}
				</div>
			</dd>
		</dl>
	);
};
