import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import InputTaskName from "@/components/form/InputTaskName";
import { WorkSpaceChangeName } from "@/services/core/workspace/WorkSpaceChangeName";

export default function HeaderDetails({
	title,
	className,
	onSave,
	currentTime2,
	subTitle = "새작업!!!!!",
	workspaceId,
	showSaveOptions = true,
	onWorkNameChange,
	saveTime
}) {
	useEffect(() => {
		setSubtitle(subTitle);
	}, [subTitle]);
	// 각 서비스 페이지 정의
	const serviceConfig = {
		"service-img2html": { iconClass: "ico-img2html-md", title: "이미지 HTML 변환기" },
		"service-contrast": { iconClass: "ico-contrast-md", title: "이미지 명도대비 최적화" },
		"service-seo": { iconClass: "ico-seo-md", title: "SEO 메타태그 생성기" },
		"service-table": { iconClass: "ico-table-md", title: "표 접근성 최적화" },
		"service-linter": { iconClass: "ico-linter-md", title: "웹 접근성 문법 검사기 [free]" }
	};
	// 현재 타입 추출
	const currentService = className?.split(" ").find((cls) => cls.startsWith("service-")) || "";

	// 각 서비스 페이지 연결
	const currenServiceConfig = serviceConfig[currentService] || {};

	const [subtitle, setSubtitle] = useState(subTitle);
	const [currentTime, setCurrentTime] = useState("");
	const [toCurrentTime, setToCurrentTime] = useState("");
	const [showSaveOptions2, setShowSaveOptions2] = useState(false);
	// 현재 시간을 업데이트
	const updateCurrentTime = () => {
		const now = new Date();
		const timeString = `${now.getHours()}:${now.getMinutes().toString().padStart(2, "0")}`;
		setCurrentTime(timeString);
		const oneMinuteLater = new Date(now.getTime() + saveTime * 1000);
		const oneMinuteLaterString = `${oneMinuteLater.getHours()}:${oneMinuteLater
			.getMinutes()
			.toString()
			.padStart(2, "0")}`;
		setToCurrentTime(oneMinuteLaterString);
	};

	// 페이지 로드 시 현재 시간 업데이트
	useEffect(() => {
		if (!currentTime2) {
			updateCurrentTime();
		} else {
			setCurrentTime(currentTime2);
			setShowSaveOptions2(true);
		}
	}, [currentTime2]);
	useEffect(() => {
		setShowSaveOptions2(false);
	}, [workspaceId]);
	// 파일명 변경
	const { isOpen: isSmPopup, open: openSmPopup, close: closeSmPopup } = usePopup();
	const [workName, setworkName] = useState(subtitle);

	const handleworkNameChange = (newworkName) => {
		setworkName(newworkName);
		onWorkNameChange(newworkName); // 부모에게 workName 변경 알림/
	};

	const handleworkNameSubmit = async (workspaceId) => {
		await WorkSpaceChangeName(workspaceId, workName);
		setSubtitle(workName); // subtitle을 새 파일명으로 업데이트
		onWorkNameChange(workName); // 부모에게 workName 변경 알림

		closeSmPopup(); // 팝업 닫기
	};
	return (
		<div className={`header-details ${className || ""}`}>
			<h2 className="title3">
				{currentService && <i className={`ico ${currenServiceConfig.iconClass}`}></i>}
				<span>{currenServiceConfig.title || title}</span>
			</h2>

			{showSaveOptions && (
				<>
					<h3 className="title3 tit-file">
						<span>{subtitle}</span>
						<a href="#;" className="btn-edit" onClick={openSmPopup}>
							<i className="ico ico-edit-sm"></i>
							<span className="sr-only">작업명 변경</span>
						</a>
					</h3>
					<div className="info-area">
						<p className="save-text">
							<i className="ico ico-cloud-sm"></i>
							<strong className="time">{currentTime}</strong>
							<span className="text">
								<Link to="/workspace" className="link">
									내 작업공간
								</Link>
								에 자동 저장되었습니다.
							</span>
						</p>
						<p className="warning">앨리는 실수를 할 수 있어요. 중요한 정보를 확인하세요.</p>
					</div>
				</>
			)}

			{!showSaveOptions && (
				<>
					<p className="save-text">
						<span className="text">이 작업은 저장되지 않아요</span>
					</p>
				</>
			)}

			<PopupLayout
				className="edit-popup"
				isOpen={isSmPopup}
				closePopup={closeSmPopup}
				headerContent={null}
				footerContent={
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeSmPopup}>
								취소
							</button>
							<button
								type="button"
								className="btn btn-xl btn-primary"
								onClick={() => handleworkNameSubmit(workspaceId)}
							>
								변경
							</button>
						</div>
					</>
				}
			>
				<h2 className="title3">작업명 변경</h2>
				<InputTaskName workName={workName} onworkNameChange={handleworkNameChange} />
			</PopupLayout>
		</div>
	);
}
