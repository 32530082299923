import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { CHANGE_PASSWORD, EMAIL_FIND } from "../url/URL";
import { useNavigate } from "react-router-dom";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";
import { alertFormatDate } from "@/services/util/TransForm";
export const ChangePassword = async (authCode, email, isEvId, checkPassword) => {

	try {
		const response = await api.post(CHANGE_PASSWORD, {
			authCode: authCode,
			email: email,
			evId: isEvId,
			password: checkPassword,
			headers: { requiresAuth: false }
		});
		return "Success";
	} catch (error) {
		const { status, data } = error.response;
		if (status === 400) {
			if (data.message === "네이버sns을 이용하세요") {
				return 1;
			}
			if (data.message === "구글sns로그인을 이용하세요") {
				return 1;
			}
			if (data.message === "잘못된 이메일") {
				return 2;
			}
			if (data.message === "기존의 비밀번호와 지금의 비빌번호가 일치함") {
				return 3;
			}
		} else {
			alert({ message: "알 수 없는 오류" });
		}

		navigateTo("/error");
		return;
	}
};

export const PasswordEmailVerification = async (email,isJoin=true) => {
	try {
		const response = await api.get(EMAIL_FIND, {
			params: { email,isJoin },
			headers: { requiresAuth: false }
		});

		if (response.status === 200) {
			return 0;
		}
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			if (status === 400) {
				if (data.message === "이메일 확인 완료") {

					return 1;
				} else if (data.message === "이메일 주소와 일치하는 회원이 없음") {
					return 2;
				} else if (data.message === "sns로그인을 이용하세요") {
					return 3;
				} else if (data.message.msg === "탈퇴한 회원입니다.") {
					const dateString = data.message.date;
					const formattedDate = alertFormatDate(dateString);
					alert({title:`[탈퇴] 탈퇴한 사용자입니다.` , message: `${formattedDate} 이후에 가입이 가능합니다.`});
					return;
				} else if (data.message === "차단한 회원입니다.") {
					alert({message: `[블럭] 계정이 잠겼습니다. 운영팀에 문의하세요.`});
					return;
				}
			}
		}
		return 4;
	}
};

