import { RESET_STATE } from "../action/ResetAction";
import { HIDE_TOAST, SHOW_TOAST, UPDATE_TOAST_KEY } from "../action/ToastAction";

const initialState = {
	showToast: false,
	toastKey: 0
};

export const toastReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_TOAST:
			return { ...state, showToast: true };

		case HIDE_TOAST:
			return {
				...state,
				showToast: false
			};
			break;
		case UPDATE_TOAST_KEY:
			return { ...state, toastKey: state.toastKey + 1 };
		case RESET_STATE:
			return initialState;
			break;
		default:
			return state;
	}
};
