import {
	CONTRAST_DASHBOARD_DATA,
	DASHBOARD_LOADED,
	DASHBOARD_LOADING,
	IMG2HTML_DASHBOARD_DATA,
	SEO_DASHBOARD_DATA,
	SET_DASHBOARD_DATA,
	TABLE_DASHBOARD_DATA,
	UPDATE_WORK_NAME_BY_ID
} from "../action/DashBoard";
import { RESET_STATE } from "../action/ResetAction";

const initialState = {
	data: {
		defaultData: [],
		img2htmlData: [],
		seoData: [],
		contrastData: [],
		tableData: []
	},
	isLoading: false
};

const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD_LOADING:
			return {
				...state,
				isLoading: true
			};
		case DASHBOARD_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SET_DASHBOARD_DATA:
			return {
				...state,
				data: {
					...state.data,
					defaultData: action.payload
				}
			};
		case IMG2HTML_DASHBOARD_DATA:
			return {
				...state,
				data: {
					...state.data,
					img2htmlData: action.payload
				}
			};
		case SEO_DASHBOARD_DATA:
			return {
				...state,
				data: {
					...state.data,
					seoData: action.payload
				}
			};
		case CONTRAST_DASHBOARD_DATA:
			return {
				...state,
				data: {
					...state.data,
					contrastData: action.payload
				}
			};
		case TABLE_DASHBOARD_DATA:
			return {
				...state,
				data: {
					...state.data,
					tableData: action.payload
				}
			};

			break;
		default:
			return state;
	}
};

export default dashboardReducer;
