import { getTimeMin } from "@/services/util/DateTimeModule";
import { RESET_STATE } from "../action/ResetAction";

const {
	SET_IMAGE_DATA,

	UPDATE_IMAGE_BRIGHTNESS_SAVE_ITEM,
	REFRESH_SAVE_DATA,
	UPDATE_IMAGE_OUTPUT,
	SAVE_IMAGE_OUTPUT,
	RESET_IMAGE_OUTPUT,
	SET_BRIGHTNESS,
	SET_BRIGHTNESS_STEP,
	RESET_BRIGHTNESS_RESET_TIME
} = require("../action/BrightnessAction");

const initialState = {
	images: []
};

const saveImgState = {
	selectedColor: []
};

const oupPutImgState = {
	outPutImg: null
};

export const saveImgStateReducer = (state = saveImgState, action) => {
	switch (action.type) {
		case REFRESH_SAVE_DATA:
			return {
				...state,
				selectedColor: action.payload
			};
			break;
		case RESET_STATE:
			return saveImgState;
			break;
		default:
			return state;
	}
};

export const outPutImgReducer = (state = oupPutImgState, action) => {
	switch (action.type) {
		case SAVE_IMAGE_OUTPUT:
			return {
				...state,
				outPutImg: action.payload
			};
			break;
		case RESET_IMAGE_OUTPUT:
			return {
				...oupPutImgState
			};
		case RESET_STATE:
			return oupPutImgState;
		default:
			return state;

	}
};

export const brightnessReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_IMAGE_DATA:
			return {
				...state,
				images: {
					...action.payload,
					output: state.images.output
				}
			};
			break;
			
		case UPDATE_IMAGE_BRIGHTNESS_SAVE_ITEM:
			const time = getTimeMin();
			return {
				...state,
				images: {
					...state.images,
					data: {
						...state.images.data,
						saveTime: time
					}
				}
			};
			break;
			case RESET_BRIGHTNESS_RESET_TIME:
				
				return {
					...state,
					images: {
						...state.images,
						data: {
							...state.images.data,
							saveTime: null
						}
					}
				}
				break;
		case SET_BRIGHTNESS:
			return {
				...state,
				isBrightness: action.payload
			};
			break;
		case SET_BRIGHTNESS_STEP:
			return {
				...state,
				step: action.payload
			};
			break;
		case UPDATE_IMAGE_OUTPUT:
			return {
				...state,
				images: {
					...state.images,
					output: action.payload
				}
			};
			break;
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};
