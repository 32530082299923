// import useState from "react";
import { Extension } from "@tiptap/core";
import { NodeSelection, Plugin, PluginKey } from "@tiptap/pm/state";
import { __serializeForClipboard } from "@tiptap/pm/view";

// import { CellSelection, TableMap } from "@tiptap/pm/tables";

function absoluteRect(node) {
	const data = node.getBoundingClientRect();
	return {
		top: data.top,
		left: data.left,
		width: data.width
	};
}

function nodeDOMAtCoords(coords) {
	return document
		.elementsFromPoint(coords.x, coords.y)
		.find(
			(elem) =>
				elem.parentElement?.matches?.(".ProseMirror") ||
				elem.matches(["li", "p:not(:first-child)", "pre", "blockquote", "h1, h2, h3, h4, h5, h6"].join(", "))
		);
}

function nodePosAtDOM(node, view, options) {
	const boundingRect = node.getBoundingClientRect();
	return view.posAtCoords({
		left: boundingRect.left + 50 + options.dragHandleWidth,
		top: boundingRect.top + 1
	})?.inside;
}

function findTablePos(tableWrapper, view) {
	const { doc } = view.state;
	let position = null;
	doc.descendants((node, pos) => {
		if (node.type.name === "table" && tableWrapper.contains(view.nodeDOM(pos))) {
			position = pos;
			return false; // 탐색 중단
		}
	});
	return position;
}

const DragHandle = (options) => {
	const pluginKey = new PluginKey("dragHandle");

	function handleDragStart(event, view) {
		view.focus();
		if (!event.dataTransfer) return;

		const node = nodeDOMAtCoords({
			x: event.clientX + 50 + options.dragHandleWidth,
			y: event.clientY
		});

		if (!(node instanceof Element)) return;
		let nodePos;

		nodePos = nodePosAtDOM(node, view, options);
		if (nodePos == null || nodePos < 0) return;

		view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));

		const slice = view.state.selection.content();
		const { dom, text } = __serializeForClipboard(view, slice);

		event.dataTransfer.clearData();
		event.dataTransfer.setData("text/html", dom.innerHTML);
		event.dataTransfer.setData("text/plain", text);
		event.dataTransfer.effectAllowed = "copyMove";

		event.dataTransfer.setDragImage(node, 0, 0);
		view.dragging = { slice, move: event.ctrlKey };

		const tableWrapper = node.closest(".tableWrapper");
		if (tableWrapper) {
			const tableHTML = tableWrapper.outerHTML;
			const pos = findTablePos(tableWrapper, view);
			if (pos == null || pos < 0) return;
			view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, pos)));
			const slice = view.state.selection.content();

			event.dataTransfer.clearData();
			event.dataTransfer.setData("text/html", tableHTML);
			event.dataTransfer.effectAllowed = "copyMove";

			event.dataTransfer.setDragImage(tableWrapper, 0, 0);
			view.dragging = { slice, move: event.ctrlKey };
			return;
		}
	}

	function handleClick(event, view) {
		view.focus();
		view.dom.classList.remove("dragging");

		const node = nodeDOMAtCoords({
			x: event.clientX + 50 + options.dragHandleWidth,
			y: event.clientY
		});

		// // 테이블 래퍼 요소 확인
		const tableElement = node.closest(".tableWrapper");
		if (tableElement) {
			const pos = findTablePos(tableElement, view);
			if (pos !== null) {
				// 테이블 전체 선택
				const selection = NodeSelection.create(view.state.doc, pos);
				view.dispatch(view.state.tr.setSelection(selection));
				event.preventDefault();
				return;
			}
		}

		if (!(node instanceof Element)) return;

		const nodePos = nodePosAtDOM(node, view, options);
		if (!nodePos) return;

		view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, nodePos)));

		if (options.onClick) {
			options.onClick(event, view);
		}
	}

	let dragHandleElement = null;

	function hideDragHandle() {
		if (dragHandleElement) {
			dragHandleElement.classList.add("hide");
		}
	}

	function showDragHandle() {
		if (dragHandleElement) {
			dragHandleElement.classList.remove("hide");
		}
	}

	return new Plugin({
		key: pluginKey,
		view(view) {
			dragHandleElement = document.createElement("div");
			dragHandleElement.draggable = true;
			dragHandleElement.dataset.dragHandle = "";
			dragHandleElement.classList.add("drag-handle");

			// 접근성 향상을 위한 숨김 텍스트 추가
			const accessibilityText = document.createElement("span");
			accessibilityText.className = "sr-only";
			accessibilityText.textContent = "드래그해서 옮기기 / 클릭해서 메뉴 열기";
			dragHandleElement.appendChild(accessibilityText);

			dragHandleElement.addEventListener("dragstart", (e) => handleDragStart(e, view));
			dragHandleElement.addEventListener("click", (e) => handleClick(e, view));

			hideDragHandle();

			view?.dom?.parentElement?.appendChild(dragHandleElement);

			return {
				update(view, prevState) {},

				destroy() {
					dragHandleElement?.remove();
					dragHandleElement = null;
				}
			};
		},
		props: {
			handleDOMEvents: {
				mousemove(view, event) {
					if (!view.editable) {
						return;
					}

					const node = nodeDOMAtCoords({
						x: event.clientX + 50 + options.dragHandleWidth,
						y: event.clientY
					});

					if (!(node instanceof Element)) {
						hideDragHandle();
						return false;
					}

					const compStyle = window.getComputedStyle(node);
					const lineHeight = parseInt(compStyle.lineHeight, 10);
					const paddingTop = parseInt(compStyle.paddingTop, 10);

					const rect = absoluteRect(node);

					rect.top += (lineHeight - 24) / 2;
					rect.top += paddingTop;
					if (node.matches("ul:not([data-type=taskList]) li, ol li")) {
						rect.left -= options.dragHandleWidth;
					}
					rect.width = options.dragHandleWidth;

					if (!dragHandleElement) return false;

					dragHandleElement.style.left = `${rect.left - rect.width}px`;
					dragHandleElement.style.top = `${rect.top + 2}px`;
					showDragHandle();
				},
				keydown() {
					hideDragHandle();
				},
				mousewheel() {
					hideDragHandle();
				},
				dragstart(view) {
					view.dom.classList.add("dragging");
				},
				drop(view, event) {
					view.dom.classList.remove("dragging");
				},
				dragend(view) {
					view.dom.classList.remove("dragging");
				}
			}
		}
	});
};

const DragAndDropExtension = (options = {}) => {
	return Extension.create({
		name: "dragAndDrop",

		// 확장 기능의 옵션을 설정
		addOptions() {
			return {
				// onDragHandleClick: () => {},
				dragHandleWidth: 20
			};
		},

		// 확장 기능 추가
		addExtensions() {
			return [
				// 필요한 추가 확장 기능 정의
			];
		},

		addProseMirrorPlugins() {
			return [
				DragHandle({
					dragHandleWidth: 20.5,
					onClick: (event, view, editor) => {
						// const clickPosition = { x: event.clientX, y: event.clientY };
						// options.onDragHandleClick(clickPosition);
					}
				})
			];
		}
	});
};

export default DragAndDropExtension;
