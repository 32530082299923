import { useState, useEffect } from "react";

const usePopup = (initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const [triggerElement, setTriggerElement] = useState(null);

	const open = (e) => {
		if (e && e.currentTarget) {
			
			setTriggerElement(e.currentTarget);
		}
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!isOpen && triggerElement) {
			triggerElement.focus();
			setTriggerElement(null);
		}
	}, [isOpen, triggerElement]);

	return { isOpen, open, close };
};

export default usePopup;
