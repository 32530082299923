import React, { useEffect, useState, Children, cloneElement, isValidElement } from "react";
import HeaderHome from "@/components/layouts/HeaderHome";
import Sidebar from "@/components/layouts/Sidebar";
import FooterLanding from "@/components/layouts/FooterLanding";
import CommunityWidget from "@/components/widget/CommunityWidget";
import Toast from "../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { hideToast } from "@/services/store/action/ToastAction";
// import { getCookie } from "@/services/cookies/UserCookies";
import ButtonToTop from "@/components/button/ButtonToTop";
import MobileHeader from "@/components/layouts/MobileHeader";

const LayoutCommunity = ({ children }) => {
	const dispatch = useDispatch();
	const toastState = useSelector((state) => state.toast.showToast);
	const toastKey = useSelector((state) => state.toast.toastKey);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {}, [toastKey, toastState]);

	const handleCloseToast = () => {
		dispatch(hideToast());
	};

	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			return cloneElement(child, { isAuthenticated: isAuthenticated, setIsAuthenticated: setIsAuthenticated });
		}
		return child;
	});

	return (
		<div className={`wrapper layout-community ${isAuthenticated ? "mode-logged" : "mode-public"}`}>
			<>
				{isAuthenticated ? (
					<>
						<MobileHeader />
						<Sidebar />
					</>
				) : (
					<HeaderHome setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
				)}
			</>

			{toastState && (
				<Toast
					key={toastKey}
					message="파일을 내 작업공간에 복원했습니다."
					onClose={handleCloseToast}
					icon={<i className="ico ico-add-sm"></i>}
					position="bottom"
					theme="dark"
				/>
			)}
			<div id="container">
				<article id="content">{childrenWithProps}</article>
				<aside id="widget">
					<CommunityWidget type="default" />
				</aside>
			</div>
			{/* {isAuthenticated && <PopupFeedback />} */}
			{!isAuthenticated && <FooterLanding />}
			<ButtonToTop />
		</div>
	);
};

export default LayoutCommunity;
