import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

function Pagination({ currentPage, totalPages, onPageChange }) {
	const location = useLocation();
	let startPage = Math.max(1, currentPage - 5);
	let endPage = Math.min(totalPages, currentPage + 5);

	if (totalPages <= 10) {
		startPage = 1;
		endPage = totalPages;
	} else {
		if (currentPage <= 5) {
			startPage = 1;
			endPage = 10;
		} else if (currentPage + 4 >= totalPages) {
			startPage = totalPages - 9;
			endPage = totalPages;
		}
	}

	const pageNumbers = Array.from({ length: endPage + 1 - startPage }, (_, i) => i + startPage);

	// 현재 URL을 가져와 페이지 번호를 반영한 URL을 생성하는 함수
	const createPageUrl = (pageNumber) => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set("page", pageNumber);
		return `${location.pathname}?${searchParams.toString()}`;
	};

	return (
		<div className="pagination">
			<ul className="page-num">
				<li>
					<Link
						to={createPageUrl(1)}
						className={`first ${currentPage === 1 ? "disabled" : ""}`}
						onClick={() => onPageChange(1)}
					>
						<span className="sr-only">처음페이지 이동</span>
					</Link>
				</li>
				<li>
					<Link
						to={createPageUrl(Math.max(1, currentPage - 1))}
						className={`prev ${currentPage === 1 ? "disabled" : ""}`}
						onClick={() => onPageChange(Math.max(1, currentPage - 1))}
					>
						<span className="sr-only">이전페이지 이동</span>
					</Link>
				</li>
				{pageNumbers.map((page) => (
					<li key={page}>
						<Link
							to={createPageUrl(page)}
							onClick={() => onPageChange(page)}
							title={page === currentPage ? "현재페이지" : ""}
							className={`num ${page === currentPage ? "active" : ""}`}
						>
							{page}
						</Link>
					</li>
				))}
				<li>
					<Link
						to={createPageUrl(Math.min(totalPages, currentPage + 1))}
						className={`next ${currentPage === totalPages ? "disabled" : ""}`}
						onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
					>
						<span className="sr-only">다음페이지 이동</span>
					</Link>
				</li>
				<li>
					<Link
						to={createPageUrl(totalPages)}
						className={`last ${currentPage === totalPages ? "disabled" : ""}`}
						onClick={() => onPageChange(totalPages)}
					>
						<span className="sr-only">마지막페이지 이동</span>
					</Link>
				</li>
			</ul>
		</div>
	);
}

Pagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired
};

export default Pagination;
