export const RESET_SEO_DATA="RESET_SEO_DATA"
export const UPDATE_SEO_SAVE_TIME ="UPDATE_SEO_SAVE_TIME"
export const UPDATE_SEO_META_TAG="UPDATE_SEO_META_TAG"

export const setSeoData = (seoData) => {
	return {
		type: "SET_SEO_DATA",
		payload: seoData
	};
};


export const setResetSeoData=()=>{
	return {
		type: RESET_SEO_DATA
		
	};

}

export const updateSeoSaveTime=()=>{
	return {
		type: UPDATE_SEO_SAVE_TIME
		
	};

}

export const updateSeoMetaTag=(metaTag)=>{
	return{
		type:UPDATE_SEO_META_TAG,
		payload:metaTag
	}
}

