import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import ServiceOverview from "./ServiceOverview";
import PromotionBanner from "@/components/promotion/PromotionBanner";
import PopularResources from "@/components/community/PopularResources";
import ArticleContainer from "@/components/community/ArticleContainer"; // ArticleContainer 임포트
import FooterLanding from "@/components/layouts/FooterLanding";
import usePopup from "@/hooks/usePopup";
import PopupLogin from "@/pages/popup/PopupLogin";
import { getCookie } from "@/services/cookies/UserCookies";
import CTASection from "./CTASection";

export default function HomePage() {
	const token = getCookie("token");

	const [isPromotionActive, setIsPromotionActive] = useState(false);
	const popupLoginCommon = usePopup();
	const [timeRemaining, setTimeRemaining] = useState("");

	const promotionEndTime = new Date("2024-08-31T23:59:59").getTime();

	const calculateTimeRemaining = useCallback(() => {
		const now = new Date().getTime();
		const difference = promotionEndTime - now;

		if (difference > 0) {
			const days = Math.floor(difference / (1000 * 60 * 60 * 24));
			const hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
			const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
			const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, "0");
			setTimeRemaining(`D-${days} ${hours}시간 ${minutes}분 ${seconds}초`);
		} else {
			setIsPromotionActive(false);
			setTimeRemaining("");
		}
	}, [promotionEndTime]);

	// SVG 변수화
	const A11yEmblem = () => (
		<svg
			className="a11y-embelem"
			width="41"
			height="40"
			viewBox="0 0 41 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="5.13057"
				height="30.5254"
				rx="2.56528"
				transform="matrix(-0.78814 0.615496 0.615496 0.78814 17.1382 12.5898)"
				fill="#1364FF"
			/>
			<rect
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="matrix(-0.78814 0.615496 0.615496 0.78814 25.5308 5.54199)"
				fill="#BAFD02"
			/>
			<rect
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="matrix(0.927105 0.374801 0.374801 -0.927105 3.09326 13.8506)"
				fill="#BAFD02"
			/>
			<rect
				x="17.7573"
				y="2.40137"
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="rotate(82.012 17.7573 2.40137)"
				fill="#BAFD02"
			/>
		</svg>
	);

	useEffect(() => {
		const now = new Date().getTime();
		setIsPromotionActive(now <= promotionEndTime);

		const interval = setInterval(calculateTimeRemaining, 1000);
		return () => clearInterval(interval);
	}, [calculateTimeRemaining, promotionEndTime]);

	useEffect(() => {
		if (token) {
			// 사용자 관련 추가 로직이 있을 경우 여기에 추가
		}
	}, [token]);

	return (
		<>
			<div className="home-page">
				<section className="section section-home">
					<div className="section-header">
						<h2 className="slogan-title">
							<span className="word">웹 콘텐츠에 </span> <br className="d-down-sm" />
							<A11yEmblem />
							<span className="text-secondary">앨리</span>하세요<i>!</i>
						</h2>
						<p className="desc">
							시력과 장애를 넘어,
							<br className="d-down-sm" /> 모두가 함께 누리는 웹 만들어요
						</p>
					</div>
					<div className="section-body">
						<div className="container-md">
							<ServiceOverview />
							<div className="promotion">
								<PromotionBanner type="card-lg" />
							</div>
							<div className="popular">
								<h3 className="title d-up-lg">🔥 지금 막 올라온 리소스</h3>
								<h3 className="title d-down-md">🔥 최신 리소스</h3>
								<PopularResources limit={4} />
								<Link to="/resource" className="more">
									더보기
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<rect x="0.503906" y="0.5" width="19" height="19" rx="9.5" stroke="#D1D5DB" />
										<path
											d="M5.20605 10L14.8012 10"
											stroke="#1F2937"
											strokeWidth="1.3"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.6201 13.1816L14.8021 9.99966L11.6201 6.81768"
											stroke="#1F2937"
											strokeWidth="1.3"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>
							</div>
						</div>
					</div>
				</section>

				<section className="section section-community">
					<div className="section-header">
						<h3 className="title">앨리지수 함께 높여요</h3>
						<p className="desc">웹 접근성 더 널리 알리고 솔직한 이야기 나눠요</p>
						<div className="btn-group-center">
							<Link to="/community" className="btn btn-xl btn-outline-gray">
								커뮤니티 바로가기
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M5.33154 2.34375L10.9884 8.0006L5.33154 13.6575"
										stroke="#1F2937"
										strokeWidth="1.3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Link>
						</div>
					</div>
					<div className="section-body">
						<ArticleContainer
							filterTags={["접근성이란", "아이디어", "앨리후기"]} // 필터 조건
							limit={20} // 불러올 건수 설정
							sort="random" // 랜덤 정렬
						/>
						<ArticleContainer
							filterTags={["접근성어려워요", "응원해요", "아쉬워요"]} // 필터 조건
							limit={20} // 불러올 건수 설정
							sort="random" // 랜덤 정렬
						/>
					</div>
				</section>

				<CTASection />

				<FooterLanding />
			</div>
			<PopupLogin isOpen={popupLoginCommon.isOpen} closePopup={popupLoginCommon.close} />
		</>
	);
}
