import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { DataCount } from "../mypage/MyDataCount";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { DashBoard } from "../workspace/DashBoard";
import { getCookie } from "@/services/cookies/UserCookies";
import { TableCreate } from "./TableApi";
import { setUserData } from "@/services/store/action/LoginAction";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { setTableData } from "@/services/store/action/tableAction";
import { isValidUrl } from "@/services/util/utils";

export const useHandleTableCreate= (textAreaValue, urlInputValue) => {
	const [isLoading, setIsLoading] = useState(false);
    const [noDataTost, setNoDataTost] = useState(false);
	const navigate = useNavigate();
	const tableData = useSelector((state) => state.table?.tableData||[]);
	const userData = useSelector((state) => state.user.userData);
	const allCount = userData?.data.count || null;
	const dispatch = useDispatch();
	const handleTableCheck = async () => {
		// [개발] 로딩을 시뮬레이션하기 위한 지연시간입니다. => 수정 및 변경 필요
		if (!urlInputValue){
			if(isValidUrl(textAreaValue.trim())){
				alert({
					title:"콘텐츠 입력을 다시 확인해 주세요.",
					message:"링크는 링크 입력필드에 입력해야 합니다."
				})
				return false
			}
		}

		if (allCount > 0) {
			window.confirm({
				title: "표를 분석하시겠어요?",
				content: `사용권 1장이 차감됩니다.`,
				confirmButtonText: "생성",
				cancelButtonText: "취소",
				onConfirm: () => handleTableGoCreate()
			});
		} else {
			window.confirm({
				title: "사용권이 없어요.",
				content: "새로운 사용권을 구매하거나 기간이 유효한 사용권코드를 입력해주세요.",
				confirmButtonText: "사용권 구매하기",
				cancelButtonText: "취소",
				onConfirm: () => handleBuyUsage()
			});
			setIsLoading(false);
		}

		// [개발] 사용권 사용컴펌 팝업 노출
	};
	const handleBuyUsage = () => {
		setIsLoading(false);
		navigate("/setting/license");
	};
	const handleTableGoCreate = async () => {
		setIsLoading(true);
		var url = "";
		var html = "";
		if (!urlInputValue) {
			html = textAreaValue;
			var data = {
				html: textAreaValue
			};
		} else if (!textAreaValue) {
			url = urlInputValue;
			var data = {
				url: urlInputValue
			};
		}
		const result = await TableCreate(data);
		// try {

		try {
			if (result.status === "Success") {
				const transformedData = result.data.data.tables.map((item) => {
					
					return {
						id: item.toId,
						caption: {
							text:item.caption,
							status: (item.caption === null) ? 'none' : (item.caption === '') ? 'empty' : 'present',
							recommendedCaptions:[item.summaryCaption ?? null, item.naturalCaption ?? null].filter(item => item !== null)
						},
						htmlCode: item.ori_html,
						oriHtml : item.ori_html,
						isLoading:false
					};
			  })
				const updatedTableData = {
					...tableData,
					data: {
                        resData: result.data.data,				
                        transformedData:transformedData
					}

				};

				dispatch(setTableData(updatedTableData));

				const updatedUserData = {
					...userData,
					data: {
						...userData.data,
						count: userData.data.count - 1
					}
				};

				dispatch(setUserData(updatedUserData));
				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));
			
				const workId = result.data.data.workspaceId;
				setIsLoading(false);
				navigate(`/tool/table/work-detail/${encodeURIComponent(workId)}`);

			}else if(result.status==="ERR_CANCELED"){

			}else if(result.status==="No tables found in the input data"||result.status==="Too short input data : the length of html is too short. Please check the input data."){
				setNoDataTost(true)
				setIsLoading(false);
			}else {
				setIsLoading(false);
				window.alert({
					'title':"오류가 발생하였습니다.",
					"message":"다시 시도해주세요."
	
				})

			}
		} catch(e) {
			console.error(e)
			setIsLoading(false);
			setNoDataTost(true)

		}
	};

	return {
        noDataTost,
		isLoading,
		handleTableCheck,
		setNoDataTost
	};
};
