import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "@/components/form/Dropdown";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import PopupLayer from "@/components/popup/PopupLayer";
import Toast from "@/components/toast/Toast";
import nodataImg from "@/assets/images/img-nodata@2x.png";
import previewImg from "@/assets/images/img-example1.png";
import noImg from "@/assets/images/img-noimg.png"; // 이미지 없을 경우 노출되는 이미지

import TaskData from "@/mock/TaskData";
import { TrashingIt } from "@/services/core/workspace/TrashingIt";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { useDispatch, useSelector } from "react-redux";
import WorkUsePopup from "@/services/core/workspace/WorkSpacePopup";
import { WorkSpaceChangeName } from "@/services/core/workspace/WorkSpaceChangeName";
import InputTaskNameWork from "../form/InputTaskNameWork";
import DropdownWork from "@/services/core/workspace/DropdownWork";
import { handleWorkDetail } from "@/services/core/workspace/WorkspaceDetail";
import { updateDashboardCardListDesc, updateDashboardCardListOrderBy } from "@/services/store/action/SortOrderAction";
import { API_URL } from "@/services/core/url/URL";
import numeral from "numeral";
import { fileSizeDisplay } from "@/services/util/FileSize";

export default function ListTaskCard({
	workItems: initialWorkItems,
	itemCount,
	onSelectedItemsCountChange,
	work,
	onWorkNameChange,
	onSelectAllCheckedChange,
	onTrash
}) {
	const navigate = useNavigate();
	const tableData = useSelector((state) => state.table?.tableData || []);
	const brightness = useSelector((state) => state);
	const seoData = useSelector((state) => state.seo.seoData);
	const dashBoardSort = useSelector((state) => state.dashboardSort.sort.card);
	const html = useSelector((state) => state.html?.htmlData || []);
	useEffect(() => {
		const allRows = document.querySelectorAll("ul.card-list li");

		allRows.forEach((row) => {
			row.classList.remove("checked");
		});
		setWorkItems((prevCheckboxes) => prevCheckboxes.map((checkbox) => ({ ...checkbox, checked: false })));
	}, [onSelectAllCheckedChange]);
	const dispatch = useDispatch();

	// 작업 리스트
	const [workItems, setWorkItems] = useState([]);
	const [selectId, setSelectId] = useState([]);
	const {
		isOpen: isDefaultPopup,
		open: openDefaultPopup,
		close: closeDefaultPopup,
		selectedId,
		selectedName
	} = WorkUsePopup();
	const [userWorkName, setWorkName] = useState("");

	// 더보기 팝업
	const moreButtonRef = useRef([]);
	const [popupStates, setPopupStates] = useState({});
	const [showToastRestore, setShowToastRestore] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState(null);

	const handleToastRestore = (id) => {
		setShowToastRestore((prevShowToast) => ({
			...prevShowToast,
			[id]: true
		}));
	};
	const [orderBy, setOrderBy] = useState(dashBoardSort.orderBy);

	const options14 = [
		{ value: 1, label: "마지막 수정 일시" },
		{ value: 2, label: "작업명" },
		{ value: 3, label: "원본 파일 타입" },
		{ value: 4, label: "원본 파일 크기" },
		{ value: 5, label: "생성일시" }
	];

	useEffect(() => {
		setOrderBy(dashBoardSort.orderBy);
	}, [dashBoardSort]);

	// [개발] options1에서 선택한 값에 따라 options2에서 노출되는 옵션이 달라져야 함
	// 마지막 수정 일시, 생성 일시 선택 시 : 최신순 / 오래된 순 옵션 노출
	// 작업명 선택 시 : 내림차순 / 오름차순 옵션 노출
	// 원본 파일 타입 선택 시 :  options2 Dropdown 전체 미노출
	// 원본 파일 크기 선택 시 : 작은 순 / 큰 순 노출

	const [options1, setOptions1] = useState([
		{ value: 1, label: "마지막 수정 일시" },
		{ value: 2, label: "작업명" },
		{ value: 3, label: "원본 파일 타입" },
		{ value: 4, label: "원본 파일 크기" },
		{ value: 5, label: "생성일시" }
	]);
	const [options22, setOption2s2] = useState([
		[
			{ value: true, label: "최신순" },
			{ value: false, label: "오래된순" }
		],
		[
			{ value: false, label: "내림차순" },
			{ value: true, label: "오름차순" }
		],
		[],
		[
			{ value: false, label: "작은순" },
			{ value: true, label: "큰순" }
		],
		[
			{ value: true, label: "최신순" },
			{ value: false, label: "오래된순" }
		]
	]);

	function reorderOptions(options, desc) {
		return options.map((subOptions) => {
			if (subOptions.length === 0) {
				return subOptions; // 비어있는 배열은 그대로 반환
			}

			// desc 값에 따라 배열 정렬
			const sortedSubOptions = subOptions.sort((a, b) => {
				if (a.value === desc) return -1;
				if (b.value === desc) return 1;
				return 0;
			});

			return sortedSubOptions;
		});
	}

	const reorderedOptions22 = reorderOptions(options22, dashBoardSort.desc);

	const [options2, setOptions2] = useState(options22[orderBy - 1]);
	const [selectedOption1, setSelectedOption1] = useState(orderBy);
	const [selectedOption2, setSelectedOption2] = useState(dashBoardSort.desc);
	const [selectedOptions1, setSelectedOptions1] = useState(options1[orderBy - 1]);
	const [selectedOptions2, setSelectedOptions2] = useState(reorderedOptions22[orderBy - 1][0]);
	const [newDesc, setNewDesc] = useState(dashBoardSort.desc ?? false);

	const handleSelect1 = (option) => {
		dispatch(updateDashboardCardListOrderBy(option.value));
		setSelectedOption1(option.value);
		setSelectedOptions1(option);
		switch (option.value) {
			case 1:
			case 5:
				setOptions2([
					{ value: true, label: "최신순" },
					{ value: false, label: "오래된순" }
				]);
				setSelectedOption2(true);
				setNewDesc(false);
				dispatch(updateDashboardCardListDesc(true));
				break;
			case 2:
				setOptions2([
					{ value: false, label: "내림차순" },
					{ value: true, label: "오름차순" }
				]);
				setSelectedOption2(false);
				setNewDesc(false);
				dispatch(updateDashboardCardListDesc(false));
				break;
			case 3:
				setOptions2([]);
				break;
			case 4:
				setOptions2([
					{ value: false, label: "작은순" },
					{ value: true, label: "큰순" }
				]);
				setSelectedOption2(false);
				setNewDesc(false);
				dispatch(updateDashboardCardListDesc(false));
				break;
			default:
				setOptions2([]);
				break;
		}
	};
	const handleSelect2 = (option) => {
		dispatch(updateDashboardCardListDesc(option.value));
		setSelectedOption2(option.value);
		setSelectedOptions2(option);
	};

	// 리스트 로드 갯수
	useEffect(() => {
		setWorkItems(initialWorkItems.slice(0, itemCount));
	}, [itemCount]);

	useEffect(() => {
		if (workItems.length > 0) {
			handleSort(selectedOption1, selectedOption2);
		}
	}, [selectedOption1, selectedOption2]);
	// 더보기 팝업
	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeMorePopup = (id) => {
		setPopupStates((prevStates) => ({
			...prevStates,
			[id]: false // 특정 작업 항목의 팝업을 닫음
		}));
		setActiveButtonId(null);
	};
	const toggleMorePopup = (id, event) => {
		event.stopPropagation();
		setPopupStates((prevStates) => {
			const newStates = Object.keys(prevStates).reduce((states, itemId) => {
				states[itemId] = false;
				return states;
			}, {});

			newStates[id] = !prevStates[id];
			return newStates;
		});

		setActiveButtonId((prevActiveId) => (prevActiveId === id ? null : id));
	};

	// 체크박스 변경 핸들러
	const handleCheckboxChange = (id, checked) => {
		setWorkItems((currentItems) => currentItems.map((item) => (item.id === id ? { ...item, checked } : item)));
		const selectedCount = workItems.filter((item) => (item.id === id ? checked : item.checked)).length;
		onSelectedItemsCountChange(selectedCount);

		const selectedIds = workItems.filter((item) => item.checked).map((item) => item.id);
		setSelectId(selectedIds);
	};

	const handleWorkNameChange = (newworkName) => {
		setWorkName(newworkName);
	};
	/// 휴지통 보내기
	const handleTrash = async (id) => {
		const resp = await TrashingIt(id);
		if (resp) {
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
			const dashboardData = await DashBoard(null, 10, "", null, 1, true);
			dispatch(setDashboardData(dashboardData));
			onTrash();
		}
		if (resp === "Fail") {
			alert({ message: "휴지통 보내기에 실패하였습니다." });
			return;
		}
	};

	/// 작업명 변경
	const handleChangeWorkName = async () => {
		const resp = await WorkSpaceChangeName(selectedId, userWorkName);

		if (resp === "Success") {
			const dashboardData = await DashBoard(null, 10, "", null, 1, true);
			dispatch(setDashboardData(dashboardData));
			onWorkNameChange();
		}
		if (resp === "Fail") {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};
	const [sortState, setSortState] = useState({ field: null, direction: null });

	const handleSort = (field, desc) => {
		switch (field) {
			case 1:
				field = "lastModified";
				break;
			case 2:
				field = "workName";
				break;
			case 3:
				field = "fileType";
				break;
			case 4:
				field = "fileSize";
				break;
			case 5:
				field = "createdDate";
			default:
				break;
		}
		setSortState({
			field: field,
			direction: desc
		});

		const compare = (a, b) => {
			if (a[field] < b[field]) {
				let pay = !desc ? -1 : 1;
				return pay;
			}
			if (a[field] > b[field]) {
				let pay = !desc ? 1 : -1;
				return pay;
			}
			return 0;
		};

		const getStringType = (str, desc) => {
			// 첫 글자를 추출합니다.
			const firstChar = str.charAt(0);

			if (/^\d/.test(firstChar)) return 4; // 숫자로 시작
			if (/^[!@#$%^&*()_+]/.test(firstChar)) return 3; // 특수문자로 시작
			if (/^[ㄱ-ㅎ가-힣]/.test(firstChar)) return 2; // 한글로 시작
			if (/^[A-Za-z]/.test(firstChar)) return 1; // 영어로 시작

			return 5; // 기타
		};

		// 비교 함수
		const compareStrings = (a, b) => {
			const priorityA = getStringType(a[field], desc);
			const priorityB = getStringType(b[field], desc);

			if (priorityA !== priorityB) {
				return !desc ? priorityA - priorityB : priorityB - priorityA;
			}

			// 같은 우선순위 내에서 값 비교
			if (priorityA < priorityB) {
				return !desc ? -1 : 1;
			} else if (priorityA > priorityB) {
				return !desc ? 1 : -1;
			}
			return 0;
		};
		if (field === "workName") {
			const sortedItems = [...workItems].sort(compareStrings);
			setWorkItems(sortedItems);
		} else {
			const sortedItems = [...workItems].sort(compare);
			setWorkItems(sortedItems);
		}
	};
	const handleDetail = (workspaceType, workspaceId) => {
		if (workspaceType === "seo") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, seoData, true);
		} else if (workspaceType === "table") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, tableData, true);
		} else if (workspaceType === "img2html") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, html, true);
		} else if (workspaceType === "contrast") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, brightness, true);
		}
	};

	return (
		<>
			{workItems.length > 0 ? (
				<>
					<div className="workitem-card-wrap">
						<div className="sort-area">
							<DropdownWork
								title="Dropdown"
								options={options2}
								selectedOptions={selectedOptions2}
								onSelect={handleSelect2}
								desc={newDesc}
								size="sm"
								isDashborad={true}
							/>
							<DropdownWork
								title="Dropdown"
								options={options1}
								selectedOptions={selectedOptions1}
								onSelect={handleSelect1}
								size="sm"
							/>
						</div>
						<ul className="card-list">
							{workItems.map((workitem, index) => (
								<li key={index} className={workitem.checked ? "checked" : ""}>
									<span className="check-item">
										<input
											type="checkbox"
											id={`select-chk${workitem.id}`}
											title={workitem.workName + " 선택"}
											checked={workitem.checked}
											onChange={(e) => handleCheckboxChange(workitem.id, e.target.checked)}
										/>
										<label htmlFor={`select-chk${workitem.id}`}>
											<span className="sr-only">선택</span>
										</label>
									</span>
									<div
										onClick={() => handleDetail(workitem.service.type, workitem.id)}
										tabIndex="0"
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												handleDetail(workitem.service.type, workitem.id);
											}
										}}
									>
										<div className="card-header">
											<h3 className="file-name">
												<span className="file-name">{workitem.workName}</span>
												{workitem.fileShared && (
													<span className="label-shared">
														<i className="ico ico-shared-sm"></i>
														<span className="sr-only">공개된 작업</span>
													</span>
												)}
											</h3>
										</div>
										<div className="card-body">
											<span className={`img ${!workitem.workspaceFilePath ? "no-img" : ""}`}>
												<img
													src={workitem.workspaceFilePath ? `${workitem.workspaceFilePath}` : noImg}
													alt=""
													onError={(e) => {
														e.target.src = noImg;
														e.target.parentElement.classList.add("no-img");
													}}
												/>
											</span>
										</div>
										<div className="card-footer">
											<span className="service-type">
												<i className={`ico ico-${workitem.service.type}-md`}></i>
												<span className="sr-only">{workitem.service.name}</span>
											</span>
											<span className="data-area">
												<span className="file-size">{fileSizeDisplay(workitem.fileSize)}</span>
												<span className="date-time">{workitem.createdDate}</span>
											</span>
										</div>
									</div>
									<div className="more-action-wrap">
										<span className={`more-action ${activeButtonId === workitem.id ? "on" : ""}`}>
											<button
												type="button"
												className="btn-action"
												onMouseDown={handleButtonMouseDown}
												onClick={(e) => toggleMorePopup(workitem.id, e)}
												ref={(el) => (moreButtonRef.current[workitem.id] = el)}
											>
												<i className="ico ico-more-action"></i>
												<span className="sr-only">더보기</span>
											</button>
										</span>
										{/* 더보기 레이어 팝업 */}
										<PopupLayer
											className="more-popup"
											isOpen={popupStates[workitem.id]}
											closePopup={() => closeMorePopup(workitem.id)}
											title="더보기 팝업"
											headerContent={null}
											footerContent={null}
											buttonRef={(el) => (moreButtonRef.current[workitem.id] = el)}
											position="top"
										>
											<div className="info-detail">
												<div className="btn-group">
													{work === "work" ? (
														<button
															type="button"
															className="btn btn-md"
															onClick={(e) => {
																openDefaultPopup(workitem.id, workitem.workName, e);
																setWorkName(workitem.workName);
															}}
														>
															작업명 변경
														</button>
													) : (
														<button className="btn btn-md btn-restore" onClick={() => handleToastRestore(workitem.id)}>
															복원
														</button>
													)}
													{work === "work" ? (
														<button
															type="button"
															className="btn btn-md"
															onClick={() => {
																window.confirm({
																	title: "선택한 작업을 휴지통으로 이동하시겠어요?",
																	message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
																	content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
																	onConfirm: (result) => {
																		handleTrash(workitem.id);
																		closeMorePopup();
																	},
																	confirmButtonText: "휴지통에 버리기",
																	cancelButtonText: "취소"
																});
															}}
														>
															이 작업 휴지통으로 이동
														</button>
													) : (
														<button
															type="button"
															className="btn btn-md btn-permanent-del"
															onClick={() =>
																window.confirm({
																	title: "이 작업을 영구 삭제할까요?",
																	message: "삭제 후 복구할 수 없습니다.",
																	confirmButtonText: "영구 삭제",
																	cancelButtonText: "취소",
																	buttonStatus: "invalid"
																})
															}
														>
															이 작업 영구 삭제
														</button>
													)}
												</div>
											</div>
										</PopupLayer>
									</div>
								</li>
							))}
						</ul>
					</div>
				</>
			) : (
				<div className="default-nodata">
					<div className="nodata-warp">
						<img src={nodataImg} alt="" />
						<p>
							작업 공간이 준비되었습니다!<br></br>
							작업을 생성해주세요.
						</p>
					</div>
				</div>
			)}

			<PopupLayout
				isOpen={isDefaultPopup}
				closePopup={closeDefaultPopup}
				title="작업명 변경"
				size="default"
				className="change-work-name-popup"
				footerContent={
					// null //  버튼이 없을 경우
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeDefaultPopup}>
								취소
							</button>
							<button type="button" className="btn btn-xl btn-primary" onClick={handleChangeWorkName}>
								변경
							</button>
						</div>
					</>
				}
			>
				<InputTaskNameWork
					workName={userWorkName}
					onworkNameChange={handleWorkNameChange}
					closeMorePopup={closeMorePopup}
				></InputTaskNameWork>
			</PopupLayout>
		</>
	);
}
