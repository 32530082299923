import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import PopupAgreement from "@/pages/popup/PopupAgreement";
import PopupPrivacy from "@/pages/popup/PopupPrivacy";
import {
	EmailAuthenticationCode,
	EmailCodeValidation,
	EmailSignIn,
	EmailVerification,
	GoogleSignIn,
	NaverSignIn
} from "@/services/core/singIn/EmailSingIn";
import {
	AuthStatus,
	EmailAuthenticationStatus,
	PasswordInputComponent,
	handleEmailChange
} from "@/services/pages/account/JoinPage";
// import Timer from "@/services/util/Timer";
import { getCookie, setCookie } from "@/services/cookies/UserCookies";
import {
	CookiesMaxAge,
	SingUpTimerDefault,
	emailRegex,
	isValidPhoneNumber,
	nameRegex,
	passwordRegex
} from "@/services/util/const";
// import { useLoginForm } from "@/services/core/login/LoginHandle";
import { loginSuccess } from "@/services/store/action/LoginAction";
import { login } from "@/services/core/login/LoginFormRepo";
import MyPageGet from "@/services/core/mypage/MyPageGet";
import { LicenseExamination } from "@/services/core/mypage/LicenseExamination";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { useDispatch } from "react-redux";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
// import LoadingBar from "@/components/loading/LoadingBar";
// import GoogleRedirectURL from "@/services/core/login/GoogleCallback";
// import NaverRedirectURL from "@/services/core/login/NaverCallback";

export default function JoinPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isPopupAgreement, setisPopupAgreement] = useState(false);
	const [isPopupPrivacy, setisPopupPrivacy] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [isBtnFormInlineVisible, setIsBtnFormInlineVisible] = useState(false);
	const [isBtnFormInlineVisible2, setIsBtnFormInlineVisible2] = useState(false);
	const [certiNumber, setCertiNumber] = useState("");

	// useRef를 사용하여 각 input 필드에 대한 참조 생성
	const emailRef = useRef(null);
	const pwRef = useRef(null);
	const pwCheckRef = useRef(null);
	const secondaryEmailRef = useRef(null);
	const numberRef = useRef(null);
	const nameRef = useRef(null);
	const certiNumberInputRef = useRef(null);

	const openPopup1 = () => setisPopupAgreement(true);
	const openPopup2 = () => setisPopupPrivacy(true);
	const [isAllAgreed, setIsAllAgreed] = useState(false);
	const [isRequestingAuth, setIsRequestingAuth] = useState(false);
	const [isCertiNumberAreaVisible, setIsCertiNumberAreaVisible] = useState(false);
	const [nameError, setNameError] = useState("");

	/// 이메일 인증관련
	const [emailAuthentication, setEmailAuthentication] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [isEmailSent, setIsEmailSent] = useState(false);
	const [isVerifyEmailCode, setIsVerifyEmailCode] = useState(null);
	const [timeLeft, setTimeLeft] = useState(SingUpTimerDefault);
	const [isTimerActive, setIsTimerActive] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [AuthenticationButton, setAuthenticationButton] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
	const passwordInputRef = useRef(null);
	const [allRequiredAgreed, setAllRequiredAgreed] = useState(false);
	const [previousEmail, setPreviousEmail] = useState("");
	const [successEmail, setSuccessEmail] = useState(false);
	const [inputEmail, setInputEmail] = useState(true);
	const [emailCheck, setEmailCheck] = useState(false);
	const [nameCheck, setNameCheck] = useState(false);
	const [PhoneNumberCheck, setPhoneNumberCheck] = useState(false);
	const [phoneNumberError, setPhonNumberError] = useState(false);
	const [passwordErrorCheck, setpasswordErrorCheck] = useState(false);
	const [buttonClose, setButtonClose] = useState(false);
	const [inputPhoneNumber, setInputPhoneNumber] = useState(null);
	const isInputValueProvided = inputPhoneNumber?.trim().length > 0;
	const isInputPhoneNumberValid = isInputValueProvided && isValidPhoneNumber.test(inputPhoneNumber);

	// 구글, 네이버 로그인시
	const location = useLocation();
	const [snsName, setName] = useState("");
	useEffect(() => {
		const token = getCookie("token");
		if (token) {
			navigate("/");
		}
	}, [navigate]);
	const { fromAuth, userData } = location.state || { fromAuth: false, userData: {} };
	useEffect(() => {
		if (fromAuth && userData.email) {
			setUserEmail(userData.email);
			if (emailRef.current) {
				emailRef.current.disabled = true;
			}
		} else {
			setUserEmail("");
			if (emailRef.current) {
				emailRef.current.disabled = false;
			}
		}
	}, [fromAuth, userData.email]);

	useEffect(() => {
		if (fromAuth && userData.phone) {
			setPhoneNumber(userData.phone);
			setIsPhoneNumberValid(true);
		}
	}, [fromAuth, userData.phone]);

	const renderPasswordInput = () => {
		if (!fromAuth) {
			return <PasswordInputComponent ref={passwordInputRef} setPasswordErrorCheck={setpasswordErrorCheck} />;
		}
		return null;
	};

	const handleVerifyEmailCode = async () => {
		try {
			const response = await EmailCodeValidation(userEmail, certiNumber);

			setIsVerifyEmailCode(response.status);
			if (response.status === "Success") {
				setIsTimerActive(false);
				setIsButtonDisabled(true);
				setAuthenticationButton(true);
				setIsVerifyEmailCode("Success");
				setTimeLeft(0);
			}
			if (response.status === "fail") {
				if (timeLeft === 0) {
					setIsVerifyEmailCode(null);
				} else {
					setIsVerifyEmailCode("fail");
				}
			}
		} catch (error) {
			setIsVerifyEmailCode("fail");
		}
	};

	useEffect(() => {}, [isVerifyEmailCode]);
	const handleCheckEmailDuplicate = async () => {
		const email = emailRef.current ? emailRef.current.value : "";
		if (!email) {
			alert("이메일을 입력해주세요.");
			return;
		}
		if (!emailRegex.test(email)) {
			alert("유효한 이메일 형식이 아닙니다.");
			return;
		}
		try {
			const verificationResult = await EmailVerification(email);
			if (verificationResult === 2) {
				setSuccessEmail(true);
				setInputEmail(false);
			}

			setEmailAuthentication(verificationResult);
			setUserEmail(email);
			setIsEmailSent(false);
			setIsCertiNumberAreaVisible(false);
		} catch (error) {
			alert("이미 사용 중인 이메일입니다.");
		}
	};

	// '가입하기' 버튼 클릭 시 실행되는 함수 (sns가입)
	const handleLogin = async () => {
		const sns = userData.sns;
		const id = userData.id;
		const email = emailRef.current.value;
		const phoneNumber = numberRef.current.value;
		const name = nameRef.current.value;
		if (!emailRef.current || !emailRef.current.value) {
			emailRef.current && emailRef.current.focus();
			return;
		}
		const fields = [
			{ ref: numberRef, name: "전화번호" },
			{ ref: nameRef, name: "이름" }
		];
		/// 회원가입시 로직확인

		for (const field of fields) {
			if (!field.ref.current || !field.ref.current.value) {
				field.ref.current && field.ref.current.focus();
				return;
			}
		}
		if (!allRequiredAgreed) {
			alert({
				title: "필수 이용약관 항목에 동의해주세요.",
				content: `서비스 이용을 위해 필요한 동의사항입니다.<br>`
			});
			return;
		}
		try {
			let resp;
			if (!fromAuth) {
				navigate("/login");
			} else {
				if (sns === "google") {
					resp = await GoogleSignIn(id, name, phoneNumber, email);

					// navigate("/dashboard");
				} else {
					resp = await NaverSignIn(id, name, phoneNumber, email);
					// navigate("/dashboard");
				}
			}
			if (resp.status == 200) {
				setCookie("token", resp.data.access_token, { path: "/", maxAge: CookiesMaxAge });
				await MyPageGet(dispatch);

				await LicenseExamination(dispatch);

				// 인증 상태 설정 추가
				dispatch(loginSuccess(resp.data.user));

				const dataCountResult = await DataCount();
				dispatch(setDataCount(dataCountResult));
				const dashboardData = await DashBoard(null, 10, "", null, 1, true);
				dispatch(setDashboardData(dashboardData));

				if (sns === "google" || sns === "naver") {
					navigate("/middlePage");
				} else {
					navigate("/dashboard");
				}
			}
		} catch (error) {
			console.error(error);
			alert({ message: "회원가입 처리 중 문제가 발생하였습니다." });
			return;
		}
	};

	// '가입하기' 버튼 클릭 시 실행되는 함수 (email가입)
	const emailHandleLogin = async () => {
		const sns = userData.sns;
		const id = userData.id;
		const password = passwordInputRef.current.getPw();
		const passwordCheck = passwordInputRef.current.getPwCheck();
		const regex = passwordRegex;
		const nameCheck = nameRegex;
		const phoneNumber = numberRef.current.value.replace(/[^0-9]/g, "");
		const name = nameRef.current.value;
		const fields = [
			{ ref: numberRef, name: "전화번호" },
			{ ref: nameRef, name: "이름" }
		];

		/// 이름이 비었을경우
		if (!name) {
			nameRef.current.focus();
			setNameCheck(true);
		} else {
			setNameCheck(false);
		}

		const phoneNumberField = fields.find((field) => field.name === "전화번호");
		if (phoneNumberField && (!phoneNumberField.ref.current || !phoneNumberField.ref.current.value)) {
			phoneNumberField.ref.current && phoneNumberField.ref.current.focus();
			setPhoneNumberCheck(true);
		} else {
			setPhoneNumberCheck(false);
		}
		if (!fromAuth) {
			if (!regex.test(password)) {
				passwordInputRef.current.focusPw();
			}
		}
		if (password !== passwordCheck) {
			passwordInputRef.current.focusPwCheck();
		}

		if (!password || !passwordCheck) {
			setpasswordErrorCheck(true);

			if (!password) {
				passwordInputRef.current.focusPw();
			} else {
				passwordInputRef.current.focusPwCheck();
			}
		} else {
			setpasswordErrorCheck(false);
		}

		/// 이메일이 비었을 경우
		if (inputEmail === true) {
			const email = emailRef.current.value;
			if (emailError === true) {
				emailRef.current && emailRef.current.focus();
				// return;
			}
			if (!emailRef.current || !emailRef.current.value) {
				emailRef.current && emailRef.current.focus();
				setEmailError(false);
				setEmailCheck(true);
				return;
			} else {
				setEmailCheck(false);
			}
		}

		if (isVerifyEmailCode !== "Success") {
			certiNumberInputRef.current && certiNumberInputRef.current.focus();
			alert({
				title: "이메일 인증이 완료되지 않았습니다.",
				content: "이미 가입된 이메일을 입력했는지 인증번호를 입력하지 않았는지 확인해주세요."
			});
			return;
		}

		if (!allRequiredAgreed) {
			alert({
				title: "필수 이용약관 항목에 동의해주세요.",
				content: `서비스 이용을 위해 필요한 동의사항입니다.<br>`
			});
			return;
		}

		if (!passwordRegex.test(password)) {
			passwordInputRef.current.focusPw();
			return;
		}

		if (!passwordRegex.test(passwordCheck)) {
			passwordInputRef.current.focusPwCheck();
			return;
		}
		if (!isValidPhoneNumber.test(phoneNumber)) {
			/// 우선 여기고
			setPhonNumberError(true);
			numberRef.current.focus();
			return;
		}
		setPhonNumberError(false);
		if (!nameRegex.test(name)) {
			nameRef.current.focus();
			return;
		}

		if (password !== passwordCheck) {
			passwordInputRef.current.focusPwCheck();
			return;
		}
		try {
			let resp;
			if (!fromAuth) {
				resp = await EmailSignIn(userEmail, password, phoneNumber, name);
				if (resp.status !== "fail") {
					try {
						const data = await login(userEmail, password);
						if (data.data.access_token) {
							setCookie("token", data.data.access_token, { path: "/", maxAge: CookiesMaxAge });
							await MyPageGet(dispatch);
							await LicenseExamination(dispatch);

							// 인증 상태 설정 추가
							dispatch(loginSuccess(data.data.user));

							const dataCountResult = await DataCount();
							dispatch(setDataCount(dataCountResult));
							const dashboardData = await DashBoard(null, 10, "", null, 1, true);
							dispatch(setDashboardData(dashboardData));

							navigate("/dashboard");
						} else {
						}
					} catch (error) {
						// 에러 메시지를 상태에 저장
						if (error.response && error.response.status === 400) {
						}
						if (error.response && error.response.status === 422) {
						}
					}
				} else {
					if (resp.data === "잘못된형식의 전화번호") {
						numberRef.current.focus();
						return;
					}
					return;
				}
				if (resp.message === "잘못된형식의 전화번호") {
					numberRef.current.focus();
					return;
				}
			}
		} catch (error) {
			alert({ message: "회원가입 처리 중 문제가 발생하였습니다." });
			return;
		}
	};

	// 이메일 확인
	const emailInputChangeHandler = (event) => {
		const inputValue = event.target.value;

		handleEmailChange(
			event,
			setEmailError,
			setIsBtnFormInlineVisible,
			setEmailAuthentication,
			setEmailCheck,
			emailRegex
		);
	};
	useEffect(() => {
		setEmailError(false);

		setIsBtnFormInlineVisible(false);
		setIsBtnFormInlineVisible2(false);
	}, []);

	// 인증번호 입력 확인
	const handleCertiNumberChange = (event) => {
		const number = event.target.value;

		// 정규식을 사용하여 숫자만 입력되도록 체크
		if (/^\d*$/.test(number)) {
			setCertiNumber(number);
			setIsBtnFormInlineVisible2(!!number);

			const inputElement = document.getElementById("user-certi-number");
			if (inputElement) {
				inputElement.classList.add("padding-right-100");

				if (!number) {
					inputElement.classList.remove("padding-right-100");
				}
			}
		}
	};

	useEffect(() => {}, [inputPhoneNumber]);
	// 전화번호 input 숫자만 입력
	const handleNumberChange = (event) => {
		if (!fromAuth || userData.phone === "") {
			setPhoneNumberCheck(false);
			setInputPhoneNumber(event.target.value);
			const inputValue = event.target.value.replace(/[^0-9]/g, "");

			setPhoneNumber(inputValue);
			setIsPhoneNumberValid(inputValue.length <= 11);
		}
	};

	// 이름 입력값 확인
	const handleUserNameChange = (event) => {
		const newName = event.target.value;
		setName(newName);

		if (!newName) {
			setNameCheck(true);
		} else {
			setNameCheck(false);
			if (!nameRegex.test(newName)) {
				setNameError("공백 없이 20자 이내로 한글 또는 영어로만 입력해주세요.");
			} else {
				setNameError("");
			}
		}
	};

	useEffect(() => {
		if (fromAuth && userData.name) {
			setName(userData.name);
		}
	}, [fromAuth, userData.name]);

	useEffect(() => {
		if (fromAuth) {
			if (userData.phone) {
				setPhoneNumber(userData.phone);
				setIsPhoneNumberValid(true);
			} else {
				setPhoneNumber("");
			}
		}
	}, [fromAuth, userData.phone]);

	// 전체 동의 체크박스 변경 시
	const handleAllAgreeChange = (event) => {
		const isChecked = event.target.checked;
		setIsAllAgreed(isChecked);

		const checkboxes = document.querySelectorAll(".agree-check-list input[type='checkbox']");
		checkboxes.forEach((checkbox) => {
			checkbox.checked = isChecked;
		});

		setAllRequiredAgreed(isChecked);
	};

	// 개별 동의 체크박스 변경 시
	const handleSingleAgreeChange = () => {
		const allCheckboxes = document.querySelectorAll(".agree-check-list input[type='checkbox']");
		const requiredCheckboxes = document.querySelectorAll(
			".agree-check-list input[type='checkbox'][data-required='true']"
		);
		let allChecked = true;
		let allRequiredChecked = true;

		requiredCheckboxes.forEach((checkbox) => {
			if (!checkbox.checked) {
				allRequiredChecked = false;
			}
		});

		allCheckboxes.forEach((checkbox) => {
			if (!checkbox.checked) {
				allChecked = false;
			}
		});

		setIsAllAgreed(allChecked);
		setAllRequiredAgreed(allRequiredChecked);
	};

	const [reSend, setReSend] = useState(false);
	// 인증 요청 버튼

	const handleEmailConfirmation = async () => {
		setButtonClose(true);
		if (reSend) {
			setReSend(false);
		} else {
			setReSend(true);
		}
		setIsButtonDisabled(true);
		setIsRequestingAuth(true);
		setSuccessEmail(false);

		// 이메일 주소가 변경되었을 경우 타이머를 재설정
		if (userEmail !== previousEmail) {
			setTimeLeft(SingUpTimerDefault); // 타이머를 5분으로 재설정
			setPreviousEmail(userEmail); // 현재 이메일 주소를 이전 이메일로 저장
		}

		setTimeLeft(SingUpTimerDefault);
		// 이메일 인증 요청 로직
		const result = await EmailAuthenticationCode(userEmail);
		if (result === true) {
			setIsEmailSent(true);
			setIsCertiNumberAreaVisible(true);
			setIsTimerActive(true); // 이메일이 성공적으로 전송되면 타이머 활성화
		} else {
			setIsEmailSent(false);
			setIsCertiNumberAreaVisible(false); // 실패 시 인증번호 입력 영역 숨김
		}

		setTimeout(() => {
			setIsButtonDisabled(false);
		}, 2000);
	};
	//타이머
	useEffect(() => {
		let intervalId;
		if (isEmailSent && isVerifyEmailCode === "Success") {
			intervalId = setInterval(() => {
				setTimeLeft((prevTime) => {
					if (prevTime <= 1) {
						clearInterval(intervalId);
						setIsTimerActive(false);
						setIsEmailSent(false);
						return 0;
					}
					return prevTime - 1;
				});
			}, 1000);
			return;
		}

		if (isEmailSent || isVerifyEmailCode === "fail") {
			intervalId = setInterval(() => {
				setTimeLeft((prevTime) => {
					if (prevTime <= 1) {
						clearInterval(intervalId);
						setIsTimerActive(false);
						setIsEmailSent(false);
						setIsVerifyEmailCode(null);
						return 0;
					}
					return prevTime - 1;
				});
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [isEmailSent, isVerifyEmailCode, reSend]);

	return (
		<>
			<div className="container account-page">
				<div className="account-header">
					<Link to="/login" className="btn btn-xs btn-back">
						<i className="ico ico-arrow-left-gray"></i>돌아가기
					</Link>
					<h2 className="title1">회원가입</h2>
				</div>
				<div className="account-body join-page">
					<form>
						<fieldset>
							<legend className="sr-only">회원가입</legend>
							<div className="form-group">
								{/* [개발] SNS 가입 시 정보 연동 가능한 경우 : disabled 처리,
								    연동 불가할 경우 또는 이메일 가입 시 : 입력 가능하도록 disabled 제거 필요 */}
								<dl className="form-item">
									<dt>
										<label htmlFor="user-email">
											이메일 아이디<span className="req">필수입력</span>
										</label>
									</dt>
									<dd>
										{/* [개발] 이메일 중복확인 단계까지만 노출되는 input 형태 */}
										{inputEmail ? (
											<div className={`input-btn-group ${isBtnFormInlineVisible ? "btn-form-inline" : ""}`}>
												<input
													type="text"
													id="user-email"
													ref={emailRef}
													className={`form-control ${isBtnFormInlineVisible ? "padding-right-100" : ""}`}
													placeholder="이메일을 입력해주세요."
													value={fromAuth ? userEmail : undefined}
													onChange={emailInputChangeHandler}
												/>
												{emailAuthentication === 0 ? (
													<button type="button" className="btn btn-sm btn-gray btn-form-inline">
														계정 찾기
													</button>
												) : (
													isBtnFormInlineVisible && (
														<button
															type="button"
															className="btn btn-sm btn-primary btn-form-inline"
															onClick={handleCheckEmailDuplicate}
														>
															중복 확인
														</button>
													)
												)}
											</div>
										) : (
											<div></div>
										)}
										{/* 계정이 있는 경우 노출 */}
										{emailCheck === true ? <p className="error-text">이메일 아이디를 입력해주세요.</p> : <div></div>}
										<EmailAuthenticationStatus
											emailAuthentication={emailAuthentication}
											userEmail={userEmail}
											isButtonDisabled={isButtonDisabled}
											onEmailConfirmation={handleEmailConfirmation}
											isEmailSent={isEmailSent}
											isRequestingAuth={isRequestingAuth}
											buttonClose={buttonClose}
										/>

										{successEmail ? (
											<div className="certi-number-area">
												<span>사용 가능한 이메일 아이디 입니다.</span>
											</div>
										) : (
											<div></div>
										)}

										{/* 이메일이 정상적으로 입력되지 않았을 경우 노출 */}
										{emailError === true && (
											<p className="error-text" style={{ marginTop: `9px` }}>
												올바른 이메일을 입력해주세요.
											</p>
										)}
										{/* 계정이 있는 경우 노출 */}
										{/* [개발] 인증번호 정상적으로 입력 시 해당 input disabled 처리 및 인증하기 버튼 미노출 필요 */}
										{isCertiNumberAreaVisible && (
											<div className="input-btn-group" id="certiNumberArea" style={{ marginTop: "12px" }}>
												<input
													type="text"
													id="user-certi-number"
													className="form-control"
													title="메일로 수신된 인증번호 4자리"
													placeholder="메일로 수신된 인증번호 4자리"
													onChange={handleCertiNumberChange}
													value={certiNumber}
													disabled={AuthenticationButton}
													ref={certiNumberInputRef}
												/>
												{isBtnFormInlineVisible2 && AuthenticationButton !== true && (
													<button
														type="button"
														className="btn btn-sm btn-primary btn-form-inline"
														onClick={handleVerifyEmailCode}
													>
														인증하기
													</button>
												)}
											</div>
										)}

										{/* 인증번호 요청 시 노출 */}
										{/* 인증번호가 정상적으로 입력되었을 경우 노출 */}
										{/* 입력한 인증번호가 잘못된 경우 노출 */}
										{/* 인증번호 유효시간 만료된 경우 노출 */}
										<AuthStatus
											isEmailSent={isEmailSent}
											isVerifyEmailCode={isVerifyEmailCode}
											timeLeft={timeLeft}
											isTimerActive={isTimerActive}
										/>
									</dd>
								</dl>

								{/* [개발] 이메일 가입인 경우에만 노출 */}
								{/* 비밀번호 Input */}
								{renderPasswordInput()}
								{passwordErrorCheck ? (
									<div className="guide-text">
										<p className="error-text">비밀번호를 입력해주세요.</p>
									</div>
								) : (
									<div></div>
								)}

								{/* [개발] SNS 가입 시 정보 연동 가능한 경우 : disabled 처리,
								    연동 불가할 경우 또는 이메일 가입 시 : 입력 가능하도록 disabled 제거 필요 */}
								<dl className="form-item number-item">
									<dt>
										<label htmlFor="user-number">
											전화번호<span className="req">필수입력</span>
										</label>
									</dt>
									<dd>
										<div className="input-group-form">
											<input
												type="text"
												id="user-number"
												ref={numberRef}
												className="form-control"
												placeholder="숫자만 입력해주세요. 예시)01012345678"
												pattern="[0-9]*"
												value={phoneNumber}
												onChange={handleNumberChange}
												disabled={fromAuth && userData.phone}
												maxLength="11"
											/>
										</div>
										{/* 잘못된 전화번호 입력 시 노출 */}
										{isInputPhoneNumberValid && !phoneNumberError && !PhoneNumberCheck && (
											<p className="guide-text">계정을 찾는 용도로 사용됩니다. 정확하게 입력해주세요.</p>
										)}

										{!isInputPhoneNumberValid && isInputValueProvided && (
											<div style={{ marginTop: "6px" }}>
												<p className="error-text mt-3">올바른 전화번호를 입력해주세요.</p>
											</div>
										)}
									</dd>
									{phoneNumberError !== true && PhoneNumberCheck && (
										<div style={{ marginTop: "6px" }}>
											<p className="error-text">전화번호를 입력해주세요.</p>
										</div>
									)}
								</dl>

								<dl className="form-item">
									<dt>
										<label htmlFor="user-name">
											이름<span className="req">필수입력</span>
										</label>
									</dt>
									<dd>
										<div className="input-group-form">
											<input
												type="text"
												id="user-name"
												ref={nameRef}
												value={snsName || ""}
												onChange={handleUserNameChange}
												className="form-control"
												placeholder="이름을 입력해주세요."
											/>
										</div>
										{!nameCheck && (
											<div>
												{nameError && (
													<p className="error-text" style={{ marginTop: "9px" }}>
														{nameError}
													</p>
												)}
											</div>
										)}
										{nameCheck ? <p className="error-text">이름을 입력해주세요.</p> : <div></div>}
									</dd>
								</dl>
							</div>
						</fieldset>
					</form>
					<div className="agree-area">
						<div className="all-agree-check-area">
							<span className="check-item">
								<input type="checkbox" id="agree-chk1" checked={isAllAgreed} onChange={handleAllAgreeChange} />
								<label htmlFor="agree-chk1">전체 동의</label>
							</span>
						</div>

						<ul className="agree-check-list">
							<li>
								<span className="check-item-sm">
									<input type="checkbox" id="agree-chk2" onChange={handleSingleAgreeChange} data-required="true" />
									<label htmlFor="agree-chk2">[필수] 만 14세 이상입니다.</label>
								</span>
							</li>
							<li>
								<span className="check-item-sm">
									<input type="checkbox" id="agree-chk3" onChange={handleSingleAgreeChange} data-required="true" />
									<label htmlFor="agree-chk3">[필수] 서비스 이용약관 동의</label>
								</span>
								<button type="button" className="btn btn-md" onClick={openPopup1}>
									자세히 보기
								</button>
							</li>
							<li>
								<span className="check-item-sm">
									<input type="checkbox" id="agree-chk4" onChange={handleSingleAgreeChange} data-required="true" />
									<label htmlFor="agree-chk4">[필수] 개인정보 수집/이용 동의</label>
								</span>
								<button type="button" className="btn btn-md" onClick={openPopup2}>
									자세히 보기
								</button>
							</li>
						</ul>
					</div>

					<div className="btn-group btn-group-flex">
						<button
							type="submit"
							className="btn btn-xl btn-primary"
							onClick={() => (fromAuth ? handleLogin() : emailHandleLogin())}
						>
							가입하기
						</button>
					</div>
				</div>
			</div>
			<PopupAgreement isOpen={isPopupAgreement} closePopup={() => setisPopupAgreement(false)} />
			<PopupPrivacy isOpen={isPopupPrivacy} closePopup={() => setisPopupPrivacy(false)} />
		</>
	);
}
