import { MyTrashCan } from "@/services/core/workspace/MyTrashCan";

export const GET_TRASHCAN_DATA = "GET_TRASHCAN_DATA";
export const TRASHCAN_LOADING = "TRASHCAN_LOADING";
export const TRASHCAN_LOADED = "TRASHCAN_LOADED";

export const setTrashCanData = (data) => {
	return {
		type: GET_TRASHCAN_DATA,
		payload: data
	};
};

export const TrashCanLoading = () => ({
	type: TRASHCAN_LOADING
});

export const TrashCanLoaded = () => ({
	type: TRASHCAN_LOADED
});

export const fetchTrashCanData = (params) => async (dispatch) => {
	dispatch(TrashCanLoading());
	try {
		const data = await MyTrashCan(
			params.after,
			params.count,
			params.search,
			params.service_type,
			params.order_by,
			params.is_desc
		);
		dispatch(setTrashCanData(data));
		
	} catch (error) {
		console.error(error)
	}
	dispatch(TrashCanLoaded());
};
