// 액션 타입
export const SET_SORT_DESC = "SET_SORT_DESC";
export const SET_TRASH_DESC = "SET_TRASH_DESC";
export const SET_DASHBOARD_DESC = "SET_DASHBOARD_DESC";
export const SET_CARD_MYPAGE_OPTION = "SET_CARD_MYPAGE_OPTION";
export const SET_CARD_MYPAGE_OPTION2 = "SET_CARD_MYPAGE_OPTION2";
export const SET_TRASH_OPTION = "SET_TRASH_OPTION";
export const SET_TRASH_OPTION2 = "SET_TRASH_OPTION2";

export const SET_WORK_ORDER_BY = "SET_WORK_ORDER_BY";
export const SET_WORK_ACTIVE = "SET_WORK_ACTIVE";

export const SET_TRASH_ORDER_BY = "SET_TRASH_ORDER_BY";
export const SET_TRASH_ACTIVE = "SET_TRASH_ACTIVE";

export const SET_DASHBOARD_LIST_SORT = "SET_DASHBOARD_LIST_SORT";
export const SET_DASHBOARD_CARD_SORT = "SET_DASHBOARD_CARD_SORT";
export const UPDATE_DASHBOARD_LIST_FIELD = "UPDATE_DASHBOARD_LIST_FIELD";
export const UPDATE_DASHBOARD_CARD_DIRECTION = "UPDATE_DASHBOARD_CARD_DIRECTION";
export const UPDATE_DASHBOARD_CARD_LIST_ORDERBY= "UPDATE_DASHBOARD_CARD_LIST_ORDERBY";
export const UPDATE_DASHBOARD_CARD_LIST_DESC = "UPDATE_DASHBOARD_CARD_LIST_DESC";
// 액션 생성자

export const updateDashboardCardListOrderBy = (orderBy) => ({
	type: UPDATE_DASHBOARD_CARD_LIST_ORDERBY,
	payload:  { orderBy }
})

export const updateDashboardCardListDesc = (desc) => ({
	type: UPDATE_DASHBOARD_CARD_LIST_DESC,
	payload: {desc}
})

export const updateDashboardListField = (field) => ({
	type: UPDATE_DASHBOARD_LIST_FIELD,
	payload: { field }
});

export const updateDashboardCardDirection = (direction) => ({
	type: UPDATE_DASHBOARD_CARD_DIRECTION,
	payload: { direction }
});

export const setDashBoardListSort = (field, direction) => ({
	type: SET_DASHBOARD_LIST_SORT,
	payload: {field, direction}
})

export const setDashBoardCardSort = (orderBy, desc) => ({
	type: SET_DASHBOARD_CARD_SORT,
	payload: {orderBy, desc}
})

export const setTrashActive = (active) => ({
	type: SET_TRASH_ACTIVE,
	payload: active
})

export const setTrashOrderBy = (orderIndex) => ({
	type: SET_TRASH_ORDER_BY,
	payload: orderIndex
})

export const setWorkActive = (active) => ({
	type: SET_WORK_ACTIVE,
	payload: active
})

export const setWorkOrderBy = (orderIndex) => ({
	type: SET_WORK_ORDER_BY,
	payload: orderIndex
})

export const setSortDesc = (descName, value) => ({
	type: SET_SORT_DESC,
	payload: { descName, value }
});

export const setTrashSortDesc = (descName, value) => ({
	type: SET_TRASH_DESC,
	payload: { descName, value }
});
export const setDashboardDesc = (descName, value) => ({
	type: SET_DASHBOARD_DESC,
	payload: { descName, value }
});

export const workSetOrderBy = (orderBy) => ({
	type: SET_CARD_MYPAGE_OPTION,
	payload: orderBy
});

export const workSetDesc = (desc) => ({
	type: SET_CARD_MYPAGE_OPTION2,
	payload: desc
});

export const trashSetOrderBy = (orderBy) => ({
	type: SET_TRASH_OPTION,
	payload: orderBy
});

export const trashSetDesc = (desc) => ({
	type : SET_TRASH_OPTION2,
	payload: desc
})
