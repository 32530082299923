import React from "react";
import { useSelector } from "react-redux";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import SettingTabs from "@/components/tabs/SettingTabs";
import PromotionBanner from "@/components/promotion/PromotionBanner";
import { levelMappings } from "@/services/store/reducer/UserReducer";

export default function SettingActivityPage() {
	const userLevel = useSelector((state) => state.user?.userData?.level);
	const userSymbol = useSelector((state) => state.user?.userData?.symbol);
	const userLevelName = useSelector((state) => state.user?.userData?.levelName);
	const userMessage = useSelector((state) => state.user?.userData?.message);
	const {
		isOpen: isActivityHistoryPopup,
		open: openActivityHistoryPopup,
		close: closeActivityHistoryPopup
	} = usePopup();

	const renderLevelHistory = () => {
		const history = [];
		const totalLevels = Object.keys(levelMappings).length;
		for (let i = 1; i <= totalLevels; i++) {
			if (i <= userLevel) {
				const { symbol, levelName } = levelMappings[i];
				history.push(
					<li key={i} className={i === userLevel ? "active" : ""} title={i === userLevel ? "현재단계" : ""}>
						<span className="level-item">
							<span className="user-level-tag">
								{symbol} {levelName}
							</span>
						</span>
					</li>
				);
			} else {
				history.push(
					<li key={i}>
						<span className="level-item">
							<span className="user-level-tag undefined">?</span>
						</span>
					</li>
				);
			}
		}
		return history;
	};

	return (
		<div className="container settings setting-activity-page">
			<div className="content-box">
				<div className="content-header mb-6">
					<h2 className="title1">설정</h2>
				</div>
				<div className="content-body">
					<SettingTabs />
					<div className="content-inner">
						<h3 className="sr-only">앨리지수</h3>
						<div className="settings-list">
							<dl>
								<dt>내 식물</dt>
								<dd>
									<div className="con-area">
										<div className="my-flower-pot">
											<div className="user-level-tag">
												{userSymbol} {userLevelName}
											</div>
											<p className="guide-text">{userMessage}</p>
										</div>
									</div>
									<div className="btn-area">
										<button type="button" className="btn btn-sm btn-outline-gray" onClick={openActivityHistoryPopup}>
											히스토리
										</button>
									</div>
								</dd>
							</dl>
							<div className="settings-container">
								<div className="box mt-8">
									<p className="text-medium">앨리지수란?</p>
									<div className="text-sm mt-1">
										<p>앨리에서 웹 접근성 활동을 하면 앨리지수가 높아지고 내 식물이 자라요.</p>
										<p>다음 활동을 통해 앨리지수를 높여보세요. 더 많은 혜택을 받을 수 있게 돼요.</p>
									</div>
									<ul className="list list2 text-sm text-gray mt-2">
										<li>앨리 베타테스터 활동</li>
										<li>피드백 참여 지수</li>
										<li>작업 완료 수</li>
										<li>내 작업 공유하기 수</li>
									</ul>
								</div>
								<PromotionBanner type="band" />
								<PromotionBanner type="card-md" />
							</div>
							<PopupLayout
								isOpen={isActivityHistoryPopup}
								closePopup={closeActivityHistoryPopup}
								title="내 식물이 자라고 있어요."
								size="default"
								footerContent={
									<div className="btn-group">
										<button type="button" className="btn btn-xl btn-primary" onClick={closeActivityHistoryPopup}>
											확인
										</button>
									</div>
								}
							>
								<div className="activity-popup-content">
									<p className="mb-4">어떤 모습일지 기대되지 않나요?</p>
									<ul className="plant-history">{renderLevelHistory()}</ul>
								</div>
							</PopupLayout>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
