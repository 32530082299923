import React, { useState, useEffect } from "react";

const Button = ({
	icon,
	text,
	loadingText,
	loadingTextInterval = 3000, // 메시지 변경 간격 (기본 3초)
	isLoading,
	className,
	size,
	color,
	type = "button",
	id,
	onClick,
	disabled
}) => {
	const [currentLoadingTextIndex, setCurrentLoadingTextIndex] = useState(0);

	const classes = `btn btn-${size} btn-${color} ${className || ""} ${isLoading ? "btn-loading" : ""}`;
	const iconSizeClass = size ? `ico-loading-${size}` : "";
	const iconClasses = icon ? `ico ${iconSizeClass} ${icon.classes || ""}` : "";
	const loadingSpinnerSizeClass = size ? `loading-spinner-${size}` : "";
	const loadingSpinnerClasses = `loading-spinner ${loadingSpinnerSizeClass}`;

	// 로딩 텍스트 배열 처리
	const loadingTextArray = Array.isArray(loadingText) ? loadingText : [loadingText];
	const actualLoadingText = isLoading ? loadingTextArray[currentLoadingTextIndex] : text;

	useEffect(() => {
		let interval;
		if (isLoading && loadingTextArray.length > 1) {
			interval = setInterval(() => {
				setCurrentLoadingTextIndex((current) => (current === loadingTextArray.length - 1 ? 0 : current + 1));
			}, loadingTextInterval);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [isLoading, loadingTextArray.length, loadingTextInterval]);

	// 로딩이 시작될 때마다 첫 번째 메시지부터 시작
	useEffect(() => {
		if (isLoading) {
			setCurrentLoadingTextIndex(0);
		}
	}, [isLoading]);

	const renderLoadingContent = () => (
		<>
			<span className={loadingSpinnerClasses}></span>
			{actualLoadingText && <span className="btn-text transition-opacity duration-200">{actualLoadingText}</span>}
		</>
	);

	const renderContent = () => {
		if (isLoading) {
			return loadingText || text ? renderLoadingContent() : <span className={loadingSpinnerClasses}></span>;
		} else {
			return (
				<>
					{icon && <i className={iconClasses}></i>}
					{text && <span className="btn-text">{text}</span>}
				</>
			);
		}
	};

	const buttonProps = {
		type,
		className: classes,
		disabled: isLoading || disabled,
		...(id && { id }),
		...(onClick && { onClick })
	};

	return <button {...buttonProps}>{renderContent()}</button>;
};

export default Button;
