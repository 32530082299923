import { API_URL } from "@/services/core/url/URL";
import { ImgPath } from "@/services/util/const";
import React, { useState, useEffect } from "react";
import { TransformWrapper, TransformComponent, KeepScale } from "react-zoom-pan-pinch";

const ImageViewer = ({ imageData, imageWidth = 418, showPins = false, onPinClick, activePinId }) => {
	const [scaledPins, setScaledPins] = useState([]);

	const handlePinClick = (pinId) => {
		if (onPinClick) {
			onPinClick(pinId);
		}
	};

	useEffect(() => {
		if (imageData) {
			const img = new Image();
			img.src = `${imageData.src}`;
			img.onload = () => {
				const scale = imageWidth / img.width;
				if (showPins) {
					const newScaledPins = imageData.pins.map((pin) => {
						const scaledPoints = pin.points.map((point) => ({
							x: point.x * scale - 2,
							y: point.y * scale - 2
						}));

						// 각도 계산
						const angle =
							Math.atan2(scaledPoints[1].y - scaledPoints[0].y, scaledPoints[1].x - scaledPoints[0].x) *
							(180 / Math.PI);
	
						return {
							...pin,
							points: scaledPoints,
							angle: angle,
							w:
								Math.sqrt(
									Math.pow(scaledPoints[1].x - scaledPoints[0].x, 2) +
										Math.pow(scaledPoints[1].y - scaledPoints[0].y, 2)
								) + 4,
							h:
								Math.sqrt(
									Math.pow(scaledPoints[2].x - scaledPoints[1].x, 2) +
										Math.pow(scaledPoints[2].y - scaledPoints[1].y, 2)
								) + 4
						};
					});

					setScaledPins(newScaledPins);
				}
			};
			img.onerror = (error) => {
				console.error("이미지 로딩 에러", error);
			};
		}
	}, [imageData, imageWidth, showPins]);

	if (!imageData) {
		return <div>이미지 데이터가 없습니다.</div>;
	}

	return (
		<div className="image-viewer image-viewer-bbox">
			<TransformWrapper
				limitToBounds={false} // 영역 밖 드래그 허용
				initialScale={1}
				minScale={0.1}
				// onWheelStop={(transformState) => // console.log(transformState.state)}
				// onPanningStop={(transformState) => // console.log(transformState.state)}
				// onPinchingStop={(transformState) => // console.log(transformState.state)}
			>
				{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
					<React.Fragment>
						<div className="tools">
							<button type="button" className="btn btn-outline-gray btn-xs" title="확대" onClick={() => zoomIn()}>
								<i className="ico ico-zoomin-xs"></i>
							</button>
							<button type="button" className="btn btn-outline-gray btn-xs" title="축소" onClick={() => zoomOut()}>
								<i className="ico ico-zoomout-xs"></i>
							</button>
							<button
								type="button"
								className="btn btn-outline-gray btn-xs"
								title="초기화"
								onClick={() => resetTransform()}
							>
								<i className="ico ico-reset-xs"></i>
							</button>
						</div>
						<TransformComponent>
							<div className="item-container">
								{/* <img src={`${API_URL.slice(0, -1)}${imageData.src}`} alt="업로드 이미지" style={{ width: imageWidth }} /> */}
								<img src={`${imageData.src}`} alt="업로드 이미지" style={{ width: imageWidth }} />
								<div className="pin-list">
									{scaledPins.map((pin, index) => (
										<div
											className={`pin-item ${activePinId === pin.id ? "active" : ""}`} // active 상태 동기화
											key={pin.id}
											style={{
												top: `${pin.points[0].y}px`,
												left: `${pin.points[0].x}px`,
												transform: `rotate(${pin.angle}deg)`,
												transformOrigin: "top left"
											}}
										>
											<div className="pin-keep">
												<KeepScale>
													<button type="button" className="pin" onClick={() => handlePinClick(pin.id)}>
														<span>
															{activePinId === pin.id ? (
																<i className="ico ico-checking-white"></i>
															) : (
																<i className="ico ico-exclamation-mark"></i>
															)}
															요소 {index + 1}
														</span>
														<span className="sr-only">{activePinId === pin.id ? "선택됨" : "상세보기"}</span>
													</button>
												</KeepScale>
											</div>
											<div
												className="bbox"
												id={"pin-" + pin.id}
												style={{ width: `${pin.w}px`, height: `${pin.h}px` }}
												onClick={() => handlePinClick(pin.id)}
											></div>
										</div>
									))}
								</div>
							</div>
						</TransformComponent>
					</React.Fragment>
				)}
			</TransformWrapper>
		</div>
	);
};

export default ImageViewer;
