// src/services/store/action/LoginAction.js

// 로그인 성공 시 호출되는 액션
export const loginSuccess = (userData) => {
	return {
		type: "LOGIN_SUCCESS",
		payload: userData
	};
};

// 사용자 데이터를 설정하는 액션 (예를 들어 사용자가 이미 로그인된 경우 등)
export const setUserData = (userData) => {
	return {
		type: "SET_USER_DATA",
		payload: userData
	};
};
