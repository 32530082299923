import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LicenseRegistration } from "@/services/core/mypage/LicenseRegistration";
import { setMyLicense } from "@/services/store/action/LicenseAction";
import { setUserData } from "@/services/store/action/LoginAction";
import PopupLayout from "@/components/popup/PopupLayout";
// import LoadingBar from "@/components/loading/LoadingBar";

// 사용권 등록
export const LicenseCodeInput = ({ initialCode }) => {
	const navigate = useNavigate();
	const [isLicenseCode, setIsLicenseCode] = useState(initialCode || "");
	const [isErrorState, setIsErrorState] = useState(0);
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);
	const registerButtonRef = useRef(null);

	useEffect(() => {
		if (initialCode) {
			setIsLicenseCode(initialCode);
			registerButtonRef.current?.classList.add("blink");
			const timer = setTimeout(() => {
				registerButtonRef.current?.classList.remove("blink");
			}, 5000); // 5초 후에 클래스 제거

			return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 클리어
		}
	}, [initialCode]);

	const handleRegister = (title, count, content) => {
		window.alert({
			title: "사용권이 등록되었습니다.",
			content: `
            <div class="box">
                <span class="text">${title}</span>
                <span class="num">+<strong>${count}</strong> 장</span>
            </div>
            <div class="license-results-notice">${content}</div>
        `,
			btnClassName: "primary",
			alertBtnText: "지금 앨리 사용해보러 가기",
			customClass: "license-results-alert",
			onClose: () => navigate("/tool/img2html/add")
		});
		setIsLicenseCode("");
	};

	const handleLicenseCode = (e) => {
		setIsLicenseCode(e.target.value);
	};

	const handleRegisterClick = async () => {
		try {
			const result = await LicenseRegistration(isLicenseCode);
			if (result.status === "Success") {
				const newCount = (userData.data.count || 0) + (result.data.data.count || 0);
				const updatedUserData = {
					...userData,
					data: {
						...userData.data,
						count: newCount
					}
				};

				const newLicenseData = {
					count: result.data.data.count,
					title: result.data.data.title,
					content: result.data.data.content,
					createAt: result.data.data.createAt
				};
				dispatch(setUserData(updatedUserData));
				dispatch(setMyLicense(newLicenseData, true));
				setIsErrorState(0);
				handleRegister(result.data.data.title, result.data.data.count, result.data.data.content);
			} else if (result === 1) {
				setIsErrorState(1);
			} else if (result === 2) {
				setIsErrorState(2);
			}
		} catch (error) {
			setIsErrorState(1);
		}
	};

	return (
		<dl className="license-input-item">
			<dt>
				<label htmlFor="user-license">사용권 코드 입력</label>
			</dt>
			<dd>
				<div className="con-area">
					<div className="input-btn-group">
						<input
							type="text"
							id="user-license"
							className="form-control"
							placeholder="사용권 코드를 입력해주세요."
							value={isLicenseCode}
							onChange={handleLicenseCode}
						/>
					</div>
					{isErrorState === 1 && <p className="error-text">이미 등록되었거나 유효하지 않습니다.</p>}
					{isErrorState === 2 && <p className="error-text">등록 가능한 기간이 아닙니다.</p>}
				</div>
				<div className="btn-area">
					<button
						type="button"
						className="btn btn-xl btn-primary"
						onClick={handleRegisterClick}
						disabled={isLicenseCode.length === 0}
						ref={registerButtonRef}
					>
						사용권 등록
					</button>
				</div>
			</dd>
		</dl>
	);
};

// 사용권 조회
export const LicenseExamination = ({ userData, openLicenseDetailsPopup }) => {
	const md = {
		marginRight: "5px"
	};
	return (
		<>
			<dl className="available-licenses-item">
				<dt>남은 사용권</dt>
				<dd>
					<div className="con-area">
						<span className="progress-num-area">
							<i className="ico ico-tool-md-primary" style={md}></i>
							<span className="use-num">
								<span className="total">
									<strong>{userData?.data?.count ?? "0"}장</strong>
								</span>
							</span>
						</span>
						<p className="guide-text">사용권이 모두 소진되면, 코드를 입력하여 충전해주세요.</p>
					</div>
					<div className="btn-area">
						<button type="button" className="btn btn-sm btn-outline-gray pl-3 pr-3" onClick={openLicenseDetailsPopup}>
							사용권 등록 내역
						</button>
					</div>
				</dd>
			</dl>
		</>
	);
};

export const LicenseDetailsPopup = ({ isOpen, closePopup }) => {
	const licenseData = useSelector((state) => state.license.myLicense.data);

	const hasLicenseData = licenseData.length > 0;

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const year = date.getFullYear();
		const month = `${date.getMonth() + 1}`.padStart(2, "0");
		const day = `${date.getDate()}`.padStart(2, "0");
		return `${year}.${month}.${day}`;
	};

	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={closePopup}
			title="등록 사용권 내역"
			size="md"
			className={"license-popup"}
			footerContent={null}
		>
			<div className="license-list-area">
				{hasLicenseData ? (
					<div>
						<p className="subject">최근 1년 이내의 내역이 표시됩니다.</p>
						<ul className="license-list">
							{licenseData.map((item, index) => (
								<li key={index}>
									<div className="content">
										<strong>{item.title}</strong>
										{/* <p>{item.content}</p> */}
										<div className="date">
											<span>사용권 등록일</span>
											<span>{formatDate(item.createAt)}</span>
										</div>
									</div>
									<div className="num">
										{item.count > 0 ? "+" : ""}
										<strong>{item.count}</strong> 장
									</div>
								</li>
							))}
						</ul>
					</div>
				) : (
					// 데이터 없을 경우
					<div className="nodata-area">
						<p>등록된 내역이 없습니다.</p>
					</div>
				)}
			</div>
		</PopupLayout>
	);
};
