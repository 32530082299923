import React, { Children, cloneElement, isValidElement, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonToTop from "@/components/button/ButtonToTop";
import usePopup from "@/hooks/usePopup";
import Logo from "@/assets/images/logo@2x.png";

const LayoutFull = ({ children, isAuthenticated, openLoginPopup }) => {
	const [isAuth, setIsAuth] = useState(isAuthenticated);
	const EventCodePopup = usePopup();
	const location = useLocation();
	const navigate = useNavigate();
	const codeParam = new URLSearchParams(location.search).get("c");
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const eventParam = searchParams.get("e");
		const codeParam = searchParams.get("c");

		if (eventParam === "t" && codeParam) {
			EventCodePopup.open();
		}
	}, [location.search, EventCodePopup]);

	const handleCloseCompletePopup = () => {
		navigate(location.pathname);
		EventCodePopup.close();
	};

	const handleLogoClick = (e) => {
		e.preventDefault();
		navigate("/");
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			return cloneElement(child, { isAuthenticated: isAuth, setIsAuthenticated: setIsAuth, openLoginPopup });
		}
		return child;
	});

	return (
		<div className="wrapper layout-full">
			<div className="header-full">
				<div className="header-container">
					<h1 id="logo">
						<Link to="/" onClick={handleLogoClick} style={{ cursor: "pointer" }}>
							<img src={Logo} alt="A11Y beta" />
						</Link>
					</h1>
				</div>
			</div>
			<article id="content">{childrenWithProps}</article>
			<ButtonToTop />
		</div>
	);
};

export default LayoutFull;
