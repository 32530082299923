import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import InfoPannel from "@/components/widget/InfoPannel";
import ListTask from "@/components/list/ListTask";
import ListTaskCard from "@/components/list/ListTaskCard";
import Dropdown from "@/components/form/Dropdown";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import PopupSearch from "@/pages/popup/PopupSearch";
// import { UserDataComponent } from "@/services/core/mypage/MyPageGet";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchDashboardData,
	setDashboardData
	// updateDashboardDataWithNewName
} from "@/services/store/action/DashBoard";
// import { tab } from "@testing-library/user-event/dist/tab";
import { formatDate } from "@/services/util/TransForm";
import { getCookie } from "@/services/cookies/UserCookies";
import { TrashingListIt } from "@/services/core/workspace/TrashingIt";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { navigateTo } from "@/services/util/Navigate";
// import styled from "styled-components";

export default function DashboardPage({ hasNavigation = true }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const options = [
		{ value: null, label: "서비스 유형" },
		{ value: "img2html", label: "이미지 HTML 변환기", iconClass: "ico-img2html-sm" },
		{ value: "contrast", label: "이미지 명도대비 최적화", iconClass: "ico-contrast-sm" },
		{ value: "seo", label: "SEO 메타태그 생성기", iconClass: "ico-seo-sm" },
		{ value: "table", label: "표 접근성 최적화", iconClass: "ico-table-sm" }
	];

	const [resetSignal, setResetSignal] = useState(0); // 초기값은 0
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [isLoading2, setIsLoading] = useState(true); // 로딩 상태 추가

	const [updateTrigger, setUpdateTrigger] = useState(false);
	const [updateTrash, setUpdateTrash] = useState(false);
	const [updateListTrash, setupdateListTrash] = useState(false);
	const handleResetClick = () => {
		setSelectedItems(new Set());
		setSelectAllChecked(false);
		setSelectedItemsCount(0);
		setResetSignal((prev) => prev + 1); // 버튼 클릭시 값 증가
	};
	const handleListTrash = () => {
		setupdateListTrash(!updateListTrash);
	};

	const handleTrashChange = () => {
		setUpdateTrash(!updateTrash);
	};

	const handleDataChange = () => {
		setUpdateTrigger(!updateTrigger);
	};
	useEffect(() => {
		const token = getCookie("token");
		if (!token) {
			navigate("/login");
		}
	}, [navigate]);

	useEffect(() => {
		const fetchDashboardData = async () => {
			setIsLoading(true); // 데이터 로딩 시작
			try {
				const dashboardData = await DashBoard(null, 10, "", null, 1, true);
				dispatch(setDashboardData(dashboardData));
			} catch (error) {
				console.error("Dashboard data fetch failed:", error);
			}
			setIsLoading(false); // 데이터 로딩 완료
		};

		fetchDashboardData();
	}, [dispatch]);

	const [selectOption, setSelectedOption] = useState("default");
	const [selectOptionNum, setSelectOptionNum] = useState(0);
	const [stateWork, setStateWork] = useState("work");

	const [selectedItems, setSelectedItems] = useState(new Set());
	const handleSelectedItemsChange = (items) => {
		setSelectedItems(items);
	};
	/// 휴지통 보내기
	const handleTrash = async (id) => {
		const idList = Array.from(id);
		const resp = await TrashingListIt(idList);
		if (resp) {
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
			const dashboardData = await DashBoard(null, 10, "", null, 1, true);
			dispatch(setDashboardData(dashboardData));
			handleListTrash();

			setSelectedItemsCount(0);
			setSelectAllChecked(false);
			setSelectedItems(new Set());
		} else {
			navigateTo("/error");
		}
	};

	const isLoading = useSelector((state) => state.dashboard.isLoading);
	const defaultData = useSelector((state) => state.dashboard.data.defaultData.data);

	const img2htmlData = useSelector((state) => state.dashboard?.data?.img2htmlData?.data);
	const seoData = useSelector((state) => state.dashboard?.data?.seoData?.data);
	const contrastData = useSelector((state) => state.dashboard?.data?.contrastData?.data);
	const tableData = useSelector((state) => state.dashboard?.data?.tableData?.data);
	useEffect(() => {
		if (selectOption !== "default") {
			dispatch(fetchDashboardData(selectOption));
		}
		dispatch(fetchDashboardData("img2html"));
		dispatch(fetchDashboardData("seo"));
		dispatch(fetchDashboardData("contrast"));
		dispatch(fetchDashboardData("table"));
	}, [selectOption, dispatch, updateTrigger, updateTrash, updateListTrash]);

	const currentData = () => {
		switch (selectOption) {
			case "img2html":
				return img2htmlData || [];
			case "seo":
				return seoData || [];
			case "table":
				return tableData || [];
			case "contrast":
				return contrastData || [];
			case "default":
				return defaultData || [];
			default:
				return defaultData;
		}
	};

	const typeTransformations = {
		img_html: "img2html",
		img_improve: "contrast",
		web_standard: "table"
	};

	const transformedData = currentData().map((item) => {
		const transformedType = typeTransformations[item.workspaceType] || item.workspaceType;
		return {
			id: item.workspaceId,
			service: {
				name: transformedType,
				type: transformedType
			},
			workName: item.workspaceName,
			fileShared: item.workspaceIsShare,
			workspaceFilePath: item.workspaceFilePath,
			fileType: item.workspaceFileType,
			fileSize: item.workspaceFileSpace,
			lastModified: formatDate(item.updateAt) || formatDate(item.createAt),
			createdDate: formatDate(item.createAt),
			delete: false
		};
	});

	// 검색 팝업
	const [isPopupSearchOpen, setIsPopupSearchOpen] = useState(false);
	const openPopupSearch = () => setIsPopupSearchOpen(true);
	const closePopupSearch = () => setIsPopupSearchOpen(false);

	// tab 메뉴
	const [activeTab, setActiveTab] = useState(() => {
		// localStorage에서 저장된 탭 상태를 가져오거나, 없으면 "listType"을 기본값으로 사용
		return localStorage.getItem("dashboardActiveTab") || "listType";
	});

	// 아래 코드로 변경
	const handleTabClick = (tabName) => {
		setSelectedItems(new Set());
		setSelectAllChecked(false);
		setSelectedItemsCount(0);

		if (tabName === "listType" || tabName === "cardTpe") {
			// localStorage에 현재 선택된 탭 저장
			localStorage.setItem("dashboardActiveTab", tabName);
			setActiveTab(tabName);
		}
	};

	const handleSelect = async (option) => {
		if (option !== null) {
			setSelectedOption(option.value);
			dispatch(fetchDashboardData(option.value));
			if (option.value === "img2html") {
				setSelectOptionNum(1);
			}
			if (option.value === "contrast") {
				setSelectOptionNum(2);
			}
			if (option.value === "seo") {
				setSelectOptionNum(3);
			}
			if (option.value === "table") {
				setSelectOptionNum(4);
			}
		} else {
			setSelectOptionNum(0);
			setSelectedOption("default");
		}
	};

	// 체크항목 개수
	const [selectedItemsCount, setSelectedItemsCount] = useState(0);
	const handleSelectedItemsCountChange = (count) => {
		setSelectedItemsCount(count);
	};

	return (
		<>
			<div className="container dashboard-page">
				<h2 className="sr-only">대시보드</h2>
				<InfoPannel />

				<div className="content-box">
					{isLoading2 ? (
						<div className="loading-area">
							<LoadingSpinner />
						</div>
					) : (
						<div className="workitem-wrap">
							<div className="workitem-header">
								<div className="title-area">
									<h3 className="title1">최근 작업</h3>

									<div className="status-area">
										{selectedItemsCount === 0 && (
											<div className="dropdown-warp">
												<Dropdown
													title="Dropdown"
													options={options}
													onSelect={handleSelect}
													currentOption={selectOptionNum}
												/>
											</div>
										)}

										{/* 체크된 항목이 1개 이상일 경우 버튼 영역 노출 */}
										{selectedItemsCount > 0 && (
											<div className="checked-btn-area">
												<div className="checked-num">
													<span>{selectedItemsCount}</span>개 선택됨
												</div>
												<button
													type="button"
													onClick={() => handleResetClick()}
													className="btn btn-sm btn-outline-gray pl-3 pr-3"
												>
													<i className="ico ico-cancel-black-xs"></i>
													<span>선택 취소</span>
												</button>
												<button
													type="button"
													className="btn btn-sm btn-outline-gray pl-3 pr-3"
													onClick={() =>
														window.confirm({
															title: "선택한 작업을 휴지통으로 이동하시겠어요?",
															message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
															content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
															onConfirm: (result) => {
																handleTrash(selectedItems);
															},
															confirmButtonText: "휴지통에 버리기",
															cancelButtonText: "취소"
														})
													}
												>
													<i className="ico ico-delete-xs"></i>
													<span>휴지통으로 이동</span>
												</button>
											</div>
										)}
									</div>
								</div>
								<div className="btn-area">
									<button type="button" className="btn btn-search" onClick={openPopupSearch}>
										<i className="ico ico-search-lg"></i>
										<span className="sr-only">문서 또는 서비스 검색</span>
									</button>
									{hasNavigation && (
										<nav className="tabmenu tabmenu-type3">
											<ul className="tab-list">
												<li className={activeTab === "listType" ? "on" : ""}>
													<Link to="#listType" onClick={() => handleTabClick("listType")}>
														<i className="ico ico-listview"></i>
														<span className="sr-only">리스트 형식</span>
													</Link>
												</li>
												<li className={activeTab === "cardTpe" ? "on" : ""}>
													<Link to="#cardTpe" onClick={() => handleTabClick("cardTpe")}>
														<i className="ico ico-cardview"></i>
														<span className="sr-only">카드 형식</span>
													</Link>
												</li>
											</ul>
										</nav>
									)}
								</div>
							</div>
							<div className="tab-content workitem-body">
								{/* [개발] 영역 리스트 로딩시 로딩바 적용 */}
								{activeTab === "listType" && (
									<>
										{isLoading ? (
											<div className="loading-area">
												<LoadingSpinner />
											</div>
										) : (
											<ListTask
												workItems={transformedData}
												itemCount={transformedData.length}
												onSelectedItemsCountChange={handleSelectedItemsCountChange}
												work={stateWork}
												onWorkNameChange={handleDataChange}
												onTrash={handleTrashChange}
												onSelectedItemsChange={handleSelectedItemsChange}
												onSelectAllCheckedChange={resetSignal}
											/>
										)}
									</>
								)}

								{activeTab === "cardTpe" && (
									<>
										{isLoading ? (
											<div className="loading-area">
												<LoadingSpinner />
											</div>
										) : (
											<ListTaskCard
												workItems={transformedData}
												itemCount={transformedData.length}
												onSelectedItemsCountChange={handleSelectedItemsCountChange}
												work={stateWork}
												onWorkNameChange={handleDataChange}
												onTrash={handleTrashChange}
												// setSelectAllChecked={setSelectAllChecked}
												onSelectAllCheckedChange={resetSignal}
											/>
										)}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			{/* 검색 모달 팝업 */}
			<PopupSearch isOpen={isPopupSearchOpen} closePopup={closePopupSearch} />
		</>
	);
}
