import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import {
	EMAIL_CODE_VALIDATION,
	EMAIL_FIND,
	EMAIL_SEND_CODE,
	USER_POST_EMAIL_SIGNIN,
	USER_POST_GOOGLE_SIGNIN,
	USER_POST_NAVER_SIGNIN
} from "../url/URL";
import api from "@/services/util/Interceptor";
import { sanitizePhoneNumber } from "@/services/util/utils";
import { alertFormatDate } from "@/services/util/TransForm";
export const EmailVerification = async (email) => {
	try {
		const response = await api.get(EMAIL_FIND, {
			params: { email },
			headers: { requiresAuth: false }
		});
		if (response.status === 200) {
			return 0;
		}
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			if (status === 400) {
				if (data.message === "이메일 확인 완료") {
					return 1;
				} else if (data.message === "이메일 주소와 일치하는 회원이 없음") {
					return 2;
				} else if (data.message.msg === "탈퇴한 회원입니다.") {
					const dateString = data.message.date;
					const formattedDate = alertFormatDate(dateString);
					alert({title:`[탈퇴] 탈퇴한 사용자입니다.` , message: `${formattedDate} 이후에 가입이 가능합니다.`});
					return;
				} else if (data.message === "차단한 회원입니다.") {
					alert({message: `[블럭] 계정이 잠겼습니다. 운영팀에 문의하세요.`});
					return;
				}
			}

			
		}
		return 3;
	}
};
/// 이메일 인증번호 발송
export const EmailAuthenticationCode = async (email, isJoin = true) => {
	try {
		const response = await api.get(EMAIL_SEND_CODE, {
			params: { email, isJoin },
			headers: { requiresAuth: false }
		});
		if (response.status === 200) {
			return true;
		}
		return false;
	} catch (error) {
		if (error.response) {
			const statusCode = error.response.status;
			const errorMessage = error.response.data.message;
			switch (statusCode) {
				case 400:
					if (errorMessage === "잘못된 형식의 이메일") {
						return 1;
					}
					if (errorMessage === "이메일 전송중에 문제생김") {
						return 2;
					}
					break;
				default:
					return false;
			}
		} else {
			return false;
		}
	}
};

export const EmailCodeValidation = async (email, authCode) => {
	try {
		const response = await api.get(EMAIL_CODE_VALIDATION, {
			params: { email, authCode },
			headers: { requiresAuth: false }
		});
		return { status: "Success", data: response.data };
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			return {
				status: "fail",
				message: data.message || "인증 처리 중 오류 발생",
				code: status
			};
		} else {
			return { status: "fail", message: "네트워크 오류 또는 알 수 없는 오류" };
		}
	}
};

export const EmailSignIn = async (userEmail, password, phone, name) => {
	try {
		const response = await api.post(
			USER_POST_EMAIL_SIGNIN,
			{
				email: userEmail,
				password,
				phone,
				name
			},
			{
				headers: { requiresAuth: false }
			}
		);

		return response.data;
	} catch (error) {
		if (error.response) {
			const { status, data } = error.response;
			if (data.message === "중복된 이메일") {
				return "fail";
			}
			if (data.message === "잘못된형식의 이메일") {
				return "fail";
			}
			if (data.message === "잘못된형식의 전화번호") {
				return { status: "fail", data: data.message };
			}
			return "fail";
		}
		return "fail";
	}
};

export const GoogleSignIn = async (id, name, phone, email) => {
	const sanitizedPhone = sanitizePhoneNumber(phone);

	try {
		const response = await api.post(
			USER_POST_GOOGLE_SIGNIN,
			{
				id,
				name,
				phone: sanitizedPhone,
				email
			},
			{
				headers: { requiresAuth: false }
			}
		);
		return response;
	} catch (error) {
		alert({ message: "회원가입에 실패하였습니다." });
		return;
	}
};

export const NaverSignIn = async (id, name, phone, email) => {
	const sanitizedPhone = sanitizePhoneNumber(phone);
	try {
		const response = await api.post(
			USER_POST_NAVER_SIGNIN,
			{
				id,
				name,
				phone: sanitizedPhone,
				email
			},
			{
				headers: { requiresAuth: false }
			}
		);
		return response;
	} catch (error) {
		alert({ message: "회원가입에 실패하였습니다." });
		return;
	}
};

export const isValidPassword = (password) => {
	const minLength = 8;
	const maxLength = 16;
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	const hasNumbers = /\d/.test(password);
	const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
	return (
		password.length >= minLength &&
		password.length <= maxLength &&
		hasUpperCase &&
		hasLowerCase &&
		hasNumbers &&
		hasSpecialChar
	);
};
