import React, { useEffect, useState } from "react";

const ButtonToTop = () => {
	const [showToTop, setShowToTop] = useState(false);
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > window.innerHeight) {
				setShowToTop(true);
			} else {
				setShowToTop(false);
			}
		};
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<button className={`btn-totop ${showToTop ? "show" : "hide"}`} onClick={scrollToTop}>
				<span className="sr-only">맨 위로</span>
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.16211 14.7754L8.16211 1.77539"
						stroke="#1F2937"
						strokeWidth="1.3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M13.3174 6.1582L8.15853 0.999346L2.99967 6.1582"
						stroke="#1F2937"
						strokeWidth="1.3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</>
	);
};

export default ButtonToTop;
