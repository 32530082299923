import React, { useEffect, useState, useCallback, useRef } from "react";
import { isCellSelection, isTableSelected } from "./Table/utils";

const BubbleMenuButtons = ({ editor }) => {
	const [showEditor, setShowEditor] = useState(false);
	const [url, setUrl] = useState("");
	const [openInNewTab, setOpenInNewTab] = useState(false);
	const editorRef = useRef(null);

	const linkButtonRef = useRef(null); // 버튼 레퍼런스

	const toggleLinkEditor = useCallback(() => {
		const previousUrl = editor.getAttributes("link").href || "";
		const previousTarget = editor.getAttributes("link").target === "_blank";
		setUrl(previousUrl);
		setOpenInNewTab(previousTarget);
		setShowEditor(!showEditor);
	}, [editor, showEditor]);

	const handleSetLink = useCallback(() => {
		if (url === "") {
			editor.chain().focus().extendMarkRange("link").unsetLink().run();
		} else {
			editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.setLink({ href: url, target: openInNewTab ? "_blank" : null })
				.run();
		}
		setShowEditor(false);
	}, [editor, url, openInNewTab]);

	const onChangeUrl = useCallback((event) => {
		setUrl(event.target.value);
	}, []);

	const onToggleOpenInNewTab = useCallback(() => {
		setOpenInNewTab(!openInNewTab);
	}, [openInNewTab]);

	const clearLink = useCallback(() => {
		editor.chain().focus().extendMarkRange("link").unsetLink().run();
		setShowEditor(false);
	}, [editor]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (editorRef.current && !editorRef.current.contains(event.target)) {
				setShowEditor(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	// 테이블 서브 메뉴
	const [activeMenu, setActiveMenu] = useState(null);
	const menuRef = useRef(null);
	const buttonRef = useRef(null);

	const toggleMenu = (menuName) => {
		if (activeMenu === menuName) {
			setActiveMenu(null);
		} else {
			setActiveMenu(menuName);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
				setActiveMenu(null);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const isTableActive = editor.isActive("table");
	const isCellSelected = isCellSelection(editor.state.selection);
	const isTableSelect = isTableSelected(editor.state.selection);

	if (!editor) {
		return null;
	}

	return (
		<>
			{isTableActive && !isCellSelected ? (
				<>
					<button
						onClick={() => editor.chain().focus().setParagraph().run()}
						className={editor.isActive("paragraph") ? "is-active" : ""}
					>
						<i className="ico ico-paragraph-sm"></i>
						<span className="sr-only">본문</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBold().run()}
						disabled={!editor.can().chain().focus().toggleBold().run()}
						className={editor.isActive("bold") ? "is-active" : ""}
					>
						<i className="ico ico-bold"></i>
						<span className="sr-only">굵게</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleItalic().run()}
						disabled={!editor.can().chain().focus().toggleItalic().run()}
						className={editor.isActive("italic") ? "is-active" : ""}
					>
						<i className="ico ico-italic"></i>
						<span className="sr-only">기울임꼴</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleUnderline().run()}
						className={editor.isActive("underline") ? "is-active" : ""}
					>
						<i className="ico ico-underline"></i>
						<span className="sr-only">밑줄</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleStrike().run()}
						disabled={!editor.can().chain().focus().toggleStrike().run()}
						className={editor.isActive("strike") ? "is-active" : ""}
					>
						<i className="ico ico-strike"></i>
						<span className="sr-only">취소선</span>
					</button>
					<span className="tool-line"></span>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						className={editor.isActive("bulletList") ? "is-active" : ""}
					>
						<i className="ico ico-bullet-list"></i>
						<span className="sr-only">글머리 기호 목록</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						className={editor.isActive("orderedList") ? "is-active" : ""}
					>
						<i className="ico ico-ordered-list"></i>
						<span className="sr-only">번호 매기기 목록</span>
					</button>
					<button
						type="button"
						ref={linkButtonRef}
						onClick={toggleLinkEditor}
						className={`${editor.isActive("link") ? "is-active" : ""} ${showEditor ? "on" : ""}`}
					>
						<i className="ico ico-link"></i>
						<span className="sr-only">링크</span>
					</button>
				</>
			) : isCellSelected && !isTableSelect ? (
				<div className="table-cell-menu">
					<div className="toggle-menu">
						<button type="button" ref={buttonRef} onClick={() => toggleMenu("column")}>
							<i className="ico ico-plus-sm"></i>열 추가/제거
						</button>
						{activeMenu === "column" && (
							<div ref={menuRef} className={`table-sub-menu ${activeMenu === "column" ? "active" : ""}`}>
								<button
									type="button"
									onClick={() => {
										editor.chain().focus().addColumnBefore().run();
									}}
									disabled={!editor.can().addColumnBefore()}
								>
									<i className="ico ico-plus-sm"></i>
									앞에 열 추가
								</button>
								<button
									type="button"
									onClick={() => editor.chain().focus().addColumnAfter().run()}
									disabled={!editor.can().addColumnAfter()}
								>
									<i className="ico ico-plus-sm"></i>
									뒤에 열 추가
								</button>
								<button
									type="button"
									onClick={() => editor.chain().focus().deleteColumn().run()}
									disabled={!editor.can().deleteColumn()}
								>
									<i className="ico ico-del-sm"></i> 열 삭제
								</button>
							</div>
						)}
					</div>
					<span className="tool-line"></span>
					<div className="toggle-menu">
						<button type="button" ref={buttonRef} onClick={() => toggleMenu("row")}>
							<i className="ico ico-plus-sm"></i> 앞에 행 추가
						</button>
						{activeMenu === "row" && (
							<div ref={menuRef} className={`table-sub-menu ${activeMenu === "row" ? "active" : ""}`}>
								<button
									type="button"
									onClick={() => editor.chain().focus().addRowBefore().run()}
									disabled={!editor.can().addRowBefore()}
								>
									<i className="ico ico-plus-sm"></i>
									앞에 행 추가
								</button>
								<button
									type="button"
									onClick={() => editor.chain().focus().addRowAfter().run()}
									disabled={!editor.can().addRowAfter()}
								>
									<i className="ico ico-plus-sm"></i>
									뒤에 행 추가
								</button>
								<button
									type="button"
									onClick={() => editor.chain().focus().deleteRow().run()}
									disabled={!editor.can().deleteRow()}
								>
									<i className="ico ico-del-sm"></i> 행 삭제
								</button>
							</div>
						)}
					</div>
					<span className="tool-line"></span>
					<button
						type="button"
						onClick={() => editor.chain().focus().mergeCells().run()}
						disabled={!editor.can().mergeCells()}
					>
						<i className="ico ico-cellmerge-sm"></i> 셀 병합
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().splitCell().run()}
						disabled={!editor.can().splitCell()}
					>
						<i className="ico ico-celldivision-sm"></i> 셀 분할
					</button>
					<span className="tool-line"></span>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeaderCell().run()}
						disabled={!editor.can().toggleHeaderCell()}
					>
						<i className="ico ico-del-sm"></i>
						제목셀 추가 / 삭제
					</button>
				</div>
			) : isTableSelect ? (
				<div className="table-cell-menu">
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeaderRow().run()}
						disabled={!editor.can().toggleHeaderRow()}
					>
						<i className="ico ico-rowtitle-sm"></i> 행 제목셀 추가
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
						disabled={!editor.can().toggleHeaderColumn()}
					>
						<i className="ico ico-columntitle-sm"></i> 열 제목셀 추가
					</button>
					{/* <button
						type="button"
						onClick={() => editor.chain().focus().toggleHeaderCell().run()}
						disabled={!editor.can().toggleHeaderCell()}
					>
						<i className="ico ico-del-sm"></i>
						제목셀 삭제
					</button> */}
					<span className="tool-line"></span>
					<button
						type="button"
						onClick={() => editor.chain().focus().deleteTable().run()}
						disabled={!editor.can().deleteTable()}
					>
						<i className="ico ico-del-sm"></i>표 삭제
					</button>
				</div>
			) : (
				<>
					{/* // 테이블 제외 버튼 렌더링 */}
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
						className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
					>
						<span className="btn-h1">제목1</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
						className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
					>
						<span className="btn-h2">제목2</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
						className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
					>
						<span className="btn-h3">제목3</span>
					</button>
					<span className="tool-line"></span>
					<button
						onClick={() => editor.chain().focus().setParagraph().run()}
						className={editor.isActive("paragraph") ? "is-active" : ""}
					>
						<i className="ico ico-paragraph-sm"></i>
						<span className="sr-only">본문</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBold().run()}
						disabled={!editor.can().chain().focus().toggleBold().run()}
						className={editor.isActive("bold") ? "is-active" : ""}
					>
						<i className="ico ico-bold"></i>
						<span className="sr-only">굵게</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleItalic().run()}
						disabled={!editor.can().chain().focus().toggleItalic().run()}
						className={editor.isActive("italic") ? "is-active" : ""}
					>
						<i className="ico ico-italic"></i>
						<span className="sr-only">기울임꼴</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleUnderline().run()}
						className={editor.isActive("underline") ? "is-active" : ""}
					>
						<i className="ico ico-underline"></i>
						<span className="sr-only">밑줄</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleStrike().run()}
						disabled={!editor.can().chain().focus().toggleStrike().run()}
						className={editor.isActive("strike") ? "is-active" : ""}
					>
						<i className="ico ico-strike"></i>
						<span className="sr-only">취소선</span>
					</button>
					<span className="tool-line"></span>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleBulletList().run()}
						className={editor.isActive("bulletList") ? "is-active" : ""}
					>
						<i className="ico ico-bullet-list"></i>
						<span className="sr-only">글머리 기호 목록</span>
					</button>
					<button
						type="button"
						onClick={() => editor.chain().focus().toggleOrderedList().run()}
						className={editor.isActive("orderedList") ? "is-active" : ""}
					>
						<i className="ico ico-ordered-list"></i>
						<span className="sr-only">번호 매기기 목록</span>
					</button>
					<button
						type="button"
						ref={linkButtonRef}
						onClick={toggleLinkEditor}
						className={`${editor.isActive("link") ? "is-active" : ""} ${showEditor ? "on" : ""}`}
					>
						<i className="ico ico-link"></i>
						<span className="sr-only">링크</span>
					</button>
				</>
			)}
			{/* 공통 버튼 렌더링 */}
			{showEditor && (
				<div
					ref={editorRef}
					style={{
						top: `36px`,
						right: `0px`
					}}
					className="link-detail-panel"
				>
					<div className="input-group">
						<input
							type="url"
							value={url}
							onChange={onChangeUrl}
							className="form-control-sm"
							placeholder="URL을 입력해주세요."
						/>
						<button type="button" className="btn btn-sm btn-primary" onClick={handleSetLink}>
							저장
						</button>
					</div>

					<div className="detail-group">
						<span className="check-item-sm">
							<input type="checkbox" id="select-chk1" checked={openInNewTab} onChange={onToggleOpenInNewTab} />
							<label htmlFor="select-chk1">새창</label>
						</span>
						<button type="button" className="btn btn-xs btn-outline-gray" onClick={clearLink} title="Remove link">
							삭제
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default BubbleMenuButtons;
