import React, { useEffect, useRef } from "react";
import { Transition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0
};

const transitionStyles = {
	entering: { opacity: 0 },
	entered: { opacity: 1 },
	exiting: { opacity: 1 },
	exited: { opacity: 0 }
};

const ModalConfirm = ({
	isOpen,
	title,
	message,
	messageClassName,
	content,
	onConfirm,
	onCancel,
	confirmButtonText = "확인",
	cancelButtonText = "취소",
	buttonStatus = "primary",
	triggerButtonRef
}) => {
	const popupRef = useRef(null);
	const nodeRef = useRef(null);
	const dimedStyleWithoutTransition = {
		opacity: isOpen ? 1 : 0,
		backdropFilter: isOpen ? "blur(10px)" : "blur(0px)"
	};

	useEffect(() => {
		if (isOpen) {
			document.body.classList.add("modal-opened");
		} else {
			document.body.classList.remove("modal-opened");
		}
		return () => {
			document.body.classList.remove("modal-opened");
		};
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			const focusableElements = popupRef.current.querySelectorAll(
				'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
			);

			if (focusableElements.length > 0) {
				focusableElements[0].focus();
			}

			const handleTabKey = (e) => {
				if (e.key !== "Tab") return;

				const focusable = Array.from(focusableElements);
				if (focusable.length === 0) return;

				const firstElement = focusable[0];
				const lastElement = focusable[focusable.length - 1];

				if (e.shiftKey) {
					if (document.activeElement === firstElement || document.activeElement === popupRef.current) {
						e.preventDefault();
						lastElement.focus();
					}
				} else {
					if (document.activeElement === lastElement) {
						e.preventDefault();
						firstElement.focus();
					}
				}
			};

			popupRef.current.addEventListener("keydown", handleTabKey);
			const currentPopupRef = popupRef.current;
			const currentTriggerButtonRef = triggerButtonRef?.current;

			return () => {
				if (currentPopupRef) {
					currentPopupRef.removeEventListener("keydown", handleTabKey);
				}
				if (currentTriggerButtonRef) {
					currentTriggerButtonRef.focus();
				}
			};
		}
	}, [isOpen, triggerButtonRef]);

	if (!isOpen) return null;

	return (
		<div className="modal-popup modal-confirm">
			<div className="dimed" style={dimedStyleWithoutTransition}></div>
			<Transition in={isOpen} timeout={duration} unmountOnExit appear nodeRef={nodeRef}>
				{(state) => (
					<div
						ref={popupRef}
						className="popup-inner"
						style={{
							...defaultStyle,
							...transitionStyles[state]
						}}
					>
						<div className="popup-header">
							<h2 className="popup-title">{title}</h2>
						</div>
						<div className="popup-body">
							{message && <p className={`message ${messageClassName}`}>{message}</p>}
							{content && <div className="container" dangerouslySetInnerHTML={{ __html: content }} />}
						</div>
						<div className="popup-footer">
							<div className="btn-group">
								<button type="button" className="btn btn-xl btn-outline-gray" onClick={onCancel}>
									{cancelButtonText}
								</button>
								<button type="button" className={`btn btn-xl btn-${buttonStatus}`} onClick={onConfirm}>
									{confirmButtonText}
								</button>
							</div>
						</div>
					</div>
				)}
			</Transition>
		</div>
	);
};

export default ModalConfirm;
