const { RESET_STATE } = require("../action/ResetAction");
const { TRASHCAN_LOADING, TRASHCAN_LOADED, GET_TRASHCAN_DATA } = require("../action/TrashCanAction");

const initialState = {
	data: {
		trashCanData: {
			data: []
		},
		isLoading: false
	}
};

const trashCanReducer = (state = initialState, action) => {
	switch (action.type) {
		case TRASHCAN_LOADING:
			return {
				...state,
				isLoading: true
			};
		case TRASHCAN_LOADED:
			return {
				...state,
				isLoading: false
			};
		case GET_TRASHCAN_DATA:
			return {
				...state,
				trashCanData: action.payload
			};
			break;
		case RESET_STATE:
			return initialState;
			break;
		default:
			return state;
	}
};

export default trashCanReducer;
