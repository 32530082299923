import api from "@/services/util/Interceptor";
import { TRASHCAN_DELETE } from "../url/URL";
import { navigateTo } from "@/services/util/Navigate";

export const TrashDelete = async (workId) => {
	try {
		const response = await api.post(TRASHCAN_DELETE, {
			workspaceIds: [workId]
		});
		return "Success";
	} catch (error) {
		return "Fail";
	}
};

export const TrashIsAllDelete = async (isAll) => {
	try {
		const response = await api.post(TRASHCAN_DELETE, {
			workspaceIds: [],
			isAll: isAll
		});
		return "Success";
	} catch (error) {
		return "Fail";
	}
};

export const TrashDeleteList = async (workId, isAll) => {
	try {
		const response = await api.post(TRASHCAN_DELETE, {
			workspaceIds: workId,
			isAll: isAll
		});
		return "Success";
	} catch (error) {
		return "Fail";
	}
};
