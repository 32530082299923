import { CLEAR_AUTH_ERROR, SET_AUTH_ERROR } from "../action/AuthAction";

const initialState = {
	authError: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_AUTH_ERROR:
			return {
				...state,
				authError: action.payload
			};
		case CLEAR_AUTH_ERROR:
			return {
				...state,
				authError: null
			};

		default:
			return state;
	}
};

export default authReducer;
