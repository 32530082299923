import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { SEO_SAVE } from "../url/URL_Seo";
import { useNavigate } from "react-router-dom";
import { navigateTo } from "@/services/util/Navigate";
export const SeoSave = async (data) => {
	const token = getCookie("token");

	try {
		const response = await axios.post(SEO_SAVE, data, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		navigateTo("/error");
		return {
			status: "file",
			
		};
	}
};
