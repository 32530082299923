import React, { useEffect } from "react";
import { useCodeMirror } from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { EditorView } from "@codemirror/view";
import { linter } from "@codemirror/lint";
import { parse } from "node-html-parser";

const tableCaptionLinter = linter((view) => {
	const diagnostics = [];
	const docText = view.state.doc.toString();
	const root = parse(docText);

	const tables = root.getElementsByTagName("table");
	tables.forEach((table) => {
		const caption = table.querySelector("caption");
		let from, to, message, severity;

		if (caption) {
			if (caption.text.trim().length > 0) {
				// caption이 있고 내부에 텍스트가 있는 경우
				from = caption.range[0];
				to = caption.range[1];
				message = "caption의 내용이 올바른지 확인해 주세요.";
				severity = "hint";
			} else {
				// caption이 있지만 비어있는 경우
				from = caption.range[0];
				to = caption.range[1];
				message = "caption이 비어있습니다. 내용을 입력해 주세요.";
				severity = "warning";
			}
		} else {
			// caption이 없는 경우
			from = table.range[0];
			to = table.range[1];
			message = "이 표에 caption을 추가해 주세요.";
			severity = "error";
		}

		diagnostics.push({
			from,
			to,
			message,
			severity,
			actions: [
				{
					name: "버튼",
					apply: function () {}
				}
			]
		});
	});

	return diagnostics;
});

const CodeMirrorHtml = ({
	initialCode,
	onCursorMove,
	onCursorPositionChange,
	onCodeChange,
	enableTableCaptionLinter
}) => {
	const { setContainer, view } = useCodeMirror({
		value: initialCode,
		language: "htmlmixed",
		extensions: [
			html(),
			EditorView.lineWrapping,
			enableTableCaptionLinter ? tableCaptionLinter : [],
			EditorView.updateListener.of((update) => {
				if (update.docChanged) {
					const newCode = update.view.state.doc.toString();
					onCodeChange?.(newCode);
				}
			})
		],
		basicSetup: {
			lineNumbers: true,
			bracketMatching: true,
			foldGutter: true,
			closeBrackets: true,
			autocompletion: true,
			lineWrapping: true
		}
	});

	useEffect(() => {
		if (view) {
			const originalDispatch = view.dispatch;
			view.dispatch = (tr) => {
				originalDispatch(tr);
				if (tr.selection) {
					const { head } = view.state.selection.main;
					const line = view.state.doc.lineAt(head).number;
					const col = head - view.state.doc.line(line).from;
					onCursorPositionChange?.({ line, col });
				}
			};

			return () => {
				view.dispatch = originalDispatch;
			};
		}
	}, [view, onCursorPositionChange]);

	useEffect(() => {
		if (onCursorMove && view) {
			onCursorMove({
				moveCursor: (line, col) => {
					const pos = view.state.doc.line(line).from + col;
					view.dispatch({
						selection: { anchor: pos },
						scrollIntoView: true
					});
					view.focus();
				}
			});
		}
	}, [view, onCursorMove]);

	return <div className="code-mirror-html" ref={setContainer} />;
};

export default CodeMirrorHtml;
