import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "@/components/button/Button";
import ToastAlert from "@/components/toast/ToastAlert";
import useToastAlert from "@/hooks/useToastAlert";
import { getCookie } from "@/services/cookies/UserCookies";
import { useHandleTableCreate } from "@/services/core/table/TableCreateHandle";
import { refreshCancelTokenSource } from "@/services/util/Interceptor";

export default function TableAddPage() {
	const navigate = useNavigate();
	// const [isLoading, setIsLoading] = useState(false);
	const noTableToast = useToastAlert();

	useEffect(() => {
		const token = getCookie("token");
		if (!token) {
			navigate("/login");
		}
	}, [navigate]);

	// [개발] 화면 구현을 위한 임시 데이터 검사 내용입니다. => 수정 및 변경 필요
	const [inputValue, setInputValue] = useState("");
	const [errorMessage, setErrorMessage] = useState(false);
	const handleInputFocus = () => {
		setErrorMessage(false);
	};

	const handleInputBlur = (e) => {
		const url = e.target.value;
		setInputValue(url);

		if (url.trim() === "") {
			setErrorMessage(false);
		} else if (isValidUrl(url)) {
			setErrorMessage(false);
		} else {
			setErrorMessage(true);
		}
	};

	// URL 형식을 검증하는 함수
	const isValidUrl = (urlString) => {
		try {
			new URL(urlString);
			return true;
		} catch (error) {
			return false;
		}
	};

	// [개발] 화면 구현을 위한 상태값 확인 내용입니다. => 수정 및 변경 필요
	const [urlInputValue, setUrlInputValue] = useState("");
	const [textAreaValue, setTextAreaValue] = useState("");
	const { noDataTost, isLoading, handleTableCheck, setNoDataTost } = useHandleTableCreate(textAreaValue, urlInputValue);
	// 기존로직
	// const handleUrlInputChange = (e) => {
	// 	setUrlInputValue(e.target.value);
	// 	const url = e.target.value;
	// 	if (url.trim() === "") {
	// 		setErrorMessage(false);
	// 	} else if (isValidUrl(url)) {
	// 		setErrorMessage(false);
	// 		setIsButtonDisabled(false);
	// 	} else {
	// 		setErrorMessage(true);
	// 		setIsButtonDisabled(true);
	// 	}
	// };

	// const handleTextAreaChange = (e) => {
	// 	setTextAreaValue(e.target.value);
	// 	setIsButtonDisabled(false);
	// 	if (e.target.value===""){
	// 		setIsButtonDisabled(true)
	// 	}
	// };
	const handleUrlInputChange = (e) => {
		const newUrlValue = e.target.value;
		setUrlInputValue(newUrlValue);

		if (newUrlValue.trim() === "" && textAreaValue.trim() === "") {
			setIsButtonDisabled(true);
			setErrorMessage(false);
		} else if (isValidUrl(newUrlValue)) {
			setErrorMessage(false);
			setIsButtonDisabled(false);
		} else {
			setErrorMessage(true);
			setIsButtonDisabled(textAreaValue.trim() === ""); // 텍스트 영역 값이 유효한지 확인
		}
	};

	const handleTextAreaChange = (e) => {
		const newTextValue = e.target.value;
		setTextAreaValue(newTextValue);

		// 텍스트 영역 값이 비어있지 않으면, URL 유효성 검사에 따라 버튼 활성화 상태 결정
		if (newTextValue.trim() === "" && urlInputValue.trim() === "") {
			setIsButtonDisabled(true);
		} else {
			setIsButtonDisabled(!isValidUrl(urlInputValue) && newTextValue.trim() === "");
		}
	};

	const location = useLocation();
	useEffect(() => {
		refreshCancelTokenSource();
		return () => {
			refreshCancelTokenSource();
		};
	}, [location]);
	useEffect(() => {
		if (noDataTost == true) {
			noTableToast.openToast(true);
			setNoDataTost(false);
		}
	}, [noDataTost]);
	const isUrlInputDisabled = textAreaValue.trim() !== "";
	const isTextAreaDisabled = urlInputValue.trim() !== "";
	const [isButtonDisabled, setIsButtonDisabled] = useState(urlInputValue.trim() === "" && textAreaValue.trim() === "");

	return (
		<>
			<div className="container table-add-page">
				<div className="content-box content-tooladd">
					<div className="tooladd-wrap">
						<div className="tooladd-header">
							<h2 className="page-title">
								<i className="ico ico-service-table-lg"></i>
								<span>표 접근성 최적화</span>
							</h2>
						</div>
						<div className="tooladd-body">
							<div className="inner">
								<div className="box ">
									<div className="info-area">
										<strong>이해의 용이성 (7.3.2. 표의 구성)</strong>
										<ul className="list list1 mt-2">
											<li>표(테이블)는 구조가 복잡해 시각장애인이 이해하기 어려운 콘텐츠에요.</li>
											<li>
												앨리와 함께 복잡한 구조의 이해를 도울 제목(캡션)을 생성하고 웹 콘텐츠에 쉽게 적용해보세요.
											</li>
										</ul>
									</div>
								</div>

								<p className="text-guide mt-8">표는 한 번에 최대 5개까지 추출되며 초과항목은 검사에서 제외돼요.</p>

								{/* [개발] 입력값이 둘 중 하나가 들어가면 다른 필드는 비활성화 */}
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleTableCheck();
									}}
								>
									<div className="input-wrap">
										<span className="input-link">
											<input
												type="url"
												id="inputurl"
												className="form-control"
												title="공개된 웹페이지 링크를 입력해주세요. 로그인 없이 접근 가능해야 합니다."
												placeholder="공개된 웹페이지 링크를 입력해주세요. 로그인 없이 접근 가능해야 합니다."
												onFocus={handleInputFocus}
												onBlur={handleInputBlur}
												onChange={handleUrlInputChange}
												value={urlInputValue}
												disabled={isUrlInputDisabled}
											/>
										</span>
										{errorMessage && (
											<p className="error-text">
												'<span>{urlInputValue}</span>'의 IP 주소는 공인 주소가 아닙니다. 올바른 웹사이트 링크를
												입력해주세요.
											</p>
										)}
									</div>
									<span className="between-text">또는</span>

									<div className="input-wrap">
										<textarea
											rows="4"
											cols="50"
											className="form-control form-control-xxl"
											title="표가 포함된 HTML 코드를 입력해주세요."
											placeholder="표가 포함된 HTML 코드를 입력해주세요."
											onChange={handleTextAreaChange}
											value={textAreaValue}
											disabled={isTextAreaDisabled}
										/>
									</div>
								</form>
							</div>
							<div className="btn-group btn-group-flex mt-8">
								<Button
									type="button"
									icon={{ classes: "ico-tool-md" }}
									text="표 분석하기"
									loadingText={true}
									isLoading={isLoading}
									size="xl"
									color="primary"
									onClick={handleTableCheck}
									disabled={isButtonDisabled}
								/>

								{noTableToast.showToast && (
									<ToastAlert
										message="표가 발견되지 않았습니다. 다시 입력해주세요."
										onClose={noTableToast.closeToast}
										icon={<i className="ico ico-notify-xs"></i>}
										position="bottom"
										theme="dark"
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
