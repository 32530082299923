import React from "react";
import mockData from "@/mock/mockData";

export default function CustomerSupport({ meta = {} }) {
	const defaultMeta = {
		// SEO 기본 설정
		title: mockData.title,
		description: mockData.description,
		keywords: mockData.keywords,
		ogTitle: mockData.ogTitle,
		ogDescription: mockData.ogDescription,
		ogUrl: mockData.ogUrl,
		ogImage: mockData.ogImage,

		// 고객지원
		supportDocRoot: mockData.supportDocRoot
	};

	const effectiveMeta = { ...defaultMeta, ...meta };
	const docRoot = mockData.supportDocRoot;
	const pageUrl = `${docRoot}${effectiveMeta.link}`;

	return (
		<div className="customer-support">
			<iframe className="supersite-iframe" src={pageUrl} title={effectiveMeta.title} />
		</div>
	);
}
