// import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "@/assets/images/logo@2x.png";
import Error from "@/assets/images/ico-error@2x.png";

export default function ErrorPage() {
	const goToPreviousPage = () => {
		window.history.back();
	  };
	return (
		<>
			<div className="error-page">
				<div className="error-inner">
					<h1 className="logo">
						<Link to="/">
							<img src={Logo} alt="A11Y" />
						</Link>
					</h1>
					<div className="error-area">
						<img src={Error} alt="Error" />
						<p className="message">페이지가 없거나 오류가 발생하였습니다.</p>
						<p className="desc">현재 페이지가 존재하지 않거나, 현재 이용할 수 없는 페이지 입니다.</p>
						<div className="btn-group">
							<button onClick={goToPreviousPage} className="btn btn-xl btn-outline-gray pl-6 pr-6">
								이전으로
							</button>
							<Link to="/" className="btn btn-xl btn-primary pl-6 pr-6">
								메인으로
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
