import { getCookie } from "@/services/cookies/UserCookies";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NullPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const token = getCookie("token");
		if (token) {
			navigate("/dashboard");
		} else {
			navigate("/login");
		}
	});
	return <div></div>;
};
