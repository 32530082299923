import { DashBoard } from "@/services/core/workspace/DashBoard";

export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";
export const IMG2HTML_DASHBOARD_DATA = "IMG2HTML_DASHBOARD_DATA";
export const SEO_DASHBOARD_DATA = "SEO_DASHBOARD_DATA";
export const CONTRAST_DASHBOARD_DATA = "CONTRAST_DASHBOARD_DATA";
export const TABLE_DASHBOARD_DATA = "TABLE_DASHBOARD_DATA";
export const UPDATE_WORK_NAME_BY_ID = "UPDATE_WORK_NAME_BY_ID";
export const UPDATE_WORK_NAME_SUCCESS = "UPDATE_WORK_NAME_SUCCESS";
/// 기본 디폴트
export const setDashboardData = (data) => {
	return {
		type: SET_DASHBOARD_DATA,
		payload: data
	};
};

export const dashboardLoading = () => ({
	type: DASHBOARD_LOADING
});

export const dashboardLoaded = () => ({
	type: DASHBOARD_LOADED
});

/// 이미지 HTML
export const setDashboardImg2htmlData = (data) => {
	return {
		type: IMG2HTML_DASHBOARD_DATA,
		payload: data
	};
};

/// SEO 메타태그
export const setDashboardSEOData = (data) => {
	return {
		type: SEO_DASHBOARD_DATA,
		payload: data
	};
};

/// 이미지 명도대비
export const setDashboardContrastData = (data) => {
	return {
		type: CONTRAST_DASHBOARD_DATA,
		payload: data
	};
};

/// 표 접근성
export const setDashboardTableData = (data) => {
	return {
		type: TABLE_DASHBOARD_DATA,
		payload: data
	};
};

export const fetchDashboardData = (optionType) => async (dispatch) => {
	dispatch(dashboardLoading());
	try {
		let data;
		switch (optionType) {
			case "img2html":
				data = await DashBoard(null, 10, "", "img_html", 1, true);
				dispatch(setDashboardImg2htmlData(data));
				break;
			case "contrast":
				data = await DashBoard(null, 10, "", "img_improve", 1, true);
				dispatch(setDashboardContrastData(data));
				break;
			case "seo":
				data = await DashBoard(null, 10, "", "seo", 1, true);
				dispatch(setDashboardSEOData(data));
				break;
			case "table":
				data = await DashBoard(null, 10, "", "web_standard", 1, true);
				dispatch(setDashboardTableData(data));
				break;
			case null:
				dispatch(setDashboardData(data));
				break;
			default:
				dispatch(setDashboardData(data));
		}
	} catch (error) {
		console.error("대시보드 에러", error);
	} finally {
		dispatch(dashboardLoaded());
	}
};
