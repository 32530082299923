import React from "react";
import PopupLayout from "@/components/popup/PopupLayout";
import { termsOfServiceText } from "@/data/legal";

const PopupAgreement = ({ isOpen, closePopup }) => {
	return (
		<PopupLayout
			className="agreement-popup"
			isOpen={isOpen}
			closePopup={closePopup}
			title="서비스 이용약관"
			size="md"
			footerContent={null}
		>
			<div className="agreement-article" dangerouslySetInnerHTML={{ __html: termsOfServiceText }} />
		</PopupLayout>
	);
};

export default PopupAgreement;
