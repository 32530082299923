import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as pdfjsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import Dropzone from "@/components/form/DragAndDrop";
import Button from "@/components/button/Button";
import { useHandleSeoCreate } from "@/services/core/seo/SeoCreateHandle";
import { refreshCancelTokenSource } from "@/services/util/Interceptor";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function SeoAddPage() {
	const [errorMessage, setErrorMessage] = useState(false);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [file, setFile] = useState(null);
	const [urlInputValue, setUrlInputValue] = useState("");
	const [textAreaValue, setTextAreaValue] = useState("");
	const [pdfText, setPdfText] = useState("");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const { isLoading, handleSeoCheck } = useHandleSeoCreate(isFileUploaded ? pdfText : textAreaValue, urlInputValue);

	const location = useLocation();
	useEffect(() => {
		refreshCancelTokenSource();
		return () => refreshCancelTokenSource();
	}, [location]);

	const isValidUrl = (urlString) => {
		try {
			new URL(urlString);
			return true;
		} catch (error) {
			return false;
		}
	};

	const handleUrlInputChange = (e) => {
		const newUrlValue = e.target.value;
		setUrlInputValue(newUrlValue);

		if (newUrlValue.trim() === "") {
			setIsButtonDisabled(true);
			setErrorMessage(false);
		} else if (isValidUrl(newUrlValue)) {
			setErrorMessage(false);
			setIsButtonDisabled(false);
		} else {
			setErrorMessage(true);
			setIsButtonDisabled(true);
		}
	};

	const handleTextAreaChange = (e) => {
		const newTextValue = e.target.value;
		setTextAreaValue(newTextValue);
		setIsButtonDisabled(newTextValue.trim() === "");
	};

	const isDropzoneDisabled = urlInputValue.trim() !== "" || textAreaValue.trim() !== "";
	const isUrlInputDisabled = isFileUploaded || textAreaValue.trim() !== "";
	const isTextAreaDisabled = isFileUploaded || urlInputValue.trim() !== "";

	const onFileRemoved = useCallback(() => {
		setIsFileUploaded(false);
		setFile(null);
		setPdfText("");
		setTextAreaValue("");
		setUrlInputValue("");
		setIsButtonDisabled(true);
	}, []);

	const onFileUploaded = useCallback(async (file) => {
		if (file.type === "application/pdf") {
			setFile(file);
			setIsFileUploaded(true);

			try {
				const reader = new FileReader();
				reader.onload = async () => {
					const typedarray = new Uint8Array(reader.result);
					const pdf = await pdfjsLib.getDocument(typedarray).promise;

					let fullText = "";
					for (let i = 1; i <= pdf.numPages; i++) {
						const page = await pdf.getPage(i);
						const textContent = await page.getTextContent();
						const pageText = textContent.items.map((item) => item.str).join(" ");
						fullText += pageText + "\n";
					}

					setPdfText(fullText);
					setUrlInputValue("");
					setTextAreaValue("");
					setIsButtonDisabled(false);
				};
				reader.readAsArrayBuffer(file);
			} catch (err) {
				console.error("PDF 처리 중 오류:", err);
				setIsFileUploaded(false);
				setFile(null);
				setPdfText("");
				setIsButtonDisabled(true);
			}
		}
	}, []);

	return (
		<div className="container seowork-page">
			<div className="content-box content-tooladd">
				<div className="tooladd-wrap">
					<div className="tooladd-header">
						<h2 className="page-title">
							<i className="ico ico-service-seo-lg"></i>
							<span>SEO 메타태그 생성기</span>
						</h2>
					</div>
					<div className="tooladd-body">
						<div className="inner">
							<div className="box">
								<div className="info-area">
									<strong>운용의 용이성 (6.4.2. 제목 제공)</strong>
									<ul className="list list1 mt-2">
										<li>
											웹 콘텐츠에 적절한 제목을 넣으면 시각장애인의 스크린리더 사용과 검색엔진 노출 효과가 좋아져요.
										</li>
										<li>콘텐츠의 제목, 키워드, 요약문, 섬네일, 메타태그를 앨리 AI가 간편하게 생성해드려요.</li>
									</ul>
								</div>
							</div>

							<p className="text-guide mt-8">
								블로그, 뉴스 등 의미를 가진 텍스트가 충분히 포함되어 있어야 정상적인 결과를 얻을 수 있어요.
							</p>

							<form
								onSubmit={(e) => {
									e.preventDefault();
									handleSeoCheck();
								}}
							>
								<div className="input-wrap">
									<span className="input-link">
										<input
											type="url"
											id="inputurl"
											title="웹 콘텐츠 링크를 입력해주세요."
											className="form-control"
											placeholder="웹 콘텐츠 링크를 입력해주세요."
											onChange={handleUrlInputChange}
											value={urlInputValue}
											disabled={isUrlInputDisabled}
										/>
									</span>
									{errorMessage && (
										<p className="error-text">
											'<span>{urlInputValue}</span>'의 IP 주소는 공인 주소가 아닙니다. 올바른 웹사이트 링크를
											입력해주세요.
										</p>
									)}
								</div>

								<span className="between-text">또는</span>

								<div className="input-wrap">
									<textarea
										rows="3"
										cols="50"
										className="form-control form-control-xxl"
										title="HTML 코드, 텍스트를 입력해주세요."
										placeholder="HTML 코드, 텍스트를 입력해주세요."
										onChange={handleTextAreaChange}
										value={textAreaValue}
										disabled={isTextAreaDisabled}
									/>
								</div>

								<span className="between-text">또는</span>

								<div className="tool-file-upload file-upload-pdf">
									<Dropzone
										showDeleteButton={true}
										hideGuideText={!isFileUploaded}
										onFileUploaded={onFileUploaded}
										onFileRemoved={onFileRemoved}
										service={"SEO"}
										acceptedFileTypes={[".pdf"]}
										disabled={isDropzoneDisabled}
										textAreaValue={pdfText}
									/>
								</div>
							</form>
						</div>
						<div className="btn-group btn-group-flex mt-8">
							<Button
								type="button"
								id="checkSeo"
								icon={{ classes: "ico-tool-md" }}
								text="메타태그 AI 생성하기"
								loadingText={true}
								isLoading={isLoading}
								size="xl"
								color="primary"
								onClick={handleSeoCheck}
								disabled={isButtonDisabled}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
