import React from "react";
import PopupLayout from "@/components/popup/PopupLayout";
import { privacyPolicyText } from "@/data/legal";

const PopupPrivacy = ({ isOpen, closePopup }) => {
	return (
		<PopupLayout
			className="privacy-popup"
			isOpen={isOpen}
			closePopup={closePopup}
			title="개인정보처리방침"
			size="md"
			footerContent={null}
		>
			<div className="agreement-article" dangerouslySetInnerHTML={{ __html: privacyPolicyText }} />
		</PopupLayout>
	);
};

export default PopupPrivacy;
