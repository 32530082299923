import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Logo from "@/assets/images/logo@2x.png";
import logoSm from "@/assets/images/logo-a11y-sm@2x.png";
import LoadingBar from "@/components/loading/LoadingBar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL, IMG2_GET_SHARE } from "@/services/core/url/URL";
import imgProfile from "@/assets/images/img-profile-sm@2x.png";

export default function SharePage() {
	const { pathParam } = useParams();
	const [imageData, setImageData] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isUserCount, setUserCount] = useState(0);
	const [userName, setUserName] = useState("");
	const [userProfile, setUserProfile] = useState("");
	const [h1Text, setH1Text] = useState("기본값");
	const [imagePath, setImagePath] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			setIsLoaded(false); // 로딩 상태 시작

			try {
				const resp = await axios.get(`${IMG2_GET_SHARE}${pathParam}`);

				if (resp?.data?.data?.html) {
					setUserCount(resp.data.data.count);
					setUserName(resp.data.data.name);
					setUserProfile(resp.data.data.profileImg);
					const parser = new DOMParser();
					const htmlString = resp.data.data.html; // API 응답에서 HTML 문자열 추출
					const doc = parser.parseFromString(htmlString, "text/html");
					const h1 = doc.querySelector("h1") ? doc.querySelector("h1").textContent : "기본값";
					setH1Text(h1);

					const formattedData = {
						alt: h1,
						html: resp.data.data.html,
						src: resp.data.data.imgPath
					};
					setImagePath(resp.data.data.imgPath);

					setImageData(formattedData); // 파싱된 데이터로 상태 업데이트
				}

				setIsLoaded(true); // 로딩 상태 완료
			} catch (error) {
				console.error("데이터 로딩 중 에러 발생", error);
				setIsLoaded(true); // 에러 발생 시에도 로딩 상태 완료 처리
			}
		};

		fetchData();
	}, [pathParam]);

	useEffect(() => {
		const metaNames = [
			"description",
			"keywords",
			"viewport",
			"theme-color",
			"og:title",
			"og:description",
			"og:image",
			"og:url",
			"og:type",
			"twitter:card",
			"twitter:title",
			"twitter:description",
			"twitter:image"
		];

		// 기존 메타 태그 제거
		metaNames.forEach((name) => {
			const existingMeta = document.querySelectorAll(`meta[name='${name}'], meta[property='${name}']`);
			existingMeta.forEach((meta) => meta.remove());
		});

		// 기존 링크 태그 제거
		const existingLinks = document.querySelectorAll("link[rel='stylesheet']");
		existingLinks.forEach((link) => link.remove());
	}, []);

	if (!isLoaded) {
		return (
			<HelmetProvider>
				<Helmet>
					<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/SharePage.css" />
				</Helmet>
				<div className="share-page">
					<div className="loading-area">
						<LoadingBar />
					</div>
				</div>
			</HelmetProvider>
		);
	}

	return (
		<HelmetProvider>
			<Helmet>
				<html lang="ko" />
				<link
					rel="icon"
					type="image/png"
					href="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/images/favicon.ico"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="theme-color" content="#000000" />
				<meta name="description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
				<meta name="keywords" content="웹접근성, 웹 접근성, a11y, 인시퀀스, 앨리, AI, 인공지능, SEO" />
				<meta property="og:title" content={h1Text} />
				<meta property="og:description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
				<meta property="og:image" content={`https://a11y.co.kr${imagePath}`} />
				<meta property="og:url" content="https://a11y.co.kr" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={h1Text} />
				<meta name="twitter:description" content="웹 접근성 작업 앨리와 함께 부스트업!" />
				<meta name="twitter:image" content={`https://a11y.co.kr${imagePath}`} />
				<title>[a11y.co.kr] {h1Text}</title>
				{/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/inseq/a11y-output@@latest/SharePage.css" /> */}
				<link rel="stylesheet" href="/share/SharePage.module.css" />
			</Helmet>
			<div className="share-page">
				<div className="a11y-container">
					<header>
						<h1 className="logo-a11y">
							<a href={API_URL} target="_blank" title="새창" rel="noreferrer">
								<img src={Logo} alt="A11Y beta" />
							</a>
						</h1>
						<h2 className="a11y-title">이미지 HTML 변환 결과물</h2>

						<p className="a11y-info">
							<span className="a11y-profile-thumb">
								<img src={`${userProfile || imgProfile}`} alt="프로필 이미지" />
							</span>
							<span className="a11y-text">
								<strong className="a11y-user">{userName}</strong>님이 앨리와 함께
								<strong className="a11y-count">{isUserCount}</strong>번째 웹 접근성 개선에 기여했어요.
							</span>
						</p>

						<div className="box">
							<i className="ico ico-notify"></i>
							<a
								href="https://a11y.co.kr/help/img2html-output"
								target="_blank"
								title="새창"
								className="a11y-link"
								rel="noreferrer"
							>
								이미지에 대체 텍스트 적용 방법(IR 기법) 자세히 보기
							</a>
						</div>
					</header>
					<article>
						<figure>
							{imageData && (
								<>
									<img src={`${imageData.src}`} alt={imageData.alt} />
									<figcaption
										dangerouslySetInnerHTML={{ __html: imageData.html }}
										className="a11y-sr-only html-preview"
									/>
								</>
							)}
						</figure>
					</article>
					<footer>
						<div className="box box-flex">
							<p className="a11y-producer">
								<img src={logoSm} alt="A11Y" className="logo-a11y-sm" />
								앨리에서 제작되었습니다.
							</p>

							<a href={API_URL} target="_blank" title="새창" className="btn btn-a11y" rel="noreferrer">
								<i className="ico ico-link-sm-white"></i>
								앨리 바로가기
							</a>
						</div>
					</footer>
				</div>
			</div>
		</HelmetProvider>
	);
}
