import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import PopupLayer from "@/components/popup/PopupLayer";
import Toast from "@/components/toast/Toast";
import nodataImg from "@/assets/images/img-nodata@2x.png";

import TaskData from "@/mock/TaskData";
import { TrashRestore } from "@/services/core/workspace/TrashRestore";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import {
	fetchDashboardData,
	setDashboardData,
	updateDashboardDataWithNewName,
	updateDashboardDataWithNewNameSuccess
} from "@/services/store/action/DashBoard";
import { useDispatch, useSelector } from "react-redux";

import WorkUsePopup from "@/services/core/workspace/WorkSpacePopup";
import { WorkSpaceChangeName } from "@/services/core/workspace/WorkSpaceChangeName";
import { TrashingIt } from "@/services/core/workspace/TrashingIt";
import { navigateTo } from "@/services/util/Navigate";
import InputTaskNameWork from "../form/InputTaskNameWork";
import { compare } from "@/services/util/Compare";
import { handleWorkDetail } from "@/services/core/workspace/WorkspaceDetail";
import { updateDashboardCardDirection, updateDashboardListField } from "@/services/store/action/SortOrderAction";
import numeral from "numeral";
import { fileSizeDisplay } from "@/services/util/FileSize";

export default function ListTask({
	workItems: initialWorkItems,
	itemCount,
	onSelectedItemsCountChange,
	work,
	onWorkNameChange,
	onTrash,
	onSelectedItemsChange,
	onSelectAllCheckedChange
}) {
	const seoData = useSelector((state) => state.seo.seoData);
	const tableData = useSelector((state) => state.table?.tableData || []);
	const brightness = useSelector((state) => state);
	const dashBoardList = useSelector((state) => state.dashboardSort.sort);
	const html = useSelector((state) => state.html?.htmlData || []);
	const dispatch = useDispatch();
	useEffect(() => {
		setSelectAllChecked(false); // 신호가 변경될 때마다 실행
		setSelectedItems(new Set());
		const allRows = document.querySelectorAll(".table-task tbody tr");
		allRows.forEach((row) => {
			row.classList.remove("on");
		});

		onSelectedItemsCountChange(0);
	}, [onSelectAllCheckedChange]);
	// 작업 리스트
	const [workItems, setWorkItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState(new Set());
	const [selectAllChecked, setSelectAllChecked] = useState(false);

	const [sortState, setSortState] = useState({
		field: dashBoardList.list.field,
		direction: dashBoardList.list.direction
	});

	const {
		isOpen: isDefaultPopup,
		open: openDefaultPopup,
		close: closeDefaultPopup,
		selectedId,
		selectedName
	} = WorkUsePopup();
	const [userWorkName, setWorkName] = useState("");

	// 더보기 팝업
	const moreButtonRef = useRef([]);
	const [popupStates, setPopupStates] = useState({});
	const [showToastRestore, setShowToastRestore] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState(null);

	/// 휴지통 보내기
	const handleTrash = async (id) => {
		const resp = await TrashingIt(id);
		if (resp) {
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
			const dashboardData = await DashBoard(null, 10, "", null, 1, true);
			dispatch(setDashboardData(dashboardData));
			onTrash();
		}

		if (resp === "Fail") {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};
	const handleWorkNameChange = (event) => {
		setWorkName(event);
	};

	// 리스트 로드 갯수

	useEffect(() => {
		setWorkItems(initialWorkItems.slice(0, itemCount)); // itemCount만큼 항목 로드
	}, [itemCount]);
	// 더보기 팝업
	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeMorePopup = (id) => {
		setPopupStates((prevStates) => ({
			...prevStates,
			[id]: false // 특정 작업 항목의 팝업을 닫음
		}));
		setActiveButtonId(null);
	};
	const toggleMorePopup = (id, event) => {
		event.stopPropagation();
		setPopupStates((prevStates) => {
			const newStates = Object.keys(prevStates).reduce((states, itemId) => {
				states[itemId] = false;
				return states;
			}, {});

			newStates[id] = !prevStates[id];
			return newStates;
		});

		setActiveButtonId((prevActiveId) => (prevActiveId === id ? null : id));
	};

	// 리스트 선택
	const handleSelectItem = (id) => {
		const newSelectedItems = new Set(selectedItems);

		if (selectedItems.has(id)) {
			newSelectedItems.delete(id);
		} else {
			newSelectedItems.add(id);
		}
		onSelectedItemsChange(newSelectedItems);
		setSelectedItems(newSelectedItems);

		// 전체 선택 상태 검사 (하나의 선택이라도 해제되었을 때)
		const allSelected = workItems.length > 0 && workItems.every((item) => newSelectedItems.has(item.id));
		setSelectAllChecked(allSelected);

		// 체크된 항목에 대한 부모 tr에 클래스 추가 또는 제거
		const checkboxes = document.querySelectorAll('.check-item-wrap input[type="checkbox"]');
		checkboxes.forEach((checkbox) => {
			const td = checkbox.closest(".check-item-wrap");
			const tr = td.closest("tr");

			if (tr && checkbox.checked) {
				tr.classList.add("on");
			} else {
				tr.classList.remove("on");
			}
		});

		// 부모 컴포넌트에 체크된 항목의 개수 전달
		onSelectedItemsCountChange(newSelectedItems.size);
	};

	/// 작업명 변경
	const handleChangeWorkName = async () => {
		const resp = await WorkSpaceChangeName(selectedId, userWorkName);
		if (resp === "Success") {
			const dashboardData = await DashBoard(null, 10, "", null, 1, true);
			dispatch(setDashboardData(dashboardData));
			onWorkNameChange();
			setSortState({
				field: "lastModified",
				direction: "desc"
			});
			closeDefaultPopup();
		}
		if (resp === "Fail") {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};
	useEffect(() => {
		setSelectAllChecked(false); // 신호가 변경될 때마다 실행
		setSelectedItems(new Set());
		const allRows = document.querySelectorAll(".table-task tbody tr");
		allRows.forEach((row) => {
			row.classList.remove("on");
		});

		onSelectedItemsCountChange(0);
	}, [onSelectAllCheckedChange]);
	// 리스트 전체 선택
	const handleSelectAll = () => {
		if (selectAllChecked) {
			// 전체 선택 해제
			setSelectedItems(new Set());
			setSelectAllChecked(false);

			// 모든 tr에 대한 on 클래스 제거
			const allRows = document.querySelectorAll(".table-task tbody tr");
			allRows.forEach((row) => {
				row.classList.remove("on");
			});
		} else {
			// 전체 선택
			const newSelectedItems = new Set(workItems.map((item) => item.id));
			setSelectedItems(newSelectedItems);
			onSelectedItemsChange(newSelectedItems);
			setSelectAllChecked(true);

			// 모든 tr에 대한 on 클래스 추가
			const allRows = document.querySelectorAll(".table-task tbody tr");
			allRows.forEach((row) => {
				row.classList.add("on");
			});
		}

		// 부모 컴포넌트에 체크된 항목의 개수 전달
		onSelectedItemsCountChange(!selectAllChecked ? workItems.length : 0);
	};
	const link = {
		display: "flex",
		alignItems: "center"
	};

	const handleDetail = (workspaceType, workspaceId) => {
		if (workspaceType === "seo") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, seoData, true);
		} else if (workspaceType === "table") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, tableData, true);
		} else if (workspaceType === "img2html") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, html, true);
		} else if (workspaceType === "contrast") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, brightness, true);
		}
	};
	// 리스트 링크
	const navigate = useNavigate();
	const handleTableRowClick = (type, id) => {};

	const handleSort = (field) => {
		// 새 정렬 상태 설정
		setSortState((prevState) => {
			const isCurrentField = prevState.field === field;
			const newDirection = isCurrentField && prevState.direction === "asc" ? "desc" : "asc";

			dispatch(updateDashboardListField(field));
			dispatch(updateDashboardCardDirection(newDirection));
			return { field, direction: newDirection };
		});
		// 정렬된 아이템 설정
		setWorkItems((prevItems) => {
			const newDirection = sortState.field === field && sortState.direction === "asc" ? "desc" : "asc";
			const newItems = [...prevItems].sort((a, b) => compare(a, b, newDirection, field));
			return newItems;
		});
	};

	return (
		<>
			{workItems.length > 0 ? (
				<>
					<div className="table table-task">
						<table>
							<caption className="sr-only">최근 작업 목록</caption>
							<colgroup>
								<col style={{ width: "48px" }} />
								<col />
								<col style={{ width: "120px" }} />
								<col style={{ width: "120px" }} />
								<col style={{ width: "160px" }} />
								<col style={{ width: "160px" }} />
								<col style={{ width: "40px" }} />
							</colgroup>
							<thead>
								<tr>
									<th>
										<span className="check-item">
											<input type="checkbox" id="select-chkall" onChange={handleSelectAll} checked={selectAllChecked} />
											<label htmlFor="select-chkall">
												<span className="sr-only">전체 선택</span>
											</label>
										</span>
									</th>
									<th className="text-left">
										<button
											className={`btn-sort ${sortState.field === "workName" ? "active" : ""}`}
											onClick={() => handleSort("workName")}
										>
											작업명
											{sortState.field === "workName" && (
												<i
													className={`ico ${sortState.direction === "asc" ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}
												></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${sortState.field === "fileType" ? "active" : ""}`}
											onClick={() => handleSort("fileType")}
										>
											원본 파일 타입
											{sortState.field === "fileType" && (
												<i
													className={`ico ${sortState.direction === "asc" ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}
												></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${sortState.field === "fileSize" ? "active" : ""}`}
											onClick={() => handleSort("fileSize")}
										>
											원본 파일 크기
											{sortState.field === "fileSize" && (
												<i
													className={`ico ${sortState.direction === "asc" ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}
												></i>
											)}{" "}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${sortState.field === "lastModified" ? "active" : ""}`}
											onClick={() => handleSort("lastModified")}
										>
											마지막 수정 일시
											{sortState.field === "lastModified" && (
												<i
													className={`ico ${sortState.direction === "asc" ? "ico-arrow-up-sm" : "ico-arrow-down-sm"}`}
												></i>
											)}{" "}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${sortState.field === "createdDate" ? "active" : ""}`}
											onClick={() => handleSort("createdDate")}
										>
											생성일시
											{sortState.field === "createdDate" && (
												<i
													className={`ico ${sortState.direction === "asc" ? "ico-arrow-up-sm" : "ico-arrow-down-sm"}`}
												></i>
											)}{" "}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>작업</th>
								</tr>
							</thead>
							<tbody>
								{workItems.map((workitem, index) => (
									<tr key={index} onClick={() => handleTableRowClick(workitem.service.type, workitem.id)}>
										<td className="check-item-wrap" onClick={(e) => e.stopPropagation()}>
											<span className="check-item">
												<input
													type="checkbox"
													id={`select-chk${workitem.id}`}
													checked={selectedItems.has(workitem.id)}
													title={workitem.workName + " 선택"}
													onChange={(e) => {
														e.stopPropagation();
														handleSelectItem(workitem.id);
													}}
												/>
												<label htmlFor={`select-chk${workitem.id}`}>
													<span className="sr-only">선택</span>
												</label>
											</span>
										</td>
										<td>
											{/* 임시보류  */}

											<div
												style={link}
												onClick={() => handleDetail(workitem.service.type, workitem.id)}
												tabIndex="0"
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														handleDetail(workitem.service.type, workitem.id);
													}
												}}
											>
												<span className="service-type">
													<i className={`ico ico-${workitem.service.type}-md`}></i>
													<span className="sr-only">{workitem.service.name}</span>
												</span>
												<strong className="task-name">
													<p className="file-name">{workitem.workName}</p>

													{workitem.fileShared && (
														<span className="label-shared">
															<i className="ico ico-shared-md"></i>
															<span>공개된 작업</span>
														</span>
													)}
												</strong>
											</div>
										</td>
										<td>
											<span className="file-type">{workitem.fileType}</span>
										</td>
										<td>
											<span className="file-size">{fileSizeDisplay(workitem.fileSize)}</span>
										</td>
										<td>
											<span className="last-date">{workitem.lastModified}</span>
										</td>
										<td>
											<span className="date-time">{workitem.createdDate}</span>
										</td>
										<td className="more-action-wrap" onClick={(e) => e.stopPropagation()}>
											<span className={`more-action ${activeButtonId === workitem.id ? "on" : ""}`}>
												<button
													type="button"
													className="btn-action"
													onMouseDown={handleButtonMouseDown}
													onClick={(e) => toggleMorePopup(workitem.id, e)}
													ref={(el) => (moreButtonRef.current[workitem.id] = el)}
												>
													<i className="ico ico-more-action"></i>
													<span className="sr-only">더보기</span>
												</button>
											</span>

											{/* 더보기 레이어 팝업 */}
											<PopupLayer
												className="more-popup"
												isOpen={popupStates[workitem.id]}
												closePopup={() => closeMorePopup(workitem.id)}
												title="더보기 팝업"
												headerContent={null}
												footerContent={null}
												buttonRef={(el) => (moreButtonRef.current[workitem.id] = el)}
												position="top"
											>
												<div className="info-detail">
													<div className="btn-group">
														{/* [개발] 대시보드, 내 작업공간 페이지에서는 (작업명 변경, 이 작업 휴지통으로 이동) 버튼만 노출 / 
                                휴지통 페이지에서는 (복원, 이 작업 영구 삭제) 버튼만 노출 */}
														{work === "work" ? (
															<div>
																<button
																	type="button"
																	className="btn btn-md"
																	onClick={(e) => {
																		openDefaultPopup(workitem.id, workitem.workName, e);
																		setWorkName(workitem.workName);
																	}}
																>
																	작업명 변경
																</button>
																<button
																	type="button"
																	className="btn btn-md"
																	onClick={() =>
																		window.confirm({
																			title: "선택한 작업을 휴지통으로 이동하시겠어요?",
																			message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
																			content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
																			onConfirm: (result) => {
																				handleTrash(workitem.id);
																				closeMorePopup();
																			},
																			confirmButtonText: "휴지통에 버리기",
																			cancelButtonText: "취소"
																		})
																	}
																>
																	이 작업 휴지통으로 이동
																</button>
															</div>
														) : (
															<div>
																<button className="btn btn-md btn-restore">복원</button>
																<button
																	type="button"
																	className="btn btn-md btn-permanent-del"
																	onClick={() =>
																		window.confirm({
																			title: "이 작업을 영구 삭제할까요?",
																			message: "삭제 후 복구할 수 없습니다.",

																			confirmButtonText: "영구 삭제",
																			cancelButtonText: "취소",
																			buttonStatus: "invalid"
																		})
																	}
																>
																	이 작업 영구 삭제
																</button>
															</div>
														)}
													</div>
												</div>
											</PopupLayer>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<div className="default-nodata">
					<div className="nodata-warp">
						<img src={nodataImg} alt="" />
						<p>
							작업 공간이 준비되었습니다!<br></br>
							작업을 생성해주세요.
						</p>
					</div>
				</div>
			)}

			<PopupLayout
				isOpen={isDefaultPopup}
				closePopup={closeDefaultPopup}
				title="작업명 변경"
				size="default"
				className="change-work-name-popup"
				footerContent={
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeDefaultPopup}>
								취소
							</button>
							<button type="button" className="btn btn-xl btn-primary" onClick={handleChangeWorkName}>
								변경
							</button>
						</div>
					</>
				}
			>
				<InputTaskNameWork
					workName={userWorkName}
					onworkNameChange={handleWorkNameChange}
					closeMorePopup={closeMorePopup}
				></InputTaskNameWork>
			</PopupLayout>
		</>
	);
}
