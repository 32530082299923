import createMetaTag from "@/services/util/seoMeta";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SeoMetaSave } from "./SeoMetaSave";
import { setResetSeoData, setSeoData, updateSeoMetaTag, updateSeoSaveTime } from "@/services/store/action/seoAction";
import { SeoSave } from "./SeoSave";

const useSeoMetaPopup = (html,initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const [triggerElement, setTriggerElement] = useState(null);
	const seoData = useSelector((state) => state.seo.seoData);
	const data = useSelector((state) => state.seo.seoData.data||[]);
	
	const dispatch = useDispatch();
	
	var keyword = "";
	const skIds = [];
	if (data){
		data?.choiceData?.keywords.forEach((key, index, array) => {
			skIds.push(key.skid);
			// 배열의 마지막 요소인 경우의 처리
			if (index === array.length - 1) {
				// 여기서 원하는 작업을 수행하세요.
				// 예: 마지막 요소임을 확인하거나, 마지막에 특별한 처리를 하고 싶은 경우
				keyword += `${key.keyword}`;
			} else {
				keyword += `${key.keyword}, `;
			}
		});
		
	}
	
	
	const open = (e) => {
		if (e && e.currentTarget) {
			setTriggerElement(e.currentTarget);
		}
		setIsOpen(true);
	};
	const callSave = async () => {
		if (!data.choiceData.sumnail.ssId) {
			var ssId = data.choiceData.sumnail.ssId;
		} else {
			ssId = null;
		}
		const req_data = {
			siId: data.resData.siId,
			skIds: skIds,
			sotId: data.choiceData.title.sotId,
			ssId: ssId
		};
		await SeoSave(req_data);
	};
	
	
	const handleMetaSave = async()=>{
		
		const datas = {
			siId: data.resData.siId,

			html: html
		};
		dispatch(updateSeoMetaTag(html))
		await SeoMetaSave(datas);
	}

	const close = async () => {
		handleMetaSave()
		callSave();
		dispatch(updateSeoSaveTime())	
			
		setIsOpen(false);
	};
	useEffect(() => {

		window.addEventListener("beforeunload", handleMetaSave);

		return () => {
			window.removeEventListener("beforeunload", handleMetaSave);
		};
	}, [html]);
	useEffect(() => {
		if (!isOpen && triggerElement) {
			triggerElement.focus();
			setTriggerElement(null);
		}
	}, [isOpen, triggerElement]);

	return { isOpen, open, close };
};

export default useSeoMetaPopup;
