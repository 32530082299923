import { getTimeMin } from "@/services/util/DateTimeModule";
import { AUTO_SAVE_HTML, CREATE_HTML_FAILURE, CREATE_HTML_SUCCESS, RESET_SAVE_TIME, UPDATE_IS_ADDRESS, UPDATE_IS_SHARE } from "../action/Img2HtmlAction";
import { RESET_STATE } from "../action/ResetAction";

const initialState = {
	htmlData: null,
	error: null,
	saveTime: null
};

export function htmlReducer(state = initialState, action) {
	const time = getTimeMin();

	switch (action.type) {
		case CREATE_HTML_SUCCESS:
			return {
				...state,
				htmlData: action.payload,
				error: null
			};
			break;
		case AUTO_SAVE_HTML:
			return {
				...state,
				saveTime: time
			};
			break;
			case RESET_SAVE_TIME:
				return {
					...state,
					saveTime: null
				};
				break;

			case UPDATE_IS_SHARE:
				return {
					...state,
					htmlData: {
						...state.htmlData,
						response: {
							...state.htmlData.response,
							data: {
								...state.htmlData.response.data,
								// isShare: action.payload
								data: {
									...state.htmlData.response.data.data,
									isShare: action.payload
								}
							}
						}
					}

				}
				break;

				case UPDATE_IS_ADDRESS:
					return {
						...state,
						htmlData: {
							...state.htmlData,
							response: {
								...state.htmlData.response,
								data: {
									...state.htmlData.response.data,
									data: {
										...state.htmlData.response.data.data,
										address: action.payload	
									}
								}
							}
						}
					}
break;
		case CREATE_HTML_FAILURE:
			return {
				...state,
				error: action.payload
			};
break;
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
}

// export function shareReducer(state = initialStateShare, action) {
// 	switch (action.type) {
// 		case SHARE_HTML:
// 			return {
// 				...state,
// 				data: action.payload
// 			};
// 		case SHARE_RESET_STATE:
// 			return initialStateShare;
// 			break;
// 		default:
// 			return state;
// 	}
// }
