import React, { useEffect, useState, useCallback, useRef } from "react";

export const EditorToolbar = ({ editor }) => {
	const [showEditor, setShowEditor] = useState(false);
	const [url, setUrl] = useState("");
	const [openInNewTab, setOpenInNewTab] = useState(false);
	const editorRef = useRef(null);

	const linkButtonRef = useRef(null); // 버튼 레퍼런스
	const [editorPosition, setEditorPosition] = useState({ top: 0, left: 0 });

	const toggleLinkEditor = useCallback(() => {
		const previousUrl = editor.getAttributes("link").href || "";
		const previousTarget = editor.getAttributes("link").target === "_blank";
		setUrl(previousUrl);
		setOpenInNewTab(previousTarget);
		setShowEditor(!showEditor);

		if (linkButtonRef.current) {
			const rect = linkButtonRef.current.getBoundingClientRect();
			const top = rect.bottom + window.scrollY;
			const right = window.innerWidth - rect.right - window.scrollX;
			setEditorPosition({ top, right });
		}
	}, [editor, showEditor]);

	const handleSetLink = useCallback(() => {
		if (url === "") {
			editor.chain().focus().extendMarkRange("link").unsetLink().run();
		} else {
			editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.setLink({ href: url, target: openInNewTab ? "_blank" : null })
				.run();
		}
		setShowEditor(false);
	}, [editor, url, openInNewTab]);

	const onChangeUrl = useCallback((event) => {
		setUrl(event.target.value);
	}, []);

	const onToggleOpenInNewTab = useCallback(() => {
		setOpenInNewTab(!openInNewTab);
	}, [openInNewTab]);

	const clearLink = useCallback(() => {
		editor.chain().focus().extendMarkRange("link").unsetLink().run();
		setShowEditor(false); // 링크를 제거한 후 인라인 에디터를 닫음
	}, [editor]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (editorRef.current && !editorRef.current.contains(event.target)) {
				setShowEditor(false); // 에디터 패널을 닫음
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="editor-toolbar">
			<button
				type="button"
				onClick={() => editor.chain().focus().undo().run()}
				disabled={!editor.can().chain().focus().undo().run()}
			>
				<i className="ico ico-undo"></i>
				<span className="sr-only">실행취소</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().redo().run()}
				disabled={!editor.can().chain().focus().redo().run()}
			>
				<i className="ico ico-redo"></i>
				<span className="sr-only">재실행</span>
			</button>
			<span className="tool-line"></span>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
			>
				<span className="btn-h1">제목1</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
			>
				<span className="btn-h2">제목2</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
				className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
			>
				<span className="btn-h3">제목3</span>
			</button>
			<span className="tool-line"></span>
			<button
				onClick={() => editor.chain().focus().setParagraph().run()}
				className={editor.isActive("paragraph") ? "is-active" : ""}
			>
				<i className="ico ico-paragraph-sm"></i>
				<span className="sr-only">본문</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleBold().run()}
				disabled={!editor.can().chain().focus().toggleBold().run()}
				className={editor.isActive("bold") ? "is-active" : ""}
			>
				<i className="ico ico-bold"></i>
				<span className="sr-only">굵게</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleItalic().run()}
				disabled={!editor.can().chain().focus().toggleItalic().run()}
				className={editor.isActive("italic") ? "is-active" : ""}
			>
				<i className="ico ico-italic"></i>
				<span className="sr-only">기울임꼴</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleUnderline().run()}
				className={editor.isActive("underline") ? "is-active" : ""}
			>
				<i className="ico ico-underline"></i>
				<span className="sr-only">밑줄</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleStrike().run()}
				disabled={!editor.can().chain().focus().toggleStrike().run()}
				className={editor.isActive("strike") ? "is-active" : ""}
			>
				<i className="ico ico-strike"></i>
				<span className="sr-only">취소선</span>
			</button>
			<span className="tool-line"></span>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				className={editor.isActive("bulletList") ? "is-active" : ""}
			>
				<i className="ico ico-bullet-list"></i>
				<span className="sr-only">글머리 기호 목록</span>
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				className={editor.isActive("orderedList") ? "is-active" : ""}
			>
				<i className="ico ico-ordered-list"></i>
				<span className="sr-only">번호 매기기 목록</span>
			</button>
			<span className="tool-line"></span>
			<button
				type="button"
				onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
			>
				<i className="ico ico-table"></i>
				<span className="sr-only">표삽입</span>
			</button>
			<span className="tool-line"></span>
			<button
				type="button"
				ref={linkButtonRef}
				onClick={toggleLinkEditor}
				className={`${editor.isActive("link") ? "is-active" : ""} ${showEditor ? "on" : ""}`}
			>
				<i className="ico ico-link"></i>
				<span className="sr-only">링크</span>
			</button>

			{showEditor && (
				<div
					ref={editorRef}
					style={{
						top: `${editorPosition.top}px`,
						right: `${editorPosition.right}px`
					}}
					className="link-detail-panel"
				>
					<div className="input-group">
						<input
							type="url"
							value={url}
							onChange={onChangeUrl}
							className="form-control-sm"
							placeholder="URL을 입력해주세요."
						/>
						<button type="button" className="btn btn-sm btn-primary" onClick={handleSetLink}>
							저장
						</button>
					</div>

					<div className="detail-group">
						<span className="check-item-sm">
							<input type="checkbox" id="select-chk1" checked={openInNewTab} onChange={onToggleOpenInNewTab} />
							<label htmlFor="select-chk1">새창</label>
						</span>
						<button type="button" className="btn btn-xs btn-outline-gray" onClick={clearLink} title="Remove link">
							삭제
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
