import api from "@/services/util/Interceptor";
import { MY_DATA_COUNT } from "../url/URL";
import { navigateTo } from "@/services/util/Navigate";

export const DataCount = async () => {
	try {
		const response = await api.get(MY_DATA_COUNT);
		return response.data;
	} catch (error) {
		navigateTo("/error");
	}
};
