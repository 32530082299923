import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import { fetchGoogleSheetData } from "@/services/util/GoogleSheet"; // 유틸리티 함수 임포트
import Pagination from "@/components/navigation/Pagination";
import nodataImg from "@/assets/images/img-nodata@2x.png";
import { filterOptions } from "@/data/communityFilter";
import PopupLayout from "@/components/popup/PopupLayout";
import { useSelector } from "react-redux";
import TallyIframe from "@/components/plugins/TallyIframe";
import usePopup from "@/hooks/usePopup";

export default function ResourcePage() {
	const [items, setItems] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
	const [filters, setFilters] = useState({
		difficulty: "",
		category: "",
		type: "",
		lang: ""
	});
	const [currentPage, setCurrentPage] = useState(1); // 기본 페이지는 1로 설정
	const [loading, setLoading] = useState(true);
	const itemsPerPage = 10; // 페이지당 항목 수
	const [isAscending, setIsAscending] = useState(false); // 최신순: false, 오래된순: true

	const infoSharePopup = usePopup();
	const location = useLocation();
	const navigate = useNavigate();

	const getUserData = useSelector((state) => state.user?.userData);
	const userData = {
		userEmailId: getUserData?.data?.email,
		userName: getUserData?.data?.name,
		userTel: getUserData?.data?.phone
	};

	const ExternalLinkIcon = () => (
		<svg
			className="ico-right"
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.5 2.5H3.5C2.94772 2.5 2.5 2.94772 2.5 3.5V13.5C2.5 14.0523 2.94772 14.5 3.5 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V8.5"
				stroke="#9CA3AF"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.0607 2.76777L8.5 8.5M11.5858 2.41421H14.4142V5.24264"
				stroke="#9CA3AF"
				strokeWidth="1.3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	// 페이지 로드 시 데이터 로드
	useEffect(() => {
		// Fetch Google Sheets data
		const fetchData = async () => {
			setLoading(true); // 로딩 상태 시작
			const data = await fetchGoogleSheetData("리소스");
			const itemsData = data
				.filter((row) => row[7] === "Y") // 게시여부가 "Y"인 항목만 필터링
				.map((row) => ({
					difficulty: row[0], // 난이도
					category: row[1], // 카테고리
					type: row[2], // 유형
					lang: row[3], // 제공언어
					subject: row[4], // 서비스명
					url: row[5], // URL
					content: row[6], // 특징
					date: row[8] // 등록일시
				}));
			setItems(itemsData);
			setLoading(false); // 로딩 상태 종료
		};
		fetchData();
	}, []);

	// 페이지 로드 시 URL 파라미터에서 필터 값 읽어오기
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const difficulty = searchParams.get("difficulty") || "";
		const category = searchParams.get("category") || "";
		const type = searchParams.get("type") || "";
		const lang = searchParams.get("lang") || "";
		const search = searchParams.get("search") || "";

		setFilters({
			difficulty,
			category,
			type,
			lang
		});

		setSearchTerm(search);
		setDebouncedSearchTerm(search);
	}, [location.search]);

	// 디바운스 함수 설정
	const debouncedSearch = useCallback(
		debounce((value) => {
			setDebouncedSearchTerm(value);
			setCurrentPage(1);
			updateURLParams(filters, value); // 검색어와 페이지를 URL에 반영
		}, 300), // 300ms 디바운스
		[filters]
	);

	// 정렬 순서를 변경하는 함수
	const handleSortToggle = () => {
		setIsAscending((prevState) => !prevState); // 현재 정렬 순서를 토글
	};

	const updateURLParams = (newFilters, newSearchTerm, newPage = 1) => {
		const params = new URLSearchParams();

		if (newSearchTerm) params.set("search", newSearchTerm);
		if (newFilters.difficulty) params.set("difficulty", newFilters.difficulty);
		if (newFilters.category) params.set("category", newFilters.category);
		if (newFilters.type) params.set("type", newFilters.type);
		if (newFilters.lang) params.set("lang", newFilters.lang);

		// 페이지 번호가 1이 아닐 때만 page 파라미터를 추가
		if (newPage !== 1) {
			params.set("page", newPage);
		}

		navigate(`?${params.toString()}`);
	};

	const handleSearchTermChange = (e) => {
		const value = e.target.value;
		setSearchTerm(value);
		debouncedSearch(value);
	};

	const handleFilterChange = (e, key) => {
		const value = e.target.value;
		const newFilters = {
			...filters,
			[key]: value === "전체" ? "" : value
		};
		setFilters(newFilters);
		setCurrentPage(1);
		updateURLParams(newFilters, debouncedSearchTerm);
	};

	const handleReset = () => {
		setSearchTerm("");
		setDebouncedSearchTerm("");
		const newFilters = {
			difficulty: "",
			category: "",
			type: "",
			lang: ""
		};
		setFilters(newFilters);
		setCurrentPage(1);
		updateURLParams(newFilters, "");
	};

	// 피드백 파라미터에 따른 모달 오픈 및 포커스 처리
	const infoShareButtonRef = useRef(null);
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const sendParam = searchParams.get("send");

		if (sendParam === "open") {
			infoSharePopup.open();
		}
		if (sendParam === "focus") {
			infoShareButtonRef.current?.focus();
			infoShareButtonRef.current?.classList.add("blink");
			setTimeout(() => {
				infoShareButtonRef.current?.classList.remove("blink");
			}, 5000); // 5초 후에 클래스 제거
		}
	}, [location.search, infoSharePopup]);

	// 필터링된 항목
	const filteredItems = items.filter((item) => {
		const matchSearchTerm = item.subject.includes(debouncedSearchTerm) || item.content.includes(debouncedSearchTerm);
		const matchFilters = Object.keys(filters).every((key) => (filters[key] ? item[key] === filters[key] : true));
		return matchSearchTerm && matchFilters;
	});

	// 필터링된 항목을 정렬하는 부분
	const sortedItems = filteredItems.sort((a, b) => {
		const dateA = new Date(a.date);
		const dateB = new Date(b.date);
		return isAscending ? dateA - dateB : dateB - dateA; // 정렬 순서에 따른 비교
	});

	// 페이징된 항목
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

	// 총 페이지 수
	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

	const handleClick = (pageNumber) => {
		setCurrentPage(pageNumber);
		updateURLParams(filters, debouncedSearchTerm, pageNumber);
	};

	const getCategoryOption = (value) => {
		for (const category of filterOptions[0].options) {
			if (category.value === value) {
				return category;
			}
		}
		return null;
	};

	const handleOpenInfoSharePopup = () => {
		navigate("?send=open");
		infoSharePopup.open();
	};

	const handleCloseInfoSharePopup = () => {
		navigate(location.pathname);
		infoSharePopup.close();
	};

	// 오늘 날짜와 게시글 등록일을 비교
	const isNew = (dateString) => {
		const today = new Date();
		const postDate = new Date(dateString);
		const differenceInTime = today.getTime() - postDate.getTime();
		const differenceInDays = differenceInTime / (1000 * 3600 * 24); // 밀리초를 일(day) 단위로 변환
		return differenceInDays <= 30; // 30일 이내인지 확인
	};

	return (
		<>
			<div className="container resource-page">
				<div className="page-header">
					<div className="title-area">
						<h2 className="page-title">웹 접근성 리소스</h2>
					</div>
				</div>
				<div className="page-body">
					<div className="top-info">
						<div className="message">
							<p className="text-medium">
								{/* <span className="label">EVENT</span> */}
								<br className="d-down-sm" /> 유용한 접근성 정보 함께 채워요!
							</p>
							<p className="text-sm text-gray">
								다른 사람들에게 정보 공유하고
								<br className="d-down-sm" /> 앨리지수 높여요 🌱🚿
							</p>
						</div>
						<button
							className="btn btn-lg btn-primary"
							onClick={handleOpenInfoSharePopup}
							ref={infoShareButtonRef} // ref 속성 추가
						>
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 2.5V14.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
								<path d="M14 8.5L2 8.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
							</svg>
							정보 공유하기
						</button>
					</div>
					<div className="list-container">
						<form className="col col-filter">
							<div className="filter-header">
								<h3 className="title">필터</h3>
								<button
									type="reset"
									className="btn btn-outline-none btn-sm"
									onClick={handleReset}
									disabled={!searchTerm && !Object.values(filters).some((filter) => filter)}
								>
									<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.38672 8.00052C3.38672 5.16753 5.68121 2.87305 8.51419 2.87305C11.3472 2.87305 13.6417 5.16753 13.6417 8.00052C13.6417 10.8335 11.3472 13.128 8.51419 13.128C7.22647 13.128 6.05582 12.648 5.15441 11.8754"
											stroke="#4B5563"
											strokeWidth="1.3"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M1.83887 6.14844L3.38052 8.01946L5.25154 6.47781"
											stroke="#4B5563"
											strokeWidth="1.3"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									초기화
								</button>
							</div>
							<div className="filter-body">
								{filterOptions.map((filter, idx) => (
									<dl key={idx} className={`${filter.key} ${filter.mobileUse === false ? "d-up-md" : ""}`}>
										<dt>{filter.title}</dt>
										<dd>
											<span className="check-item-btn">
												<input
													type="radio"
													name={filter.key}
													id={`${filter.key}-전체`}
													value="전체"
													checked={filters[filter.key] === ""}
													onChange={(e) => handleFilterChange(e, filter.key)}
												/>
												<label htmlFor={`${filter.key}-전체`}>전체</label>
											</span>
											{filter.options.map((option, index) => (
												<span key={index} className="check-item-btn">
													<input
														type="radio"
														name={filter.key}
														id={`${filter.key}-${index}`}
														value={option.value}
														checked={filters[filter.key] === option.value}
														onChange={(e) => handleFilterChange(e, filter.key)}
													/>
													<label htmlFor={`${filter.key}-${index}`}>{option.value}</label>
												</span>
											))}
										</dd>
									</dl>
								))}
							</div>
						</form>
						<div className="col col-list">
							<div className="list-control">
								<div className="right">
									<input
										type="text"
										className="form-control-md"
										title="검색어를 입력해주세요."
										aria-label="입력시 검색결과 실시간 노출"
										placeholder="검색어를 입력해주세요."
										value={searchTerm} // 검색어 상태를 value에 연결
										onChange={handleSearchTermChange}
									/>
								</div>
								<div className="right">
									<button onClick={handleSortToggle}>
										{isAscending ? (
											<>
												<span>오래된순</span> {/* 현재 상태 표시 */}
												<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M4.30103 1.84403C4.14167 1.6619 3.85833 1.6619 3.69897 1.84404L1.08048 4.8366C0.854173 5.09523 1.03784 5.5 1.38151 5.5L6.61849 5.5C6.96216 5.5 7.14583 5.09523 6.91952 4.8366L4.30103 1.84403Z"
														fill="#1F2937"
													/>
												</svg>
											</>
										) : (
											<>
												<span>최신순</span> {/* 현재 상태 표시 */}
												<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M4.30103 6.15597C4.14167 6.3381 3.85833 6.3381 3.69897 6.15596L1.08048 3.1634C0.854173 2.90477 1.03784 2.5 1.38151 2.5L6.61849 2.5C6.96216 2.5 7.14583 2.90477 6.91952 3.1634L4.30103 6.15597Z"
														fill="#1F2937"
													/>
												</svg>
											</>
										)}
									</button>
								</div>
							</div>
							{loading ? (
								<div className="default-nodata">
									<div className="loading-area">
										<LoadingSpinner message="데이터를 불러오고 있어요" />
									</div>
								</div>
							) : currentItems.length === 0 ? (
								<div className="default-nodata">
									<div className="nodata-warp">
										<img src={nodataImg} alt="No data" />
										<p>조회된 데이터가 없어요.</p>
									</div>
								</div>
							) : (
								<>
									<div className="data-list">
										{currentItems.map((item, index) => {
											const categoryOption = getCategoryOption(item.category);
											const showNewBadge = isNew(item.date); // 등록일이 30일 이내인지 확인
											return (
												<div className="data-item" key={index}>
													<Link to={item.url} target="_blank" title="새창" className="link">
														<dl>
															<dt>
																{/* 이모지 노출 영역 */}
																<div
																	className="icon"
																	style={categoryOption ? { backgroundColor: categoryOption.bgcolor } : {}}
																>
																	{categoryOption && categoryOption.emoji}
																	{showNewBadge && <span className="badge">NEW</span>}
																</div>
																<div className="subject">
																	<span className="text">{item.subject}</span>
																	<ExternalLinkIcon />
																</div>
															</dt>
															<dd>
																<div className="content">{item.content}</div>
															</dd>
														</dl>
													</Link>
													<div className="info">
														{categoryOption ? (
															<span
																className="category"
																style={{ color: categoryOption.color, backgroundColor: categoryOption.bgcolor }}
															>
																{item.category}
															</span>
														) : (
															<span className="category">{item.category}</span>
														)}
														<span className="difficulty">
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path d="M12 6L5 3V9L12 6Z" fill="#6B7280" />
																<path
																	d="M5 14V9M5 9V3L12 6L5 9Z"
																	stroke="#6B7280"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															{item.difficulty}
														</span>
														<span className="type">{item.type}</span>
														<span className="lang">{item.lang}</span>
													</div>
												</div>
											);
										})}
									</div>
									<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handleClick} />
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* 정보 공유하기 레이어 팝업 */}
			<PopupLayout
				className="info-share-popup"
				isOpen={infoSharePopup.isOpen}
				closePopup={handleCloseInfoSharePopup}
				headerContent={null}
				footerContent={null}
				size="sm"
				triggerButtonRef={infoSharePopup.triggerRef}
			>
				<TallyIframe className="info-share" dataTallyOpen="3E0pxq" frameTitle="정보 공유하기" userData={userData} />
			</PopupLayout>
		</>
	);
}
