import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { WORKSPACE_CHANGE_NAME } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const WorkSpaceChangeName = async (workId, newName) => {
	try {
		const payload = {
			workspaceId: workId,
			newName: newName
		};
		const response = await api.post(WORKSPACE_CHANGE_NAME, payload);
		return "Success";
	} catch (error) {
		console.error("작업명 변경오류", error)
		return "Fail";
	}
};
