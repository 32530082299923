import React, { useEffect, useState } from "react";
import { EditorContent, useEditor, BubbleMenu } from "@tiptap/react";

import TurndownService from "turndown"; // 마크다운 플러그인

import Extensions from "@/plugins/tiptap-editor/Extensions"; // tool 확장 플러그인 목록
import Props from "@/plugins/tiptap-editor/extensions/props";
import { EditorToolbar } from "@/plugins/tiptap-editor/extensions/EditorToolbar"; // toolbar
import BubbleMenuButtons from "@/plugins/tiptap-editor/extensions/BubbleMenuButtons"; // BubbleMenuButtons
import DragAndDropExtension from "@/plugins/tiptap-editor/extensions/DragAndDropExtension"; // BubbleMenuButtons
// import DragHandleMenu from "@/plugins/tiptap-editor/blockEditor/DragHandleMenu"; // DragHandleMenu

// 포메터 라이브러리 추가
const beautify = require("js-beautify").html;
// html 추출 코드 가공

const cleanHtmlContent = (htmlString) => {
	// DOM 파싱
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, "text/html");

	// caption 처리를 가장 먼저
	doc.querySelectorAll("table").forEach((table) => {
		const dataCaptionAttr = table.getAttribute("data-caption");
		if (dataCaptionAttr) {
			const caption = document.createElement("caption");
			caption.textContent = dataCaptionAttr;
			// caption을 table의 첫 번째 자식으로 삽입
			table.insertBefore(caption, table.firstChild);
			table.removeAttribute("data-caption");
		}
	});

	// draggable-item div 처리
	doc.querySelectorAll('div[data-type="draggable-item"]').forEach((div) => {
		while (div.firstChild) {
			div.parentNode.insertBefore(div.firstChild, div);
		}
		div.remove();
	});

	// table caption 처리 - class/style 속성이 제거되기 전에 처리
	doc.querySelectorAll("table").forEach((table) => {
		// caption이 첫 번째 tr에 있는 경우 처리
		const firstRow = table.querySelector("tr:first-child");
		if (firstRow) {
			const firstCell = firstRow.querySelector("td, th");
			if (firstCell && firstCell.textContent.includes("caption")) {
				const captionElement = doc.createElement("caption");
				captionElement.textContent = firstCell.textContent;
				table.insertBefore(captionElement, table.firstChild);
				firstRow.remove();
			}
		}

		// caption 속성이 있는 경우 처리
		const caption = table.getAttribute("caption");
		if (caption) {
			const captionElement = doc.createElement("caption");
			captionElement.textContent = caption;
			table.insertBefore(captionElement, table.firstChild);
			table.removeAttribute("caption");
		}
	});

	// 속성 제거
	doc.querySelectorAll("[class], [data-tight], [style]").forEach((el) => {
		el.removeAttribute("class");
		el.removeAttribute("data-tight");
		el.removeAttribute("style");
	});

	// ul>li 및 ol>li 내부의 <p> 태그 제거
	doc.querySelectorAll("li > p").forEach((p) => {
		while (p.firstChild) {
			p.parentNode.insertBefore(p.firstChild, p);
		}
		p.remove();
	});

	// td 및 th 내부의 <p> 태그 제거
	doc.querySelectorAll("td > p, th > p").forEach((p) => {
		while (p.firstChild) {
			p.parentNode.insertBefore(p.firstChild, p);
		}
		p.remove();
	});

	// HTML 변환 및 포매팅
	let cleanedHtml = doc.body.innerHTML;
	const options = {
		indent_size: 2,
		space_in_empty_paren: true
	};
	cleanedHtml = beautify(cleanedHtml, options);

	return cleanedHtml;
};

const Img2htmlEditor = ({ imageData, onHtmlChange, onMarkdownChange }) => {
	const [, setHtmlString] = useState("");
	const [, setMarkdown] = useState("");

	// const [menuOpen, setMenuOpen] = useState(false);
	// const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

	// const toggleMenu = (position) => {
	// 	// setMenuOpen(!menuOpen);
	// 	if (menuOpen && menuPosition.x === position.x && menuPosition.y === position.y) {
	// 		setMenuOpen(false);
	// 	} else {
	// 		// 메뉴 위치를 업데이트하고 메뉴를 엽니다.
	// 		setMenuPosition({ x: position.x, y: position.y });
	// 		setMenuOpen(true);
	// 	}
	// };

	const editor = useEditor({
		extensions: [
			...Extensions,
			DragAndDropExtension({
				dragHandleWidth: 20.5
				// onDragHandleClick: (position) => toggleMenu(position)
			})
		],
		editorProps: Props,

		onTransaction: ({ editor }) => {
			const generatedHtmlString = editor.getHTML();
			const cleanedHtmlString = cleanHtmlContent(generatedHtmlString);
			setHtmlString(cleanedHtmlString);
			onHtmlChange(cleanedHtmlString);

			var { gfm } = require("@guyplusplus/turndown-plugin-gfm");
			var { confluenceGfm } = require("turndown-plugin-confluence-to-gfm");

			const turndownService = new TurndownService({
				headingStyle: "atx" // 'atx' 또는 'setext'로 설정
			});

			gfm(turndownService);
			confluenceGfm(turndownService);

			const markdownOutput = turndownService.turndown(generatedHtmlString);
			setMarkdown(markdownOutput);
			onMarkdownChange(markdownOutput);
		}
	});

	useEffect(() => {
		if (editor && imageData.html) {
			requestAnimationFrame(() => {
				const parser = new DOMParser();
				const doc = parser.parseFromString(imageData.html, "text/html");

				// caption 정보를 data 속성으로 저장
				doc.querySelectorAll("table caption").forEach((caption) => {
					const table = caption.parentElement;
					table.setAttribute("data-original-caption", caption.textContent);
				});

				editor.commands.setContent(doc.body.innerHTML);
			});
		}
	}, [editor, imageData.html]);

	if (!editor) {
		return null;
	}

	return (
		<>
			<div className="editor-wrap">
				<EditorToolbar editor={editor} />
				<EditorContent editor={editor} className="edit-area" />

				{editor && (
					<BubbleMenu
						className="bubble-menu"
						tippyOptions={{
							duration: 100,
							zIndex: 100
						}}
						editor={editor}
					>
						<BubbleMenuButtons editor={editor} />
					</BubbleMenu>
				)}

				{/* {menuOpen && <DragHandleMenu editor={editor} position={menuPosition} />} */}
			</div>
		</>
	);
};

export default Img2htmlEditor;
