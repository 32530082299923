// import { Extension } from "@tiptap/core";

// const Keymap = Extension.create({
// 	name: "Keymap",

// 	addCommands() {
// 		return {
// 			handleEnterKey:
// 				() =>
// 				({ state, chain }) => {
// 					const { selection, doc } = state;
// 					const { $from, $to } = selection;

// 					if ($from.node(-1).type.name === "rootblock" && $from.parent.type.isBlock && $to.pos === $from.pos) {
// 						if ($to.pos === $from.end()) {
// 							return chain()
// 								.insertContentAt($from.pos, {
// 									type: "rootblock",
// 									content: [
// 										{
// 											type: "paragraph"
// 										}
// 									]
// 								})
// 								.focus($from.pos + 4)
// 								.run();
// 						} else {
// 							let currentActiveNodeTo = -1;
// 							doc.descendants((node, pos) => {
// 								if (currentActiveNodeTo !== -1) return false;
// 								if (node.type.name === "rootblock") return;

// 								const [nodeFrom, nodeTo] = [pos, pos + node.nodeSize];
// 								if (nodeFrom <= $from.pos && $to.pos <= nodeTo) currentActiveNodeTo = nodeTo;

// 								return false;
// 							});

// 							const content = doc.slice($from.pos, currentActiveNodeTo)?.toJSON().content;

// 							return chain()
// 								.insertContentAt(
// 									{ from: $from.pos, to: currentActiveNodeTo },
// 									{
// 										type: "rootblock",
// 										content
// 									}
// 								)
// 								.focus($from.pos + 4)
// 								.run();
// 						}
// 					}

// 					return false;
// 				}

// 			// handleBackspaceKey:
// 			// 	() =>
// 			// 	({ state, dispatch }) => {
// 			// 		const { selection } = state;
// 			// 		const { $from, empty } = selection;

// 			// 		// 리스트 아이템이고, 커서 위치가 아이템의 시작 부분에 있으며, 첫 번째 아이템일 때의 조건 검사
// 			// 		if (
// 			// 			!empty ||
// 			// 			($from.parent.type.name === "list_item" && $from.parentOffset === 0 && $from.index($from.depth - 1) === 0)
// 			// 		) {
// 			// 			// 리스트의 첫 번째 아이템에서 백스페이스를 사용할 경우
// 			// 			// 아무런 동작도 수행하지 않고 true를 반환하여 기본 동작을 방지합니다.
// 			// 			return true;
// 			// 		}

// 			// 		// 다른 경우에는 기본 동작을 방지하지 않습니다.
// 			// 		return false;
// 			// 	}
// 		};
// 	},

// 	addKeyboardShortcuts() {
// 		return {
// 			Enter: ({ editor }) => editor.commands.handleEnterKey()
// 			// Backspace: ({ editor }) => editor.commands.handleBackspaceKey()
// 		};
// 	}
// });

// export default Keymap;

// fixme
import { Extension } from "@tiptap/core";

const Keymap = Extension.create({
	name: "Keymap",

	addCommands() {
		return {
			selectTextWithinNodeBoundaries:
				() =>
				({ editor, commands }) => {
					const { state } = editor;
					const { tr } = state;
					const startNodePos = tr.selection.$from.start();
					const endNodePos = tr.selection.$to.end();
					return commands.setTextSelection({
						from: startNodePos,
						to: endNodePos
					});
				}
		};
	},

	addKeyboardShortcuts() {
		return {
			"Mod-a": ({ editor }) => {
				const { state } = editor;
				const { tr } = state;
				const startSelectionPos = tr.selection.from;
				const endSelectionPos = tr.selection.to;
				const startNodePos = tr.selection.$from.start();
				const endNodePos = tr.selection.$to.end();
				const isCurrentTextSelectionNotExtendedToNodeBoundaries =
					startSelectionPos > startNodePos || endSelectionPos < endNodePos;
				if (isCurrentTextSelectionNotExtendedToNodeBoundaries) {
					editor.chain().selectTextWithinNodeBoundaries().run();
					return true;
				}
				return false;
			}
		};
	}
});

export default Keymap;
