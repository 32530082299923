// export const API_URL2 = "http://localhost:8000/";
//export const API_URL2 = "https://xrai-dev.store/";
export const API_URL = "https://a11y.co.kr/";
export const API_URL2 = "/";
// export const API_URL2 = "https://xrai-dev.store/";
// http://xrai-dev.store:8000/

///번호변경
export const CHANGE_PHONE = `${API_URL2}user/mypage/change/phone`;

///이름변경
export const CHANGE_NAME = `${API_URL2}user/mypage/change/name`;

///프로필 업로드
export const UPLOAD_PROFILEIMG = `${API_URL2}user/mypage/profileimg/upload`;

///프로필 이미지 기본이미지 처리
export const UPLOAD_DEFAULTIMG = `${API_URL2}user/mypage/profileimg/default`;
///내정보 호출
export const USER_GET_MYPAGE_ME = `${API_URL2}user/mypage/me`;

///로그인 호출
export const USER_POST_LOGIN = `${API_URL2}user/auth/login`;

///회원가입 호출
export const USER_POST_EMAIL_SIGNIN = `${API_URL2}user/auth/signin`;

//구글회원가입 호출
export const USER_POST_GOOGLE_SIGNIN = `${API_URL2}user/google/signup`;

//네이버회원가입 호출
export const USER_POST_NAVER_SIGNIN = `${API_URL2}user/naver/signup`;

///설정에서 비밀번호 변경
export const MY_CHANGE_PASSWORD = `${API_URL2}user/mypage/change/password`;

///비밀번호 확인
export const CHECK_PASSWORD = `${API_URL2}user/mypage/check/password`;

///비밀번호 변경
export const CHANGE_PASSWORD = `${API_URL2}user/auth/change/password`;

///이메일 중복확인
export const EMAIL_FIND = `${API_URL2}user/email/find`;

///이메일 인증코드 전송
export const EMAIL_SEND_CODE = `${API_URL2}user/email/sendauthcode`;

///이메일 인증코드 확인
export const EMAIL_CODE_VALIDATION = `${API_URL2}user/email/verifyauthcode`;

///비밀번호 확인
export const PASSWORD_FIND = `${API_URL2}user/mypage/me`;

///대시보드 가져오기
export const WORKSPACE_ME = `${API_URL2}workspace/me`;

///워크스페이스 이름변경
export const WORKSPACE_CHANGE_NAME = `${API_URL2}workspace/change/name`;

///워크스페이스 업데이트
export const WORKSPACE_UPDATE = `${API_URL2}workspace/update/{workspaceId}`;

///휴지통 가져오기
export const TRASHCAN_ME = `${API_URL2}workspace/trashcan/me`;

///휴지통 버리기
export const TRASHCAN_THROW = `${API_URL2}workspace/trashcan/throw`;

///영구삭제
export const TRASHCAN_DELETE = `${API_URL2}workspace/trashcan/delete`;

///복원
export const TRASHCAN_RESTORE = `${API_URL2}workspace/trashcan/restore`;

///사용권 등록
export const PROMOTION_CODE = `${API_URL2}user_usage/promotion`;

///사용권 내역조회
export const PROMOTION_LOG = `${API_URL2}user_usage/promotion/log`;

/// 작업공강 카운트 호출
export const MY_DATA_COUNT = `${API_URL2}workspace/count`;

/// 워크스페이스 디테일 가져오기
export const WORKSPACE_DETAIL = `${API_URL2}workspace/detail/{workspace_id}`;

/// 명도대비 스텝1
export const IMGBRIGHTNESS_ADD = `${API_URL2}img_brightness/step_one`;

/// 명도대비 스탭2
export const IMGBRIGHTNESS_ADD2 = `${API_URL2}img_brightness/step_two`;


/// img2Html 생성
export const UMG2_HTML_CREATE = `${API_URL2}img_html/create`;

/// img2Html 재생성
export const UMG2_HTML_RECREATE = `${API_URL2}img_html/recreate`;

/// img2Html 다운로드
export const IMG2_HTML_DOWNLOAD = `${API_URL2}img_html/download/`;

/// img2Html 공유하기
export const IMG2_SHARE = `${API_URL2}img_html/share`;

/// img2Html 공유 호출하기
export const IMG2_GET_SHARE = `${API_URL2}img_html/share/`;

/// img2Html 자동저장
export const IMG2_AUTO_SAVE = `${API_URL2}img_html/save`;

/// 회원탈퇴
export const USER_WITHDRAWAL = `${API_URL}user/mypage/withdrawal`;