import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginNaverLogo from "@/assets/images/ico-naver@2x.png";
import loginGoogleLogo from "@/assets/images/ico-google@2x.png";
import porofileImg from "@/assets/images/img-profile@2x.png";
import usePopup from "@/hooks/usePopup";
import { useSelector } from "react-redux";
import {
	ChangeNamePopup,
	ChangePassWord,
	ChangePhoneNumber,
	ChangePhotoPopup
} from "@/services/pages/setting/SettingAccountPage";
import LogoutButton2 from "@/services/util/LogoutButton2";
import SettingTabs from "@/components/tabs/SettingTabs";
import PopupLayout from "@/components/popup/PopupLayout";
import { nameRegex } from "@/services/util/const";
import { Withdrawal } from "@/services/core/setting/Withdrawal";
import { removeCookie } from "@/services/cookies/UserCookies";

const ReasonItem = ({ id, reason, checked, onChange }) => (
	<li>
		<span className="check-item">
			<input type="radio" name="leave-reason" id={id} value={reason} onChange={onChange} checked={checked} />
			<label htmlFor={id}>{reason}</label>
		</span>
	</li>
);

export default function SettingAccountPage() {
	const userData = useSelector((state) => state?.user?.userData || []);
	const userName = userData?.data?.name; // 고객명
	const [selectedReason, setSelectedReason] = useState(""); // 선택한 탈퇴 사유 상태
	const [otherReason, setOtherReason] = useState(""); // 기타 사유 입력 상태
	const { isOpen: isSecessionPopup, open: openSecessionPopup, close: closeSecessionPopup } = usePopup();
	const navigate = useNavigate();
	const [step, setStep] = useState(1); // 추가된 상태: 현재 단계

	// popup
	const { isOpen: isChangeNamePopup, open: openChangeNamePopup, close: closeChangeNamePopup } = usePopup(); // 이름 변경
	const { isOpen: isChangePhotoPopup, open: openChangePhotoPopup, close: closeChangePhotoPopup } = usePopup(); // 사진변경
	const { isOpen: isChangePwPopup, open: openChangePwPopup, close: closeChangePwPopup } = usePopup(); // 비밀번호 변경
	const { isOpen: isChangeNumberPopup, open: openChangeNumberPopup, close: closeChangeNumberPopup } = usePopup(); // 전화번호 변경

	const handleNameChange = (name) => {
		if (nameRegex.test(name)) {
			// 이름 변경 로직
		}
	};

	const maskPhoneNumber = (userNumber) => {
		return userNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
	};

	const handleCheckboxChange = (event) => {
		const { value } = event.target;
		if (value === "기타") {
			setSelectedReason(`기타: ${otherReason}`);
		} else {
			setSelectedReason(value);
		}
	};

	const handleOtherReasonChange = (event) => {
		setOtherReason(event.target.value);
		if (selectedReason.startsWith("기타")) {
			setSelectedReason(`기타: ${event.target.value}`);
		}
	};

	const isButtonDisabled = selectedReason === "";

	const handleWithdraw = async () => {
		const userInfo = {
			userEmailId: userData?.data?.email,
			userName: userData?.data?.name
		};

		const allReasons = selectedReason;

		try {
			const resp = await Withdrawal(userInfo, allReasons);
			if (resp === "Success") {
				removeCookie("token", { path: "/" });
				closeSecessionPopup();
				navigate("/");
			}
		} catch (error) {
			console.error("회원탈퇴 실패", error);
			alert("회원탈퇴 실패");
			return;
		}
	};

	const reasons = [
		"이용이 불편하고 장애가 많아서",
		"다른 서비스가 더 좋아서",
		"거의 사용하지 않아서",
		"요금이 너무 비싸서",
		"기타"
	];

	return (
		<>
			<div className="container settings setting-account-page">
				<div className="content-box">
					<div className="content-header mb-6">
						<h2 className="title1">설정</h2>
						<div className="btn-area">
							<LogoutButton2 />
						</div>
					</div>
					<div className="content-body">
						<SettingTabs />
						<div className="content-inner">
							<h3 className="sr-only">계정 정보</h3>
							<div className="settings-list">
								<dl>
									<dt>프로필</dt>
									<dd className="profile">
										<div className="con-area">
											<span className="img">
												<img src={userData?.data?.profileImg ?? porofileImg} alt="프로필 이미지" />
											</span>
											<div className="user-info">
												<p className="user-name">{userData?.data?.name}</p>
											</div>
										</div>
										<div className="btn-area">
											<button type="button" className="btn btn-sm btn-outline-gray" onClick={openChangeNamePopup}>
												이름 변경
											</button>
											<button type="button" className="btn btn-sm btn-outline-gray" onClick={openChangePhotoPopup}>
												사진 변경
											</button>
										</div>
									</dd>
								</dl>
								<dl>
									<dt>이메일 아이디</dt>
									<dd>
										<div className="con-area">
											<p>{userData?.data?.email}</p>
										</div>
									</dd>
								</dl>
								{userData?.data?.loginType === "naver" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginNaverLogo} alt="NAVER" />
												</span>
												<p>NAVER</p>
											</div>
										</dd>
									</dl>
								) : userData?.data?.loginType === "google" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginGoogleLogo} alt="Google" />
												</span>
												<p>Google</p>
											</div>
										</dd>
									</dl>
								) : userData?.data?.loginType === "NL" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginNaverLogo} alt="NAVER" />
												</span>
												<p>NAVER</p>
											</div>
										</dd>
									</dl>
								) : userData?.data?.loginType === "GL" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginGoogleLogo} alt="Google" />
												</span>
												<p>Google</p>
											</div>
										</dd>
									</dl>
								) : userData?.data?.loginType === "GN" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginGoogleLogo} alt="Google" />
													<img src={loginNaverLogo} alt="NAVER" />
												</span>
												<p>Google, NAVER</p>
											</div>
										</dd>
									</dl>
								) : userData?.data?.loginType === "All" ? (
									<dl className="sns">
										<dt>SNS 연동</dt>
										<dd>
											<div className="con-area">
												<span className="img">
													<img src={loginGoogleLogo} alt="Google" />
													<img src={loginNaverLogo} alt="NAVER" />
												</span>
												<p>Google, NAVER</p>
											</div>
										</dd>
									</dl>
								) : null}
								<dl>
									<dt>전화번호</dt>
									<dd>
										<div className="con-area">
											<p>{maskPhoneNumber(userData?.data?.phone)}</p>
										</div>
										<div className="btn-area">
											<button type="button" className="btn btn-sm btn-outline-gray" onClick={openChangeNumberPopup}>
												변경하기
											</button>
										</div>
									</dd>
								</dl>
								{!(
									userData?.data?.loginType === "naver" ||
									userData?.data?.loginType === "google" ||
									userData?.data?.loginType === "GN"
								) && (
									<dl>
										<dt>비밀번호 변경</dt>
										<dd>
											<div className="con-area">
												<p>****************</p>
											</div>
											<div className="btn-area">
												<button type="button" className="btn btn-sm btn-outline-gray" onClick={openChangePwPopup}>
													변경하기
												</button>
											</div>
										</dd>
									</dl>
								)}
								<div className="leave-btn-area">
									<button type="button" className="btn btn-leave" onClick={openSecessionPopup}>
										<span className="text-gray">서비스 탈퇴</span>
										<i className="ico ico-arrow-right-sm-gray"></i>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* 비밀번호 변경 */}
			<ChangePassWord isOpen={isChangePwPopup} closePopup={closeChangePwPopup} />
			{/* 사용자 이름변경 */}
			<ChangeNamePopup isOpen={isChangeNamePopup} closePopup={closeChangeNamePopup} userName={userData?.data?.name} />
			{/* 프로필 변경 */}
			<ChangePhotoPopup
				isOpen={isChangePhotoPopup}
				closePopup={closeChangePhotoPopup}
				profile={userData?.data?.profileImg}
				baseProfile={porofileImg}
			/>
			{/* 전화번호 변경 */}
			<ChangePhoneNumber
				isOpen={isChangeNumberPopup}
				closePopup={closeChangeNumberPopup}
				userNumber={userData?.data?.phone}
			/>
			{/* 탈퇴 사유 팝업 */}
			<PopupLayout
				isOpen={isSecessionPopup}
				closePopup={() => {
					setStep(1); // 팝업 닫힐 때 단계 초기화
					closeSecessionPopup();
				}}
				title="서비스 탈퇴"
				size="default"
				footerContent={
					<>
						<div className="btn-group">
							<button
								type="button"
								className="btn btn-xl btn-outline-gray"
								onClick={() => {
									setSelectedReason(""); // 선택한 이유 초기화
									setOtherReason(""); // 기타 이유 초기화
									setStep(1); // 팝업이 열릴 때 단계 초기화
									closeSecessionPopup();
								}}
							>
								취소
							</button>
							{step === 1 ? (
								<button type="button" className="btn btn-xl btn-primary" onClick={() => setStep(2)}>
									다음
								</button>
							) : (
								<button
									type="button"
									className="btn btn-xl btn-red"
									onClick={handleWithdraw}
									disabled={isButtonDisabled}
								>
									탈퇴
								</button>
							)}
						</div>
					</>
				}
			>
				{step === 1 ? (
					<>
						<h3 className="text-md mb-1">
							<span className="text-medium">{userName}</span>님, 정말 떠나시겠어요? 😢
						</h3>
						<ul className="list list2 text-sm text-gray">
							<li>유/무료 사용권과 작업물이 모두 사라지고 복구할 수 없어요.</li>
							<li>동일한 계정으로 1개월간 재가입 할 수 없게 돼요.</li>
						</ul>
					</>
				) : (
					<>
						<h3 className="text-md">그래도 떠나려는 이유는 무엇인가요?</h3>
						<ul className="secession-check-list mt-4">
							{reasons.map((reason, index) => (
								<ReasonItem
									key={index}
									id={`secession-chk${index + 1}`}
									reason={reason}
									checked={selectedReason === reason || (reason === "기타" && selectedReason.startsWith("기타"))}
									onChange={handleCheckboxChange}
								/>
							))}
							{selectedReason.startsWith("기타") && (
								<div className="input-group-form mt-2">
									<input
										type="text"
										title="탈퇴 사유를 입력해주세요."
										className="form-control"
										placeholder="탈퇴 사유를 입력해주세요."
										value={otherReason}
										onChange={handleOtherReasonChange}
									/>
								</div>
							)}
						</ul>
					</>
				)}
			</PopupLayout>
		</>
	);
}
