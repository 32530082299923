import React, { useState } from "react";
import Toast from "@/components/toast/Toast";
import { useSelector } from "react-redux";

export default function SeoMetaDescription() {
	const data = useSelector((state) => state.seo.seoData.data.resData.description
	);

	const [showToast, setShowToast] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const copyToClipboard = () => {
		const textToCopy = data.text;

		const tempDiv = document.createElement("div");
		tempDiv.style.position = "absolute";
		tempDiv.style.left = "-1000px";
		tempDiv.style.top = "-1000px";
		tempDiv.textContent = textToCopy;
		document.body.appendChild(tempDiv);

		// 텍스트를 선택하고 복사
		const range = document.createRange();
		const selection = window.getSelection();
		range.selectNodeContents(tempDiv);
		selection.removeAllRanges();
		selection.addRange(range);

		try {
			document.execCommand("copy");
			setShowToast(true);
			setIsButtonDisabled(true);
		} catch (error) {
			console.error("복사 실패:", error);
			setIsButtonDisabled(true);
		} finally {
			// 생성한 가상의 div 엘리먼트를 제거
			document.body.removeChild(tempDiv);
			selection.removeAllRanges();
		}
	};

	const handleToastClose = () => {
		setShowToast(false);
		setIsButtonDisabled(false);
	};

	return (
		<>
			<div className="title-wrap">
				<div className="wrap-left">
					<h4 className="title3">SEO 디스크립션</h4>
					<span className="guide-text">페이지 요약 설명문으로 사용할 수 있습니다.</span>
				</div>
			</div>
			<div className="seowork-content">
				<div className="box box-outline">
					<p
						className="seo-description"
						style={{ userSelect: "all", cursor: "pointer" }}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						{data.text}
					</p>
				</div>

				<button
					type="button"
					className="btn btn-copy"
					onClick={() => {
						copyToClipboard();
					}}
					disabled={isButtonDisabled}
				>
					<i className={`ico ${isButtonDisabled ? "ico-copy-gray-sm" : "ico-copy-sm"}`}></i>
					텍스트 전체 복사
				</button>

				{showToast && (
					<Toast
						message="SEO 디스크립션 텍스트가 복사되었습니다."
						onClose={handleToastClose}
						icon={<i className="ico ico-copy-white-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
			</div>
		</>
	);
}
