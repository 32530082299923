export const SEARCH_DATA = "SEARCH_DATA";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_LOADED = "SEARCH_LOADING";

export const searchLoading = () => ({
	type: SEARCH_LOADING
});

export const searchLoaded = () => ({
	type: SEARCH_LOADED
});

export const searchData = (data) => {
	return {
		type: SEARCH_DATA,
		payload: data
	};
};
