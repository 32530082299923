export const SET_AUTH_ERROR = "SET_AUTH_ERROR";

export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";

export const setAuthError = (errorStatus) => ({
	type: SET_AUTH_ERROR,
	payload: errorStatus
});

export const clearAuthError = () => ({
	type: CLEAR_AUTH_ERROR
});
