import React, { useState, useEffect } from "react";

function InputTaskNameWork({ workName, onworkNameChange,closeMorePopup }) {
	const [localworkName, setLocalworkName] = useState(workName);

	useEffect(() => {
		setLocalworkName(workName);
	}, [workName]);

	const handleInputChange = (event) => {
		const inputValue = event.target.value;
		if (inputValue.length <= 50) {
			setLocalworkName(inputValue);
			onworkNameChange(inputValue);
		}
	};

	return (
		<div className="change-work-name-inner">
			<div className="input-group-form">
				<input
					type="text"
					id="user-name"
					title="작업명 변경"
					className="form-control"
					value={localworkName}
					onChange={handleInputChange}
					onClose={closeMorePopup}
				/>
			</div>
			<p className="characters-long">
				<span>{localworkName.length}</span>/50자
			</p>
		</div>
		// <div className="inner">
		// 	<input
		// 		type="text"
		// 		id="InputTaskName"
		// 		value={localworkName}
		// 		className="form-control"
		// 		onChange={handleInputChange}
		// 		placeholder="작업명을 입력해주세요."
		// 		maxLength="50"
		// 		title="작업명 변경"
		// 	/>
		// 	<span className="text-count">{localworkName.length}/50 자</span>
		// </div>
	);
}

export default InputTaskNameWork;
