// Extensions.js
import Keymap from "./blockEditor/keymap";
// import DraggableItem from "./blockEditor/DraggableItem";

import StarterKit from "@tiptap/starter-kit";
// import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph";
// import Text from "@tiptap/extension-text";
// import ListItem from "@tiptap/extension-list-item";
// import BulletList from "@tiptap/extension-bullet-list";
// import OrderedList from "@tiptap/extension-ordered-list";
// import Table from "@tiptap/extension-table";
import Table from "./extensions/Table/Table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import { Markdown } from "tiptap-markdown";

const Extensions = [
	StarterKit.configure({
		// table 관련 설정 추가
		table: false
	}),
	Link.configure({
		openOnClick: false,
		autolink: true
	}),
	Underline,
	Table.configure({
		resizable: true,
		handleWidth: false,
		cellMinWidth: 100,
		lastColumnResizable: false
	}),
	TableRow,
	TableHeader,
	TableCell,
	Keymap,
	Markdown
	// DraggableItem,
	// DragAndDrop({})
];

export default Extensions;
