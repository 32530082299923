import { ChangePassword } from "@/services/core/singIn/ChangePassword";
import { FindpwStep1, FindpwStep2 } from "@/services/pages/account/FindpwPage";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function FindpwPage() {
	const StyledButtonGroup = styled.div`
		margin-top: 40px;
	`;

	const navigate = useNavigate();

	const [authStatus, setAuthStatus] = useState("");
	const [isRequesting, setIsRequesting] = useState(false);

	//스탭전환 핸들러
	const [isStep, setIsStep] = useState(false);
	const handleStepContinue = () => {
		setIsStep(true);
	};

	//스탭에 있는 이메일 비밀번호 가져오기
	const [email, setEmail] = useState("");
	const [authCode, setAuthCode] = useState("");
	const [password, setPassword] = useState("");
	const [checkPassword, setCheckPassword] = useState("");
	const [isEvId, setIsEvId] = useState("");
	const [isSamePassword, setIsSamePassword] = useState(false);
	// 버튼 클릭 이벤트 핸들러
	const passwordChangeHandle = async () => {
		setIsRequesting(true);

		const result = await ChangePassword(authCode, email, isEvId, checkPassword);
		if (result === "Success") {
			navigate("/login");
		} else if (result === 3) {
			setIsSamePassword(true);
		}
		setIsRequesting(false);
	};

	return (
		<>
			<div className="container account-page">
				<div className="account-header">
					<Link to="/login" className="btn btn-xs btn-back">
						<i className="ico ico-arrow-left-gray"></i>돌아가기
					</Link>
					<h2 className="title1">비밀번호 재설정</h2>
				</div>
				<div className="account-body findpw-page">
					<div className="subject-area">
						{/* step 1. 이메일 아이디 입력 시 노출 */}
						<p className="subject">가입한 이메일로 인증번호를 보내드려요.</p>

						{/* step 2. 비밀번호 재설정 시 노출 */}
					</div>

					<form>
						<fieldset>
							<legend className="sr-only">비밀번호 재설정</legend>
							<div className="form-group">
								{/* [개발] step 1. 이메일 아이디 입력 시 노출 (step2 미노출) */}
								{!isStep ? (
									<FindpwStep1
										status={setAuthStatus}
										setEmail={setEmail}
										setAuthCode={setAuthCode}
										setIsEvId={setIsEvId}
									/>
								) : (
									<FindpwStep2
										setPassword={setPassword}
										setCheckPassword={setCheckPassword}
										isSamePassword={isSamePassword}
										setIsSamePassword={setIsSamePassword}
									/>
								)}
								{/* [개발] step 2. 새 비밀번호 입력 시 노출 (step1 미노출) */}
							</div>
						</fieldset>
					</form>

					{/* [개발] step 1. 이메일 정상적으로 입력 시 노출 */}
					{!isStep ? (
						<StyledButtonGroup className="btn-group btn-group-flex">
							<button
								type="button"
								className="btn btn-xl btn-primary"
								disabled={authStatus !== "Success"}
								onClick={handleStepContinue}
							>
								계속
							</button>
						</StyledButtonGroup>
					) : (
						<StyledButtonGroup className="btn-group btn-group-flex">
							<button
								type="submit"
								className="btn btn-xl btn-primary"
								onClick={passwordChangeHandle}
								disabled={isRequesting || password !== checkPassword || password === "" || checkPassword === ""}
							>
								재설정
							</button>
						</StyledButtonGroup>
					)}

					{/* [개발] step 2. 새 비밀번호 입력 시 노출 */}
				</div>
			</div>
		</>
	);
}
