// results 객체를 배열로 변환하고, 각 item을 createPins 함수가 처리할 수 있는 형태로 매핑하는 함수
const processResults = (resultsObj) => {
	return Object.values(resultsObj).map((item, index) => ({
		// id가 없다면 순차적인 번호를 사용하여 유니크한 값 할당
		id: index + 1,
		x1: item.bbox[0][0],
		y1: item.bbox[0][1],
		x2: item.bbox[1][0],
		y2: item.bbox[1][1],
		x3: item.bbox[2][0],
		y3: item.bbox[2][1],
		x4: item.bbox[3][0],
		y4: item.bbox[3][1],
		bgColor: item.background,
		fontColor: item.origin_color, // fontColor에 해당하는 값
		suggestedColor:
			item.recom_color && item.recom_color.length
				? [item.recom_color[0], item.recom_color[item.recom_color.length - 1]]
				: [] // 기타 필요한 데이터 매핑
	}));
};

// createPins 함수: processResults 함수를 통해 변환된 results 배열을 사용하여 pins 객체 배열 생성
const createPins = (results) => {
	return results.map((result) => ({
		id: result.id,
		points: [
			{ x: result.x1, y: result.y1 },
			{ x: result.x2, y: result.y2 },
			{ x: result.x3, y: result.y3 },
			{ x: result.x4, y: result.y4 }
		],
		bgColor: result.bgColor,
		fontColor: result.fontColor,
		suggestedColor: result.suggestedColor // 이 값도 실제 사용 상황에 맞게 조정할 수 있습니다.
	}));
};

// testData 함수: imgdata와 resultsObj를 인자로 받아 최종적인 images 데이터 구조를 생성
export const formatData = (imgdata, resultsObj, newImagePath) => ({
	images: [
		{
			src: imgdata.images.data.oriImgPath, // 이미지 경로
			output: newImagePath || null,
			pins: createPins(processResults(resultsObj)) // processResults를 통해 변환된 results 배열을 createPins 함수에 사용
		}
	]
});

/// 마지막 step2 넘어가기전 엔디포인트 호출 구조

export const formatDataStepTwo = (stepTwoData, selectedColors) => {
	// changeWordColor 배열 생성
	const changeWordColor = [];

	// totalBrightnessData.images[0].pins.length 만큼 반복
	for (let i = 1; i <= stepTwoData.images[0].pins.length; i++) {
		// selectedColors에서 색상 찾기
		// 색상이 없는 경우 null 추가
		changeWordColor.push(selectedColors[i] || null);
	}

	return {
		changeWordColor
	};
};
