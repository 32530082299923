import Logo from "@/assets/images/logo@2x.png";
import { Link } from "react-router-dom";

const MobileHeader = () => {
	const toggleAllMenu = () => {
		document.body.classList.toggle("allmenu-opened");
	};

	return (
		<>
			<header id="header" className="d-down-md header-full">
				<div className="header-container">
					<h1 id="logo">
						<Link to="/dashboard">
							<img src={Logo} alt="A11Y beta" />
						</Link>
					</h1>
					<div className="right">
						<button id="btn-allmenu" className="btn btn-icon d-down-md" onClick={toggleAllMenu}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<title>전체메뉴</title>
								<path d="M3 5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3 12H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M3 19H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</button>
					</div>
				</div>
			</header>{" "}
		</>
	);
};

export default MobileHeader;
