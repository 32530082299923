import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCookie } from "@/services/cookies/UserCookies";
import giftImg from "@/assets/images/promotion/img-gift-winter@2x.png";

const PromotionBanner = ({ isHidden, isOnBlack, type = "card" }) => {
	const userData = useSelector((state) => state.user?.userData?.data);
	const [isPromotionActive, setIsPromotionActive] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const PROMOTIONS = [
		{
			title: "앨리 2024년 가을 이벤트 예고", // 브라우저 타이틀
			end: new Date("2024-09-11T23:59:59").getTime(),
			bgColor: "#f14800", // 주황색
			text: (
				<>
					<strong>3rd 가을 이벤트 🌰</strong> <span>9.12 ~ 10.31</span>
					<strong className="text-lg">
						앨리 사용권 &amp; 기프티콘 받고 싶다면?
						<br /> 로그인하고 3분 설문 참여하기!
					</strong>
					<img src={giftImg} alt="" className="img-gift" />
				</>
			),
			buttonText: "9/12 이벤트 미리보기",
			to: "9-10월-앨리-로그인-설문-참여-이벤트",
			bannerClass: "event3"
		},
		{
			title: "앨리 2024년 가을 이벤트",
			end: new Date("2024-10-31T23:59:59").getTime(),
			bgColor: "#f14800", // 주황색 배경
			text: (
				<>
					<strong>3rd 가을 이벤트 🌰</strong> <span>9.12 ~ 10.31</span>
					<strong className="text-lg">
						앨리 사용권 &amp; 기프티콘 받고 싶다면?
						<br /> 로그인하고 3분 설문 참여하기!
					</strong>
					<img src={giftImg} alt="" className="img-gift" />
				</>
			),
			buttonText: "참여하고 사용권 즉시 받기",
			to: "9-10월-앨리-로그인-설문-참여-이벤트",
			bannerClass: "event3"
		},
		{
			title: "앨리 2024년 겨울 이벤트 예고",
			end: new Date("2024-11-18T14:59:59").getTime(),
			bgColor: "#124225",
			text: (
				<>
					<strong>4th 겨울 이벤트 ⛄</strong> <span>24.11.18 ~ 25.01.31</span>
					<strong className="text-lg">
						올해의 마지막 겨울 이벤트!
						<br /> 곧 공개됩니다!
					</strong>
					<img src={giftImg} alt="" className="img-gift" />
				</>
			),
			buttonText: "이벤트 미리보기",
			to: "24112501-앨리-겨울-이벤트",
			bannerClass: "event4"
		},
		{
			title: "앨리 2024년 겨울 이벤트",
			end: new Date("2025-01-31T23:59:59").getTime(),
			bgColor: "#124225",
			text: (
				<>
					<strong>4th 겨울 이벤트 ⛄</strong> <span>24.11.18 ~ 25.01.31</span>
					<strong className="text-lg">
						앨리 API 플러그인 얼리버드 50
						<br /> 선착순 특별 혜택을 놓치지 마세요!
					</strong>
					<img src={giftImg} alt="" className="img-gift" />
				</>
			),
			buttonText: "이벤트 자세히보기",
			to: "24112501-앨리-겨울-이벤트",
			bannerClass: "event5"
		}
	];

	const BANNER_CLASSES = {
		band: "type-band",
		"card-lg": "type-card type-card-lg",
		"card-sm": "type-card type-card-sm",
		"card-md": "type-card type-card-md",
		card: "type-card"
	};

	const calculateTimeRemaining = useCallback(() => {
		const now = new Date().getTime();
		const activePromotion = PROMOTIONS.find((promo, index) => {
			const prevEnd = index === 0 ? 0 : PROMOTIONS[index - 1].end;
			return now > prevEnd && now <= promo.end;
		});
		setIsPromotionActive(activePromotion || null);
	}, []);

	useEffect(() => {
		calculateTimeRemaining();
		const interval = setInterval(calculateTimeRemaining, 1000);
		return () => clearInterval(interval);
	}, [calculateTimeRemaining]);

	useEffect(() => {
		const token = getCookie("token");
		setIsAuthenticated(!!token);
	}, []);

	const truncateName = (name) => {
		if (!name) return "";
		const maxLen = /^[a-zA-Z]+$/.test(name) ? 8 : 5;
		return name.length > maxLen ? name.slice(0, maxLen) + "" : name;
	};

	const content = isPromotionActive;

	const bannerClass = `${BANNER_CLASSES[type] || BANNER_CLASSES["card-md"]} ${content?.bannerClass}`;
	const bannerStyle = content?.bgColor ? { backgroundColor: content.bgColor } : {};

	const getLink = (defaultLink) => {
		// if (isAuthenticated) {
		return `/contents?to=event/${encodeURIComponent(defaultLink)}&title=${encodeURIComponent(content?.title)}`;
		// }
		// return `https://a11y.super.site/event/${defaultLink}`;
	};

	return (
		<div
			className={`promotion-banner ${bannerClass} ${isHidden ? "hide" : ""} ${isOnBlack ? "bg-active" : ""}`}
			style={bannerStyle}
		>
			<Link to={getLink(content?.to)} className="banner-content">
				<p className="text">
					{userData?.userName && <span className="d-up-md">{truncateName(userData.userName)}님,</span>}
					{content?.text}
				</p>
				<span className={`btn ${type === "card-lg" ? "btn-lg" : "btn-md"} btn-outline-white btn-cta`}>
					{content?.buttonText}
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.33008 2.34326L10.9869 8.00012L5.33008 13.657"
							stroke="white"
							strokeWidth="1.3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
			</Link>
		</div>
	);
};

PromotionBanner.propTypes = {
	isHidden: PropTypes.bool,
	isOnBlack: PropTypes.bool,
	type: PropTypes.oneOf(["card-lg", "card-md", "card-sm", "band", "card"])
};

export default PromotionBanner;
