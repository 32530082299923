
import numeral from "numeral";



export const fileSizeDisplay = (fileSize) => {
	const sizeInKB = fileSize / 1024;
	if (sizeInKB >= 1000) {
		return `${numeral(sizeInKB / 1024).format(`0,0.00`)} MB`;
	} else {
    return `${numeral(sizeInKB < 0.1 ? 0.1 : sizeInKB).format('0,0.0')} KB`;
	}
};

