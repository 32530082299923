import { action } from "redux";
import { RESET_SEO_DATA, UPDATE_SEO_META_TAG, UPDATE_SEO_SAVE_TIME } from "../action/seoAction";
import { getTimeMin } from "@/services/util/DateTimeModule";
const { RESET_STATE } = require("../action/ResetAction");

const initialState = {
	seoData: {}
};

const seoReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_SEO_DATA":
			return {
				...state,
				seoData: action.payload
			};
			break;
		case UPDATE_SEO_SAVE_TIME:
			const time = getTimeMin()
			return{
				...state,
				seoData:{
					data:{
						...state.seoData.data,
						saveTime:time
					}
					
				}
			}
		case UPDATE_SEO_META_TAG:
			return{
				...state,
				seoData:{
					data:{
						...state.seoData.data,
						metaTag :action.payload
					}
					
				}

			}
		case RESET_STATE:
			return initialState;
			break;

		case RESET_SEO_DATA:
			return {
				seoData:{
					data:{
						html:state.seoData.data.html,
						url:state.seoData.data.html
					}
					
				}
			};
			break;
		default:
			return state;
	}
};


export default seoReducer;
