import React from "react";
import ReactDOM from "react-dom/client";
import "@/scss/style.scss";
import App from "@/App";
import { Provider } from "react-redux";
import store, { persistor } from "./services/store/ReduxStore";
import { PersistGate } from "redux-persist/integration/react";

const defaultTitle = process.env.REACT_APP_DEFAULT_TITLE;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App defaultTitle={defaultTitle} />
			</PersistGate>
		</Provider>
	</React.StrictMode>
);
