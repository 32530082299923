import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { combineReducers } from "redux";
import userReducer from "./reducer/UserReducer";
import storage from "redux-persist/lib/storage";
import licenseReducer from "./reducer/LicenseReducer";
import seoReducer from "./reducer/seoReducer";

import { persistReducer, persistStore } from "redux-persist";
import dashboardReducer from "./reducer/DashBoardReducer";
import { thunk } from "redux-thunk";
import workSpaceReducer from "./reducer/WorkSpaceReducer";
import searchReducer from "./reducer/SearchReducer";
import trashCanReducer from "./reducer/TrashCanReducer";
import myDataCountReducer from "./reducer/MyDataCountReducer";
import { toastReducer } from "./reducer/ToastReducer";
import authReducer from "./reducer/AuthReducer";
import {
	sortDashBoardReducer,
	sortReducer,
	trashCardSortingReducer,
	trashSortReducer,
	workCardSortingReducer
} from "./reducer/SortOrderReducer";
import { htmlReducer } from "./reducer/Img2HtmlReducer";
import { brightnessReducer, outPutImgReducer, saveImgStateReducer } from "./reducer/BrightnessReducer";
import tableReducer from "./reducer/tableReducer";
import { sidSelectReducer } from "./reducer/SelectReducer";

const rootReducer = combineReducers({
	user: userReducer,
	dataCount: myDataCountReducer,
	license: licenseReducer,
	seo: seoReducer,
	dashboard: dashboardReducer,
	workspace: workSpaceReducer,
	search: searchReducer,
	trash: trashCanReducer,
	toast: toastReducer,
	auth: authReducer,
	sort: sortReducer,
	dashboardSort: sortDashBoardReducer,
	trashSort: trashSortReducer,
	workCardSort: workCardSortingReducer,
	trashCarSort: trashCardSortingReducer,
	html: htmlReducer,
	// shareData: shareReducer,,
	brightness: brightnessReducer,
	table: tableReducer,
	brightnessColor: saveImgStateReducer,
	brightnessOutPut: outPutImgReducer,
	sidSelect: sidSelectReducer
});

const persistConfig = {
	key: "root",
	storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store);

export default store;
