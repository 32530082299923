const { RESET_STATE } = require("../action/ResetAction");
const { WORKSPACE_STATUS, WORKSPACE_LOADING, WORKSPACE_LOADED } = require("../action/WorkSpaceAction");

const initialState = {
	workSpaceData: [],
	isLoading: false
};

const workSpaceReducer = (state = initialState, action) => {
	switch (action.type) {
		case WORKSPACE_LOADING:
			return {
				...state,
				isLoading: true
			};
		case WORKSPACE_LOADED:
			return {
				...state,
				isLoading: false
			};
		case WORKSPACE_STATUS:
			return {
				...state,
				workSpaceData: action.payload
			};
			break;
		case RESET_STATE:
			return initialState;
			break;
		default:
			return state;
	}
};

export default workSpaceReducer;
