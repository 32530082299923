import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Check from "@/assets/images/ico-check-xl-primary@2x.png";

const MiddlePage = ({ setIsAuthenticated, isAuthenticated }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login");
		} else {
			setIsAuthenticated(true);
		}
	}, [isAuthenticated, navigate, setIsAuthenticated]);

	const routerDashboardHandler = () => {
		navigate("/dashboard");
	};

	return (
		<>
			<div className="info-page">
				<div className="info-inner">
					<div className="info-area">
						<img src={Check} alt="" />
						<p className="message">앨리 가입이 완료되었어요! 🎉</p>
						<p className="desc">
							모두를 포용하는 웹 세상.
							<br className="d-down-sm" /> 앨리와 함께 만들 준비되셨나요?
						</p>
						{/* <div className="text-guide mt-1">
							<i className="ico ico-info-sm"></i>
							<p>앨리 베타는 데스크탑에 최적화 되어있어요.</p>
						</div> */}
						<div className="btn-group">
							<button onClick={() => routerDashboardHandler()} className="btn btn-xl btn-primary pl-6 pr-6">
								대시보드로 이동
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MiddlePage;
