import api from "@/services/util/Interceptor";
import { TRASHCAN_RESTORE } from "../url/URL";
import { navigateTo } from "@/services/util/Navigate";

export const TrashRestore = async (workId) => {
	try {
		const response = await api.post(TRASHCAN_RESTORE, {
			workspaceIds: [workId]
		});
	} catch (error) {
		navigateTo("/error");
	}
};
