const { RESET_STATE } = require("../action/ResetAction");
const {  SELECT_SID2 } = require("../action/SelectAction");



const initialState2 = {
  text: null
};
export const sidSelectReducer = (state = initialState2, action) => {
  switch (action.type) {
    case SELECT_SID2:
      return {
        ...state,
        text: action.payload
      };
    case RESET_STATE:
      return initialState2;
    default:
      return state;
  }
}