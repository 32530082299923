import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

const CTASection = () => {
	const [isPromotionActive, setIsPromotionActive] = useState(false);
	const [timeRemaining, setTimeRemaining] = useState("");

	const promotionEndTime = new Date("2024-09-11T23:59:59").getTime();

	const calculateTimeRemaining = useCallback(() => {
		const now = new Date().getTime();
		const difference = promotionEndTime - now;

		if (difference > 0) {
			const days = Math.floor(difference / (1000 * 60 * 60 * 24));
			const hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
			const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
			const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, "0");
			setTimeRemaining(`${days}일 ${hours}시간 ${minutes}분 ${seconds}초`);
		} else {
			setIsPromotionActive(false);
			setTimeRemaining("");
		}
	}, [promotionEndTime]);

	// SVG 변수화
	const A11yEmblem = () => (
		<svg
			className="a11y-embelem"
			width="41"
			height="40"
			viewBox="0 0 41 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="5.13057"
				height="30.5254"
				rx="2.56528"
				transform="matrix(-0.78814 0.615496 0.615496 0.78814 17.1382 12.5898)"
				fill="#1364FF"
			/>
			<rect
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="matrix(-0.78814 0.615496 0.615496 0.78814 25.5308 5.54199)"
				fill="#BAFD02"
			/>
			<rect
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="matrix(0.927105 0.374801 0.374801 -0.927105 3.09326 13.8506)"
				fill="#BAFD02"
			/>
			<rect
				x="17.7573"
				y="2.40137"
				width="26.9891"
				height="5.90387"
				rx="2.95194"
				transform="rotate(82.012 17.7573 2.40137)"
				fill="#BAFD02"
			/>
		</svg>
	);
	useEffect(() => {
		const now = new Date().getTime();
		setIsPromotionActive(now <= promotionEndTime);

		const interval = setInterval(calculateTimeRemaining, 1000);
		return () => clearInterval(interval);
	}, [calculateTimeRemaining, promotionEndTime]);

	return (
		<section className="section section-cta">
			<strong className="title">
				웹 콘텐츠에 <br className="d-down-sm" /> <A11yEmblem />
				<span className="text-secondary">앨리</span>했나요?
			</strong>
			<p className="desc">
				앨리 베타 얼리억세스하고 더 큰 혜택 받으세요!
				{/* <br className="d-down-sm" /> {timeRemaining} 전 */}
			</p>
			<div className="cta-area">
				<Link to="/login" className="btn btn-xl btn-outline-white">
					무료로 시작하기
					<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.83154 2.34375L11.4884 8.0006L5.83154 13.6575"
							stroke="white"
							strokeWidth="1.3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
			</div>
		</section>
	);
};

export default CTASection;
