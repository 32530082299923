export const HtmlcodeData = `<!doctype html>
<html lang="ko">
	<head>
		<style>
			table {
				margin-top: 1em;
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;
			}
			table th,
			table td {
				border: 1px solid #000;
				height: 20px;
			}
			table:nth-child(12) > thead > tr > th {
				background: red;
			}
		</style>
	</head>
	<body>
		<h1>웹사이트 제목</h1>
		<h3>부제목</h3><h2>중요 섹션</h2><label for="text">테스트 중입니다.</label>
			<input type="text" id="inputTest" />
		<input type="text" id="inputTest" />
	<img src="image.jpg" />
				<a href="details.html" target="_blank">여기</a>
		<a href=" ">여기</a>
		<img src="image5.jpg" />
		<table>
			<caption>
				이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th>
					
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
					<td>4</td>
				</tr>
			</tbody>
		</table>

		<table>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
		<table>
			<caption></caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
		<table>
			<caption>
				적합한 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
	</body>
</html>`;

export const HtmlcodeData2 = `<!doctype html>
<html lang="ko">
	
	<body>
		<h1>웹사이트 제목</h1><h2>중요 섹션</h2>
		<h3>부제목</h3><label for="text">테스트 중입니다.</label>
			<input type="text" id="inputTest" />
		<input type="text" id="inputTest" />
	<img src="image.jpg" />
				<a href="details.html" target="_blank">여기</a>
		<a href=" ">여기</a>
		<img src="image5.jpg" />
		<table>
			<caption>
				이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th>
					
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
					<td>4</td>
				</tr>
			</tbody>
		</table>

		<table>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th>123</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
		<table>
			<caption></caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th>333</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
		<table>
			<caption>
				적합한 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
	</body>
</html>`;

export const TableCaptionData1 = `<!DOCTYPE html>
<html lang="ko">
	<head>
		<title>test</title>
	</head>
	<body>
		<table>
			<tr>
				<th scope="col">이것은 잘못된 캡션입니다</th>
			</tr>
			<tr>
				<td scope="col">데이터 1</td>
				<td>데이터 2</td>
			</tr>
			<tr>
				<td>데이터 3</td>
				<td>데이터 4</td>
			</tr>
		</table>

		<table>
			<caption class="sr-only">
				현장공무원 비율(지차체유형별,현장공무원 비율(본청,소속기관))에 대한 테이블입니다.
			</caption>
			<colgroup>
				<col style="width: 33%" />
				<col />
				<col />
			</colgroup>
			<thead>
				<tr>
					<th rowspan="2" scope="row">지차체유형별</th>
					<th colspan="2" scope="col">현장공무원 비율</th>
				</tr>
				<tr>
					<th scope="col">본청</th>
					<th scope="col">소속기관</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>파주시</td>
					<td>897명(48.2%)</td>
					<td>964명(51.8%)</td>
				</tr>
				<tr>
					<td>50만&nbsp;이상 시 평균</td>
					<td>968명(38.0%)</td>
					<td>1,741명(62.0%)</td>
				</tr>
				<tr>
					<td>50만&nbsp;이상 시(구청 x)&nbsp;평균</td>
					<td>1,036명(47.7%)</td>
					<td>1,166명(52.3%)</td>
				</tr>
			</tbody>
		</table>

		<table>
			<caption>
				혁신법 시행에 따른 변화
			</caption>
			<thead>
				<tr>
					<td></td>
					<th>기존</th>
					<th>변경</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th>과제 운영체재</th>
					<td>본청(사업기획·선정), 소속기관(협약·관리)</td>
					<td><strong>본청 통합</strong>(사업기획 - 관리)</td>
				</tr>
				<tr>
					<th>협약대상</th>
					<td>(주관/공동/위탁)연구책임자</td>
					<td>(주관/공동/위탁)<strong>연구개발기관</strong></td>
				</tr>
				<tr>
					<th>3책5공</th>
					<td>(주관/공동)책임자 → 책임자</td>
					<td>(주관)<strong>책임자 → 책임자</strong> (공동)<strong>책임자 → 참여연구원</strong></td>
				</tr>
			</tbody>
		</table>

		<table summary="만들다 만 표를 여기에 불러왔습니다.">
			<caption>
				만들다 만 표를 여기에 불러왔습니다.
			</caption>
			<tr>
				<th></th>
				<th></th>
				<th></th>
			</tr>
			<tr>
				<td>1</td>
				<td>홍길동</td>
				<td><input type="text" /></td>
			</tr>
		</table>

		<table>
			<tr>
				<td>1</td>
				<td>홍길동</td>
				<td>example@example.com</td>
			</tr>
			<tr>
				<td>2</td>
				<td>김철수</td>
				<td>example2@example.com</td>
			</tr>
		</table>

		<table>
			<tr>
				<th>ID</th>
				<th>이름</th>
				<th>이메일</th>
			</tr>
			<tr>
				<td>1</td>
				<td>홍길동</td>
				<td>example@example.com</td>
			</tr>
		</table>

		<table>
			<caption>
				이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는
				테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다. 이슈가 있는 테이블 caption
				테스트 데이터 입니다. 이슈가 있는 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
					<td>4</td>
				</tr>
			</tbody>
		</table>

		<table>
			<caption>
				적합한 테이블 caption 테스트 데이터 입니다.
			</caption>
			<colgroup>
				<col />
			</colgroup>
			<thead>
				<tr>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>3</td>
				</tr>
			</tbody>
		</table>
	</body>
</html>`;

export const TableCaptionData2 = `<!-- adBannerArea import -->

<!-- //adBannerArea import -->


<!DOCTYPE html>
<html lang="ko">
<head>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<meta http-equiv="X-UA-Compatible" content="ie=edge">
	<meta name="format-detection" content="telephone=no">
	<meta name="description" content="시민중심! 더 큰 파주! 대표 홈페이지를 통해 다양한 시정정보를 제공합니다.">
	<meta property="og:locale:alternate" content="ko_KR">
	<meta property="og:title" content="파주시청">
	<meta property="og:url" content="http://www.paju.go.kr">
	<meta property="og:image" content="http://www.paju.go.kr/resources/user/common/images/pajucity.png">
	<meta property="og:description" content="시민중심! 더 큰 파주! 대표 홈페이지를 통해 다양한 시정정보를 제공합니다.">
	<meta name="twitter:card" content="summary">
	<meta name="twitter:title" content="파주시청">
	<meta name="twitter:url" content="http://www.paju.go.kr">
	<meta name="twitter:image" content="http://www.paju.go.kr/resources/user/common/images/pajucity.png">
	<meta name="twitter:description" content="시민중심! 더 큰 파주! 대표 홈페이지를 통해 다양한 시정정보를 제공합니다.">
	<title>
		현장공무원 비율 : HOME &gt; 파주소개 &gt; 시청안내 &gt; 조직정보 &gt; 현장공무원 비율
	</title>

	<script type="text/javascript" src="/resources/openworks/spi/jquery/jquery-1.7.1.min.js"></script>
    <link rel="stylesheet" type="text/css" href="/resources/openworks/spi/jquery/colobox/themes/theme5/colorbox.css" >
    <script type="text/javascript" src="/resources/openworks/spi/jquery/colobox/jquery.colorbox-min.js"></script>
    <link rel="stylesheet" type="text/css" href="/resources/openworks/spi/jquery/printPreview/print-preview.css" >
    <script type="text/javascript" src="/resources/openworks/spi/jquery/printPreview/jquery.print-preview.js"></script>
    <script type="text/javascript" src="/resources/openworks/spi/jquery/form/jquery.form.js"></script>
    

	<link rel="stylesheet" href="/resources/user/www/css/style.css">

	<script src="/resources/user/_common/plugins/slick/slick.min.js"></script>
	<script src="/resources/user/_common/plugins/rwdImageMaps/jquery.rwdImageMaps.min.js"></script>
	<script src="/resources/user/_common/js/datepicker.js"></script>
	<script src="/resources/user/_common/js/ui-global.js"></script>

	<script type="text/javascript">CTX_PATH="";</script>
	<script type="text/javascript" src="/component/callcfg/ND_globalConfig.do"></script>
	<script type="text/javascript" src="/component/callcfg/ND_massageConfig.do"></script>
	<script type="text/javascript" src="/component/callcfg/ND_customConfig.do?rootKey=system-config"></script>
	<script type="text/javascript" src="/resources/openworks/spi/openworks/openworks.global.js"></script>
	<script type="text/javascript" src="/resources/openworks/spi/openworks/openworks.function.js"></script>
	<script type="text/javascript" src="/resources/user/commons/js/paju_2015.js"></script>

	<!-- 모니터링 임시주석 -->
	<script type='text/javascript'>
		var _bwa = [];
		_bwa.push(['setCollectorUrl', 'https://analy.paju.go.kr/openapi/collect']);
		_bwa.push(['setCustomerId', 'pajucity']);
		var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		g.type='text/javascript';
		g.src='/resources/user/commons/js/blueworksWebAgent-2.1-min.js';
		g.async=g.defer=true;
		s.parentNode.insertBefore(g, s);
	</script>

	<script type="text/javascript">
		$().ready(function() {
			

			//공유하기 횟수 가져오기
			jsGetSnsShareCnt();
			var curPage = document.location.href;
		});

		//프린트
		var jsDivPirnt = function() {
			var initBody = document.body.innerHTML;
			/*window.onbeforeprint  = function(){
				document.body.innerHTML = document.getElementById("content").innerHTML;
			};
			window.onafterprint  = function(){
				document.body.innerHTML = initBody;
			};*/
			document.body.innerHTML = initBody;
			window.print();
		};

	</script>

	
    <script src="/resources/user/_common/plugins/chart/chart-2.9.3.min.js"></script>
<script src="/resources/user/_common/plugins/chart/chartjs-plugin-datalabels.min.js"></script>
<script>
    $(document).ready(function () {
        // 이곳에 정의
        Chart.defaults.global.defaultFontSize = 14;
        Chart.defaults.global.defaultFontFamily =
            '"Noto Sans KR", "Noto Sans CJK KR", "NotoSansKR", "malgun gothic", Arial, "sans-serif"';
        Chart.Legend.prototype.afterFit = function () {
            this.height = this.height + 20;
        };
        var ctx = document.getElementById('myChart-bar-organinfo-0504-type1').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['본청', '소속기관'],
                datasets: [{
                    label: '파주시',
                    data: [897, 964],
                    backgroundColor: [
                        'rgba(89, 135, 238,0.2)',
                        'rgba(129, 183, 44,0.2)',
                    ],
                    borderColor: [
                        'rgba(89, 135, 238,1)',
                        'rgba(129, 183, 44,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '파주시'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });
        var ctx = document.getElementById('myChart-bar-organinfo-0504-type2').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['본청', '소속기관'],
                datasets: [{
                    label: '50만 이상 시 평균',
                    data: [968, 1741],
                    backgroundColor: [
                        'rgba(89, 135, 238,0.2)',
                        'rgba(129, 183, 44,0.2)',
                    ],
                    borderColor: [
                        'rgba(89, 135, 238,1)',
                        'rgba(129, 183, 44,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '50만 이상 시 평균'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        color: '#000000',
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });

        var ctx = document.getElementById('myChart-bar-organinfo-0504-type3').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['본청', '소속기관'],
                datasets: [{
                    label: '50만 이상 시(구청 x) 평균',
                    data: [1036, 1166],
                    backgroundColor: [
                        'rgba(89, 135, 238,0.2)',
                        'rgba(129, 183, 44,0.2)',
                    ],
                    borderColor: [
                        'rgba(89, 135, 238,1)',
                        'rgba(129, 183, 44,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '50만 이상 시(구청 x) 평균'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        color: '#000000',
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });
        var ctx = document.getElementById('myChart-bar-organinfo-0504-type4').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['총원(읍면동 외)', '읍면동'],
                datasets: [{
                    label: '파주시',
                    data: [1395, 466],
                    backgroundColor: [
                        'rgba(45, 172, 187,0.2)',
                        'rgba(255, 204, 0,0.2)',
                    ],
                    borderColor: [
                        'rgba(45, 172, 187,1)',
                        'rgba(255, 204, 0,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '파주시'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        color: '#000000',
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });
        var ctx = document.getElementById('myChart-bar-organinfo-0504-type5').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['총원(읍면동 외)', '읍면동'],
                datasets: [{
                    label: '50만 이상 시 평균',
                    data: [2905, 613],
                    backgroundColor: [
                        'rgba(45, 172, 187,0.2)',
                        'rgba(255, 204, 0,0.2)',
                    ],
                    borderColor: [
                        'rgba(45, 172, 187,1)',
                        'rgba(255, 204, 0,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '50만 이상 시 평균'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        color: '#000000',
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });
        var ctx = document.getElementById('myChart-bar-organinfo-0504-type6').getContext('2d');
        var chart = new Chart(ctx, {
            // The type of chart we want to create
            type: 'bar',

            // The data for our dataset
            data: {
                labels: ['총원(읍면동 외)', '읍면동'],
                datasets: [{
                    label: '50만 이상 시(구청 x) 평균',
                    data: [1670, 532],
                    backgroundColor: [
                        'rgba(45, 172, 187,0.2)',
                        'rgba(255, 204, 0,0.2)',
                    ],
                    borderColor: [
                        'rgba(45, 172, 187,1)',
                        'rgba(255, 204, 0,1)',
                    ],
                    borderWidth: 1,
                    barThickness: 50,
                }]
            },

            // Configuration options go here
            options: {
                responsive: true,
                maintainAspectRatio: false, // https://ncube.net/fix-chartjs-graph-display-error-in-ie11/
                title : {
                    display : true,
                    text : '50만 이상 시(구청 x) 평균'  
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 0,
                        fontSize: 16,
                    }
                },
                plugins: {
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        display: true,
                        color: '#000000',
                        formatter: function(value)
                        {
                            return value.toLocaleString() + '명';
                        },
                    },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            max: 3000,
                        }
                    }],
                    xAxes: [{
                        gridLines: [{
                            display: false,
                        }],
                        ticks: {
                            padding: 10,
                        }
                    }]
                },
            }
        });
    });
</script>

</head>
<body>

<!-- 로그인 -->
<form name="globalLoginForm" id="globalLoginForm" method="post" action="https://www.paju.go.kr/login/user/BD_index.do">
	
			<input type="hidden" name="returnUrl" value="https://www.paju.go.kr/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_04.jsp" />
		
	<input type="submit" style="display:none; " />
</form>

<!--skip navigation-->
<nav id="skip">
	<ul>
		<li><a href="#content">본문 바로가기</a></li>
		<li><a href="#gnb">주메뉴 바로가기</a></li>
		<li><a href="#footer">푸터 바로가기</a></li>
	</ul>
</nav>
<!--//skip navigation-->

<div class="wrapper">
	<!-- header -->
	
	<header id="header" class="header-www">
		
<nav id="top-nav">
    <div class="container">
        <ul class="top-nav-list">
            <!-- [개발] 활성화 탭 li class="on" -->
            <li class="www on"><a href="https://www.paju.go.kr/index.do">파주시청</a></li>
            <li class="news "><a href="https://www.paju.go.kr/news/index.do">파주뉴스</a></li>
            <li class="tour "><a href="https://tour.paju.go.kr/user/tour/main/index.do">문화관광</a></li>
            <li class="mayor "><a href="https://mayor.paju.go.kr/mayor/index.do">On 시장실</a></li>
            <li class="council"><a href="http://www.pajucouncil.go.kr" target="_blank" title="새창">파주시의회</a></li>
        </ul>
        <ul class="member-menu">
            
                    <li><a href="https://www.paju.go.kr/login/user/BD_index.do" class="btn"><i class="ico ico-login-white"></i><span class="text">로그인</span></a></li>
                    <li><a href="https://www.paju.go.kr/user/register/BD_index.do" class="btn"><i class="ico ico-join-white"></i><span class="text">회원가입</span></a></li>
                
        </ul>
    </div>
</nav>

		
	<script type="text/javascript">
		//날씨 정보
		$(function() {
			$.get("/user/miniPrograms/INC_weatherInfo.do",{},
				function(result){
					var temp = "";
					if(result != "" && result != null && result.temperature != ""){
						temp = "<span class='desc'>"+result.temperature+"˚C " ;
						if(result.sky == 1){
							temp += "맑음 ";
						}else if(result.sky == 2){
							temp += "구름조금 ";
						}else if(result.sky == 3){
							temp += "구름많음 ";
						}else{
							temp += "흐림 ";
						}
						temp += "<a href='http://air.rbinsight.co.kr/paju/' target='_blank' title='파주 대기질 모니터링 새창열림' style='display: inline-block;'>미세먼지("+result.pm10Img.split("&")[1]+")";
						temp += "·초미세먼지("+result.pm25Img.split("&")[1]+")</a></span>";
					}
					if(result.issueSituation != "-"){
						temp += " <strong class='title'>"+result.issueSituation+"</strong>";
					}
					$(".air_info").html(temp);
				}
			);
		});
	</script>

<div class="container util-container">
    <h1 id="logo">
        
        	<a href="/www/intropaju/intropaju_03/intropaju_03_01.jsp"><img src="/resources/user/www/images/layout/logo.png" alt="시민중심 더 큰 파주" /></a>
<!--             <a href="/index.do"><img src="/resources/user/www/images/layout/logo@2x.png" alt="한반도 평화수도 PAJU"></a> -->
        
    </h1>
    <a href="#global-search" class="btn btn-search-open">
        <i class="ico ico-search-primary"></i>
        <span class="sr-only">통합검색 열기</span>
    </a>
    <div id="global-search">
        <form action="https://search.paju.go.kr/paju/search/search.jsp" id="konanSearch" name="konanSearch" method="get">
            <fieldset>
                <legend class="sr-only">통합검색</legend>
                <div class="form-group">
                    <select name="category" id="selectCate" class="form-control form-control-option" title="구분">
                        <option value="TOTAL">통합검색</option>
                        <option value="BOARD">웹게시물</option>
                        <option value="WEBPAGE">웹페이지</option>
                        <option value="PERSON">직원/업무</option>
                        <option value="MINWONDOC">민원서식</option>
                        <option value="IMAGE">이미지</option>
                        <option value="EDULEC">교육강좌</option>
                        <option value="TPINFO">관광명소</option>
                    </select>
                    <input type="text" name="kwd" id="konanSearchKwd" class="form-control form-control-keyword" title="검색어입력폼" placeholder="">
                    <button type="submit" class="btn btn-global-search" id="konanSearchBtn">
                        <i class="ico ico-search-primary"></i>
                        <span class="sr-only">검색</span>
                    </button>
                </div>
            </fieldset>
        </form>
        <button type="button" class="btn btn-search-close">
            <i class="ico ico-close-primary"></i>
            <span class="sr-only">통합검색 닫기</span>
        </button>
    </div>
    <div class="util-menu">
        <div class="screen-scale">
            <button type="button" class="btn btn-icon btn-scale-up">
                <i class="ico ico-plus"></i>
                <span class="sr-only">확대</span>
            </button>
            <span class="btn btn-icon btn-scale-default" title="확대/축소">가</span>
            <button type="button" class="btn btn-icon btn-scale-down">
                <i class="ico ico-minus"></i>
                <span class="sr-only">축소</span>
            </button>
        </div>
        <dl class="dropdown-list">
            <dt><button type="button">SNS</button></dt>
            <dd>
                <ul>
                    <li><a href="https://twitter.com/pajusi" target="_blank" title="새창 열림" >트위터</a></li>
                    <li><a href="https://www.facebook.com/hopepaju/" target="_blank" title="새창 열림" >페이스북</a></li>
                    <li><a href="http://blog.naver.com/paju_si" target="_blank" title="새창 열림" >네이버블로그</a></li>
                    <li><a href="https://www.youtube.com/channel/UCicR_6NEnfhLP-FvM4txuUA/featured?view_as=subscriber" target="_blank" title="새창 열림" >유튜브</a></li>
                    <li><a href="https://www.instagram.com/paju_si/" target="_blank" title="새창 열림" >인스타그램</a></li>
                </ul>
            </dd>
        </dl>
        <!-- 언어선택-start -->
        
        <dl class="dropdown-list">
            <dt><button type="button">언어선택</button></dt>
            <dd>
                <ul>
                    <li><a href="#layer-chrome-korea" onclick="openModal('#layer-chrome-korea', this);">Korean</a></li>
                    <li><a href="#layer-chrome-english" onclick="openModal('#layer-chrome-english', this);">English</a></li>
                    <li><a href="#layer-chrome-china" onclick="openModal('#layer-chrome-china', this);">Chinese</a></li>
                    <li><a href="#layer-chrome-japan" onclick="openModal('#layer-chrome-japan', this);">Japanese</a></li>
                </ul>
            </dd>
        </dl>
        <!-- 언어선택-end -->
        <div class="wheather air_info"></div>
    </div>
</div>

	<!-- 20220104 start -->
	<!-- Modal Popup : 크롬웹페이지번역 korea -->
	<div class="modal-popup modal-full hide layer-chrome" id="layer-chrome-korea">
	<div class="dimed"></div>
	<div class="popup-inner">
		<div class="popup-header">
			<h2 class="popup-title">Chrome 웹페이지 번역</h2>
		</div>
		<div class="popup-body">
			<ul class="chrome-list">
				<li>
					<strong>1</strong>
					<p>컴퓨터에서<i class="ico-chrome"></i>Chrome을 엽니다.</p>
				</li>
				<li>
					<strong>2</strong>
					<p>다른 언어로 작성된 웹페이지로 이동합니다.</p>
				</li>
				<li>
					<strong>3</strong>
					<p>주소 표시줄 오른쪽에서 번역<i class="ico-chrome-language"></i>을 클릭합니다.</p>
				</li>
				<li>
					<strong>4</strong>
					<p><i class="ico-chrome"></i>Chrome에서 현재 웹페이지를 번역합니다.</p>
				</li>
				<li>
					<strong>!</strong>
					<p><b>작동하지 않나요?</b> 웹페이지를 새로고침해 보세요. 그래도 작동하지 않을 경우 마우스 오른쪽 버튼으로 페이지의 아무 곳이나 클릭한 다음 <b>[언어]로 번역</b>을 클릭합니다.</p>
				</li>
			</ul>
			<!-- chrome-list end -->
			<p class="ref">
				※ Google Chrome 고객센터
				<a href="https://support.google.com/chrome/answer/173424?hl=ko&co=GENIE.Platform%3DDesktop" target="_blank" title="새창열림" class="text-primary">
					https://support.google.com/chrome/answer/173424?hl=ko&co=GENIE.Platform%3DDesktop
				</a>
			</p>
			<ul class="chrome-img">
				<li><img src="/resources/user/www/images/home/chrome_korea_img01.png" alt="주소 표시줄 오른쪽에서 번역 을 클릭합니다. 작동하지 않나요? 웹페이지를 새로고침해 보세요. 그래도 작동하지 않을 경우 마우스 오른쪽 버튼으로 페이지의 아무 곳이나 클릭한 다음 [언어]로 번역을 클릭합니다." /></li>
			</ul>
		</div>
		<button type="button" class="btn btn-close-popup">닫기</button>
	</div>
</div>
<!-- layer-chrome-korea end -->

<!-- Modal Popup : 크롬웹페이지번역 english -->
<div class="modal-popup modal-full hide layer-chrome" id="layer-chrome-english">
	<div class="dimed"></div>
	<div class="popup-inner">
		<div class="popup-header">
			<h2 class="popup-title">Tanslate webpages in Chrome</h2>
		</div>
		<div class="popup-body">
			<ul class="chrome-list">
				<li>
					<strong>1</strong>
					<p>On your computer, open<i class="ico-chrome"></i>Chrome.</p>
				</li>
				<li>
					<strong>2</strong>
					<p>Go to a webpage written in another language.</p>
				</li>
				<li>
					<strong>3</strong>
					<p>On the right of the address bar, click Translate<i class="ico-chrome-language"></i>.</p>
				</li>
				<li>
					<strong>4</strong>
					<p><i class="ico-chrome"></i>Chrome will translate your current webpage.</p>
				</li>
				<li>
					<strong>!</strong>
					<p><b>Not working?</b> Try refreshing the webpage. If it’s still not working, right-click anywhere on the page. Then, click <b>Translate to [Language].</b></p>
				</li>
			</ul>
			<!-- chrome-list end -->
			<p class="ref">
				※ Google Chrome Help
				<a href="https://support.google.com/chrome/answer/173424?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" title="새창열림" class="text-primary">
					https://support.google.com/chrome/answer/173424?hl=en&co=GENIE.Platform%3DDesktop
				</a>
			</p>
			<ul class="chrome-img">
				<li><img src="/resources/user/www/images/home/chrome_english_img01.png" alt="On the right of the address bar, click Translate, Not working? Try refreshing the webpage. If it’s still not working, right-click anywhere on the page. Then, click Translate to [Language]." /></li>
			</ul>
		</div>
		<button type="button" class="btn btn-close-popup">닫기</button>
	</div>
</div>
<!-- layer-chrome-english end -->

<!-- Modal Popup : 크롬웹페이지번역 china -->
<div class="modal-popup modal-full hide layer-chrome" id="layer-chrome-china">
	<div class="dimed"></div>
	<div class="popup-inner">
		<div class="popup-header">
			<h2 class="popup-title">在 Chrome 中翻译网页</h2>
		</div>
		<div class="popup-body">
			<ul class="chrome-list">
				<li>
					<strong>1</strong>
					<p>在计算机上打开<i class="ico-chrome"></i>Chrome.</p>
				</li>
				<li>
					<strong>2</strong>
					<p>转到一个不是以您要翻译成的目标语言显示的网页.</p>
				</li>
				<li>
					<strong>3</strong>
					<p>在地址栏右侧点击“翻译”图标<i class="ico-chrome-language"></i>.</p>
				</li>
				<li>
					<strong>4</strong>
					<p><i class="ico-chrome"></i>Chrome 即会翻译当前网页.</p>
				</li>
				<li>
					<strong>!</strong>
					<p><b>无法正常翻译?</b> 请尝试刷新该网页。如果仍然无法正常翻译，请右键点击该页面中的任意位置，然后点击 <b>翻成[相应语言]</b>.</p>
				</li>
			</ul>
			<!-- chrome-list end -->
			<p class="ref">
				※ Google Chrome Help
				<a href="https://support.google.com/chrome/answer/173424?hl=zh-Hans&co=GENIE.Platform%3DDesktop" target="_blank" title="새창열림" class="text-primary">
					https://support.google.com/chrome/answer/173424?hl=zh-Hans&co=GENIE.Platform%3DDesktop
				</a>
			</p>
			<ul class="chrome-img">
				<li><img src="/resources/user/www/images/home/chrome_china_img01.png" alt="在地址栏右侧点击“翻译”图标, 无法正常翻译？请尝试刷新该网页。如果仍然无法正常翻译，请右键点击该页面中的任意位置，然后点击翻成[相应语言]" /></li>
			</ul>
		</div>
		<button type="button" class="btn btn-close-popup">닫기</button>
	</div>
</div>
<!-- layer-chrome-china end -->

<!-- Modal Popup : 크롬웹페이지번역 japan -->
<div class="modal-popup modal-full hide layer-chrome" id="layer-chrome-japan">
	<div class="dimed"></div>
	<div class="popup-inner">
		<div class="popup-header">
			<h2 class="popup-title">Chrome でウェブページを翻訳する</h2>
		</div>
		<div class="popup-body">
			<ul class="chrome-list">
				<li>
					<strong>1</strong>
					<p>パソコンで<i class="ico-chrome"></i>Chromeを開きます.</p>
				</li>
				<li>
					<strong>2</strong>
					<p>他の言語で書かれたウェブページにアクセスします.</p>
				</li>
				<li>
					<strong>3</strong>
					<p>アドレスバーの右の翻訳<i class="ico-chrome-language"></i>をクリックします.</p>
				</li>
				<li>
					<strong>4</strong>
					<p><i class="ico-chrome"></i>Chromeで現在のウェブページが翻訳されます.</p>
				</li>
				<li>
					<strong>!</strong>
					<p><b>翻訳されない場合:</b> ウェブページを更新してみてください。それでもうまくいかない場合は、ページのどこかを右クリックし、<b>[(言語)に翻訳]</b>をクリックします.</p>
				</li>
			</ul>
			<!-- chrome-list end -->
			<p class="ref">
				※ Google Chrome Help
				<a href="https://support.google.com/chrome/answer/173424?hl=ja&co=GENIE.Platform%3DDesktop" target="_blank" title="새창열림" class="text-primary">
					https://support.google.com/chrome/answer/173424?hl=ja&co=GENIE.Platform%3DDesktop
				</a>
			</p>
			<ul class="chrome-img">
				<li><img src="/resources/user/www/images/home/chrome_japan_img01.png" alt="アドレスバーの右の翻訳  をクリックします, 翻訳されない場合: ウェブページを更新してみてください。それでもうまくいかない場合は、ページのどこかを右クリックし、[<言語>に翻訳] をクリックします." /></li>
			</ul>
		</div>
		<button type="button" class="btn btn-close-popup">닫기</button>
	</div>
</div><!-- layer-chrome-japan end -->
	<!-- 20220104 end -->
		

<div class="container nav-container">
    <nav id="gnb">
        <ul class="gnb-list">
            
                        <li>
                            <a href="/user/board/BD_board.list.do?bbsCd=1001" title="하위메뉴 닫힘" ><span>소통·참여</span></a>
                            
                                    <div class="lnb" style="display: none;">
                                        <div class="container">
                                            <div class="lnb-title">
                                                <h3>소통·참여</h3>
                                                
                                                        <p>시민과 함께하는<br> 열린 소통공간</p>
                                                        <div class="artwork">
		                                                    <img src="/resources/user/_common/images/artwork-gnb1.png" alt="">
		                                                </div>
                                                    
                                            </div>
                                            <ul class="lnb-list">
                                                
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1001" >
                                                            	<span >시민의 공간</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1001" >자유토크</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1002" >칭찬합시다</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/civil_speak/civil_speak03/civil_speak03_01.jsp" >적극행정</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/miniPrograms/BD_IdeaEpeopleList.do" >
                                                            	<span >시민제안</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="https://www.epeople.go.kr/nep/prpl/selectGnrlPrpslRqstPage.paid" class="new_link bg_top9" target="_blank">일반제안신청하기</a></li>
	                                                                
			                                                            <li><a href="https://www.epeople.go.kr/nep/prpl/selectPbsbsrpnPrpslOngoingList.npaid " class="new_link bg_top9" target="_blank">공모제안신청하기</a></li>
	                                                                
			                                                            <li><a href="/user/miniPrograms/BD_IdeaEpeopleList.do" >국민생각함</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1013" >
                                                            	<span >정책참여</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1013" >주민참여예산</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1016" >주민참여예산 신청</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_03/paticipation_03_01.jsp" >정책토론</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_03/paticipation_03_02.jsp" >전자공청회</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_03/paticipation_03_03.jsp" >설문조사</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/facilityReserve/BD_selectSportsFacilityList.do" >
                                                            	<span >체육시설 대관신청</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/facilityReserve/BD_selectSportsFacilityList.do" >공공체육시설 목록</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_04/paticipation_04_04.jsp" >대관이용안내</a></li>
	                                                                
			                                                            <li><a href="/user/facilityReserve/BD_selectfacilityReserveList.do" >대관신청</a></li>
	                                                                
			                                                            <li><a href="/user/facilityReserve/BD_selectFacilityReserveLotResultList.do" >대관현황</a></li>
	                                                                
			                                                            <li><a href="/user/facilityReserve/BD_selectMyFacilityReserveList.do" >나의 신청현황</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_04/paticipation_04_08.jsp" >체육시설사용료</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9051" >이용수칙 위반자 공개</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/paticipation/advertise/advertise_1.jsp" >
                                                            	<span >옥외광고물 사전협의</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_1.jsp" >옥외광고물이란</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9006" >옥외광고물 알림방</a></li>
	                                                                
			                                                            <li><a href="/user/outdoorAbvrt/BD_outdoorAbvrtList.do" >옥외광고물사전협의신청</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2002&q_parentCtgCd=2033" >업종별간판디자인</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_05.jsp" >거리가 아파요</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_06.jsp" >옥외광고물 디자인지침</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_07.jsp" >파주시 간판시범거리</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_08/advertise_08_01.jsp" >타 시군 간판시범거리</a></li>
	                                                                
			                                                            <li><a href="/www/paticipation/advertise/advertise_09.jsp" >관련 조례</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9015" >관련서식</a></li>
	                                                                
			                                                            <li><a href="/user/freeAdvert/BD_freeAdvertList.do" >인터넷무료광고 신청</a></li>
	                                                                
			                                                            <li><a href="http://www.pjgoaa.or.kr/sub02-01.jsp" class="new_link bg_top9" target="_blank">현수막걸이대 신청</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/paticipation/contest_02/contest_intro/contestIntroduce.jsp" >
                                                            	<span >예쁜간판 공모</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/paticipation/contest_02/contest_intro/contestIntroduce.jsp" >공모전소개</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1032" >공모전소식</a></li>
	                                                                
			                                                            <li><a href="/user/competition/BD_competitionList.do" >공모전 현황</a></li>
	                                                                
			                                                            <li><a href="/user/competition/BD_myCompetitionRequestList.do" >공모전접수확인</a></li>
	                                                                
			                                                            <li><a href="/user/competition/BD_competitionAwardList.do" >공모전수상작 무상신청</a></li>
	                                                                
			                                                            <li><a href="/user/competition/BD_competitionFreeRequestList.do" >무상신청 결과조회</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4002" >간판공모전작품집</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/paticipation/paticipation_09/paticipation_09_01.jsp" >
                                                            	<span >식품업소 자율점검</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/paticipation/paticipation_09/paticipation_09_01.jsp" >식품업소 자율점검 안내</a></li>
	                                                                
			                                                            <li><a href="/user/communication/foodCorp/BD_foodCorpForm.do" >식품업소등록</a></li>
	                                                                
			                                                            <li><a href="/user/communication/foodCorp/BD_foodCorpChkList.do" >식품제조업소 자율점검</a></li>
	                                                                
			                                                            <li><a href="/user/communication/foodCorp/BD_foodHospChkList.do" >식품접객업소 자율점검</a></li>
	                                                                
			                                                            <li><a href="/user/communication/grpMealSvcCorp/BD_grpMealForm.do" >집단급식소등록</a></li>
	                                                                
			                                                            <li><a href="/user/communication/grpMealSvcCorp/BD_grpMealChkList.do" >집단급식소 자율점검</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1006" >
                                                            	<span >저렴해서 가볼만한 곳</span></a>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1036" >
                                                            	<span >나눔장터</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1036" >삽니다</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1037" >팝니다</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1038" >무료</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1039" >교환</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/appDocs/BD_selectMgrList.do?docsSeq=7&q_rowPerPage=8" >
                                                            	<span >온라인신청</span></a>
                                                            
                                                        </li>
                                                    
                                            </ul>
                                        </div>
                                    </div>
                                
                        </li>
                    
                        <li>
                            <a href="/www/petition/petition_01/petition_01_01.jsp" title="하위메뉴 닫힘" ><span>민원</span></a>
                            
                                    <div class="lnb" style="display: none;">
                                        <div class="container">
                                            <div class="lnb-title">
                                                <h3>민원</h3>
                                                
                                                        <p>생활민원 등<br> 종합 행정서비스</p>
							                            <div class="artwork">
							                                <img src="/resources/user/_common/images/artwork-gnb2.png" alt="">
							                            </div>
                                                    
                                            </div>
                                            <ul class="lnb-list">
                                                
                                                        <li>
                                                            <a href="/www/petition/petition_01/petition_01_01.jsp" >
                                                            	<span >종합민원안내</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_01.jsp" >민원실안내</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_02.jsp" >지방세민원실안내</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_03.jsp" >민원수수료</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_04.jsp" >무인민원발급</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_06/petition_01_06_02.jsp" >편리한 민원시책</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_01/petition_01_07.jsp" >행정정보공동이용</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/complaintsForm/BD_index.do" >
                                                            	<span >민원편람·서식</span></a>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/petition_03/petition_03_01.jsp" >
                                                            	<span >온라인 민원</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_03/petition_03_02.jsp" class="new_link bg_top9" target="_blank">민원상담신청</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_03/petition_03_03.jsp" class="new_link bg_top9" target="_blank">접수민원조회</a></li>
	                                                                
			                                                            <li><a href="https://eminwon.paju.go.kr/emwp/gov/mogaha/ntis/web/emwp/cns/action/EmwpCnslWebAction.do?method=selectCnslWebPage&menu_id=EMWPCnslWebInqL&jndinm=EmwpCnslWebEJB&methodnm=selectCnslWebPage&context=NTIS" class="new_link bg_top9" target="_blank">(구)민원조회</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_03/petition_03_01.jsp" >정부24(인터넷민원발급)</a></li>
	                                                                
			                                                            <li><a href="https://eminwon.paju.go.kr/emwp/gov/mogaha/ntis/web/caf/mwwd/action/CafMwWdOpenAction.do?method=selectListMwOpn&amp;menu_id=CAFOPNWebMwOpenL&amp;jndinm=CafMwWdOpenEJB&amp;methodnm=selectListMwOpn&amp;context=NTIS" class="new_link bg_top9" target="_blank">민원처리공개</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/petition_09/petition_09_01.jsp" >
                                                            	<span >시민고충처리위원회</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_09/petition_09_01.jsp" >위원회 소개</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9040" >위원회 자료실</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9059" >고충민원 신청</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/petition_04/petition_04_01.jsp" >
                                                            	<span >여권민원</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_04/petition_04_01.jsp" >여권민원접수</a></li>
	                                                                
			                                                            <li><a href="http://www.passport.go.kr" class="new_link bg_top9" target="_blank">여권민원안내</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/petition_05/petition_05_01/petition_05_01_01.jsp" >
                                                            	<span >차량종합민원</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_09.jsp" >자동차 온라인 민원</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_01/petition_05_01_01.jsp" >자동차등록</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_02.jsp" >이륜차등록</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_03.jsp" >건설기계등록</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_04.jsp" >차량세금과태료</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_05/petition_05_05.jsp" >차량등록사업소 위치</a></li>
	                                                                
			                                                            <li><a href="http://car.paju.go.kr " class="new_link bg_top9" target="_blank">주정차민원</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/petition_08/petition_08_03/petition_08_03_01.jsp" >
                                                            	<span >민원신고센터</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_03/petition_08_03_01.jsp" >규제개혁</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_02.jsp" class="new_link bg_top9" target="_blank">부정/불량식품신고</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_05.jsp" class="new_link bg_top9" target="_blank">부동산 불법거래 신고센터</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_06.jsp" class="new_link bg_top9" target="_blank">주정차위반신고</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_07.jsp" class="new_link bg_top9" target="_blank">무단쓰레기신고</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_08.jsp" class="new_link bg_top9" target="_blank">불법유동광고물신고</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_10.jsp" class="new_link bg_top9" target="_blank">관급공사체불신고</a></li>
	                                                                
			                                                            <li><a href="/www/petition/petition_08/petition_08_14.jsp" class="new_link bg_top9" target="_blank">장애인전용 주차구역 신고</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/petition/upright/petition_08_01.jsp" >
                                                            	<span >청렴신고센터</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/petition/upright/petition_08_01.jsp" >공직자부조리신고센터</a></li>
	                                                                
			                                                            <li><a href="https://www.kbei.org/helpline/paju" class="new_link bg_top9" target="_blank">파주시청 헬프라인</a></li>
	                                                                
			                                                            <li><a href="/www/petition/upright/petition_08_12.jsp" >공익신고 안내</a></li>
	                                                                
			                                                            <li><a href="https://www.epeople.go.kr/nep/pttn/gjDmgPttn/gjDmgReportCenterContent.npaid" class="new_link bg_top9" target="_blank">갑질피해 신고센터</a></li>
	                                                                
			                                                            <li><a href="/www/petition/upright/petition_08_13/petition_08_13_01.jsp" >청탁금지법 안내</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                            </ul>
                                        </div>
                                    </div>
                                
                        </li>
                    
                        <li>
                            <a href="/user/board/BD_board.list.do?bbsCd=9025" title="하위메뉴 닫힘" ><span>정보공개</span></a>
                            
                                    <div class="lnb" style="display: none;">
                                        <div class="container">
                                            <div class="lnb-title">
                                                <h3>정보공개</h3>
                                                
                                                        <p>시민의 알 권리,<br> 정보의 투명성 보장</p>
                                                        <div class="artwork">
                                                            <img src="/resources/user/_common/images/artwork-gnb3.png" alt="">
                                                        </div>
                                                    
                                            </div>
                                            <ul class="lnb-list">
                                                
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=9025" >
                                                            	<span >주요시책</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9025" >주요사업 추진현황</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1010" >시정현안 문답풀이</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4005" >시정백서</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_02/open_info_02_01/open_info_02_01_01.jsp" >정책실명제</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/open_info/open_info_03/open_info_03_01/open_info_03_01_01.jsp" >
                                                            	<span >입찰계약공개</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_03/open_info_03_01/open_info_03_01_01.jsp" >발주계획</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_03/open_info_03_02/open_info_03_02_01.jsp" >입찰정보</a></li>
	                                                                
			                                                            <li><a href="/user/gopen/BD_situationList.do?section=1001" >계약정보</a></li>
	                                                                
			                                                            <li><a href="/user/gopen/BD_paymentList.do" >대금지급</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9010" >계약공지사항</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4006" >
                                                            	<span >예산/재정정보</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4006" >예산서</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_04/open_info_04_understanding.jsp" >예산이해</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_04/open_info_04_scale.jsp" >예산규모</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1015" >재정공시의견</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1014" >재정공시</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1017" >지방채관리계획</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1018" >업무추진비</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4008" >연도별결산서</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_04/open_info_04_11/open_info_04_11_01.jsp" >공유재산관리</a></li>
	                                                                
			                                                            <li><a href="https://www.cleaneye.go.kr/user/itemGongsi.do" class="new_link bg_top9" target="_blank">지방공기업</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_04/open_info_04_13.jsp" >학술용역 결과공개</a></li>
	                                                                
			                                                            <li><a href="http://lofin.mois.go.kr/websquare/websquare.jsp?w2xPath=/ui/portal/stat/local/budget/sd002_bg204.xml" class="new_link bg_top9" target="_blank">지방재정365</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9047" >민간투자사업</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/open_info/open_info_05/open_info_05_open.jsp" >
                                                            	<span >공공데이터개방</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_05/open_info_05_open.jsp" >공공데이터개방</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_05/open_info_05_02.jsp" >공공데이터개방목록</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9046" >공공데이터 상시 수요조사</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/open_info/open_info_06/open_info_06_using.jsp" >
                                                            	<span >정보공개청구</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_06/open_info_06_using.jsp" >이용안내</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1020" >사전정보공표</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_06/open_info_06_04.jsp" >비공개목록</a></li>
	                                                                
			                                                            <li><a href="/user/miniPrograms/BD_wonmunInfoList.do" >정보목록</a></li>
	                                                                
			                                                            <li><a href="/user/violation/BD_violationList.do" >위반업소공개</a></li>
	                                                                
			                                                            <li><a href="/www/open_info/open_info_06/open_info_06_07.jsp" >행정정보소재안내</a></li>
	                                                                
			                                                            <li><a href="/user/miniPrograms/BD_openInfoList.do" >행정정보목록검색</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2003&q_ctgCd=3003" >정보공개자료실</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=9026" >
                                                            	<span >감사정보공개</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9026" >감사사전예고</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9027" >감사결과</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9028" >부패공직자 제재현황</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9029" >감사관련정보</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=2003&q_ctgCd=3002" >
                                                            	<span >공개자료실</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2003&q_ctgCd=3002" >부서별 공개자료실</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9036" >석면해체제거작업 공개</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9039" >공무국외여행정보 등</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/statistics/vehicle/BD_vehicleInfo.do?q_menuCd=1001&ctgry=C" >
                                                            	<span >통계정보</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="http://stat.paju.go.kr/content.do?key=2207251326841" class="new_link bg_top9" target="_blank">인구/가구통계</a></li>
	                                                                
			                                                            <li><a href="/user/statistics/vehicle/BD_vehicleInfo.do?q_menuCd=1001&ctgry=C" >차량등록통계</a></li>
	                                                                
			                                                            <li><a href="http://stat.paju.go.kr/ " class="new_link bg_top9" target="_blank">파주시통계</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9041" >공무원통계</a></li>
	                                                                
			                                                            <li><a href="https://www.gg.go.kr/gg_special_cop/graphic_crime_typea" class="new_link bg_top9" target="_blank">경기도 민생범죄통계</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="https://www.elis.go.kr/locgovalr/locgovSeAlrList?ctpvCd=41&sggCd=510" target="_blank">
                                                            	<span class="new_link">자치법규</span></a>
                                                            
                                                        </li>
                                                    
                                            </ul>
                                        </div>
                                    </div>
                                
                        </li>
                    
                        <li>
                            <a href="/user/board/BD_board.list.do?bbsCd=2001" title="하위메뉴 닫힘" ><span>공고·홍보</span></a>
                            
                                    <div class="lnb" style="display: none;">
                                        <div class="container">
                                            <div class="lnb-title">
                                                <h3>공고·홍보</h3>
                                                
                                                        <p>다양한 시정소식과<br> 홍보자료 공간</p>
                                                        <div class="artwork">
							                                <img src="/resources/user/_common/images/artwork-gnb4.png" alt="">
							                            </div>
                                                    
                                            </div>
                                            <ul class="lnb-list">
                                                
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=2001" >
                                                            	<span >시정소식</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2001" >새소식</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=9060" >주요업무일정</a></li>
	                                                                
			                                                            <li><a href="http://tour.paju.go.kr/user/link/cultural/BD_index.do" class="new_link bg_top9" target="_blank">이달의문화행사</a></li>
	                                                                
			                                                            <li><a href="https://www.paju.go.kr/news/index.do" class="new_link bg_top9" target="_blank">파주뉴스(뉴스포털)</a></li>
	                                                                
			                                                            <li><a href="/www/news/rectify_news/rectify_news_04/rectify_news_04_03.jsp" >파주시 코로나19 정보</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4062" >
                                                            	<span >공고·입법예고</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4062" >입법예고</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4063" >고시공고</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4064" >채용공고</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4065" >도시계획열람공고</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1022&q_ctgCd=4066" >무연분묘개장공고</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=1023&q_ctgCds=5226,5227" >
                                                            	<span >보도·미디어</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1023&q_ctgCds=5226,5227,5229" >보도자료</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=1024" >설명자료</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4003" >
                                                            	<span >시정·관광 홍보자료실</span></a>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/user/miniPrograms/BD_GgReport.do" >
                                                            	<span >도정소식</span></a>
                                                            
                                                        </li>
                                                    
                                            </ul>
                                        </div>
                                    </div>
                                
                        </li>
                    
                        <li>
                            <a href="/user/organization/BD_pajuList.do" title="하위메뉴 닫힘" id="currentTopMenu"><span>파주소개</span></a>
                            
                                    <div class="lnb" style="display: none;">
                                        <div class="container">
                                            <div class="lnb-title">
                                                <h3>파주소개</h3>
                                                
                                                        <p>살고 싶은 도시,<br> 기업이 편한 파주</p>
                                                        <div class="artwork">
							                                <img src="/resources/user/_common/images/artwork-gnb5.png" alt="">
							                            </div>
                                                    
                                            </div>
                                            <ul class="lnb-list">
                                                
                                                        <li>
                                                            <a href="/user/organization/BD_pajuList.do" >
                                                            	<span >시청안내</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/organization/BD_pajuList.do" >조직도</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_01.jsp" >조직정보</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intro_cityhall/intro_cityhall_02.jsp" >청사안내</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intro_cityhall/intro_cityhall_03/intro_cityhall_03_01.jsp" >찾아오시는 길</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intro_cityhall/intro_cityhall_04/intro_cityhall_04_01.jsp" >주차장안내</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intro_cityhall/intropaju_06_01.jsp" >파주시연혁</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/intropaju/intropaju_02/intropaju_02_01.jsp" >
                                                            	<span >파주의 상징</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_02/intropaju_02_01.jsp" >시의 상징</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_02/intropaju_02_02.jsp" >상징물</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_02/intropaju_02_03.jsp" >평화도시</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_02/intropaju_02_04.jsp" >파주의 노래</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/intropaju/intropaju_03/intropaju_03_01.jsp" >
                                                            	<span >일반현황</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_03/intropaju_03_01.jsp" >파주비전</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_03/intropaju_03_02.jsp" >행정구역현황</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/intropaju/intropaju_04/intropaju_04_01/intropaju_04_01_01.jsp" >
                                                            	<span >자매·교류도시</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_04/intropaju_04_01/intropaju_04_01_01.jsp" >국내자매도시</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_04/intropaju_04_04/intropaju_04_04_01.jsp" >국내교류도시</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_04/intropaju_04_02/intropaju_04_02_01.jsp" >국외자매도시</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_04/intropaju_04_03/intropaju_04_03_02.jsp" >국외교류도시</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/intropaju/intropaju_05/intropaju_05_01.jsp" >
                                                            	<span >시민이용시설</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_01.jsp" >읍면동 주민자치센터</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_02.jsp" >재난재해대피시설</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_03.jsp" >파주시노인복지관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_04.jsp" >파주시장애인종합복지관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_05.jsp" >파주시가족센터</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_06.jsp" >청소년수련시설현황</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_07.jsp" >문산종합사회복지관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_09.jsp" >청소년상담복지센터</a></li>
	                                                                
			                                                            <li><a href="https://lib.paju.go.kr" class="new_link bg_top9" target="_blank">도서관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_11/intropaju_05_11_01.jsp" >평생학습관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_12/intropaju_05_12_01.jsp" >시민회관</a></li>
	                                                                
			                                                            <li><a href="https://www.paju.go.kr/user/facilityReserve/BD_selectSportsFacilityList.do" >공공체육시설</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_18/intropaju_05_18_01.jsp" >학교 개방형 다목적 체육관</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_14/intropaju_05_14_09.jsp" >공영주차장</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_15.jsp" >공중화장실설치현황</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_16.jsp" >파주진로체험지원센터</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_05/intropaju_05_17.jsp" >파주시육아종합지원센터</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                                        <li>
                                                            <a href="/www/intropaju/intropaju_06/intropaju_06_01.jsp" >
                                                            	<span >파주의 역사</span></a>
                                                            
	                                                            <ul>
	                                                                
			                                                            <li><a href="/user/mayorHistory/BD_mayorHistoryList.do?q_ofcpsCode=1001" >역대시장/군수</a></li>
	                                                                
			                                                            <li><a href="/www/intropaju/intropaju_06/intropaju_06_06/intropaju_06_06_01.jsp" >파주유래</a></li>
	                                                                
			                                                            <li><a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4004" >파주시지</a></li>
	                                                                
                                                                </ul>
                                                            
                                                        </li>
                                                    
                                            </ul>
                                        </div>
                                    </div>
                                
                        </li>
                    
                        <li>
                            <a href="#" class="gnb_feild" title="하위메뉴 닫힘" ><span>분야별정보</span></a>
                            <div class="lnb" style="display: none;">
                                <div class="container">
                                    <div class="lnb-title">
                                        <h3>분야별 정보</h3>
                                        <p>시민과 함께하는<br> 열린 소통공간</p>
                                        <div class="artwork">
                                            <img src="/resources/user/_common/images/artwork-gnb6.png" alt="">
                                        </div>
                                    </div>
                                    <div class="part-menu-wrap">
                                        <ul class="part-list">
                                            
	                                            <li ><a href="#part-content1">청년/복지</a></li>
                                            
	                                            <li ><a href="#part-content2">기업/경제/일자리</a></li>
                                            
	                                            <li ><a href="#part-content3">도시개발</a></li>
                                            
	                                            <li ><a href="#part-content4">부동산/주택</a></li>
                                            
	                                            <li ><a href="#part-content5">재정/지방세</a></li>
                                            
	                                            <li ><a href="#part-content6">환경/교통</a></li>
                                            
	                                            <li ><a href="#part-content7">건강/보건/위생</a></li>
                                            
	                                            <li ><a href="#part-content8">행정/감사/기획</a></li>
                                            
                                        </ul>

                                        
                                            <div class="part-content" id="part-content1">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_01.jsp" ><span >청년</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_01.jsp" class="lnb_ellipsis"><span>청년 네트워크 운영</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_02.jsp" class="lnb_ellipsis"><span>청년 취·창업 지원</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_03.jsp" class="lnb_ellipsis"><span>파주시 청년드림옷장</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_04.jsp" class="lnb_ellipsis"><span>청년 주거 지원</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_05.jsp" class="lnb_ellipsis"><span>청년기본소득</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_10/www_02_01_10_06.jsp" class="lnb_ellipsis"><span>청년공간GP1934</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_01.jsp" ><span >희망복지</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>기초생활보장</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_01.jsp" >생계급여</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_02/www_02_01_01_01_02_01.jsp" >의료급여</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_03.jsp" >주거급여</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_04.jsp" >교육급여</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01/www_02_01_01_01_06.jsp" >해산·장제급여</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>긴급지원제도</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_02/www_02_01_01_02_01.jsp" >긴급지원제도</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_02/www_02_01_01_02_03/www_02_01_01_02_03_01.jsp" >파주시 긴급복지 핫라인</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_04.jsp" class="lnb_ellipsis"><span>나눔문화</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://www.happyms.or.kr/ " class="lnb_ellipsis " target="_blank"><span>시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_01_05.jsp" class="lnb_ellipsis"><span>자활사업</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>파주희망캠프</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_06/www_02_01_01_06_01.jsp" >프로그램 안내</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/dmz/program/BD_dmzProgramRequestCal.do" >프로그램 신청·확인</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_06/www_02_01_01_06_03.jsp" >재능기부 신청·확인</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_01/www_02_01_01_07.jsp" class="lnb_ellipsis"><span>보훈</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_01.jsp" ><span >노인</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_01.jsp" class="lnb_ellipsis"><span>정책</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_02/www_02_01_02_02_01.jsp" >저소득(독거)노인지원사업</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_02/www_02_01_02_02_02.jsp" >사회활동참여사업</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_02/www_02_01_02_02_03.jsp" >경로당지원사업</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_03/www_02_01_02_03_01/www_02_01_02_03_01_01.jsp" >파주시노인복지관</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_welfareSections=Y" >노인복지시설</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>파주 실버경찰대</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_04/www_02_01_02_04_01.jsp" >연혁 및 구성</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_02/www_02_01_02_04/www_02_01_02_04_02.jsp" >운영규정</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2016" >활동사진</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_03/www_02_01_03_01.jsp" ><span >장애인</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/www_02_01/www_02_01_03/www_02_01_03_01.jsp" class="lnb_ellipsis"><span>정책</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_03/www_02_01_03_02/www_02_01_03_02_01/www_02_01_03_02_01_01.jsp" >생활안정지원</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_03/www_02_01_03_02/www_02_01_03_02_02/www_02_01_03_02_02_01.jsp" >의료지원</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_03/www_02_01_03_02/www_02_01_03_02_03/www_02_01_03_02_03_01.jsp" >복지지원</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="http://www.ableservice.or.kr/" target="_blank">장애인활동지원</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_fcltyClCode=1003" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_04/www_02_01_04_02/www_02_01_04_02_01.jsp" ><span >가족</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="http://www.mogef.go.kr/mp/pcf/mp_pcf_f001.do" class="lnb_ellipsis " target="_blank"><span>정책</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_04/www_02_01_04_02/www_02_01_04_02_01.jsp" >한부모가족</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_04/www_02_01_04_02/www_02_01_04_02_02.jsp" >청소년부모</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="https://www.familynet.or.kr/" target="_blank">건강가정지원센터</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_fcltyClCode=1004" >가족복지시설</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="https://www.liveinkorea.kr/center/main/main.do?centerId=pajusi" target="_blank">파주시가족센터</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_05/www_02_01_05_03/www_02_01_05_03_01.jsp" ><span >여성</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_05/www_02_01_05_03/www_02_01_05_03_01.jsp" >여성안심택배함</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_05/www_02_01_05_03/www_02_01_05_03_02.jsp" >보건위생물품 무료자판기</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_fcltyClCode=1005" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_06/www_02_01_06_02/www_02_01_06_02_01.jsp" ><span >아동·청소년</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_06/www_02_01_06_02/www_02_01_06_02_01.jsp" >아동학대 예방사업</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_06/www_02_01_06_02/www_02_01_06_02_02.jsp" >결식아동급식지원</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="https://www.dreamstart.go.kr/paju/" target="_blank">드림 스타트</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="https://online.bokjiro.go.kr/apl/info/aplInfoApplChldAlw.do" target="_blank">아동수당</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_fcltyClCode=1006" >아동·청소년복지시설</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_06/www_02_01_06_03/www_02_01_06_03_03.jsp" >지역아동센터</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_07/www_02_01_07_02/www_02_01_07_02_01.jsp" ><span >보육</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_07/www_02_01_07_02/www_02_01_07_02_01.jsp" >다자녀 가정 지원사업</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="http://www.pajuscc.or.kr/" target="_blank">파주시육아종합지원센터</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_07/www_02_01_07_02/www_02_01_07_02_03.jsp" >보육료·양육수당</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="http://www.childcare.go.kr/" target="_blank">어린이집</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=Y&q_fcltyClCode=1007" >보육복지시설</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_01/www_02_01_08_01_01/www_02_01_08_01_01_01.jsp" ><span >장사</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>정책</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_01/www_02_01_08_01_01/www_02_01_08_01_01_01.jsp" >관련법령</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_01/www_02_01_08_01_02/www_02_01_08_01_02_01.jsp" >동화경모공원</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/user/board/BD_board.list.do?bbsCd=1035" >장사전용공고</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>시설</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_01.jsp" >묘지(공설)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_02.jsp" >묘지(법인)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_03.jsp" >묘지(종교)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_04.jsp" >묘지(공동)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_05.jsp" >봉안시설(당,묘,탑)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/www_02_01/www_02_01_08/www_02_01_08_02/www_02_01_08_02_06.jsp" >장례식장</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/www_02_01_09_01/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1002" ><span >뉴스·자료실</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/www_02_01_09_01/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1002" class="lnb_ellipsis"><span>복지소식</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=2003&q_parentCtgCd=3004" class="lnb_ellipsis"><span>자료실</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2009" class="lnb_ellipsis"><span>갤러리</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/welfareFacility/BD_welfareList.do?q_welfareSection=N" class="lnb_ellipsis"><span>사회복지시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content2">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=9052" ><span >기업</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=9052" class="lnb_ellipsis"><span>기업지원 사업공고</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="https://www.paju.go.kr/webcontent/ckeditor/2022/7/6/6496cb08-1208-41b8-8cca-d0e839769c43.pdf" class="lnb_ellipsis " target="_blank"><span>기업지원 시책 안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_company/industry_company_07/industry_company_07_01.jsp" class="lnb_ellipsis"><span>공장설립</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://pajucci.korcham.net/corporate" class="lnb_ellipsis " target="_blank"><span>기업정보검색</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_company/industry_company_09.jsp" class="lnb_ellipsis"><span>안심사업장</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=1026" ><span >경제</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=1026" class="lnb_ellipsis"><span>물가정보</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_01/industry_01_02.jsp" class="lnb_ellipsis"><span>소비자상담센터</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_01/industry_01_03.jsp" class="lnb_ellipsis"><span>가격표시제안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_01/industry_company_02.jsp" class="lnb_ellipsis"><span>전통시장안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_01/industry_01_06.jsp" class="lnb_ellipsis"><span>지역화폐(파주페이)</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/industry/industry_02/industry_02_08.jsp" ><span >일자리</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_02/industry_02_08.jsp" class="lnb_ellipsis"><span>일자리 유관기관 목록</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_02/industry_02_01.jsp" class="lnb_ellipsis"><span>일자리센터안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_02/industry_02_02.jsp" class="lnb_ellipsis"><span>취업지원 프로그램</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_02/industry_02_03.jsp" class="lnb_ellipsis"><span>일자리 채용행사안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=1027" class="lnb_ellipsis"><span>금주의 취업정보</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>공공일자리사업</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/industry/industry_02/industry_02_05/industry_02_05_01.jsp" >공공근로사업</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/industry/industry_02/industry_02_05/industry_02_05_02.jsp" >지역공동체일자리</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/industry/industry_02/industry_02_05/industry_02_05_03.jsp" >파주형 희망일자리</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/industry/industry_atr/industry_atr_01.jsp" ><span >투자유치</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_atr/industry_atr_01.jsp" class="lnb_ellipsis"><span>파주시 투자여건</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_atr/industry_atr_02.jsp" class="lnb_ellipsis"><span>캠프하우즈 개발사업</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/industry/industry_atr/industry_atr_03.jsp" class="lnb_ellipsis"><span>미개발 반환공여지 현황</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2025" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/industry_04/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1005" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content3">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/www_02/city/city_01/city_01_01.jsp" ><span >신도시</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/city/city_01/city_01_01.jsp" class="lnb_ellipsis"><span>운정 1,2지구</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/city/city_01/city_01_02.jsp" class="lnb_ellipsis"><span>운정3지구</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=2003&q_ctgCd=3032" class="lnb_ellipsis"><span>자료실</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/city/city_02/city_02_01.jsp" ><span >도시계획</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/city/city_02/city_02_01.jsp" class="lnb_ellipsis"><span>교하지구</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/city/city_02/city_02_02.jsp" class="lnb_ellipsis"><span>금촌지구</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=2003&q_ctgCd=3033" class="lnb_ellipsis"><span>자료실</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/city/city_indus/city_indus_00/city_indus_00_01.jsp" ><span >산업단지</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>산업단지 안내</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_00/city_indus_00_01.jsp" >산업단지 소개</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_00/city_indus_00_02.jsp" >입주계약 및 공장등록</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>국가산업단지</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_01/city_indus_01_0.jsp" >파주출판문화정보</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_01/city_indus_01_02.jsp" >파주탄현중소기업전용</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>LCD클러스터산업단지</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_02/city_indus_02_01.jsp" >LCD일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_02/city_indus_02_02.jsp" >월롱일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_02/city_indus_02_03.jsp" >당동일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_02/city_indus_02_04.jsp" >선유일반산업단지</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>일반산업단지</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_01.jsp" >문발Ⅰ일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_02.jsp" >문발Ⅱ일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_03.jsp" >오산일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_04.jsp" >탄현일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_05.jsp" >신촌일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_07.jsp" >축현일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_08.jsp" >금파일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_09.jsp" >법원1일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_10.jsp" >법원2일반산업단지</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/city/city_indus/city_indus_03/city_indus_03_06.jsp" >적성일반산업단지</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2026" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/city_07/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1006" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content4">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/www_02/housing/housing_property/housing_property_01.jsp" ><span >부동산</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/housing/housing_property/housing_property_01.jsp" class="lnb_ellipsis"><span>중개수수료 안내</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/housing/housing_property/housing_property_03.jsp" class="lnb_ellipsis"><span>공시지가</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>도로명주소 안내</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/housing/housing_property/housing_property_04/housing_property_04_01.jsp" >도로명주소 안내</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="http://www.juso.go.kr/openIndexPage.do" target="_blank">도로명주소안내시스템</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://klis.gg.go.kr/sis/main.do" class="lnb_ellipsis " target="_blank"><span>토지정보시스템</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/housing/housing_build/housing_build_01.jsp" ><span >건설</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/housing/housing_build/housing_build_01.jsp" class="lnb_ellipsis"><span>도로시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://www.eais.go.kr/" class="lnb_ellipsis " target="_blank"><span>건축행정시스템</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=1028" ><span >주택</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=1028" class="lnb_ellipsis"><span>분양정보</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://kras.gg.go.kr/land_info/info/landprice/landprice.do" class="lnb_ellipsis " target="_blank"><span>개별공시지가 열람</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="https://www.realtyprice.kr:447/notice/town/nfSiteLink.htm" class="lnb_ellipsis " target="_blank"><span>공동주택가격</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://rt.molit.go.kr/" class="lnb_ellipsis " target="_blank"><span>아파트실거래가공개</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2027" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/housing_03/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1007" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content5">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4006" ><span >예산</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4006" class="lnb_ellipsis"><span>예산서</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/open_info/open_info_04/open_info_04_scale.jsp" class="lnb_ellipsis"><span>예산규모</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=1013" class="lnb_ellipsis"><span>주민참여예산</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/finance/budget/budget_04.jsp" class="lnb_ellipsis"><span>예산낭비신고</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/user/board/BD_board.list.do?bbsCd=1014" ><span >재정</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=1014" class="lnb_ellipsis"><span>재정공시</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=1015" class="lnb_ellipsis"><span>재정공시의견</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=1017" class="lnb_ellipsis"><span>지방채관리계획</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="https://www.cleaneye.go.kr/" class="lnb_ellipsis " target="_blank"><span>지방공기업</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/finance/finance_03/finance_03_intro.jsp" ><span >지방세</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/finance/finance_03/finance_03_intro.jsp" class="lnb_ellipsis"><span>지방세개요</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://www.wetax.go.kr" class="lnb_ellipsis " target="_blank"><span>지방세 납부방법</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4007" class="lnb_ellipsis"><span>지방세 가이드</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/finance/finance_03/finance_03_03.jsp" class="lnb_ellipsis"><span>납세자보호관 제도</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/finance/finance_03/finance_03_04.jsp" class="lnb_ellipsis"><span>마을세무사</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="http://water-pos.kwater.or.kr/index.jsp" target="_blank"><span class="new_link bg_top5">수도요금조회</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2028" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/fin_news/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1008" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content6">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/www_02/environment/environment_01/environment_01_01/environment_01_01_01.jsp" ><span >환경</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_01/environment_01_01_01.jsp" class="lnb_ellipsis"><span>수질환경</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_02.jsp" class="lnb_ellipsis"><span>토양환경</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_03/environment_01_03_01.jsp" class="lnb_ellipsis"><span>자연환경</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_04.jsp" class="lnb_ellipsis"><span>파주시 환경관리센터</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_05.jsp" class="lnb_ellipsis"><span>운정환경관리센터</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_01/environment_01_06.jsp" class="lnb_ellipsis"><span>파주환경순환센터</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/environment/environment_02/environment_02_01/environment_02_01_03/environment_02_01_03_01.jsp" ><span >공원녹지</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>공원현황</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_02/environment_02_01/environment_02_01_03/environment_02_01_03_01.jsp" >공원녹지 관리현황</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_02/environment_02_05.jsp" class="lnb_ellipsis"><span>물놀이 수경시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/environment/environment_03/environment_03_01/environment_03_01_01.jsp" ><span >대기</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_03/environment_03_01/environment_03_01_01.jsp" class="lnb_ellipsis"><span>대기환경</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/miniPrograms/BD_airInfo.do" class="lnb_ellipsis"><span>대기오염</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_03/environment_03_03/environment_03_03_01.jsp" class="lnb_ellipsis"><span>폐수처리시설</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_03/environment_03_05/environment_03_05_01.jsp" class="lnb_ellipsis"><span>환경법규/배출시설기준</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/environment/environment_04/environment_04_01/environment_04_01_01.jsp" ><span >상하수도</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>상하수 시설현황</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_01/environment_04_01_01.jsp" >상수시설</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_01/environment_04_01_02.jsp" >하수시설</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>상하수 시설공사</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_02/environment_04_02_02.jsp" >하수시설(신설)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_02/environment_04_02_03.jsp" >하수시설(증설)</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/complaintsForm/BD_index.do?q_sn=&q_tyCode=16&q_searchKey=&q_searchVal=&q_currPage=1&q_sortName=&q_sortOrder=" class="lnb_ellipsis"><span>상하수 민원사무</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_04/environment_04_04.jsp" class="lnb_ellipsis"><span>상하수 요금</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>상수도 정보</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_05/environment_04_05_01.jsp" >수질평가, 수질안심확인</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_05/environment_04_05_02.jsp" >상수도 급수공사 대행업체</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_05/environment_04_05_03.jsp" >급수공사</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_05/environment_04_05_04.jsp" >누수확인 요령 및 감면</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_05/environment_04_05_05.jsp" >정수장견학</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_04/environment_04_07.jsp" class="lnb_ellipsis"><span>하수관로정비사업</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>하수도 정보</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_08/environment_04_08_01.jsp" >원인자부담금</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_08/environment_04_08_02.jsp" >하수상식</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_08/environment_04_08_03.jsp" >개인하수도관리</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_08/environment_04_08_04.jsp" >하수도원가정보공개</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_04/environment_04_08/environment_04_08_05.jsp" >정화조 청소 수수료</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_01.jsp" ><span >폐기물</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>생활쓰레기(폐기물)</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_01.jsp" >생활쓰레기(폐기물)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_02.jsp" >재활용품 배출요령</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_03.jsp" >과태료 부과기준</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_04.jsp" >대형폐기물 배출</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/environment_05/environment_05_01/environment_05_01_05.jsp" >종량제봉투</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_05/environment_05_02.jsp" class="lnb_ellipsis"><span>농촌폐기물</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_05/environment_05_03.jsp" class="lnb_ellipsis"><span>건설폐기물처리</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_05/environment_05_04.jsp" class="lnb_ellipsis"><span>석면폐기물처리</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_05/environment_05_05.jsp" class="lnb_ellipsis"><span>사업장폐기물처리</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/environment_05/environment_05_06.jsp" class="lnb_ellipsis"><span>1회용품 사용규제</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_01/traffic_safe_traffic_01_01.jsp" ><span >교통</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>버스</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_01/traffic_safe_traffic_01_01.jsp" >교통정보센터안내</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="https://traffic.paju.go.kr/bus_route.view" target="_blank">버스노선검색</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>자동차 종합검사</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_05/traffic_safe_traffic_05_01.jsp" >자동차 종합검사</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="https://www.kotsa.or.kr/car/carTest.do?menuCode=04050200" target="_blank">자동차검사(SMS)신청</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_06.jsp" class="lnb_ellipsis"><span>자동차 의무보험</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>자전거/개인형이동장치(PM)</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_07/traffic_safe_traffic_07_01.jsp" >자전거판매/수리점</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_07/traffic_safe_traffic_07_02.jsp" >자전거 안전하게 타기</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_07/traffic_safe_traffic_07_03.jsp" >개인형 이동장치 안전하게 타기</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_08/traffic_safe_traffic_08_01.jsp" class="lnb_ellipsis"><span>주정차위반</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_09/traffic_safe_traffic_09_01.jsp" class="lnb_ellipsis"><span>공영주차장</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/environment/traffic_safe_traffic/traffic_safe_traffic_10.jsp" class="lnb_ellipsis"><span>광역교통망계획</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2029" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/environment_07/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1009" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content7">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/www/www_02/health/healthy/healthy_03/healthy_03_01.jsp" ><span >건강</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li class="lnb_5dep">
				                                                           		<a href="#" class="lnb_ellipsis"><span>금연클리닉</span></a>

				                                                           		
				                                                           				<ul>
						                                                           		
																							<li>
                                                                								<a href="/www/www_02/health/healthy/healthy_03/healthy_03_01.jsp" >금연실천(준비기)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/health/healthy/healthy_03/healthy_03_02.jsp" >금연실천(실행기)</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/health/healthy/healthy_03/healthy_03_03.jsp" >금연유지기</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/www_02/health/healthy/healthy_03/healthy_03_04.jsp" >금연비법</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/health/healthy/healthy_03/healthy_03_05.jsp" >흡연욕구 저하방법</a>
                                                                							</li>
				                                                           				
																							<li>
                                                                								<a href="/www/health/healthy/healthy_03/healthy_03_06.jsp" >금연의 신체적 이득</a>
                                                                							</li>
				                                                           				
				                                                           				</ul>
				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/health/healthy/healthy_04.jsp" class="lnb_ellipsis"><span>건강식단</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/health/healthy/healthy_05/healthy_05_01.jsp" class="lnb_ellipsis"><span>계절별 건강식단</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="http://www.e-gen.or.kr" target="_blank"><span class="new_link bg_top5">보건</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/health/health_03/health_03_02.jsp" ><span >위생</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/health/health_03/health_03_02.jsp" class="lnb_ellipsis"><span>좋은식단실천</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/health/health_03/health_03_04.jsp" class="lnb_ellipsis"><span>식중독예방</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/health/health_03/health_03_05.jsp" class="lnb_ellipsis"><span>부정불량식품 식별</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/health/health_03/health_03_06.jsp" class="lnb_ellipsis"><span>모범음식점 지정</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/health/health_03/health_03_07.jsp" class="lnb_ellipsis"><span>시설개선자금융자</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/health/health_03/health_03_08.jsp" class="lnb_ellipsis"><span>착한가격업소</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2030" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/heal_news/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1010" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                            <div class="part-content" id="part-content8">
                                                <h4 class="sr-only">복지포털</h4>
                                                
	                                                <ul class="lnb-list lnb-5dep-wrap">
	                                                   
                                                            <li>
                                                                <a href="/user/policy_01/board/BD_board.list.do?bbsCd=1025" ><span >행정/정책</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/policy_01/board/BD_board.list.do?bbsCd=1025" class="lnb_ellipsis"><span>파주시보</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/policy_02/board/BD_board.list.do?bbsCd=1018" class="lnb_ellipsis"><span>업무추진비</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=9025" class="lnb_ellipsis"><span>시정업무계획</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/www/www_02/plan/plan_02/plan_02_01.jsp" ><span >감사</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/www_02/plan/plan_02/plan_02_01.jsp" class="lnb_ellipsis"><span>예산규모</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/www/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4008" class="lnb_ellipsis"><span>연도별결산서</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://www.paju.go.kr/www/petition/upright/petition_08_01.jsp" class="lnb_ellipsis " target="_blank"><span>청렴신고센터</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/statistics/vehicle/BD_vehicleInfo.do?q_menuCd=1002&ctgry=C" ><span >통계</span></a>
                                                                
                                                                    <ul>
                                                                        
				                                                            <li >
				                                                           		<a href="http://stat.paju.go.kr/content.do?key=2207251326841" class="lnb_ellipsis " target="_blank"><span>인구/가구통계</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="/user/statistics/vehicle/BD_vehicleInfo.do?q_menuCd=1002&ctgry=C" class="lnb_ellipsis"><span>차량등록통계</span></a>

				                                                           		
				                                                           	</li>
                                                                        
				                                                            <li >
				                                                           		<a href="http://stat.paju.go.kr/ " class="lnb_ellipsis " target="_blank"><span>통계연보</span></a>

				                                                           		
				                                                           	</li>
                                                                        
                                                                    </ul>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/plan_news/board/BD_board.list.do?bbsCd=2001&q_ctgCd=1012" ><span >새소식</span></a>
                                                                
                                                            </li>
                                                        
                                                            <li>
                                                                <a href="/user/board/BD_board.list.do?bbsCd=2002&q_ctgCd=2032" ><span >갤러리</span></a>
                                                                
                                                            </li>
                                                        
	                                                </ul>
	                                            
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                    
        </ul>
    </nav>

    <!--
        [개발] 뉴스 사이트는 Desktop 모드 전체메뉴 버튼 미사용, 멀티클래스 .d-down-md 추가 필요
        <a href="#;" class="btn btn-allmenu-open d-down-md">
    -->
    <a href="#;" class="btn btn-allmenu-open">
        <span class="sr-only">전체메뉴</span>
        <i class="ico ico-allmenu"></i>
    </a>
</div>

<input type="hidden" id="allMenuHidden" name="allMenuHidden" value="Y"/>
<span id="allMenuDiv">

</span>
	</header>
	<!-- //header -->
	<aside id="breadcrumb"></aside>
	<div id="container">
		<aside id="sidebar" >
			<h2 class="sidebar-title">
				파주소개
			</h2>
			
				<ul class="snb-list">
				
									<li class="on">
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/user/organization/BD_pajuList.do" >시청안내</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/user/organization/BD_pajuList.do" >
																조직도
															</a>
														</li>
													
														<li class="on">
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_01.jsp" >
																조직정보
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intro_cityhall/intro_cityhall_02.jsp" >
																청사안내
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intro_cityhall/intro_cityhall_03/intro_cityhall_03_01.jsp" >
																찾아오시는 길
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intro_cityhall/intro_cityhall_04/intro_cityhall_04_01.jsp" >
																주차장안내
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intro_cityhall/intropaju_06_01.jsp" >
																파주시연혁
															</a>
														</li>
													
												</ul>
											
									</li>
								
									<li >
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/www/intropaju/intropaju_02/intropaju_02_01.jsp" >파주의 상징</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_02/intropaju_02_01.jsp" >
																시의 상징
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_02/intropaju_02_02.jsp" >
																상징물
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_02/intropaju_02_03.jsp" >
																평화도시
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_02/intropaju_02_04.jsp" >
																파주의 노래
															</a>
														</li>
													
												</ul>
											
									</li>
								
									<li >
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/www/intropaju/intropaju_03/intropaju_03_01.jsp" >일반현황</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_03/intropaju_03_01.jsp" >
																파주비전
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_03/intropaju_03_02.jsp" >
																행정구역현황
															</a>
														</li>
													
												</ul>
											
									</li>
								
									<li >
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/www/intropaju/intropaju_04/intropaju_04_01/intropaju_04_01_01.jsp" >자매·교류도시</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_04/intropaju_04_01/intropaju_04_01_01.jsp" >
																국내자매도시
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_04/intropaju_04_04/intropaju_04_04_01.jsp" >
																국내교류도시
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_04/intropaju_04_02/intropaju_04_02_01.jsp" >
																국외자매도시
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_04/intropaju_04_03/intropaju_04_03_02.jsp" >
																국외교류도시
															</a>
														</li>
													
												</ul>
											
									</li>
								
									<li >
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/www/intropaju/intropaju_05/intropaju_05_01.jsp" >시민이용시설</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_01.jsp" >
																읍면동 주민자치센터
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_02.jsp" >
																재난재해대피시설
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_03.jsp" >
																파주시노인복지관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_04.jsp" >
																파주시장애인종합복지관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_05.jsp" >
																파주시가족센터
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_06.jsp" >
																청소년수련시설현황
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_07.jsp" >
																문산종합사회복지관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_09.jsp" >
																청소년상담복지센터
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="https://lib.paju.go.kr" target="_blank" title="새창">
																도서관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_11/intropaju_05_11_01.jsp" >
																평생학습관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_12/intropaju_05_12_01.jsp" >
																시민회관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="https://www.paju.go.kr/user/facilityReserve/BD_selectSportsFacilityList.do" >
																공공체육시설
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_18/intropaju_05_18_01.jsp" >
																학교 개방형 다목적 체육관
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_14/intropaju_05_14_09.jsp" >
																공영주차장
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_15.jsp" >
																공중화장실설치현황
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_16.jsp" >
																파주진로체험지원센터
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_05/intropaju_05_17.jsp" >
																파주시육아종합지원센터
															</a>
														</li>
													
												</ul>
											
									</li>
								
									<li >
										<!-- 비회원이면서 나의 민원 메뉴만 표시하는 경우, 비회원이 아닌 경우(모든메뉴 표시)  -->
										
											<a href="/www/intropaju/intropaju_06/intropaju_06_01.jsp" >파주의 역사</a>
										
												<ul>
											
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/user/mayorHistory/BD_mayorHistoryList.do?q_ofcpsCode=1001" >
																역대시장/군수
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/www/intropaju/intropaju_06/intropaju_06_06/intropaju_06_06_01.jsp" >
																파주유래
															</a>
														</li>
													
														<li >
															<!-- 비회원이면서 현수막걸이대 신청현황 메뉴를 표시하지 않는경우, 비회원이 아닌경우(모든메뉴 표시) -->
															
															<a href="/user/board/BD_board.list.do?bbsCd=2004&q_ctgCd=4004" >
																파주시지
															</a>
														</li>
													
												</ul>
											
									</li>
								
				</ul>
			
		</aside>

		<article id="content">
			<!-- ad-banner -->
			
			<!-- //ad-banner -->

			<div class="content-header">
				<h2 class="content-title">
					 조직정보 
				</h2>
				<ol class="breadcrumb-list">
					
								<li>홈</li>
							
								<!-- 분야별 정보 Location -->
								
									<li> 파주소개 </li>
								
								<!-- 분야별 정보 Location -->
								
									<li> 시청안내 </li>
								
								<!-- 분야별 정보 Location -->
								
									<li> 조직정보 </li>
								
								<!-- 분야별 정보 Location -->
								
									<li> 현장공무원 비율</li>
								
				</ol>
			</div>

			<div class="content-body">
				<div class="container">
					<!-- 게시물 상세 sns -->
					

					<!-- 본문 영역 -->
					<!-- 본문 영역 -->
<nav class="tab-menu tab-menu1">
<ul class="tab-list">
	<li><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_01.jsp"><span>공무원정원</span></a></li>
	<li><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_02.jsp"><span>과장급 이상 상위직 비율</span></a></li>
	<li><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_03.jsp"><span>공무원 1인당 주민수</span></a></li>
	<li class="on"><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_04.jsp"><span>현장공무원 비율</span></a></li>
	<li><a href="/www/intropaju/intro_cityhall/intro_cityhall_05/intro_cityhall_05_05.jsp"><span>재정규모 대비 인건비 비율</span></a></li>
</ul>
</nav>

<h3 class="title1">현장공무원 비율</h3>

<h4 class="title2">본청 - 소속기관 정원 비율</h4>

<div class="indent">
<div class="indent-none">
<p class="text-unit">(2023. 6. 30. 기준)</p>

<div class="table table-narrow">
<table>
	<caption class="sr-only">현장공무원 비율(지차체유형별,현장공무원 비율(본청,소속기관))에 대한 테이블입니다.</caption>
	<colgroup>
		<col style="width: 33%;" />
		<col />
		<col />
	</colgroup>
	<thead>
		<tr>
			<th rowspan="2" scope="row">지차체유형별</th>
			<th colspan="2" scope="col">현장공무원 비율</th>
		</tr>
		<tr>
			<th scope="col">본청</th>
			<th scope="col">소속기관</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>파주시</td>
			<td>897명(48.2%)</td>
			<td>964명(51.8%)</td>
		</tr>
		<tr>
			<td>50만&nbsp;이상 시 평균</td>
			<td>968명(38.0%)</td>
			<td>1,741명(62.0%)</td>
		</tr>
		<tr>
			<td>50만&nbsp;이상 시(구청 x)&nbsp;평균</td>
			<td>1,036명(47.7%)</td>
			<td>1,166명(52.3%)</td>
		</tr>
	</tbody>
</table>
</div>
<!-- 차트 시작 -->

<div class="content-row mt-md">
<div class="inner">
<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type1">현장공무원(소속기관) 비율(파주시) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>파주시</h4>

<ul>
	<li>본청 : 897명</li>
	<li>소속기관 : 964명</li>
</ul>
</div>
</div>
</div>
</div>

<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type2">현장공무원(소속기관) 비율(50만 미만 시 평균) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>50만&nbsp;이상 시 평균</h4>

<ul>
	<li>본청 : 968명</li>
	<li>소속기관 : 1,741명</li>
</ul>
</div>
</div>
</div>
</div>

<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type3">현장공무원(소속기관) 비율(30만~50만 미만 시 평균) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>50만&nbsp;이상 시(구청 x)&nbsp;평균</h4>

<ul>
	<li>본청 : 1,036명</li>
	<li>소속기관 : 1,166명</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- 차트 끝 --></div>

<ul class="list list1 mt-md">
	<li>비교 지자체 현황
	<ul class="list list2">
		<li>인구 50만&nbsp;이상 일반시(19개) : 파주, 수원, 용인, 고양, 화성, 성남, 부천, 남양주, 안산, 평택, 시흥, 안양, 김포, 천안, 청주, 전주, 포항, 창원, 김해</li>
		<li>인구 50만 이상 시(구청 x)(8개) : 파주,&nbsp;화성, 부천, 남양주, 평택, 시흥, 김포, 김해</li>
	</ul>
	</li>
</ul>
</div>

<h4 class="title2">시군구 - 읍면동 정원 비율</h4>

<div class="indent">
<div class="indent-none">
<p class="text-unit">(2023. 6. 30. 기준)</p>

<div class="table table-narrow">
<table>
	<caption class="sr-only">현장공무원 비율에 대한 테이블입니다.</caption>
	<colgroup>
		<col style="width: 33%;" />
		<col />
		<col />
	</colgroup>
	<thead>
		<tr>
			<th rowspan="2" scope="row">지차체유형별</th>
			<th colspan="2" scope="col">현장공무원 비율</th>
		</tr>
		<tr>
			<th scope="col">총원(읍면동 제외)</th>
			<th scope="col">읍면동</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>파주시</td>
			<td>1,395명(75.0%)</td>
			<td>466명(25.0%)</td>
		</tr>
		<tr>
			<td>50만&nbsp;이상 시 평균</td>
			<td>2,905명(77.2%)</td>
			<td>613명(22.8%)</td>
		</tr>
		<tr>
			<td>50만&nbsp;이상 시(구청 x)&nbsp;평균</td>
			<td>1,670명(76.4%)</td>
			<td>532명(23.6%)</td>
		</tr>
	</tbody>
</table>
</div>
<!-- 차트 시작 -->

<div class="content-row mt-md">
<div class="inner">
<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type4">현장공무원(읍면동) 비율(파주시) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>파주시</h4>

<ul>
	<li>본청(읍면동 제외) : 1,395명</li>
	<li>읍면동 : 466명</li>
</ul>
</div>
</div>
</div>
</div>

<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type5">현장공무원(읍면동) 비율(50만 미만 시 평균) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>50만&nbsp;이상 시 평균</h4>

<ul>
	<li>본청(읍면동 제외) : 2,095명</li>
	<li>읍면동 : 613명</li>
</ul>
</div>
</div>
</div>
</div>

<div class="col-item">
<div class="box" style="display: flex; justify-content: center;">
<div style="width: 240px; height: 350px;"><canvas id="myChart-bar-organinfo-0504-type6">현장공무원(읍면동) 비율(30만~50만 미만 시 평균) 차트(하단 내용)</canvas>

<div class="sr-only">
<h4>50만&nbsp;이상 시(구청 x)&nbsp;평균</h4>

<ul>
	<li>본청(읍면동 제외) : 1,670명</li>
	<li>읍면동 : 532명</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- 차트 끝 --></div>

<ul class="list list1 mt-md">
	<li>비교 지자체 현황
	<ul class="list list2">
		<li>인구 50만&nbsp;이상 일반시(19개) : 파주, 수원, 용인, 고양, 화성, 성남, 부천, 남양주, 안산, 평택, 시흥, 안양, 김포, 천안, 청주, 전주, 포항, 창원, 김해</li>
		<li>인구 50만 이상 시(구청 x)(8개) : 파주,&nbsp;화성, 부천, 남양주, 평택, 시흥, 김포, 김해</li>
	</ul>
	</li>
</ul>
</div>
<!-- // 본문 영역 -->
					<!-- // 본문 영역 -->
				</div>
			</div>

			<div id="content-info" class="container">
				<!--kogl 공공누리 S-->
				
				<!--//kogl 공공누리 E-->
				
					<!-- 관리 부서정보 -->
					

	<div class="content-satisfy box contact-info-box">
	
		<dl class="contact-info">
			<dt>자료제공</dt>
			<dd>
				<span class="item">
					<i class="ico ico-bag-gray"></i>
					<span class="text">담당부서 : 인사팀</span>
				</span>
				<span class="item">
					<i class="ico ico-tel-gray"></i>
					<span class="text">문의처 : <a href="tel:031-940-4123">031-940-4123</a></span>
				</span>
			</dd>
		</dl>
	</div>


			</div>
		</article>
	</div>

	<footer id="footer">
		

<div class="container footer-util-container">
    <p class="logo-bottom">
        <a href="https://www.paju.go.kr/index.do">
            <img src="/resources/user/_common/images/logo-paju-gray.png" alt="파주시" />
        </a>
    </p>

	

    <div class="btn-group-util">
        <ul class="sns-list">
            <li>
                <a href="https://twitter.com/pajusi" target="_blank" title="새창">
                    <img src="/resources/user/_common/images/ico-sns-x@2x.png" alt="엑스">
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/hopepaju/" target="_blank" title="새창">
                    <img src="/resources/user/_common/images/ico-sns-facebook@2x.png" alt="페이스북">
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCicR_6NEnfhLP-FvM4txuUA/featured?view_as=subscriber" target="_blank" title="새창">
                    <img src="/resources/user/_common/images/ico-sns-yutube@2x.png" alt="유튜브">
                </a>
            </li>
            <li>
                <a href="http://blog.naver.com/paju_si" target="_blank" title="새창">
                    <img src="/resources/user/_common/images/ico-sns-blog@2x.png" alt="블로그">
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/paju_si/" target="_blank" title="새창">
                    <img src="/resources/user/_common/images/ico-sns-insta@2x.png" alt="인스타그램">
                </a>
            </li>
        </ul>
        
        <a href="http://www.webwatch.or.kr/Situation/WA_Situation.html?MenuCD=110" class="wa-mark" target="_blank" title="새창열림">
            <img src="/resources/user/_common/images/wa-mark.png" alt="과학기술정보통신부 WA(WEB접근성) 품질인증 마크, 웹와치(WebWatch) 2023.09.16 ~ 2024.09.15" title="WA 품질인증 마크, 웹와치(WebWatch) 2023.09.16 ~ 2024.09.15">
        </a>
        
    </div>
</div>
<div class="container address-container">
    <ul class="policy-list">
        <li class="text-bold" style="color:#00a4b7;"><a href="https://www.paju.go.kr/www/page_guide/page_guide_01/page_guide_01_info.jsp" target="_blank" title="새창열림">개인정보처리방침</a></li>
        <li><a href="https://www.paju.go.kr/www/page_guide/page_guide_cp.jsp" target="_blank" title="새창열림">저작권정책</a></li>
        <li><a href="https://www.paju.go.kr/user/board/BD_board.list.do?bbsCd=9035" target="_blank" title="새창열림">이용안내</a></li>
        <li><a href="https://www.paju.go.kr/www/intropaju/intro_cityhall/intro_cityhall_03/intro_cityhall_03_01.jsp" target="_blank" title="새창열림">찾아오시는길</a></li>
    </ul>
    <p class="no-spam">파주시 홈페이지에 게시된 전자우편주소는 무단으로<br class="d-down-sm"> 수집할 수 없으며, 위반시 정보통신망법에 의해 처벌됩니다.</p>
    <address class="address">
    	
        	<p>(우 10932) 경기도 파주시 시청로 50 | 대표전화 : 031) 940-4114</p>
        	
        <p>COPYRIGHT(C) 2021, PAJU CITY ALL RIGHTS RESERVED</p>
    </address>
</div>


	</footer>

</div>
</body>
</html>`;

export const MetaTagMock = `<meta charset="UTF-8">
<meta name="robots" content="index, follow">

<!-- Primary Meta Tags -->
<title>굳이? 이렇게까지 수정해야 되나요? | 인시퀀스</title>
<meta name="description" content="웹 접근성 프로젝트에서 실제로 들었던 말입니다.">
<meta name="keywords" content="웹 접근성, a11y, 앨리, 홈페이지, 마케팅, 웹접근성, IT프로젝트, 공공기관">

<!-- Open Graph / Facebook, KaKaoTalk -->
<meta property="og:url" content="https://inseq.co.kr/ko/bbs/i-24/show.do?seq=37&searchCl1=&searchMulti=&searchKeyword=">
<meta property="og:title" content="굳이? 이렇게까지 수정해야 되나요? | 인시퀀스">
<meta property="og:description" content="웹 접근성 프로젝트에서 실제로 들었던 말입니다.">
<meta property="og:type" content="website"><!-- // TODO : 웹사이트 콘텐츠 성격에 맞는 Open Graph type을 선택하세요. 기본은 website, 보다 구체적 카테고리는 article, video.movie, music 등을 사용합니다. 커스텀 type이 있으면 그것을 사용해도 좋습니다. -->
<meta property="og:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 -->

<!-- Twitter -->
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="굳이? 이렇게까지 수정해야 되나요? | 인시퀀스">
<meta name="twitter:description" content="웹 접근성 프로젝트에서 실제로 들었던 말입니다.">
<meta name="twitter:image" content=""><!-- // TODO : 섬네일 이미지를 CDN 서버에 올린 뒤 http 포함한 이미지 경로를 입력해주세요 -->`;

export const AllTableHtmlExport = `<!-- Table1 -->
<table>
  <caption>
		테이블1에 대한 caption정보입니다. 테이블1에 대한 caption정보입니다. 테이블1에 대한 caption정보입니다. 테이블1에 대한 caption정보입니다.
  </caption>
	<tbody>
		<tr>
			<th>Company</th>
			<th>Contact</th>
		</tr>
		<tr>
			<td>Alfreds Futterkiste</td>
			<td>Maria Anders</td>
		</tr>
	</tbody>
</table>

<!-- Table2 -->
<table>
  <caption>
		테이블2에 대한 caption정보입니다. 테이블2에 대한 caption정보입니다. 테이블2에 대한 caption정보입니다. 테이블2에 대한 caption정보입니다.
  </caption>
	<tbody>
		<tr>
			<th>Company</th>
			<th>Contact</th>
		</tr>
		<tr>
			<td>Alfreds Futterkiste</td>
			<td>Maria Anders</td>
		</tr>
	</tbody>
</table>

<!-- Table3 -->
<table>
  <caption>
		테이블3에 대한 caption정보입니다. 테이블3에 대한 caption정보입니다. 테이블3에 대한 caption정보입니다. 테이블3에 대한 caption정보입니다.
  </caption>
	<tbody>
		<tr>
			<th>Company</th>
			<th>Contact</th>
		</tr>
		<tr>
			<td>Alfreds Futterkiste</td>
			<td>Maria Anders</td>
		</tr>
	</tbody>
</table>`;
