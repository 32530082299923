// mockData.js
const mockData = {
	// SEO 기본 설정
	title: "웹 접근성 AI 마법사, 앨리",
	description: "웹 접근성 작업 앨리와 함께 부스트업!",
	keywords: "웹접근성, 웹 접근성, a11y, 인시퀀스, 앨리, AI, 인공지능, SEO, 퍼블리싱",
	ogTitle: "웹 접근성 AI 마법사, 앨리",
	ogDescription: "웹 접근성 작업 앨리와 함께 부스트업!",
	ogUrl: "https://a11y.co.kr",
	ogImage: "https://a11y.co.kr/logo-a11y-kakao.png",

	// 고객지원
	supportDocRoot: "https://a11y.super.site/",

	// 유저 정보 임시 데이터
	userInfo: {
		name: "김앨리",
		email: "works@inseq.co.kr",
		tel: "01012345678"
	}
};

export default mockData;
