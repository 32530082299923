const { RESET_STATE } = require("../action/ResetAction");
const { SEARCH_LOADING, SEARCH_DATA, SEARCH_LOADED } = require("../action/SearchAction");

const initialState = {
	searchData: [],
	isLoading: false
};

const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_LOADING:
			return {
				...state,
				isLoading: true
			};
		case SEARCH_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SEARCH_DATA:
			return {
				...state,
				searchData: action.payload
			};
			break;
		case RESET_STATE:
			return initialState;
			break;
		default:
			return state;
	}
};

export default searchReducer;
