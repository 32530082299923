import React from "react";
import { termsOfServiceText } from "@/data/legal";

export const TermsOfService = () => {
	return (
		<div className="agreement-container">
			<h2 className="agreement-title">서비스 이용약관</h2>
			<div className="agreement-article" dangerouslySetInnerHTML={{ __html: termsOfServiceText }} />
		</div>
	);
};

export default TermsOfService;
