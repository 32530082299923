import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthError } from "../store/action/AuthAction";

const { Navigate } = require("react-router-dom");

export function ErrorRedirector() {
	const dispatch = useDispatch();
	const errorCode = useSelector((state) => state.auth.error);
	let shouldRedirect = false;
	let redirectTo = "";

	switch (errorCode) {
		case 401:
		case 403:
			redirectTo = "/";
			shouldRedirect = true;
			break;
		case 404:
		case 422:
		case 409:
			redirectTo = "/error";
			shouldRedirect = true;
			break;
		default:
			break;
	}

	useEffect(() => {
		if (shouldRedirect) {
			dispatch(clearAuthError());
		}
	}, [dispatch, shouldRedirect]);

	return shouldRedirect ? <Navigate to={redirectTo} replace /> : null;
}
