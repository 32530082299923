export function getPriority(value, desc) {
	if (desc) {
		if (/^\d+$/.test(value)) return 4; // 숫자
		if (/^[!@#$%^&*()_+]+$/.test(value)) return 3; // 특수문자
		if (/^[ㄱ-ㅎ가-힣]+$/.test(value)) return 2; // 한글
		if (/^[A-Za-z]+$/.test(value)) return 1; // 영어
	} else {
		if (/^\d+$/.test(value)) return 1; // 숫자
		if (/^[!@#$%^&*()_+]+$/.test(value)) return 2; // 특수문자
		if (/^[ㄱ-ㅎ가-힣]+$/.test(value)) return 3; // 한글
		if (/^[A-Za-z]+$/.test(value)) return 4; // 영어
	}
	return 5; // 기타
}

export function firstGetPriority(value, desc) {
	// 첫 글자를 추출합니다.
	const firstChar = value.charAt(0);
	

	

		if (/^\d/.test(firstChar)) return 4; // 숫자로 시작
		if (/^[!@#$%^&*()_+]/.test(firstChar)) return 3; // 특수문자로 시작
		if (/^[ㄱ-ㅎ가-힣]/.test(firstChar)) return 2; // 한글로 시작
		if (/^[A-Za-z]/.test(firstChar)) return 1; // 영어로 시작

	return 5; // 기타
}

const fileTypePriority = {
	jpeg:6,	
	jpg: 5,
	png: 4,
	gif: 3,
	link: 2,
	html: 1
}

export function compare(a, b, direction, field) {

	if (field === "fileType") {
    const priorityA = fileTypePriority[a[field]] || Number.MAX_SAFE_INTEGER;
    const priorityB = fileTypePriority[b[field]] || Number.MAX_SAFE_INTEGER;
    if (priorityA < priorityB) {
      return direction === "asc" ? -1 : 1;
    } else if (priorityA > priorityB) {
      return direction === "asc" ? 1 : -1;
    }
    return 0;
  }
	if (field === "workName") {

		
		const aValue = a[field];
		const bValue = b[field];
		const priorityA = firstGetPriority(aValue, direction === "asc");
		const priorityB = firstGetPriority(bValue, direction === "asc");
		// 우선순위 비교
		if (priorityA !== priorityB) {
			return direction === "asc" ? priorityA - priorityB : priorityB - priorityA;
		}

		// 같은 우선순위 내에서 값 비교
		if (priorityA < priorityB) {
			return direction === "asc" ? -1 : 1;
		} else if (priorityA > priorityB) {
			return direction === "asc" ? 1 : -1;
		}
		return 0;
	} else {
		const aValue = a[field];
		const bValue = b[field];

		// 같은 우선순위 내에서 값 비교
		if (aValue < bValue) {
			return direction === "asc" ? -1 : 1;
		} else if (aValue > bValue) {
			return direction === "asc" ? 1 : -1;
		}
		return 0;
	}
}

const getStringType = (str, desc) => {
	if (desc) {
		if (str.match(/^\d/)) return 1; // 숫자로 시작하는 경우
		if (str.match(/^[@!#$%^&*()_+]/)) return 2; // 특수문자로 시작하는 경우
		if (str.match(/^[ㄱ-ㅎ가-힣]/)) return 3; // 한글로 시작하는 경우
		if (str.match(/^[a-zA-Z]/)) return 4; // 영어로 시작하는 경우
		return 5; // 기타
	} else {
		if (str.match(/^\d/)) return 4; // 숫자로 시작하는 경우
		if (str.match(/^[@!#$%^&*()_+]/)) return 3; // 특수문자로 시작하는 경우
		if (str.match(/^[ㄱ-ㅎ가-힣]/)) return 2; // 한글로 시작하는 경우
		if (str.match(/^[a-zA-Z]/)) return 1; // 영어로 시작하는 경우
		return 5; // 기타
	}
};
