import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../cookies/UserCookies";

export const ProtectedRoute = ({ children }) => {
	const location = useLocation();
	const token = getCookie("token"); // 쿠키에서 토큰 가져오기

	// 토큰이 없다면 로그인 페이지로 리디렉션
	if (!token) {
		// 리디렉션 시, 사용자가 원래 요청했던 페이지로 돌아갈 수 있도록 state를 전달할 수 있음
		console.log(location);
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children; // 토큰이 있다면 원래 접근하려던 페이지를 렌더링
};
