import { emailRegex } from "@/services/util/const";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function FindidPage() {
	const [userEmail, setUserEmail] = useState("");
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [resendButtonText, setResendButtonText] = useState("확인");
	const [isBtnFormInlineVisible, setIsBtnFormInlineVisible] = useState(false);
	const [certiNumber, setCertiNumber] = useState("");

	// 이메일 확인
	const handleEmailChange = (event) => {
		const email = event.target.value;
		const isValidEmail = emailRegex.test(email);

		setUserEmail(email);
		setIsEmailValid(isValidEmail);

		if (!email) {
			setResendButtonText("확인");
		}
	};

	// 인증번호 입력 확인
	const handleCertiNumberChange = (event) => {
		const number = event.target.value;

		// 정규식을 사용하여 숫자만 입력되도록 체크
		if (/^\d*$/.test(number)) {
			setCertiNumber(number);
			setIsBtnFormInlineVisible(!!number);

			const inputElement = document.getElementById("user-certi-number");
			if (inputElement) {
				inputElement.classList.add("padding-right-100");

				if (!number) {
					inputElement.classList.remove("padding-right-100");
				}
			}
		}
	};

	useEffect(() => {
		setIsBtnFormInlineVisible(false);
	}, []);

	const handleResendButtonClick = () => {
		if (isEmailValid) {
			setResendButtonText("인증번호 재전송");
		} else {
			setResendButtonText("확인");
		}
	};

	return (
		<>
			<div className="container account-page">
				<div className="account-header">
					<Link to="/login" className="btn btn-xs btn-back">
						<i className="ico ico-arrow-left-gray"></i>돌아가기
					</Link>
					<h2 className="title1">계정 찾기</h2>
				</div>
				<div className="account-body findid-page">
					<p className="subject">계정을 찾기 위해 보조 이메일을 입력해주세요.</p>

					<form>
						<fieldset>
							<legend className="sr-only">계정찾기</legend>
							<div className="input-group">
								<input
									type="text"
									id="user-secondary-email"
									title="보조 이메일 입력"
									className="form-control"
									value={userEmail}
									placeholder="예) a11y@domain.com"
									onChange={handleEmailChange}
								/>
								<span className="input-addon">
									<button type="button" className="btn btn-xl btn-gray" onClick={handleResendButtonClick}>
										{resendButtonText}
									</button>
								</span>
							</div>
							{userEmail && !isEmailValid && <p className="error-text">올바른 이메일을 입력해주세요.</p>}

							{/* 최초 진입 시 상태 */}
							<div className="input-btn-group">
								<input
									type="text"
									id="user-certi-number2"
									title="위 이메일로 수신된 인증번호 6자리를 입력해주세요."
									className="form-control"
									placeholder="위 이메일로 수신된 인증번호 6자리를 입력해주세요."
									disabled
								/>
							</div>
							{/* 이메일 정상적으로 입력 후, 확인 버튼 클릭 시 상태 */}
							<div className="input-btn-group">
								<input
									type="text"
									id="user-certi-number"
									title="위 이메일로 수신된 인증번호 6자리를 입력해주세요."
									className="form-control"
									placeholder="위 이메일로 수신된 인증번호 6자리를 입력해주세요."
									onChange={handleCertiNumberChange}
									value={certiNumber}
								/>
								{isBtnFormInlineVisible && (
									<button type="button" className="btn btn-sm btn-primary btn-form-inline">
										중복 확인
									</button>
								)}
							</div>

							{/* 인증번호 요청 시 노출 */}
							<div className="certi-number-area">
								<span>인증번호가 요청되었습니다.</span>
								<span>유효시간 05:00</span>
							</div>

							{/* 인증번호가 정상적으로 입력되었을 경우 노출 */}
							<div className="certi-number-area waiting">
								<p>인증번호가 확인되었습니다.</p>
							</div>

							{/* 입력한 인증번호가 잘못된 경우 노출 */}
							<div className="certi-number-area invalid">
								<span>올바르지 않은 인증번호입니다.</span>
								<span>유효시간 05:00</span>
							</div>

							{/* 인증번호 유효시간 만료된 경우 노출 */}
							<div className="certi-number-area invalid">
								<p>인증번호 유효시간이 만료되었습니다. 재전송 버튼을 눌러주세요.</p>
							</div>
						</fieldset>
					</form>

					<div className="btn-group btn-group-flex">
						<Link to="/findid/result" className="btn btn-xl btn-primary">
							<span>계속</span>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
