import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import mockData from "@/mock/mockData";

const ContentPage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const defaultMeta = {
		// SEO 기본 설정
		title: mockData.title,
		description: mockData.description,
		keywords: mockData.keywords,
		ogTitle: mockData.ogTitle,
		ogDescription: mockData.ogDescription,
		ogUrl: mockData.ogUrl,
		ogImage: mockData.ogImage,
		eventDocRoot: mockData.supportDocRoot
	};

	// 쿼리 스트링에서 받은 파라미터로 meta 정보 설정
	const meta = {
		...defaultMeta,
		// title 쿼리스트링이 있을 경우에만 타이틀을 변경
		...(queryParams.get("title") && {
			title: `${queryParams.get("title")} | ${defaultMeta.title}`
		}),
		description: queryParams.get("description") || defaultMeta.description,
		link: queryParams.get("to") || "/"
	};

	const docRoot = meta.eventDocRoot;
	const pageUrl = `${docRoot}${meta.link}`;

	return (
		<div className="content-page">
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.description} />
				<meta property="og:title" content={meta.ogTitle} />
				<meta property="og:description" content={meta.ogDescription} />
				<meta property="og:url" content={meta.ogUrl} />
				<meta property="og:image" content={meta.ogImage} />
			</Helmet>
			<iframe className="event-iframe" src={pageUrl} title={meta.title} />
		</div>
	);
};

export default ContentPage;
