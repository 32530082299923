// data/communityFilter.js

export const tagData = [
	{ text: "접근성이란", emoji: "🦯", placeholder: "접근성이란 무엇일까요?" },
	{ text: "접근성어려워요", emoji: "😰", placeholder: "접근성에 어려움을 느낀 적이 있나요?" },
	{ text: "아이디어", emoji: "🔮", placeholder: "개선 아이디어가 있나요?" },
	{ text: "아쉬워요", emoji: "😿", placeholder: "앨리의 어떤점이 아쉬운가요?" },
	{ text: "응원해요", emoji: "💪", placeholder: "접근성 지킴이들을 응원해주세요" },
	{
		text: "앨리후기",
		emoji: "😍",
		placeholder: "앨리와 함께해보니 어떠셨나요?",
		tools: [
			{ to: "/tool/img2html/add", keyword: "img2html", text: "이미지HTML변환기" },
			{ to: "/tool/contrast/add", keyword: "contrast", text: "명도대비최적화" },
			{ to: "/tool/seo/add", keyword: "seo", text: "SEO메타태그생성기" },
			{ to: "/tool/table/add", keyword: "table", text: "표접근성최적화" }
		]
	}
];

export const filterOptions = [
	{
		title: "카테고리",
		key: "category",
		options: [
			{ key: "news", value: "뉴스·소식", color: "#151C62", bgcolor: "#D3D5ED", emoji: "📰" },
			{ key: "law", value: "법령·고시", color: "#1F2937", bgcolor: "#E5E7EB", emoji: "📜" },
			{ key: "guide", value: "지침·가이드", color: "#253709", bgcolor: "#E9F7B3", emoji: "📗" },
			{ key: "education", value: "교육·콘텐츠", color: "#043C04", bgcolor: "#CFF9CF", emoji: "📚" },
			{ key: "resources", value: "자료", color: "#002A7C", bgcolor: "#CFE3FF", emoji: "📁" },
			{ key: "testing", value: "검사도구", color: "#611A00", bgcolor: "#FFDACC", emoji: "⚗️" },
			// { key: "development", value: "개발도구", color: "#003A4F", bgcolor: "#C7EDFA", emoji: "💻" },
			{ key: "solution", value: "통합 솔루션", color: "#4F2804", bgcolor: "#F1DDCA", emoji: "🔧" },
			{ key: "assessment", value: "심사기관", color: "#043E3E", bgcolor: "#CEEBEB", emoji: "🎖️" },
			{ key: "remedy", value: "권리구제", color: "#710A0A", bgcolor: "#FCCCCC", emoji: "⚖️" },
			{ key: "community", value: "커뮤니티", color: "#651D4C", bgcolor: "#FFDEF3", emoji: "💟" }
		]
	},
	{
		title: "난이도",
		key: "difficulty",
		mobileUse: false,
		options: [
			{ key: "beginner", value: "입문" },
			{ key: "novice", value: "초심자" },
			{ key: "intermediate", value: "중급자" },
			{ key: "expert", value: "전문가" },
			{ key: "other", value: "기타" }
		]
	},
	{
		title: "서비스 유형",
		key: "type",
		mobileUse: false,
		options: [
			{ key: "installation", value: "설치형" },
			{ key: "chrome-extension", value: "크롬 확장도구" },
			{ key: "web-service", value: "웹 서비스" },
			{ key: "web-content", value: "웹 콘텐츠" },
			{ key: "video", value: "영상 콘텐츠" },
			{ key: "document", value: "문서" },
			{ key: "website", value: "웹 사이트" },
			{ key: "social-media", value: "소셜미디어" }
		]
	},
	{
		title: "제공 언어",
		key: "lang",
		mobileUse: false,
		options: [
			{ key: "domestic", value: "국내" },
			{ key: "foreign", value: "해외" },
			{ key: "translated", value: "번역" }
		]
	}
];
