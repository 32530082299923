import React, { useCallback, useEffect, useRef, useState } from "react";
import axe from "axe-core";
import koLocale from "axe-core/locales/ko.json";

const AxeValidator = ({ htmlCode, onCaptionButtonClick }) => {
	const isAxeRunning = useRef(false);
	const [axeResults, setAxeResults] = useState(null);
	const violations = axeResults?.violations || [];

	const performAxeCheck = useCallback(async (htmlCode) => {
		if (isAxeRunning.current) {
			return; // Axe가 이미 실행 중이면 실행을 중단합니다.
		}
		isAxeRunning.current = true;

		const testDiv = document.createElement("div");
		testDiv.innerHTML = htmlCode;
		document.body.appendChild(testDiv);

		try {
			axe.configure({
				locale: koLocale
			});
			const results = await axe.run(testDiv);
			setAxeResults(results);
		} catch (error) {
			console.error("Axe 접근성 검사 오류:", error);
			setAxeResults(null);
		} finally {
			document.body.removeChild(testDiv);
		}

		isAxeRunning.current = false;
	}, []);

	useEffect(() => {
		performAxeCheck(htmlCode);
	}, [htmlCode, performAxeCheck]); // 의존성 배열 수정

	let errorIndex = 0; // 전체 오류 건수에 대한 인덱스

	if (!axeResults || violations.length === 0) {
		// 위반 사항이 없는 경우
		return (
			<>
				<p className="text-center mt-10">
					✅<br /> 웹 접근성 구문 오류가 없습니다.
				</p>

				{/* 테이블이 존재할 때 */}
				<p className="text-center">table caption을 진단하고 AI로 개선해 보세요.</p>
				<div className="text-center mt-5">
					<button type="button" className="btn btn-primary btn-md" onClick={onCaptionButtonClick}>
						table caption 진단하기 🔍
					</button>
				</div>
			</>
		);
	} else {
		// 위반 사항이 있는 경우
		return (
			<div className="axe-validator">
				<h4 className="title-area">
					<span>
						⚠️ {violations.reduce((acc, violation) => acc + violation.nodes.length, 0)}건의 문법오류를 모두 해결하세요.
					</span>
				</h4>
				<div className="axe-result-area">
					<div className="axe-violation-list">
						{violations.map((violation) => (
							<dl key={violation.id} className="linter-list">
								<dt className={`title ${violation.impact}`}>
									<span className="sr-only">{violation.impact}</span>
									<span className="description">{violation.description}</span>
								</dt>
								<dd>
									<ul className="item-list">
										{violation.nodes.map((node) => {
											errorIndex += 1; // 오류 인덱스 증가
											return (
												<li key={node.target.join()}>
													<strong className="mr-1">
														<span className="mr-1">
															{errorIndex}. {/* 일련번호 표시 */}
														</span>
														<code className="mr-1">{node.target}</code>
														<code className="mr-1">{node.html}</code>
														<button
															type="button"
															className="btn btn-xs btn-lightgray"
															// onClick={() => moveToPosition(10, 2)}
														>
															코드에서 찾기
														</button>
													</strong>
													{node.failureSummary.split("\n").slice(1).length > 1 && (
														<p className="solution">
															<strong>🛠️ 다음 중 하나를 해결해 주세요.</strong>
														</p>
													)}
													<ul className="fail-list">
														{node.failureSummary
															.split("\n")
															.slice(1)
															.map((line, lineIndex) => (
																<li key={lineIndex}>{line}</li>
															))}
													</ul>
												</li>
											);
										})}
									</ul>
								</dd>
							</dl>
						))}
					</div>
				</div>
			</div>
		);
	}
};

export default AxeValidator;
