import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { CHANGE_PHONE } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const MyPageChangePhone = async (phoneNumber) => {
	try {
		const response = await api.post(CHANGE_PHONE, {
			phone: phoneNumber
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		navigateTo("/error");
		return "fail";
	}
};
