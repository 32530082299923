import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { persistor } from "../store/ReduxStore";
import resetState from "../store/action/ResetAction";
import { getCookie, removeCookie } from "../cookies/UserCookies";
import { navigateTo } from "./Navigate";

const LogoutButton = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogout = async () => {
		try {
			removeCookie("token", { path: "/" }); // 쿠키 제거
			await persistor.purge();
			dispatch(resetState());
			navigate("/");
		} catch (error) {
			navigate("/");
		}
	};

	return (
		<button onClick={handleLogout} className="btn btn-sm">
			로그아웃
		</button>
	);
};

export default LogoutButton;
