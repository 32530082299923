import React, { Children, cloneElement, isValidElement, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderHome from "@/components/layouts/HeaderHome";
import ButtonToTop from "@/components/button/ButtonToTop";
import usePopup from "@/hooks/usePopup";

const LayoutFull = ({ children, isAuthenticated, openLoginPopup }) => {
	const [isAuth, setIsAuth] = useState(isAuthenticated);
	const EventCodePopup = usePopup();
	const location = useLocation();
	const navigate = useNavigate();
	const codeParam = new URLSearchParams(location.search).get("c");

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const eventParam = searchParams.get("e");
		const codeParam = searchParams.get("c");

		if (eventParam === "t" && codeParam) {
			EventCodePopup.open();
		}
	}, [location.search, EventCodePopup]);

	const handleCloseCompletePopup = () => {
		navigate(location.pathname);
		EventCodePopup.close();
	};

	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			return cloneElement(child, { isAuthenticated: isAuth, setIsAuthenticated: setIsAuth, openLoginPopup });
		}
		return child;
	});

	return (
		<div className="wrapper layout-home">
			<HeaderHome setIsAuthenticated={setIsAuth} isAuthenticated={isAuth} openLoginPopup={openLoginPopup} />
			<article id="content">{childrenWithProps}</article>
			<ButtonToTop />
		</div>
	);
};

export default LayoutFull;
