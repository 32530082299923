// Table.js
import TiptapTable from "@tiptap/extension-table";
import { Plugin } from "prosemirror-state";

// export const Table -> export default로 변경
export default TiptapTable.extend({
	name: "table",

	parseHTML() {
		console.log("Parsing table:", {
			original: document.querySelector("table")?.innerHTML,
			caption: document.querySelector("caption")?.textContent
		});
	},

	addProseMirrorPlugins() {
		const plugins = this.parent?.() || [];

		return [
			...plugins,
			new Plugin({
				view: () => ({
					update: (view) => {
						console.log("Table updated:", {
							doc: view.state.doc.toJSON()
						});
					}
				})
			})
		];
	}
});
