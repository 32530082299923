import { Link } from "react-router-dom";

export default function FindidResultPage() {
	return (
		<>
			<div className="container account-page">
				<div className="account-header">
					<Link to="/Findid" className="btn btn-xs btn-back">
						<i className="ico ico-arrow-left-gray"></i>돌아가기
					</Link>
					<h2 className="title1">계정찾기</h2>
				</div>
				<div className="account-body findid-result-page">
					<div className="subject-area">
						<p className="subject">계정을 찾았어요.</p>
						<p className="subtext">보조 이메일 정보가 포함된 가입계정을 알려드려요.</p>
					</div>

					<div className="box box-point mb-6">
						<p>oloomin0300@gmail.com</p>
					</div>

					<div className="btn-group btn-group-flex">
						<Link to="/login" className="btn btn-xl btn-primary">
							<span>로그인 화면으로 돌아가기</span>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
