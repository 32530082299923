const { RESET_STATE } = require("../action/ResetAction");

const initialState = {
	dataCount: {}
};

const myDataCountReducer = (state = initialState, action) => {
	switch (action.type) {
		case "DATA_COUNT":
			return {
				...state,
				dataCount: action.payload
			};
			break;

		default:
			return state;
	}
};

export default myDataCountReducer;
