// TableWorkDetailPage.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";
import TableAccItem from "./TableWorkDetailComponents/TableAccItem";

import LoadingOverlay from "@/components/loading/LoadingOverlay";
import Toast from "@/components/toast/Toast";
import PopupLayout from "@/components/popup/PopupLayout";
import usePopup from "@/hooks/usePopup";
import { useDispatch, useSelector } from "react-redux";
import CodeMirrorMerge from "react-codemirror-merge";
import { EditorState } from "@codemirror/state";
import { html } from "@codemirror/lang-html";
import { material } from "@uiw/codemirror-theme-material";
import pretty from "pretty";
import { debounce } from "@/services/util/Debouns";
import { DebounceTime, DefaultAutoSaveTime } from "@/services/util/const";
import useTablePopup from "@/services/core/table/TableTagPopup";
import { TableAutoSave, TableCaptionALLHtmlSave } from "@/services/core/table/TableApi";
import { updateTableResultHtml, updateTableSaveTime } from "@/services/store/action/tableAction";
import { refreshCancelTokenSource } from "@/services/util/Interceptor";

const TableWorkDetailPage = () => {
	const location = useLocation();
	useEffect(() => {
		// URL에서 쿼리 파라미터 제거
		const urlWithoutQueryParams = window.location.pathname;

		// History API를 사용하여 쿼리 파라미터 없이 현재 URL을 대체
		window.history.replaceState({}, "", urlWithoutQueryParams);
	}, []);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
	const tableData = useSelector((state) => state.table.tableData?.data?.resData || []);
	const datas = useSelector((state) => state.table.tableData?.data || []);
	const [allTableHtmlExport, SetAllTableHtmlExport] = useState("");
	const [allTableModifyHtmlExport, SetAllTableModifyHtmlExport] = useState(tableData.resultHtml || "");
	const [tables, setTables] = useState([]); // table 상태 초기화
	const tables2 = useSelector((state) => state.table.tableData?.data?.transformedData || []); // table 상태 초기화
	const [tableSteps, setTableSteps] = useState([]); // table step 상태
	const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false); // 전체 step2 상태
	const {
		isOpen: isTableCreatePopup,
		open: openExportPopup,
		close: closeTableCreatePopup
	} = useTablePopup(allTableModifyHtmlExport); // popup 상태
	const [showToastHtmlCopy, setShowToastHtmlCopy] = useState(false); // toast Html 상태
	const [showToastOriginalCodeCopy, setshowToastOriginalCodeCopy] = useState(false); // toast code 상태
	const [isChangeTag, setIsChangeTag] = useState(false);
	const firstRender = useRef(true);
	const dispatch = useDispatch();
	const handleWorkNameUpdate = (newWorkName) => {};
	// [개발] 가상의 테이블 데이터
	function loadTables() {
		setIsLoading(true);
		const iniStep = tables2.map((data) =>
			data.caption.recommendedCaptions.length == 0
				? {
						step: 1,
						isLoading: data.isLoading
				  }
				: {
						step: 2,
						isLoading: data.isLoading
				  }
		);

		setTableSteps(iniStep);

		handleCheckAllStep(iniStep);

		setTables(tables2);
		setIsLoading(false);
	}
	const debouncedSave = useCallback(
		debounce((nextValue) => SetAllTableModifyHtmlExport(nextValue), DebounceTime),
		[]
	);

	const handleChange = (value) => {
		debouncedSave(value);
	};
	useEffect(() => {
		loadTables();
	}, []);
	const handleStep = () => {
		const iniStep = tables2.map((data) =>
			data.caption.recommendedCaptions.length == 0
				? {
						step: 1,
						isLoading: data.isLoading
				  }
				: {
						step: 2,
						isLoading: data.isLoading
				  }
		);

		setTableSteps(iniStep);
		return iniStep;
	};

	useEffect(() => {
		refreshCancelTokenSource();
		return () => {
			refreshCancelTokenSource();
		};
	}, [location]);

	//// 자동저장 로직
	useEffect(() => {
		let saveTime = tableData.saveSecond * 1000;
		if (!saveTime) {
			saveTime = DefaultAutoSaveTime;
		}
		if (firstRender) {
		}
		const intervalId = setInterval(() => {
			// 자동 저장 기능

			handleAutoSave(2);
		}, saveTime);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		const routerTable = async () => {
			const resp = handleAutoSave(1);
		};

		return () => {
			routerTable();
		};
	}, [location, tables2]);

	const handleAutoSave = async (num = 0) => {
		const data = {
			trandata: tables2
		};
		const res = await TableAutoSave(data);
		if (res.status == "Success") {
			dispatch(updateTableSaveTime());
		}
	};
	useEffect(() => {
		setTables(tables2);
		const iniStep = handleStep();
		handleCheckAllStep(iniStep);
		let newOridata = "";
		const options = {
			indent_size: 2,
			space_in_empty_paren: true
		};

		tables2.map((data, index) => {
			newOridata += `<!-- Table${index + 1} -->`;
			newOridata += data.htmlCode;
			newOridata += "\n";
		});
		newOridata = pretty(newOridata, { ocd: true });
		SetAllTableHtmlExport(newOridata);
		if (tableData.resultHtml) {
			const newHtml = pretty(tableData.resultHtml);
			SetAllTableModifyHtmlExport(newHtml);
		}

		if (tableData.resultHtml === "" || tableData.resultHtml == undefined) {
			SetAllTableModifyHtmlExport(newOridata);
		}

		if (isChangeTag) {
			SetAllTableModifyHtmlExport(newOridata);
			setIsChangeTag(false);
		}
	}, [tables2]);

	useEffect(() => {
		const handleBeforeClose = async () => {
			const data = {
				tiId: tableData.tiId,
				html: allTableModifyHtmlExport,
				trandata: tables2
			};
			await TableCaptionALLHtmlSave(data);
		};

		window.addEventListener("beforeunload", handleBeforeClose);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeClose);
		};
	}, [tables2, allTableModifyHtmlExport]);
	const updateStep = (index, newStep) => {
		const newTableSteps = [...tableSteps];
		newTableSteps[index]["step"] = newStep;

		setTableSteps(newTableSteps);

		handleCheckAllStep(newTableSteps);
	};
	const handleCheckAllStep = (newTableSteps) => {
		let allStepTwo;
		if (newTableSteps.length > 0) {
			allStepTwo = newTableSteps.every((step) => step.step === 2 && !step.isLoading);
		} else {
			allStepTwo = false;
		}
		setIsAllStepsCompleted(allStepTwo);
	};

	const handleBack = () => {
		navigate(`/tool/table/add`);
	};

	// 편집한 HTML 복사
	const handleToastHtmlCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(allTableModifyHtmlExport);
		} catch (error) {
			console.error("클립보드 복사 에러", error);
		}
		setShowToastHtmlCopy(true);
	};

	// 원본 코드 복사
	const handleToastOriginalCodeCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(allTableHtmlExport);
		} catch (error) {
			console.error("클립보드 복사 에러", error);
		}
		setshowToastOriginalCodeCopy(true);
	};

	return (
		<>
			<div className="container table-work-detail-page table-work-detail-step1-page">
				<HeaderDetails
					className="service-table"
					subTitle={tableData.workspaceName}
					workspaceId={tableData.workspaceId}
					currentTime2={datas.saveTime ?? undefined}
					onWorkNameChange={handleWorkNameUpdate}
					saveTime={datas?.resData?.saveSecond || DefaultAutoSaveTime}
				/>
				<div className="body-details">
					{isLoading ? (
						<>
							<div className="content-header">
								<div className="title-area">
									<p className="title">표를 추출하고 있습니다.</p>
								</div>
							</div>
							<div className="table-container">
								<div className="table-acc-item opened table-acc-loading">
									<LoadingOverlay loadingText="표 추출 및 axe 검사 중..." />
									<div className="table-item-header">
										<div className="left">
											<h3 className="title">표 1</h3>
											<p>
												<span className="badge badge-violation">
													<i className="ico ico-violation-sm"></i> 위반
												</span>
											</p>
										</div>
										<div className="right">
											<span className="status btn btn-toggle">
												<i className="ico ico-caret-down-darkgray-sm"></i>
											</span>
										</div>
									</div>
									<div className="table-item-body">
										<div className="preview-area">
											<div className="html-preview">
												<table>
													<caption></caption>
													<colgroup>
														<col width="*" />
														<col width="43%" />
														<col width="43%" />
													</colgroup>
													<tbody>
														<tr>
															<th className="a11y-warning">..</th>
															<th scope="col">사범대학생</th>
															<th scope="col">교직과정이수자</th>
														</tr>
														<tr>
															<td>2008이전</td>
															<td>일반대학에서 교직복수전공 과목의 실점평균이 80점이상</td>
															<td>전공과목과 교직 과목의 실점 평균이 각각 80점 이상</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="result-area">
											<ol className="step-area">
												<li className="current" aria-current="step">
													<span className="num">1</span>
													<span className="txt">표 접근성 위반 검사</span>
												</li>
												<li>
													<span className="num">2</span>
													<span className="txt">표 캡션 AI 생성</span>
												</li>
											</ol>
											<div className="axe-table-validator">
												<div className="violations">
													<h4 className="title">
														<span>
															표 접근성 위반 <span className="text-invalid">1</span>건 검출
														</span>
														<small>axe 4.8.3</small>
													</h4>
													<div className="result-list">
														<div className="icon">
															<i className="ico ico-rowtitle-sm"></i>
														</div>
														<dl className="violation-item">
															<dt>
																<p className="desc">빈 제목 셀에 내용을 입력하세요.</p>
																<p className="help">테이블 헤더는 인식 가능한 텍스트를 포함해야 합니다.</p>
															</dt>
															<dd className="item-find">
																<strong className="find-title">위반 요소 찾기</strong>
																<ul className="violation-list">
																	<li>
																		<button className="btn btn-xs btn-primary" type="button">
																			1
																		</button>
																	</li>
																</ul>
															</dd>
														</dl>
													</div>
												</div>
												<div className="btn-group-flex mt-4">
													<button type="button" className="btn btn-lg btn-gray">
														접근성 위반 검사하기
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="content-header">
								<div className="title-area">
									<p className="title">
										표가 <span className="text-primary">{tables.length}건</span> 추출되었습니다.
									</p>
								</div>
								<p className="guide-text">
									<i className="ico ico-warning-sm"></i>
									데이터가 변하는 표(게시판, 통계)는 캡션도 자동 갱신 처리해보세요.
								</p>
							</div>
							<div className="table-container">
								{tableSteps.map((step, index) => (
									<TableAccItem
										key={index}
										index={index}
										prestep={step.step}
										tableData={tables2[index]}
										setIsChangeTag={setIsChangeTag}
										updateStep={(newStep) => updateStep(index, newStep)}
									/>
								))}
							</div>
						</>
					)}
				</div>
				<FooterDetails>
					<div className="wrap-left">
						<div className="btn-group">
							<button type="button" className="btn btn-lg btn-gray" onClick={handleBack}>
								<i className="ico ico-arrow-left-white"></i> 처음으로
							</button>
						</div>
					</div>
					<div className="wrap-right">
						<div className="guide-area">
							<i className="ico ico-info-sm"></i>
							<p>내보내기에서 HTML을 수정할 수 있어요.</p>
						</div>

						<button
							className="btn btn-lg btn-primary"
							// [개발] 모든 캡션이 생성 완료되었을 때 disabled="false"
							disabled={!isAllStepsCompleted}
							onClick={openExportPopup}
						>
							<i className="ico ico-export-white-sm"></i> 전체 표 HTML 내보내기
						</button>
					</div>
				</FooterDetails>
			</div>

			{/* 전체 표 HTML 내보내기 팝업 */}
			<PopupLayout
				isOpen={isTableCreatePopup}
				closePopup={closeTableCreatePopup}
				title="전체 표 HTML 내보내기"
				size="md"
				className="popup-seocreate"
				footerContent={
					<>
						<div className="wrap-left">
							<div className="guide-area">
								<i className="ico ico-warning-sm"></i>
								<p>데이터가 변하는 표(게시판, 통계)는 캡션도 자동 갱신 처리해보세요.</p>
							</div>
						</div>
						<div className="wrap-right">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={handleToastHtmlCopy}>
								<i className="ico ico-copy-sm"></i>
								편집한 HTML 복사
							</button>
						</div>
					</>
				}
			>
				<div className="seocreate-result">
					<div className="seocreate-result-area">
						<div className="title-area">
							<div className="title3">
								<strong>
									<span className="num">1</span>선택한 <code className="font-red">&lt;caption&gt;</code> 캡션이 적용된
									테이블 코드 입니다.
								</strong>
								<button type="button" className="btn btn-xs btn-outline-gray" onClick={handleToastOriginalCodeCopy}>
									<i className="ico ico-copy-xs"></i>
									원본 코드 복사
								</button>
							</div>
							<div className="title3">
								<strong>
									<span className="num">2</span>1번의 원본 코드를 자유롭게 수정해보세요!
								</strong>
							</div>
						</div>
						<div className="diff-area">
							<CodeMirrorMerge className="code-mirror-merge" orientation="a-b" theme={material}>
								<CodeMirrorMerge.Original
									value={allTableHtmlExport}
									extensions={[EditorState.readOnly.of(true), html()]}
								/>
								<CodeMirrorMerge.Modified
									value={allTableModifyHtmlExport}
									extensions={[html()]}
									onChange={handleChange}
									editable={true}
								/>
							</CodeMirrorMerge>
						</div>
					</div>

					{showToastOriginalCodeCopy && (
						<Toast
							message="원본 코드가 복사되었습니다."
							onClose={() => {
								setshowToastOriginalCodeCopy(false);
							}}
							icon={<i className="ico ico-copy-white-sm"></i>}
							position="bottom"
							theme="dark"
						/>
					)}
					{showToastHtmlCopy && (
						<Toast
							message="편집된 HTML이 복사되었습니다."
							onClose={() => {
								setShowToastHtmlCopy(false);
							}}
							icon={<i className="ico ico-copy-white-sm"></i>}
							position="bottom"
							theme="dark"
						/>
					)}
				</div>
			</PopupLayout>
		</>
	);
};

export default TableWorkDetailPage;
