import { action } from "redux";
import { RESET_TABLE_DATA, SET_TABLE_DATA, UPDATE_TABLE_CAPTION_CHOICE, UPDATE_TABLE_CAPTION_LOADING, UPDATE_TABLE_HTMLCODE, UPDATE_TABLE_RECOMMAND_CAPTION, UPDATE_TABLE_RESULT_HTML, UPDATE_TABLE_SAVE_TIME } from "../action/tableAction";
import { getTimeMin } from "@/services/util/DateTimeModule";

const { RESET_STATE } = require("../action/ResetAction");

const initialState = {
	tableData: {}
};

const tableReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TABLE_DATA:
			return {
				...state,
				tableData: action.payload
			};
			break;

		case UPDATE_TABLE_RECOMMAND_CAPTION:

			const transformedData=state.tableData.data.transformedData.map((item)=>//{
		
				item.id ===action.payload.toId
				?
				{
					...item,
					htmlCode :action.payload.html,
					caption:{
						...item.caption,
						recommendedCaptions:[action.payload.summaryCaption,action.payload.naturalCaption]
					},
					choice:false
				}
				:item
			)
			return{

				...state,
				tableData:{
						data: {
						...state.tableData.data,								
						transformedData:transformedData
					}
				}
			}
		case UPDATE_TABLE_HTMLCODE:

				const transformedData3=state.tableData.data.transformedData.map((item)=>//{
			
					item.id ===action.payload.toId
					?
					{
						...item,
						htmlCode:action.payload.html
						
					}
					:item
				)
				return{
	
					...state,
					tableData:{
							data: {
							...state.tableData.data,								
							transformedData:transformedData3
						}
					}
				}
		case UPDATE_TABLE_CAPTION_LOADING:
	
			const transformedData2=state.tableData.data.transformedData.map((item)=>//{
		
				item.id ===action.payload.toId
				?
				{
					...item,
					isLoading:action.payload.isLoading
					
				}
				:item
			)
			return{

				...state,
				tableData:{
						data: {
						...state.tableData.data,								
						transformedData:transformedData2
					}
				}
			}

		case UPDATE_TABLE_CAPTION_CHOICE:
			let transformedData4=state.tableData.data.transformedData
			transformedData4[action.payload.idx].choice=action.payload.choice
			return{

				...state,
				tableData:{
						data: {
						...state.tableData.data,								
						transformedData:transformedData4
					}
				}
			}

		case UPDATE_TABLE_RESULT_HTML:
			return{
				...state,
				tableData:{
					data:{
						...state.tableData.data,
						resData:{
							...state.tableData.data.resData,
							resultHtml : action.payload.resultHtml
						}
					}
				}
			}
		case UPDATE_TABLE_SAVE_TIME:
			const time = getTimeMin()
			return{
				...state,
				tableData:{
					data:{
						...state.tableData.data,
						saveTime : time
					}
				}
			}

		case RESET_STATE:
			return initialState;
			break;

		case RESET_TABLE_DATA:
			return initialState;
			break
			
			;
		default:
			return state;
	}
};

export default tableReducer;
