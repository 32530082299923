import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { persistor } from "@/services/store/ReduxStore";
import resetState from "@/services/store/action/ResetAction";
import { removeCookie } from "@/services/cookies/UserCookies";

const LogoutButton3 = ({ setAuth, setIsProfileMorePopupOpen }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleLogout = async () => {
		try {
			setIsProfileMorePopupOpen(false);
			setAuth(false);
			removeCookie("token", { path: "/" }); // 쿠키 제거
			await persistor.purge();
			dispatch(resetState());
			navigate("/");
		} catch (error) {
			navigate("/");
		}
	};

	return (
		<button onClick={handleLogout} className="btn btn-sm">
			로그아웃
		</button>
	);
};

export default LogoutButton3;
