import React, { useEffect, useState, useCallback } from "react";
import axe from "axe-core";
import koLocale from "axe-core/locales/ko.json";
import AxeCoreViolationActions from "@/plugins/axe-linter/AxeCoreViolationActions";

// Axe 실행 큐를 관리하는 객체
const axeRunQueue = (() => {
	let queue = [];
	let isRunning = false;

	const runNext = async () => {
		if (isRunning || queue.length === 0) return;
		isRunning = true;
		const task = queue.shift();
		const { previewRef, config, resolve, reject } = task;
		try {
			axe.configure({ locale: koLocale });
			if (previewRef.current) {
				// previewRef.current가 유효한지 확인
				const results = await axe.run(previewRef.current, config);
				resolve(results);
			} else {
				reject(new Error("Preview reference is no longer valid."));
			}
		} catch (error) {
			reject(error);
		} finally {
			isRunning = false;
			runNext();
		}
	};

	const enqueue = (previewRef, config) =>
		new Promise((resolve, reject) => {
			queue.push({ previewRef, config, resolve, reject });
			if (!isRunning) runNext(); // 직접 호출 대신 상태 확인 후 호출
		});

	return { enqueue };
})();

const AxeTableValidator = ({ previewRef, onViolationCountChange, handleCreateCaption, htmlPreviewRendered, setHasInitialCheckCompleted }) => {
	const [axeResults, setAxeResults] = useState(null);
	const [totalNodes, setTotalNodes] = useState(0);
	const [showTooltip, setShowTooltip] = useState(false);
	const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);

	const handleAxeCheck = useCallback(() => {
		
		axeRunQueue
			.enqueue(previewRef, {
				runOnly: {
					type: "rule",
					values: [
						"empty-table-header",
						// "table-fake-caption",
						"td-headers-attr",
						"th-has-data-cells",
						"table-duplicate-name"
						// 기타 필요한 규칙 추가
					]
				}
			})
			.then((results) => {
				setAxeResults(results);
				const totalNodesCount = results.violations.reduce((acc, curr) => acc + curr.nodes.length, 0);
				setTotalNodes(totalNodesCount);
				onViolationCountChange(totalNodesCount);

				// 최초의 자동 검사 완료 상태 업데이트
				if (!isInitialCheckDone) {
					setIsInitialCheckDone(true);
					
				}

				
		
				
			})
			.catch((error) => {
				console.error(`[Axe 접근성 검사 오류]:`, error);
				
			});
	}, [previewRef, onViolationCountChange, isInitialCheckDone]);

	const handleAxeCheckAndCaption  = useCallback(() => {
		axeRunQueue
			.enqueue(previewRef, {
				runOnly: {
					type: "rule",
					values: [
						"empty-table-header",
						"table-fake-caption",
						"td-headers-attr",
						"th-has-data-cells",
						"table-duplicate-name"
						// 기타 필요한 규칙 추가
					]
				}
			})
			.then((results) => {
				setAxeResults(results);
				const totalNodesCount = results.violations.reduce((acc, curr) => acc + curr.nodes.length, 0);
				setTotalNodes(totalNodesCount);
				onViolationCountChange(totalNodesCount);
				// 최초의 자동 검사 완료 상태 업데이트
				if (!isInitialCheckDone) setIsInitialCheckDone(true);
				            // Axe 검사 후, handleCreateCaption 실행
				if (totalNodesCount<=0){
					handleCreateCaption();
				}
				
			})
			.catch((error) => {
				console.error(`[Axe 접근성 검사 오류]:`, error);
				
			});
	}, [previewRef, onViolationCountChange, isInitialCheckDone]);
	// 최초의 자동 검사 완료 시점에 반응
	const handleClickAxeCheck = () =>{
		handleAxeCheck()
		setHasInitialCheckCompleted(true);
	}
	useEffect(() => {
		// htmlPreviewRendered가 true이고, 최초의 자동 검사가 아직 수행되지 않았다면 검사를 실행
		
		if (htmlPreviewRendered) {
			handleAxeCheck();
		}
	}, [htmlPreviewRendered, handleAxeCheck, isInitialCheckDone]);

	useEffect(() => {
		if (axeResults && axeResults.violations.length > 0) {
			axeResults.violations.forEach((violation) => {
				const action = AxeCoreViolationActions[violation.id] || AxeCoreViolationActions.default;
				if (action.init) {
					violation.nodes.forEach((node) => {
						action.init(node.target[0]);
					});
				}
			});
		}
		// 의존성 배열에 axeResults를 추가하여 axeResults가 변경될 때마다 이 효과를 실행합니다.
	}, [axeResults]);

	// 툴팁
	const handleShowTooltip = () => setShowTooltip(true);
	const handleHideTooltip = () => setShowTooltip(false);

	return (
		<div className="axe-table-validator">
			{!axeResults ? (
				<div className="loading">
					<p>분석 중...</p>
				</div>
			) : axeResults.violations.length > 0 ? (
				<>
					<div className="violations">
						<h4 className="title">
							<span>
								표 접근성 위반 <span className="text-invalid">{totalNodes}</span>건 검출
							</span>
							<small>axe 4.8.3</small>
						</h4>
						{axeResults?.violations.map((violation, vIndex) => {
							// AxeCoreViolationActions에서 오류 유형에 대한 정보를 가져옴
							const action = AxeCoreViolationActions[violation.id] || AxeCoreViolationActions.default;
							return (
								<div key={vIndex} className="result-list">
									<div className="icon">{action.icon}</div>
									<dl className="violation-item">
										<dt>
											{/* 조건부 렌더링으로 help 및 description 출력 */}
											{action.help ? (
												<>
													<p className="desc">{action.description}</p>
													<p className="help">{action.help}</p>
												</>
											) : (
												<>
													<p className="desc">{violation.help}</p>
													<p className="help">{violation.description}</p>
												</>
											)}
										</dt>
										<dd className="item-find">
											<strong className="find-title">위반 요소 찾기</strong>
											<ul className="violation-list">
												{violation.nodes.map((node, nIndex) => {
													return (
														<li key={nIndex}>
															<button
																className="btn btn-xs btn-primary"
																type="button"
																onClick={() => {
																	action.onClick(node.target[0], previewRef.current);
																}}
															>
																{nIndex + 1}
															</button>
														</li>
													);
												})}
											</ul>
										</dd>
									</dl>
								</div>
							);
						})}
					</div>
					<div className="btn-group-flex mt-4">
						<button type="button" className="btn btn-lg btn-dark" onClick={handleClickAxeCheck}>
							접근성 위반 검사하기
						</button>
					</div>
				</>
			) : (
				<>
					<div className="no-result">
						<i className="ico ico-pass mb-3"></i>
						<p>통과했어요.</p>
					</div>
					{showTooltip && (
						<div className="tooltip">
							<p>입력된 내용으로 캡션을 생성할까요?</p>
							<p className="subject">더 이상 표를 편집할 수 없게 됩니다.</p>
						</div>
					)}
					<div className="btn-group-flex">
						<button
							type="button"
							className="btn btn-lg btn-primary"
							onClick={handleAxeCheckAndCaption}
							onMouseEnter={handleShowTooltip}
							onMouseLeave={handleHideTooltip}
							onFocus={handleShowTooltip}
							onBlur={handleHideTooltip}
						>
							표 캡션 AI 생성하기 <i className="ico ico-arrow-right-sm"></i>
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default AxeTableValidator;
