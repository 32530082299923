import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PopupLayout from "@/components/popup/PopupLayout";
import PopupLayer from "@/components/popup/PopupLayer";
import Toast from "@/components/toast/Toast";
import nodataImg from "@/assets/images/img-nodata@2x.png";

import TaskData from "@/mock/TaskData";
import WorkUsePopup from "@/services/core/workspace/WorkSpacePopup";
import { WorkSpaceChangeName } from "@/services/core/workspace/WorkSpaceChangeName";
import { TrashingIt } from "@/services/core/workspace/TrashingIt";
import CustomConfirmModal from "@/services/core/workspace/TrashingConfirm";
import { DataCount } from "@/services/core/mypage/MyDataCount";
import { useDispatch, useSelector } from "react-redux";
import { setDataCount } from "@/services/store/action/MyDataCountAction";
import { DashBoard } from "@/services/core/workspace/DashBoard";
import { setDashboardData } from "@/services/store/action/DashBoard";
import { TrashRestore } from "./TrashRestore";
import { fetchTrashCanData } from "@/services/store/action/TrashCanAction";
import { TrashDelete } from "./TreashDelete";
import { navigateTo } from "@/services/util/Navigate";
import InputTaskNameWork from "@/components/form/InputTaskNameWork";
import { hideToast, showToast, updateToastKey } from "@/services/store/action/ToastAction";
import {
	setSortDesc,
	setTrashActive,
	setTrashOrderBy,
	setTrashSortDesc,
	setWorkActive,
	setWorkOrderBy
} from "@/services/store/action/SortOrderAction";
import { handleWorkDetail } from "./WorkspaceDetail";
import numeral from "numeral";
import { fileSizeDisplay } from "@/services/util/FileSize";

export default function ListTaskWork({
	reduxSort,
	workItems: initialWorkItems,
	itemCount,
	onSelectedItemsCountChange,
	setOrderBy,
	setDesc,
	orderBy,

	work,
	onWorkNameChangeSuccess,
	trashItSuccess,
	onSelectAllCheckedChange,
	onSelectedItemsChange
}) {
	const MyPageDesc = useSelector((state) => state.sort.desc);
	const TrashDesc = useSelector((state) => state.trashSort.desc);
	const seoData = useSelector((state) => state.seo.seoData);
	const tableData = useSelector((state) => state.table?.tableData || []);
	const brightness = useSelector((state) => state);

	const html = useSelector((state) => state.html?.htmlData || []);
	const listDataRowNumber = useSelector((state) => state.workspace?.workSpaceData?.data?.[0]?.rownum - 1 || 0);
	const TrashDataRowNumber = useSelector((state) => state.trash?.trashCanData?.data?.[0]?.rownum - 1 || 0);

	let desc, currentDesc1, currentDesc2, currentDesc3, currentDesc4, currentDesc5;
	if (reduxSort === "MYPAGE") {
		desc = MyPageDesc;
		currentDesc1 = MyPageDesc.desc1;
		currentDesc2 = MyPageDesc.desc2;
		currentDesc3 = MyPageDesc.desc3;
		currentDesc4 = MyPageDesc.desc4;
		currentDesc5 = MyPageDesc.desc5;
	} else if (reduxSort === "TRASHPAGE") {
		desc = TrashDesc;
		currentDesc1 = TrashDesc.desc1;
		currentDesc2 = TrashDesc.desc2;
		currentDesc3 = TrashDesc.desc3;
		currentDesc4 = TrashDesc.desc4;
		currentDesc5 = TrashDesc.desc5;
	}

	useEffect(() => {
		setSelectAllChecked(false); // 신호가 변경될 때마다 실행
		setSelectedItems(new Set());
		const allRows = document.querySelectorAll(".table-task tbody tr");
		allRows.forEach((row) => {
			row.classList.remove("on");
		});

		onSelectedItemsCountChange(0);
	}, [onSelectAllCheckedChange]);
	const dispatch = useDispatch();
	// 작업 리스트
	const [workItems, setWorkItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState(new Set());
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [sortState, setSortState] = useState({ field: null, direction: null });
	const {
		isOpen: isDefaultPopup,
		open: openDefaultPopup,
		close: closeDefaultPopup,
		selectedId,
		selectedName
	} = WorkUsePopup();

	const [userWorkName, setWorkName] = useState("");

	// 더보기 팝업
	const moreButtonRef = useRef([]);
	const [popupStates, setPopupStates] = useState({});
	const [showToastRestore, setShowToastRestore] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState(null);
	const [toast, setToast] = useState(false);
	const refreshData = async (after, desc, order) => {
		const refreshParams = {
			after: after,
			count: 15,
			search: "",
			service_type: "",
			order_by: order,
			is_desc: desc
		};
		dispatch(fetchTrashCanData(refreshParams));
		const dataCountResult = await DataCount();
		dispatch(setDataCount(dataCountResult));
	};

	const handleToastRestore = async (id) => {
		const isTruePresent = Object.values(TrashDesc).some((value) => value === true);
		const result = isTruePresent ? true : false;

		const validDesc = Object.entries(desc)
			.filter(([key, value]) => value !== null)
			.map(([key, value]) => key);

		const orderByNum = [
			{ id: 1, value: "desc1" },
			{ id: 2, value: "desc2" },
			{ id: 3, value: "desc3" },
			{ id: 4, value: "desc4" },
			{ id: 5, value: "desc5" }
		];

		const matchingItems = orderByNum.filter((item) => validDesc.includes(item.value));
		const ids = matchingItems.map((item) => item.id).join(", ");

		try {
			await TrashRestore(id);
			dispatch(showToast());
			dispatch(updateToastKey());
			await refreshData(TrashDataRowNumber, result, ids);
		} catch (error) {
			alert({ message: "복원에 실패하였습니다." });
			return;
		}
	};

	const handleTrashDelete = async (id) => {
		const isTruePresent = Object.values(TrashDesc).some((value) => value === true);
		const result = isTruePresent ? true : false;

		const validDesc = Object.entries(desc)
			.filter(([key, value]) => value !== null)
			.map(([key, value]) => key);

		const orderByNum = [
			{ id: 1, value: "desc1" },
			{ id: 2, value: "desc2" },
			{ id: 3, value: "desc3" },
			{ id: 4, value: "desc4" },
			{ id: 5, value: "desc5" }
		];

		const matchingItems = orderByNum.filter((item) => validDesc.includes(item.value));
		const ids = matchingItems.map((item) => item.id).join(", ");

		const resp = await TrashDelete(id);

		if (resp === "Success") {
			await refreshData(TrashDataRowNumber, result, ids);
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
		}

		if (resp === "Fail") {
			alert({ message: "영구 삭제를 실패하였습니다." });
			return;
		}
	};

	// input 요소의 값이 변경될 때 호출
	const handleWorkNameChange = (event) => {
		setWorkName(event);
	};

	useEffect(() => {
		setWorkItems(initialWorkItems.slice(0, itemCount)); // itemCount만큼 항목 로드
	}, [itemCount]);
	// 더보기 팝업
	const handleButtonMouseDown = (event) => {
		event.stopPropagation();
	};
	const closeMorePopup = (id) => {
		setPopupStates((prevStates) => ({
			...prevStates,
			[id]: false // 특정 작업 항목의 팝업을 닫음
		}));
		setActiveButtonId(null);
	};
	const toggleMorePopup = (id, event) => {
		event.stopPropagation();
		setPopupStates((prevStates) => {
			const newStates = Object.keys(prevStates).reduce((states, itemId) => {
				states[itemId] = false;
				return states;
			}, {});

			newStates[id] = !prevStates[id];
			return newStates;
		});

		setActiveButtonId((prevActiveId) => (prevActiveId === id ? null : id));
	};

	// 리스트 선택
	const handleSelectItem = (id) => {
		const newSelectedItems = new Set(selectedItems);
		if (selectedItems.has(id)) {
			newSelectedItems.delete(id);
		} else {
			newSelectedItems.add(id);
		}
		onSelectedItemsChange(newSelectedItems);
		setSelectedItems(newSelectedItems);

		// 전체 선택 상태 검사 (하나의 선택이라도 해제되었을 때)
		const allSelected = workItems.length > 0 && workItems.every((item) => newSelectedItems.has(item.id));
		setSelectAllChecked(allSelected);

		// 체크된 항목에 대한 부모 tr에 클래스 추가 또는 제거
		const checkboxes = document.querySelectorAll('.check-item-wrap input[type="checkbox"]');
		checkboxes.forEach((checkbox) => {
			const td = checkbox.closest(".check-item-wrap");
			const tr = td.closest("tr");

			if (tr && checkbox.checked) {
				tr.classList.add("on");
			} else {
				tr.classList.remove("on");
			}
		});

		// 부모 컴포넌트에 체크된 항목의 개수 전달
		onSelectedItemsCountChange(newSelectedItems.size);
	};

	// 리스트 전체 선택
	const handleSelectAll = () => {
		if (selectAllChecked) {
			// 전체 선택 해제
			setSelectedItems(new Set());
			setSelectAllChecked(false);
			// 모든 tr에 대한 on 클래스 제거
			const allRows = document.querySelectorAll(".table-task tbody tr");
			allRows.forEach((row) => {
				row.classList.remove("on");
			});
		} else {
			// 전체 선택
			const newSelectedItems = new Set(workItems.map((item) => item.id));
			setSelectedItems(newSelectedItems);
			onSelectedItemsChange(newSelectedItems);
			setSelectAllChecked(true);
			// 모든 tr에 대한 on 클래스 추가
			const allRows = document.querySelectorAll(".table-task tbody tr");
			allRows.forEach((row) => {
				row.classList.add("on");
			});
		}

		// 부모 컴포넌트에 체크된 항목의 개수 전달
		onSelectedItemsCountChange(!selectAllChecked ? workItems.length : 0);
	};

	// 리스트 링크
	const navigate = useNavigate();
	const handleTableRowClick = (type, id) => {};

	const handleDetail = (workspaceType, workspaceId) => {
		if (workspaceType === "seo") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, seoData);
		} else if (workspaceType === "table") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, tableData);
		} else if (workspaceType === "img2html") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, html);
		} else if (workspaceType === "contrast") {
			handleWorkDetail(workspaceType, workspaceId, navigate, dispatch, brightness);
		}

		//TODO 각각 타입에 따라서 처리함
	};

	const handleSetDesc = (field) => {
		// 모든 desc 상태를 초기화하고, 클릭된 필드의 상태만 업데이트
		switch (field) {
			case "workName":
				setOrderBy(2);
				if (reduxSort === "MYPAGE") {
					dispatch(setSortDesc("desc1", null));
					dispatch(setSortDesc("desc2", !currentDesc2));
					dispatch(setSortDesc("desc3", null));
					dispatch(setSortDesc("desc4", null));
					dispatch(setSortDesc("desc5", null));
					dispatch(setWorkOrderBy(2));
					dispatch(setWorkActive(!currentDesc2));
				}

				if (reduxSort === "TRASHPAGE") {
					dispatch(setTrashSortDesc("desc1", null));
					dispatch(setTrashSortDesc("desc2", !currentDesc2));
					dispatch(setTrashSortDesc("desc3", null));
					dispatch(setTrashSortDesc("desc4", null));
					dispatch(setTrashSortDesc("desc5", null));
					dispatch(setTrashOrderBy(2));
					dispatch(setTrashActive(!currentDesc2));
				}

				break;
			case "fileType":
				setOrderBy(3);
				if (reduxSort === "MYPAGE") {
					dispatch(setSortDesc("desc1", null));
					dispatch(setSortDesc("desc2", null));
					dispatch(setSortDesc("desc3", !currentDesc3));
					dispatch(setSortDesc("desc4", null));
					dispatch(setSortDesc("desc5", null));
					dispatch(setWorkOrderBy(3));
					dispatch(setWorkActive(!currentDesc3));
				}

				if (reduxSort === "TRASHPAGE") {
					dispatch(setTrashSortDesc("desc1", null));
					dispatch(setTrashSortDesc("desc2", null));
					dispatch(setTrashSortDesc("desc3", !currentDesc3));
					dispatch(setTrashSortDesc("desc4", null));
					dispatch(setTrashSortDesc("desc5", null));
					dispatch(setTrashOrderBy(3));
					dispatch(setTrashActive(!currentDesc3));
				}

				break;
			case "fileSize":
				setOrderBy(4);

				if (reduxSort === "MYPAGE") {
					dispatch(setSortDesc("desc1", null));
					dispatch(setSortDesc("desc2", null));
					dispatch(setSortDesc("desc3", null));
					dispatch(setSortDesc("desc4", !currentDesc4));
					dispatch(setSortDesc("desc5", null));
					dispatch(setWorkOrderBy(4));
					dispatch(setWorkActive(!currentDesc4));
				}

				if (reduxSort === "TRASHPAGE") {
					dispatch(setTrashSortDesc("desc1", null));
					dispatch(setTrashSortDesc("desc2", null));
					dispatch(setTrashSortDesc("desc3", null));
					dispatch(setTrashSortDesc("desc4", !currentDesc4));
					dispatch(setTrashSortDesc("desc5", null));
					dispatch(setTrashOrderBy(4));
					dispatch(setTrashActive(!currentDesc4));
				}
				break;
			case "createdDate":
				setOrderBy(5);
				if (reduxSort === "MYPAGE") {
					dispatch(setSortDesc("desc1", null));
					dispatch(setSortDesc("desc2", null));
					dispatch(setSortDesc("desc3", null));
					dispatch(setSortDesc("desc4", null));
					dispatch(setSortDesc("desc5", !currentDesc5));
					dispatch(setWorkOrderBy(5));
					dispatch(setWorkActive(!currentDesc5));
				}

				if (reduxSort === "TRASHPAGE") {
					dispatch(setTrashSortDesc("desc1", null));
					dispatch(setTrashSortDesc("desc2", null));
					dispatch(setTrashSortDesc("desc3", null));
					dispatch(setTrashSortDesc("desc4", null));
					dispatch(setTrashSortDesc("desc5", !currentDesc5));
					dispatch(setTrashOrderBy(5));
					dispatch(setTrashActive(!currentDesc5));
				}

				break;
			default:
				setOrderBy(1);

				if (reduxSort === "MYPAGE") {
					dispatch(setSortDesc("desc1", !currentDesc1));
					dispatch(setSortDesc("desc2", null));
					dispatch(setSortDesc("desc3", null));
					dispatch(setSortDesc("desc4", null));
					dispatch(setSortDesc("desc5", null));
					dispatch(setWorkOrderBy(1));
					dispatch(setWorkActive(!currentDesc1));
				}

				if (reduxSort === "TRASHPAGE") {
					dispatch(setTrashSortDesc("desc1", !currentDesc1));
					dispatch(setTrashSortDesc("desc2", null));
					dispatch(setTrashSortDesc("desc3", null));
					dispatch(setTrashSortDesc("desc4", null));
					dispatch(setTrashSortDesc("desc5", null));
					dispatch(setTrashOrderBy(1));
					dispatch(setTrashActive(!currentDesc1));
				}

				break;
		}
	};

	// 목록 정렬 필터
	const handleSort = (field) => {
		handleSetDesc(field);
	};
	/// 작업명 변경
	const handleChangeWorkName = async () => {
		const resp = await WorkSpaceChangeName(selectedId, userWorkName);
		const isTruePresent = Object.values(MyPageDesc).some((value) => value === true);
		const isFalsePresent = !isTruePresent && Object.values(MyPageDesc).some((value) => value === false);

		const result = isTruePresent ? true : false;

		if (resp) {
			onWorkNameChangeSuccess(listDataRowNumber, result);
			closeDefaultPopup();
		} else {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};

	/// 휴지통 보내기
	const handleTrash = async (id) => {
		const isTruePresent = Object.values(MyPageDesc).some((value) => value === true);
		const isFalsePresent = !isTruePresent && Object.values(MyPageDesc).some((value) => value === false);

		const result = isTruePresent ? true : false;
		const resp = await TrashingIt(id);
		if (resp) {
			trashItSuccess(listDataRowNumber, result);
			const dataCountResult = await DataCount();
			dispatch(setDataCount(dataCountResult));
		} else {
			alert({ message: "작업명 변경에 실패하였습니다." });
			return;
		}
	};
	const link = {
		display: "flex",
		alignItems: "center"
	};
	return (
		<>
			{workItems.length > 0 ? (
				<>
					<div className="table table-task">
						<table>
							<caption className="sr-only">최근 작업 목록</caption>
							<colgroup>
								<col style={{ width: "48px" }} />
								<col />
								<col style={{ width: "120px" }} />
								<col style={{ width: "120px" }} />
								<col style={{ width: "160px" }} />
								<col style={{ width: "160px" }} />
								<col style={{ width: "40px" }} />
							</colgroup>
							<thead>
								<tr>
									<th>
										<span className="check-item">
											<input type="checkbox" id="select-chkall" onChange={handleSelectAll} checked={selectAllChecked} />
											<label htmlFor="select-chkall">
												<span className="sr-only">전체 선택</span>
											</label>
										</span>
									</th>

									<th className="text-left">
										<button
											className={`btn-sort ${currentDesc2 !== null ? "active" : ""}`}
											data-field="workName"
											onClick={() => handleSort("workName")}
										>
											작업명
											{currentDesc2 !== null && (
												<i className={`ico ${currentDesc2 ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${currentDesc3 !== null ? "active" : ""}`}
											data-field="fileType"
											onClick={() => handleSort("fileType")}
										>
											원본 파일 타입
											{currentDesc3 !== null && (
												<i className={`ico ${currentDesc3 ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${currentDesc4 !== null ? "active" : ""}`}
											data-field="fileSize"
											onClick={() => handleSort("fileSize")}
										>
											원본 파일 크기
											{currentDesc4 !== null && (
												<i className={`ico ${currentDesc4 ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${currentDesc1 !== null ? "active" : ""}`}
											data-field="lastModified"
											onClick={() => handleSort("lastModified")}
										>
											{reduxSort === "TRASHPAGE" ? "휴지통 이동일시" : "마지막 수정 일시"}

											{currentDesc1 !== null && (
												<i className={`ico ${currentDesc1 ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>
										<button
											className={`btn-sort ${currentDesc5 !== null ? "active" : ""}`}
											data-field="createdDate"
											onClick={() => handleSort("createdDate")}
										>
											{reduxSort === "TRASHPAGE" ? "영구삭제 예정일시" : "생성일시"}

											{currentDesc5 !== null && (
												<i className={`ico ${currentDesc5 ? "ico-arrow-down-sm" : "ico-arrow-up-sm"}`}></i>
											)}
											<span className="sr-only">정렬</span>
										</button>
									</th>
									<th>작업</th>
								</tr>
							</thead>
							<tbody>
								{workItems.map((workitem, index) => (
									<tr key={index} onClick={() => handleTableRowClick(workitem.service.type, workitem.id)}>
										<td className="check-item-wrap" onClick={(e) => e.stopPropagation()}>
											<span className="check-item">
												<input
													type="checkbox"
													id={`select-chk${workitem.id}`}
													checked={selectedItems.has(workitem.id)}
													title={workitem.workName + " 선택"}
													onChange={(e) => {
														e.stopPropagation();
														handleSelectItem(workitem.id);
													}}
												/>
												<label htmlFor={`select-chk${workitem.id}`}>
													<span className="sr-only">선택</span>
												</label>
											</span>
										</td>
										<td>
											{/* 임시보류  */}
											<div
												style={link}
												onClick={() => handleDetail(workitem.service.type, workitem.id)}
												tabIndex="0"
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														handleDetail(workitem.service.type, workitem.id);
													}
												}}
											>
												<span className="service-type">
													<i className={`ico ico-${workitem.service.type}-md`}></i>
													<span className="sr-only">{workitem.service.name}</span>
												</span>
												<strong className="task-name">
													<p className="file-name">{workitem.workName}</p>

													{workitem.fileShared && (
														<span className="label-shared">
															<i className="ico ico-shared-md"></i>
															<span>공개된 작업</span>
														</span>
													)}
												</strong>
											</div>
											{/* </Link> */}
										</td>
										<td>
											<span className="file-type">{workitem.fileType}</span>
										</td>
										<td>
											<span className="file-size">{fileSizeDisplay(workitem.fileSize)}</span>
										</td>
										<td>
											<span className="last-date">{workitem.lastModified}</span>
										</td>
										<td>
											<span className="date-time">{workitem.createdDate}</span>
										</td>
										<td className="more-action-wrap" onClick={(e) => e.stopPropagation()}>
											<span className={`more-action ${activeButtonId === workitem.id ? "on" : ""}`}>
												<button
													type="button"
													className="btn-action"
													onMouseDown={handleButtonMouseDown}
													onClick={(e) => toggleMorePopup(workitem.id, e)}
													ref={(el) => (moreButtonRef.current[workitem.id] = el)}
												>
													<i className="ico ico-more-action"></i>
													<span className="sr-only">더보기</span>
												</button>
											</span>

											{/* 더보기 레이어 팝업 */}
											<PopupLayer
												className="more-popup"
												isOpen={popupStates[workitem.id]}
												closePopup={() => closeMorePopup(workitem.id)}
												title="더보기 팝업"
												headerContent={null}
												footerContent={null}
												buttonRef={(el) => (moreButtonRef.current[workitem.id] = el)}
												position="top"
											>
												<div className="info-detail">
													<div className="btn-group">
														{/* [개발] 대시보드, 내 작업공간 페이지에서는 (작업명 변경, 이 작업 휴지통으로 이동) 버튼만 노출 / 
                                휴지통 페이지에서는 (복원, 이 작업 영구 삭제) 버튼만 노출 */}
														{work === "work" ? (
															<div>
																<button
																	type="button"
																	className="btn btn-md"
																	onClick={(e) => {
																		openDefaultPopup(workitem.id, workitem.workName, e);
																		setWorkName(workitem.workName);
																	}}
																>
																	작업명 변경
																</button>

																<button
																	type="button"
																	className="btn btn-md"
																	onClick={() => {
																		window.confirm({
																			title: "선택한 작업을 휴지통으로 이동하시겠어요?",
																			message: "휴지통으로 이동되며 파일이 바로 삭제 되지 않아요.",
																			content: `<p class="delete-sub-text">휴지통에서 7일 이내 다시 복구할 수 있어요.</p>`,
																			onConfirm: (result) => {
																				handleTrash(workitem.id);
																				closeMorePopup();
																			},
																			confirmButtonText: "휴지통에 버리기",
																			cancelButtonText: "취소"
																		});
																	}}
																>
																	이 작업 휴지통으로 이동
																</button>
															</div>
														) : (
															<div>
																<button
																	className="btn btn-md btn-restore"
																	onClick={() => handleToastRestore(workitem.id)}
																>
																	복원
																</button>
																<button
																	type="button"
																	className="btn btn-md btn-permanent-del"
																	onClick={() =>
																		window.confirm({
																			title: "이 작업을 영구 삭제할까요?",
																			message: "삭제 후 복구할 수 없습니다.",
																			onConfirm: (result) => {
																				handleTrashDelete(workitem.id);
																			},
																			confirmButtonText: "영구 삭제",
																			cancelButtonText: "취소",
																			buttonStatus: "invalid"
																		})
																	}
																>
																	이 작업 영구 삭제
																</button>
															</div>
														)}
													</div>
												</div>
											</PopupLayer>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<div className="default-nodata">
					<div className="nodata-warp">
						<img src={nodataImg} alt="" />
						{work === "work" ? (
							<p>
								작업 공간이 준비되었습니다!<br></br>
								작업을 생성해주세요.
							</p>
						) : (
							<p>휴지통이 비어 있어요. </p>
						)}
					</div>
				</div>
			)}

			<PopupLayout
				isOpen={isDefaultPopup}
				closePopup={closeDefaultPopup}
				title="작업명 변경"
				size="default"
				className="change-work-name-popup"
				footerContent={
					// null //  버튼이 없을 경우
					<>
						<div className="btn-group">
							<button type="button" className="btn btn-xl btn-outline-gray" onClick={closeDefaultPopup}>
								취소
							</button>
							<button type="button" className="btn btn-xl btn-primary" onClick={handleChangeWorkName}>
								변경
							</button>
						</div>
					</>
				}
			>
				<InputTaskNameWork
					workName={userWorkName}
					onworkNameChange={handleWorkNameChange}
					closeMorePopup={closeMorePopup}
				></InputTaskNameWork>
			</PopupLayout>
		</>
	);
}
