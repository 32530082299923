// TableItem.js
import React, { useState, useRef, useEffect } from "react";

import HtmlPreview from "./HtmlPreview";
import AxeTableValidator from "./AxeTableValidator";
import TableCaptionChecker from "./TableCaptionChecker";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "@/components/loading/LoadingOverlay";
import Toast from "@/components/toast/Toast";
import { TableCaptionCreate } from "@/services/core/table/TableApi";
import {
	updateRecommandCaption,
	updateTableCaptionIsLoading,
	updateTablehtml
} from "@/services/store/action/tableAction";

const TableAccItem = ({ index, tableData2, prestep, updateStep, setIsChangeTag }) => {
	const [isLoading, setIsLoading] = useState(false); // 로딩 상태
	const [toggleState, setToggleState] = useState(false); // 토글 상태
	const [step, setStep] = useState(prestep); // step 상태
	const [clickState, setClickState] = useState(false);
	const tableData = useSelector((state) => state.table.tableData.data.transformedData[index]);
	const dispatch = useDispatch();
	const previewRef = useRef(null);
	const previewRef2 = useRef(null);
	const [htmlPreviewRendered, setHtmlPreviewRendered] = useState(false);
	const [violationCount, setViolationCount] = useState(null); // 위반사항 상태
	const [userInput, setUserInput] = useState({});
	const [focusSelector, setFocusSelector] = useState("");
	const [selectedCaptions, setSelectedCaptions] = useState({});
	const [hasInitialCheckCompleted, setHasInitialCheckCompleted] = useState(false);
	const [htmlCode, setHtmlCode] = useState(tableData.htmlCode);
	const [showToastTableCodeCopy, setshowToastTableCodeCopy] = useState(false);
	const [newHtmlCode, setNewHtmlCode] = useState();

	useEffect(() => {
		// Axe 검사 결과가 업데이트 된 후 최초 한 번만 실행되어야 하는 로직 //fixme 수정 필요
		if (
			tableData.caption.recommendedCaptions.length == 0 &&
			htmlPreviewRendered &&
			violationCount === 0 &&
			!hasInitialCheckCompleted &&
			!isLoading
		) {
			setIsLoading(true);
			dispatch(updateTableCaptionIsLoading(tableData.id, true));
			handleGoStep2()
				.then(() => {
					setIsLoading(false); // 로딩 상태를 false로 설정
					setHasInitialCheckCompleted(true); // 초기 검사 완료 표시
					setStep(2);
				})
				.catch((error) => {
					console.error("초기 자동 2단계 전환 중 오류 발생", error);
				});
		}
	}, [violationCount, hasInitialCheckCompleted, htmlPreviewRendered]);

	// 토글 상태 변경 함수
	const handleToggle = () => {
		setToggleState(!toggleState); // 토글 상태 반전
	};

	// step 상태 업데이트
	const moveToStep2 = () => {
		setStep(2); // step 상태를 2로 업데이트
		updateStep(2); // 부모 컴포넌트에 step 변경 알림
	};
	const moveToStep1 = () => {
		setStep(1); // step 상태를 2로 업데이트
		updateStep(1); // 부모 컴포넌트에 step 변경 알림
	};

	// 캡션 생성
	const handleCreateCaption = async () => {
		dispatch(updateTableCaptionIsLoading(tableData.id, true));
		try {
			setIsLoading(true);
			setClickState(true);

			await handleGoStep2();
		} catch (error) {
			// 비동기 작업 중 오류가 발생했을 경우 실행될 로직
			moveToStep1();
			console.error("비동기 작업 중 오류 발생", error);
		} finally {
		}
	};

	const handleGoStep2 = async () => {
		const htmlPreviewElement2 = previewRef.current.innerHTML;

		const data = {
			toId: tableData.id,
			html: htmlPreviewElement2
		};
		const result = await TableCaptionCreate(data);

		if (result.status === "Success") {
			moveToStep2();
			const naturalCaption = result.data.data.naturalCaption;
			const summaryCaption = result.data.data.summaryCaption;
			const html = result.data.data.html;
			setHtmlCode(html);
			dispatch(updateRecommandCaption(tableData.id, summaryCaption, naturalCaption, html));
			setIsLoading(false);
		} else if (result.status === "ERR_CANCELED") {
		} else {
			setIsLoading(false);
			setClickState(false);
			window.alert({
				title: "오류가 발생하였습니다.",
				message: "다시 시도해주세요."
			});
		}
	};

	// 2단계로 넘어가면 로딩 시작
	useEffect(() => {
		if (step === 2) {
			setClickState(false);
		}
	}, [step, clickState]); // step의 변경을 감지
	useEffect(() => {
		if (!isLoading) {
			dispatch(updateTableCaptionIsLoading(tableData.id, false));
		}
	}, [isLoading]);

	// 캡션 선택
	const handleCaptionSelected = (index, value) => {
		const { caption, type } = JSON.parse(value); // 문자열을 객체로 변환
		// console.error(`테이블  ${index + 1} 선택 캡션:`, caption, `유형:`, type);

		setSelectedCaptions((prev) => ({
			...prev,
			[index]: { caption, type } // caption과 type 모두 저장
		}));
	};
	useEffect(() => {
		if (previewRef2.current) {
			dispatch(updateTablehtml(tableData.id, newHtmlCode));
		}
	}, [newHtmlCode, dispatch]);

	// 위반사항 수 상태 업데이트 함수
	const updateViolationCount = (count) => {
		setViolationCount(count);
	};

	// 입력값 변경 핸들러: `AxeTableValidator`에서 사용
	const handleInputChange = (selector, value) => {
		setUserInput((prev) => ({ ...prev, [selector]: value }));
	};

	// 표 코드 복사
	const handleCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(previewRef2.current.innerHTML);
			setshowToastTableCodeCopy(true);
		} catch (error) {
			console.error("클립보드 복사 에러", error);
		}
	};

	return (
		<div
			className={`table-acc-item ${toggleState ? "" : "opened"} ${violationCount > 0 ? "warning" : "passed"} ${
				step === 1 ? "step1" : step === 2 ? "step2" : ""
			}`}
		>
			{step === 1 && (
				<>
					<div className="table-item-header">
						<div className="left">
							<h3 className="title">표 {index + 1}</h3>
							<p>
								{violationCount > 0 ? (
									<span className="badge badge-violation">
										<i className="ico ico-violation-sm"></i> 위반
									</span>
								) : (
									<span className="badge badge-pass">
										<i className="ico ico-check-green"></i> 통과
									</span>
								)}
							</p>
						</div>
						<div className="right">
							<ol className="step-area">
								<li className="current" aria-current="step">
									<span className="num">1</span>
									<span className="txt">표 접근성 위반 검사</span>
								</li>
								<li>
									<span className="num">2</span>
									<span className="txt">표 캡션 AI 생성</span>
								</li>
							</ol>
							<button className={`status btn btn-toggle ${toggleState ? "" : "on"}`} onClick={handleToggle}>
								<i className={`ico ico-caret-down-darkgray-sm`}></i>
								<span className="sr-only">{toggleState ? "펼치기" : "접기"}</span>
							</button>
						</div>
					</div>

					<div className="table-item-body">
						<div className="preview-area">
							<HtmlPreview
								ref={previewRef}
								initialCode={tableData.htmlCode}
								userInput={userInput}
								focusSelector={focusSelector}
								onRendered={setHtmlPreviewRendered}
							/>
						</div>
						<div className="result-area">
							<ol className="step-area">
								<li className="current" aria-current="step">
									<span className="num">1</span>
									<span className="txt">표 접근성 위반 검사</span>
								</li>
								<li>
									<span className="num">2</span>
									<span className="txt">표 캡션 AI 생성</span>
								</li>
							</ol>
							<AxeTableValidator
								previewRef={previewRef}
								htmlContent={tableData.htmlCode}
								onViolationCountChange={updateViolationCount}
								onFocusSelectorChange={setFocusSelector}
								onInputChange={handleInputChange}
								setHasInitialCheckCompleted={setHasInitialCheckCompleted}
								htmlPreviewRendered={htmlPreviewRendered} // 렌더링 완료 상태를 prop으로 전달
								handleCreateCaption={handleCreateCaption} // 캡션생성 및 2단계로 이동
							/>
						</div>
					</div>
				</>
			)}

			{/* [개발] 최초로딩 직후 검사 결과가 통과일 경우 바로 캡션생성단계(로딩)  */}
			{isLoading && <LoadingOverlay loadingText="표 캡션을 생성하고 있어요." />}
			{step === 2 && (
				<>
					<div className="table-item-header">
						<div className="left">
							<h3 className="title">표 {index + 1}</h3>
							{!toggleState && (
								<span className="badge badge-pass">
									<i className="ico ico-check-green"></i> 통과
								</span>
							)}
							<p className="caption-text">
								<span className="tit">
									<i className="ico ico-check-blue"></i>
									{selectedCaptions[index]?.type}
								</span>
								<span className="caption">{selectedCaptions[index]?.caption}</span>
							</p>
						</div>
						<div className="right">
							<ol className="step-area">
								<li>
									<span className="num">
										<i className="ico ico-check-white-xs"></i>
									</span>
									<span className="txt">표 접근성 위반 검사</span>
								</li>
								<li className="current" aria-current="step">
									<span className="num">2</span>
									<span className="txt">표 캡션 AI 생성</span>
								</li>
							</ol>
							<button className={`status btn btn-toggle ${toggleState ? "" : "on"}`} onClick={handleToggle}>
								<i className={`ico ico-caret-down-darkgray-sm`}></i>
								<span className="sr-only">{toggleState ? "펼치기" : "접기"}</span>
							</button>
						</div>
					</div>

					<div className="table-item-body">
						<div className="preview-area">
							<HtmlPreview
								initialCode={htmlCode}
								onContentChange={setNewHtmlCode}
								ref={previewRef2}
								tableCaption={selectedCaptions[index]?.caption || ""}
								onRendered={() => setHtmlPreviewRendered(true)}
							/>
						</div>
						<div className="result-area">
							<ol className="step-area">
								<li>
									<span className="num">
										<i className="ico ico-check-white-xs"></i>
									</span>
									<span className="txt">표 접근성 위반 검사</span>
								</li>
								<li className="current" aria-current="step">
									<span className="num">2</span>
									<span className="txt">표 캡션 AI 생성</span>
								</li>
							</ol>
							<div className="caption-result">
								<TableCaptionChecker
									tableIndex={index}
									setIsChangeTag={setIsChangeTag}
									tableCaptions={tableData.caption}
									onCaptionSelected={handleCaptionSelected}
								/>
								<div className="btn-group-flex">
									<button type="button" className="btn btn-lg btn-gray" onClick={handleCopy}>
										<i className="ico ico-copy-white-sm"></i>표 HTML 복사하기
									</button>
								</div>
							</div>
						</div>
					</div>

					{showToastTableCodeCopy && (
						<Toast
							message="표 HTML이 복사되었습니다."
							onClose={() => {
								setshowToastTableCodeCopy(false);
							}}
							icon={<i className="ico ico-copy-white-sm"></i>}
							position="bottom"
							theme="dark"
						/>
					)}
				</>
			)}
		</div>
	);
};

export default TableAccItem;
