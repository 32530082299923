// ContrastWorkDetailPage.js
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";
import ImageViewer from "@/components/plugins/ImageViewer";
import ContrastAanalysis from "./contrastWorkDetailComponents/ContrastAanalysis";
import LoadingBar from "@/components/loading/LoadingBar";
import Toast from "@/components/toast/Toast";
import { useSelector, useDispatch } from "react-redux";
import { formatData, formatDataStepTwo, testData } from "@/services/core/brightness/brightnessModel";

import LoadingOverlay from "@/components/loading/LoadingOverlay";
import { brightnessSave, brightnessStepTwo } from "@/services/core/brightness/brightnessRepo";
import { DefaultAutoSaveTime, ImgPath } from "@/services/util/const";
import {
	outPutImgData,
	outPutSaveData,
	refreshSaveData,
	resetBrightnessTime,
	resetImageOutput,
	setBrightnessStep,
	updateImageBrigthtnessSaveTime
} from "@/services/store/action/BrightnessAction";
import { saveImgStateReducer } from "@/services/store/reducer/BrightnessReducer";
import { API_URL } from "@/services/core/url/URL";

export default function ContrastWorkDetailPage() {
	useEffect(() => {
		// URL에서 쿼리 파라미터 제거
		const urlWithoutQueryParams = window.location.pathname;

		// History API를 사용하여 쿼리 파라미터 없이 현재 URL을 대체
		window.history.replaceState({}, "", urlWithoutQueryParams);
	}, []);

	const dispatch = useDispatch();
	const location = useLocation();
	const mountedRef = useRef(false); // 컴포넌트가 마운트되었는지 추적

	const queryParams = new URLSearchParams(location.search);
	const additionalData = queryParams.get("additionalData");

	const brightnessData = useSelector((state) => state.brightness);
	const userSelectColor = useSelector((state) => state.brightnessColor?.selectedColor || []);
	const userSelectColorRef = useRef(userSelectColor);

	const filePath = brightnessData.images?.data?.oriImgPath;
	const results = useSelector((state) => state.brightness?.images?.data?.results);
	/// 아웃풋 이미지 저장
	const outimg = useSelector((state) => state.brightnessOutPut?.outPutImg || "");

	const [data, setData] = useState(null);
	const [imageData, setImageData] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [showAnalysis, setShowAnalysis] = useState(true);
	const [pins, setPins] = useState([]);
	const [selectedPinId, setSelectedPinId] = useState(1);
	const [showExportImageToast, setshowExportImageToast] = useState(false);
	const [stet2Loading, setStep2Loading] = useState(false);
	const [selectedColors, setSelectedColors] = useState([]);
	const [newImagePath, setNewImagePath] = useState("");
	const [imgStep, setImgStep] = useState(false);
	const [autoSaveId, setautoSaveId] = useState("");

	/// 명도대비 정보
	const [currentStep, setCurrentStep] = useState(brightnessData.step); // 현재 단계 상태
	const extension = filePath.split(".").pop();

	/// 대이터 변환 정보
	const totalBrightnessData = formatData(brightnessData, results);
	const filterBrightnessData = (totalData) => {
		return {
			...totalData,
			images: totalData.images.map((image) => ({
				...image,
				pins: image.pins.filter((pin) => pin.suggestedColor.length > 0)
			}))
		};
	};
	/// 데이터 형식변환
	const [contrastRatio, setContrastRatio] = useState(brightnessData.isBrightness || 4.5);

	const filteredBrightnessData = filterBrightnessData(totalBrightnessData);
	const [workName, setWorkName] = useState(brightnessData.images?.data?.workspaceName);
	const [imgType, setImgType] = useState(extension);
	const saveTime = useSelector((state) => state.brightness?.images?.data?.saveTime);
	const handleWorkNameUpdate = (newWorkName) => {
		setWorkName(newWorkName);
	};

	const handleSaveData = (colorData) => {
		const color = colorData || [];

		const result = formatDataStepTwo(totalBrightnessData, color);
		const ibiiId = brightnessData.images?.data?.ibiiId;

		const payload = {
			ibiiId,
			...result
		};
		return payload;
	};

	const handleSelectedColorsChange = (selectedColors) => {
		if (additionalData !== true) {
			setSelectedColors(selectedColors);
		} else {
			setSelectedColors(userSelectColor);
		}

		if (additionalData !== true) {
			dispatch(refreshSaveData(selectedColors));
		} else {
			dispatch(refreshSaveData(userSelectColor));
		}
	};
	const downloadImage = async (imageSrc) => {
		try {
			const response = await fetch(imageSrc);
			const blob = await response.blob();
			return blob;
		} catch (error) {
			console.log("이미지 내보내기 오류", error);
			return "Fail";
		}
	};

	const handleToastClick = async (imageData, workName) => {
		try {
			const imageBlob = await downloadImage(`${imageData.replace("/mock/", "./")}`);

			if (imageBlob === "Fail") {
				alert({ message: "잠시 후 다시 시도해주세요." });
				return;
			}
			// Blob을 이용해 다운로드 URL 생성
			const url = window.URL.createObjectURL(imageBlob);
			const link = document.createElement("a");
			link.href = url;
			link.download = workName || "downloaded_image"; // 다운로드 파일명 설정, 없으면 기본값 사용
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
			setshowExportImageToast(true);
		} catch (error) {
			console.error("Failed to download the image:", error);
			alert({ message: "잠시 후 다시 시도해주세요." });
		}
	};
	const navigate = useNavigate();
	const handleContrastCheck = async () => {
		navigate(`/tool/contrast/add`);
	};
	const stepTwoHandle = async () => {
		const payload = handleSaveData(selectedColors);
		setStep2Loading(true);

		try {
			const resp = await brightnessStepTwo(payload);
			setImgStep(true);

			if (resp === "Fail") {
				alert({ title: "이미지 명도대비 자동변환", content: "이미지 명도대비 자동변환에 실패하였습니다." });

				setImgStep(false);
				setStep2Loading(false);
				return;
			} else {
				const newImagePath = resp.data.data.newImgPath;
				setNewImagePath(newImagePath);
				dispatch(resetImageOutput());
				const updatedBrightnessData = {
					...filteredBrightnessData,
					images: filteredBrightnessData.images.map((image, index) => {
						if (index === 0) {
							return {
								...image,
								output: newImagePath
							};
						}
						return image;
					})
				};
				setData(updatedBrightnessData);
				setImageData(updatedBrightnessData.images[0]);
				setPins(updatedBrightnessData.images[0].pins);
				dispatch(outPutSaveData(newImagePath));
				dispatch(outPutImgData(newImagePath));
				setStep2Loading(false);

				dispatch(setBrightnessStep(2));
				setCurrentStep(2);
			}
		} catch (error) {
			console.log("명도대비 자동변환 실패", error);
		}
	};
	useEffect(() => {
		userSelectColorRef.current = userSelectColor;
	}, [userSelectColor]);

	useEffect(() => {
		let intervalId;
		if (currentStep === 1) {
			let saveTime = brightnessData.images.data.saveSecond * 1000;
			if (!saveTime) {
				saveTime = DefaultAutoSaveTime;
			}

			intervalId = setInterval(() => {
				handleAutoSave(userSelectColorRef.current);
			}, saveTime);

			setautoSaveId(intervalId);
		}

		return () => clearInterval(intervalId);
	}, []);
	useEffect(() => {
		if (currentStep === 2) {
			clearInterval(autoSaveId);
		}
	}, [currentStep, selectedColors]);

	const handleAutoSave = async (colorData) => {
		const payload = handleSaveData(colorData);

		const resp = await brightnessSave(payload);
		if (resp === "Fail") {
		} else {
			dispatch(updateImageBrigthtnessSaveTime());
		}
	};

	useEffect(() => {
		const brightnessData = async () => {
			const payload = handleSaveData(userSelectColorRef.current);
			const resp = brightnessSave(payload);
		};

		return () => {
			dispatch(resetBrightnessTime());
			brightnessData();
		};
	}, [location]);

	useEffect(() => {
		const handleBeforeClose = async () => {
			const payload = handleSaveData(userSelectColorRef.current);
			const resp = await brightnessSave(payload);
		};

		window.addEventListener("beforeunload", handleBeforeClose);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeClose);
		};
	}, []);

	useEffect(() => {
		if (imgStep === false) {
			if (filteredBrightnessData) {
				setData(filteredBrightnessData); // 가공된 전체 데이터를 상태에 저장
				if (filteredBrightnessData.images && filteredBrightnessData.images.length > 0) {
					setImageData(filteredBrightnessData.images[0]); // 초기 이미지 데이터 설정

					setPins(filteredBrightnessData.images[0].pins); // pins 데이터 설정
				}
				setIsLoaded(true); // 데이터 로딩 완료 상태 설정
			}
		}

		if (imgStep === true) {
			if (filteredBrightnessData) {
				setData(filteredBrightnessData); // 가공된 전체 데이터를 상태에 저장
				if (filteredBrightnessData.images && filteredBrightnessData.images.length > 0) {
					setImageData(filteredBrightnessData.images[0]); // 초기 이미지 데이터 설정

					setPins(filteredBrightnessData.images[0].pins); // pins 데이터 설정
				}
				setIsLoaded(true); // 데이터 로딩 완료 상태 설정
			}
		}
	}, [newImagePath]); // filteredBrightnessData가 변경될 때마다 실행

	// 단계별 상태 관리
	useEffect(() => {
		if (currentStep === 1) {
			// 1단계 : 명도대비 진단 및 색상정보 분석
			setShowAnalysis(true);
		} else if (currentStep === 2) {
			// 2단계 : 이미지 변환
			setShowAnalysis(false);
		}
	}, [currentStep]);

	const activatePin = (pinId) => {
		if (!showAnalysis) {
			setShowAnalysis(true);
		}
		setSelectedPinId(pinId);
	};

	const showContrastAanalysis = () => {
		setShowAnalysis(true);
		setSelectedPinId(1);
	};

	const closeContrastAanalysis = () => {
		setShowAnalysis(false);
		setSelectedPinId(null);
	};

	const toggleContrastAanalysis = () => {
		if (showAnalysis) {
			closeContrastAanalysis();
		} else {
			showContrastAanalysis();
		}
	};

	// TODO : UI 테스트용 임시 로직 [개발시 삭제]
	const changeData = (index) => {
		if (data && data.images && data.images.length > index) {
			const newImageData = data.images[index];
			setImageData(newImageData); // 선택된 이미지 데이터로 업데이트
			setPins(newImageData.pins); // 선택된 이미지의 핀 데이터로 업데이트
		}

		setCurrentStep(1); // 현재 단계를 1로 설정
		setSelectedPinId(1); // 선택된 핀 ID를 초기화
	};

	if (!isLoaded) {
		return (
			<div className="loading-area">
				<LoadingBar />
			</div>
		);
	}

	return (
		<>
			<div className="container contrast-work-detail-page">
				<HeaderDetails
					className="service-contrast"
					subTitle={brightnessData.images.data.workspaceName}
					workspaceId={brightnessData.images.data.workspaceId}
					onWorkNameChange={handleWorkNameUpdate}
					currentTime2={saveTime}
					saveTime={brightnessData?.images?.data?.saveSecond || DefaultAutoSaveTime}
				/>
				<div className="body-details">
					<div className="contrast-container">
						<div className="col col-viewer col-viewer-origin">
							<div className="title-area">
								<h3 className="title3">이미지 명도대비 진단</h3>
								<p>
									{/* [개발] ContrastAddPage에서 선택한 값에 따라 클래스 추가 필요
											AA 4.5:1 선택 시 : level-aa / A 3:1 선택 시 : level-a */}
									{contrastRatio === 4.5 && (
										<span className="level level-aa">
											<span>AA</span>
											<span>{contrastRatio}:1</span>
										</span>
									)}
									{contrastRatio === 3 && (
										<span className="level level-a">
											<span>A</span>
											<span>{contrastRatio}:1</span>
										</span>
									)}
									기준 미달 요소 <strong className="text-warning">총 {imageData.pins.length}건</strong> 탐지
								</p>
							</div>
							<div className="viewer-area">
								<ImageViewer
									imageData={{ ...imageData, src: imageData?.src }}
									showPins="true"
									onPinClick={activatePin}
									activePinId={selectedPinId}
								/>
							</div>
						</div>
						{/* <LoadingBarFull /> */}
						{showAnalysis && (
							<div className="col col-analysis">
								{stet2Loading === true && <LoadingOverlay />}
								<ContrastAanalysis
									imageData={imageData}
									selectedPinId={selectedPinId}
									onFindPinClick={activatePin}
									contrastRatio={contrastRatio}
									currentStep={currentStep}
									onClose={closeContrastAanalysis}
									onSelectedColors={handleSelectedColorsChange}
								/>
							</div>
						)}
						{currentStep === 2 && pins.length > 0 && (
							<div className="col col-viewer col-viewer-result">
								<div className="title-area">
									<h3 className="title3">명도대비 개선 완료</h3>
									<div className="right-area">
										<ol className="step-area">
											<li>
												<span className="num">
													<i className="ico ico-check-white-xs"></i>
												</span>
												<span className="txt">추천 색상 선택</span>
											</li>
											<li className="current" aria-current="step">
												<span className="num">2</span>
												<span className="txt">이미지 변환 결과</span>
											</li>
										</ol>
									</div>
								</div>
								<div className="viewer-area">
									<ImageViewer
										imageData={{
											...imageData,
											src: imageData?.output || outimg
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<FooterDetails>
					<div className="wrap-left">
						<button type="button" className="btn btn-lg btn-gray" onClick={handleContrastCheck}>
							<i className="ico ico-arrow-left-white"></i>
							처음으로
						</button>
						{/* [개발] UI 확인용으로 임시 생성해놓은 버튼이므로 개발 시 삭제 필요 : 3건/1건/0건 button */}
					</div>
					<div className="wrap-right">
						{currentStep === 1 && pins.length > 0 && (
							<>
								<div className="guide-area">
									<i className="ico ico-info-sm"></i>
									<p>선택한 색상이 이미지에 적용됩니다.</p>
								</div>
								<button
									type="button"
									className="btn btn-lg btn-primary "
									onClick={() => stepTwoHandle()}
									disabled={stet2Loading}
								>
									이미지 명도대비 자동변환<i className="ico ico-arrow-right-sm"></i>
								</button>
							</>
						)}
						{currentStep === 2 && (
							<>
								{pins.length > 0 && (
									<div className="info">
										<button className="btn btn-lg btn-outline-gray" onClick={toggleContrastAanalysis}>
											<i className="ico ico-workspace-black-sm"></i>색상분석 정보{" "}
											{showAnalysis === true ? "닫기" : "보기"}
										</button>
									</div>
								)}
								<button
									type="button"
									className="btn btn-lg btn-primary"
									onClick={() => handleToastClick(outimg, workName)}
								>
									<i className="ico ico-download-white-sm"></i>
									이미지 내보내기
								</button>
								{showExportImageToast && (
									<Toast
										message={`${workName}.${imgType} 파일을 다운로드 하였습니다.`}
										onClose={() => {
											setshowExportImageToast(false);
										}}
										icon={<i className="ico ico-download-light-xs"></i>}
										position="bottom"
										theme="dark"
									/>
								)}
							</>
						)}
					</div>
				</FooterDetails>
			</div>
		</>
	);
}
