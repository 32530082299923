import { useState, useRef } from "react";

function useToastAlert() {
	const lastFocusedRef = useRef(null);
	const [showToast, setShowToast] = useState(false);

	const openToast = () => {
		lastFocusedRef.current = document.activeElement; // 현재 포커스된 요소 저장
		setShowToast(true);
	};

	const closeToast = () => {
		setShowToast(false);
		if (lastFocusedRef.current) {
			lastFocusedRef.current.focus(); // 토스트가 닫힐 때 저장된 요소에 포커스 복원
		}
	};

	return { showToast, openToast, closeToast };
}

export default useToastAlert;
