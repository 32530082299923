import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const Toast = ({ message, duration = 3500, actions, onClose, icon, theme, position }) => {
	const [showToast, setShowToast] = useState(true);

	useEffect(() => {
		const animationTime = 500; // 애니메이션 시간 (0.5초)
		const displayTime = duration - animationTime;

		const closeTimer = setTimeout(() => {
			setShowToast(false);
		}, displayTime);

		const removeTimer = setTimeout(() => {
			onClose();
		}, duration);

		return () => {
			clearTimeout(closeTimer);
			clearTimeout(removeTimer);
		};
	}, [duration, onClose]);

	const toastRef = useRef(null);

	const getToastClass = () => {
		let toastClass = `toast ${position}`;
		if (!showToast) {
			toastClass += ` slide-out-${position}`;
		}

		if (theme === "dark") {
			toastClass += " dark-theme";
		}
		return toastClass;
	};

	return (
		showToast && (
			<div ref={toastRef} className={getToastClass()}>
				{icon && <span className="toast-icon">{icon}</span>}
				<p>{message}</p>
				{actions && actions.length > 0 && <div className="toast-actions">{actions}</div>}
			</div>
		)
	);
};

Toast.propTypes = {
	message: PropTypes.string.isRequired,
	actions: PropTypes.arrayOf(PropTypes.element),
	onClose: PropTypes.func.isRequired,
	icon: PropTypes.element,
	theme: PropTypes.oneOf(["light", "dark"]), // 밝은 테마 또는 어두운 테마
	position: PropTypes.oneOf(["top", "bottom"]) // 상단 (top) 또는 하단 (bottom)
};

export default Toast;
