// LayoutDetails.js
import React, { useEffect } from "react";
import Sidebar from "@/components/layouts/Sidebar";

const LayoutDetails = ({ children }) => {
	useEffect(() => {
		// 페이지 진입 시 클래스 추가
		document.body.classList.add("body-no-overflow");

		// 컴포넌트 언마운트 시 클래스 제거
		return () => {
			document.body.classList.remove("body-no-overflow");
		};
	}, []);

	return (
		<div className="wrapper layout-details">
			<Sidebar />
			<article id="content">{children}</article>
		</div>
	);
};

export default LayoutDetails;
