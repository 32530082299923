import Timer from "@/services/util/Timer";
import { passwordRegex } from "@/services/util/const";
import React, { forwardRef, useRef, useState, useImperativeHandle } from "react";
// 인증번호 요청 시 노출
export const AuthStatus = ({ isEmailSent, isVerifyEmailCode, timeLeft, isTimerActive }) => {
	let content = null;
	if (isEmailSent && isVerifyEmailCode !== "fail" && isVerifyEmailCode !== "Success") {
		content = (
			<div className="certi-number-area">
				<span>인증번호가 요청되었습니다.</span>
				{isTimerActive && <Timer timeLeft={timeLeft} />}
			</div>
		);
	} else if (isVerifyEmailCode === "Success") {
		content = (
			<div className="certi-number-area waiting">
				<p>인증번호가 확인되었습니다.</p>
			</div>
		);
	} else if (isVerifyEmailCode === "fail") {
		content = (
			<div className="certi-number-area invalid">
				<span>올바르지 않은 인증번호입니다.</span>
				{isTimerActive && <Timer timeLeft={timeLeft} />}
			</div>
		);
	}

	//
	else if (timeLeft === 0 && !isEmailSent) {
		content = (
			<div className="certi-number-area invalid">
				<p>인증번호 유효시간이 만료되었습니다. 재전송 버튼을 눌러주세요.</p>
			</div>
		);
	}
	//
	return content ? <>{content}</> : null;
};
{
	/* [개발] 이메일 인증 완료 시 노출 필요 */
}
export const EmailAuthenticationStatus = ({
	emailAuthentication,
	userEmail,
	isButtonDisabled,
	onEmailConfirmation,
	isEmailSent,
	isRequestingAuth,
	buttonClose
}) => {
	switch (emailAuthentication) {
		case 0:
			return (
				<p className="error-text" style={{ marginTop: "9px" }}>
					이미 가입되어 있는 계정입니다.
				</p>
			);
		case 1:
			return <p className="error-text">올바른 이메일을 입력해주세요.</p>;
		case 2:
			return (
				<div className="input-group">
					<input
						type="text"
						className="form-control"
						value={userEmail}
						title="이메일을 입력해주세요."
						placeholder="이메일을 입력해주세요."
						disabled
					/>

					<span className="input-addon">
						{isEmailSent || buttonClose ? (
							// 이메일 전송 후: 인증번호 재전송 버튼
							<button
								type="button"
								className="btn btn-xl btn-gray"
								disabled={isButtonDisabled}
								onClick={onEmailConfirmation}
							>
								인증번호 재전송
							</button>
						) : (
							// 이메일 전송 전: 인증 요청 버튼
							<button
								type="button"
								className="btn btn-xl btn-gray"
								disabled={isButtonDisabled}
								onClick={onEmailConfirmation}
							>
								인증요청
							</button>
						)}
					</span>
				</div>
			);
		default:
			return null;
	}
};

export const PasswordInputComponent = forwardRef(({ setPasswordErrorCheck }, ref) => {
	const pwRef = useRef(null);
	const pwCheckRef = useRef(null);
	const [passwordError, setPasswordError] = useState("");
	const [passwordCheckError, setPasswordCheckError] = useState("");

	useImperativeHandle(ref, () => ({
		getPw: () => pwRef.current.value,
		getPwCheck: () => pwCheckRef.current.value,
		focusPw: () => {
			pwRef.current.focus();
		},
		focusPwCheck: () => {
			pwCheckRef.current.focus();
		}
	}));

	const checkPasswordStrength = () => {
		const password = pwRef.current.value;
		const regex = passwordRegex;

		if (!regex.test(password)) {
		} else {
			setPasswordError(""); // 조건을 만족할 경우 에러 메시지를 초기화
		}

		checkPasswordMatch();
	};

	const checkPasswordMatch = () => {
		const password = pwRef.current.value;
		const passwordCheck = pwCheckRef.current.value;

		if (!password || !passwordCheck) {
			setPasswordCheckError("비밀번호를 입력해주세요.");
			setPasswordErrorCheck(false);
		} else if (!passwordRegex.test(password) || !passwordRegex.test(passwordCheck)) {
			setPasswordCheckError("영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.");
			setPasswordErrorCheck(false);
		} else if (password !== passwordCheck) {
			setPasswordCheckError("비밀번호가 일치하지 않습니다.");
			setPasswordErrorCheck(false);
		} else {
			setPasswordCheckError("비밀번호가 일치합니다.");
			setPasswordErrorCheck(false); // 비밀번호가 일치하고 정규식에도 부합하는 경우, 이 상태를 true로 설정할 수 있습니다.
		}
	};

	return (
		<dl className="form-item">
			<dt>
				<label htmlFor="user-pw">
					비밀번호<span className="req">필수입력</span>
				</label>
			</dt>
			<dd>
				<div className="input-group-form">
					<input
						type="password"
						id="user-pw"
						ref={pwRef}
						className="form-control"
						placeholder="비밀번호를 입력해주세요."
						onChange={checkPasswordStrength}
					/>
				</div>

				<div className="input-group-form">
					<input
						type="password"
						id="user-pw-check"
						ref={pwCheckRef}
						className="form-control pw-chk"
						placeholder="비밀번호를 다시 입력해주세요."
						onChange={checkPasswordMatch}
					/>
				</div>
				{passwordCheckError === "비밀번호가 일치하지 않습니다." && <p className="error-text">{passwordCheckError}</p>}
				{passwordCheckError === "비밀번호가 일치합니다." && (
					<div className="guide-text">
						<p className="waiting-text">{passwordCheckError}</p>
					</div>
				)}
				{passwordCheckError ===
					"영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요." && (
					<p className="error-text">{passwordCheckError}</p>
				)}
				{passwordCheckError === "" && <p className="error-text">{passwordCheckError}</p>}
			</dd>
		</dl>
	);
});

export const handleEmailChange = (
	event,
	setEmailError,
	setIsBtnFormInlineVisible,
	setEmailAuthentication,
	setEmailCheck,
	emailRegex
) => {
	const email = event.target.value.trim();
	const isValidEmail = emailRegex.test(email);

	if (email) {
		setEmailError(!isValidEmail);
		setIsBtnFormInlineVisible(isValidEmail);
		setEmailCheck(false);
	} else {
		setEmailError(false); // 에러 메시지를 숨깁니다.
	}
	setEmailAuthentication(false);
};
