import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { WORKSPACE_ME } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const DashBoard = async (after, count, search, service_type, order_by, is_desc) => {
	try {
		const response = await api.get(WORKSPACE_ME, {
			params: {
				after: after,
				count: count,
				search: search,
				service_type: service_type,
				order_by: order_by,
				is_desc: is_desc
			}
		});

		return response.data;
	} catch (error) {
		navigateTo("/error");
		throw error;
	}
};
