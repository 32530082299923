import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

import AppRouter from "@/components/routers/AppRouter";
import LoadingBarFull from "@/components/loading/LoadingBarFull";
import { ModalProvider } from "@/context/ModalContext";
import ScrollToTop from "@/components/ScrollToTop"; // ScrollToTop 컴포넌트 임포트

const siteId = 3852938; // 핫자 계정에서 발급받은 Site ID
const hotjarVersion = 6;
const trackingId = "G-ES1ZZ79PV4"; // Google Analytics 추적 ID

function useHotjar(location) {
	useEffect(() => {
		Hotjar.init(siteId, hotjarVersion);
	}, []);

	useEffect(() => {
		Hotjar.stateChange(location.pathname);
	}, [location]);
}

function useGA(location) {
	useEffect(() => {
		ReactGA.initialize(trackingId);
		ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
	}, []);

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
	}, [location]);
}

function App() {
	return (
		<ModalProvider>
			<LoadingBarFull />
			<HelmetProvider>
				<Router>
					<ScrollToTop />
					<AppRouterWithTracking /> {/* Router 내부로 이동 */}
				</Router>
			</HelmetProvider>
		</ModalProvider>
	);
}

function AppRouterWithTracking() {
	const location = useLocation();

	useHotjar(location);
	useGA(location);

	// 라우터 변경 시 allmenu-opened 클래스 제거 (전체메뉴 닫기)
	useEffect(() => {
		document.body.classList.remove("allmenu-opened");
	}, [location]);

	return <AppRouter />;
}

export default App;
