import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routesConfig from "@/components/routers/routesConfig";
import LayoutWrapper from "@/components/layouts/LayoutWrapper";
import { ProtectedRoute } from "@/services/util/WithAuth";
import { ErrorRedirector } from "@/services/util/ErrorRedirector";

const ExternalRedirect = ({ url }) => {
	React.useEffect(() => {
		window.location.href = url;
	}, [url]);

	return null;
};

const AppRouter = () => (
	<>
		<Routes>
			{routesConfig.map((route, index) => {
				const { path, component: Component, layout, meta, redirect, isExternal, requiresAuth = false } = route;

				if (redirect) {
					if (isExternal) {
						return (
							<Route
								key={index}
								path={path}
								element={
									<>
										<ExternalRedirect url={redirect} />
										{route.defaultComponent && <route.defaultComponent />}
									</>
								}
							/>
						);
					} else {
						return <Route key={index} path={path} element={<Navigate to={redirect} replace />} />;
					}
				}

				const Element = requiresAuth ? (
					<ProtectedRoute>
						<Component meta={meta} />
					</ProtectedRoute>
				) : (
					<Component meta={meta} />
				);

				return (
					<Route
						key={index}
						path={path}
						element={
							<LayoutWrapper layout={layout} meta={meta}>
								{Element}
							</LayoutWrapper>
						}
					/>
				);
			})}
		</Routes>
		<ErrorRedirector />
	</>
);

export default AppRouter;
