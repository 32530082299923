const privacyPolicyText = `(주)인시퀀스(이하 "회사")는 정보주체의 자유와 권리 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하며, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. 본 개인정보처리방침은 회사가 제공하는 “홈페이지(a11y.co.kr)” 및 “카카오챗봇(앨리채널)” (이하 홈페이지 및 카카오챗봇을 통칭하여 “서비스”)이용에 적용되며, 이를 개정하는 경우 회사가 제공하는 서비스의 공지사항(또는 개별공지)을 통하여 공지합니다.

<strong>개인정보의 처리 목적</strong>
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
1. 회원가입 및 관리 : 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, “앨리” 이용약관 위반 회원에 대한 이용제한 조치, 가입 및 가입 횟수 제한, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존, 민원사무 처리, 서비스 이용에 대한 통계 수집 목적으로 개인정보를 처리합니다.
2. 서비스 제공에 관한 계약 이행 및 요금 정산, 앨리 내 부가서비스 또는 상담 예약, 예약 조회 및 예약 일정에 대한 고지, 서비스 요금 결제, 서비스 제공, 청구서 조회, 금융거래 본인 인증 및 결제 서비스 등 계약 체결 및 이행을 위한 목적으로 개인정보를 처리합니다.
3. 신규 서비스 개발 및 마케팅, 광고 제공 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
4. 기타 문의, 서비스 요청에 대한 응대 및 처리 : 제휴 문의에 대한 처리, 컨텐츠 요청에 대한 응대 및 안내, 상담 요청에 대한 내용 전달

<strong>개인정보의 처리 및 보유 기간</strong>
① 회사는 법령에 따른 개인정보 보유·이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
1. 서비스 이용자의 개인정보는 원칙적으로 이용자의 이용계약 해지(회원 탈퇴) 시까지 보관 후 폐기하되, 개인정보의 수집 및 이용 목적이 달성되는 경우 지체 없이 파기됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간까지 보존합니다.
2. 회사 내부 방침에 의한 정보 보관 사유
- 회사는 불량 이용자의 부정한 이용의 재발을 방지하기 위해 이용계약 해지일(회원 탈퇴일)로부터 6개월간 해당 이용자의 개인정보를 보관합니다.
- 회사는 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 다른 이용자를 보호하고 사법기관 수사의뢰 시 증거자료로 활용하기 위해 이용계약 해지일(회원 탈퇴일)로부터 6개월간 해당 이용자의 개인정보를 보관합니다.
- 회사는 홈페이지 이용에 따른 채권·채무관계 잔존 시 해당 채권·채무관계 정산 시까지 해당 이용자의 개인정보를 보관합니다.
3. 관련 법령에 의한 정보 보관 사유
- 근거 보관 정보 보존 기간
- 전자상거래 등에서의 소비자보호에 관한 법률 표시·광고에 관한 기록 6개월
- 계약 또는 청약철회 등에 관한 기록 5년
- 대금결제 및 재화 등의 공급에 관한 기록 5년
- 소비자의 불만 또는 분쟁처리에 관한 기록 3년
* 정보주체로부터 별도로 개인정보 관련 문서를 제공받은 경우 본 개인정보 처리방침에 따라 6개월 간 보관 후 폐기합니다. 다만, 파트너 제휴사와의 개인정보 처리 위수탁 계약에 따라 수탁자로서 정보를 보관하는 경우 위수탁 계약 기간 동안 보유하고 계약 종료 시 이를 폐기함을 원칙으로 합니다. 다만, 회사가 파트너 제휴사의 개인정보처리 수탁자로서 위 각 정보를 보관하는 경우 각 법령에 정한 기간 동안 각 정보를 보관합니다.
③ 2024년 6월 10일 이후 가입한 이용자가 1년간 서비스 거래기록이 없을 시, 서비스 미이용자의 개인정보는 개인정보보호법 제39조의6, 같은 법 시행령 제48조의5에 근거하여 이용자의 개인정보를 해당기간 경과 후 즉시 파기하거나 다른 이용자의 개인정보와 분리하여 별도로 저장 관리합니다. 고객의 요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 회사는 위의 기간 만료 30일 전까지 1) 개인정보가 파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목 또는 2) 개인정보가 분리되어 저장·관리되는 사실, 기간 만료일 및 분리·저장되어 관리되는 개인정보의 항목을 전자우편, 서면 등의 방법으로 고객에게 알립니다. 이를 위해 고객은 정확한 연락처 정보를 제공 및 변경 시 이를 수정하여야 합니다.
④ 제3항에 따라 분리 보관된 개인정보는 4년간 보관 후 지체 없이 파기합니다. 단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등 다른 법령의 규정에 의하여 이용자의 개인정보를 보존할 필요가 있는 경우 다른 법령에서 규정한 일정한 기간 동안 이용자 개인정보를 보관합니다.

<strong>처리하는 개인정보의 항목</strong>
① 회사는 서비스 운영에 필요한 최소한의 개인정보만 수집하며, 서비스 이용을 위하여 개인정보가 추가로 필요한 경우 회원에게 별도의 동의를 받은 후 개인정보를 수집할 예정입니다.
- 회원가입 및 관리 일반 : 회원 이름, 아이디, 비밀번호, 이메일, 휴대폰 번호, 생년월일, 구글 ID, 네이버 ID, 카카오톡 ID, 회원 탈퇴 사유, 암호화된 동일인 식별정보(CI), 중복가입 확인정보(DI)
- 결제카드 등록시 : 카드사, 카드종류, 카드번호, 카드비밀번호 앞 2자리, CVC 번호, 유효기간, 생년월일, 이름
- 계좌이체 이용 결제, 환불 이용 시 : 예금주, 은행명, 계좌번호
- 경품 수령시 : 배송 정보 수령인 이름, 휴대폰 번호, 배송 주소
- 서비스 후기 수집 : 사용후기, 기타 정보 상담 서비스 서비스 이용 내역, 상담 내역
- 서비스 이용 관련 : 접속 기기 정보, 접속 로그, IP 주소, 쿠키, 기기고유번호, 방문일시, 불량 이용 기록, 사용 이동통신사, 광고식별자
② 회사는 「개인정보보호법」과 「정보통신망법」상 개인정보 처리방침 및 개인정보보호위원회가 정한 「표준 개인정보 보호지침」을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 귀하가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.
③ 회사는 개인정보 처리방침을 사이트 첫 화면과 고객센터의 ‘개인정보 처리방침’ 항목에서 공개함으로써 이용자들이 언제나 용이하게 볼 수 있도록 조치하고 있습니다.
④ 회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보 처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된 사항을 이용자가 쉽게 알아볼 수 있도록 하고 있습니다.
⑤ 서비스 아이디를 이용한 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한하여, 추가적으로 개인정보 처리가 발생할 수 있습니다. 해당 개인정보 처리 시점에서 이용자에게 처리하는 개인정보 항목(이름, 휴대폰 번호, 이벤트 응모 및 상담 내용), 처리목적, 개인정보 보유 및 이용기간에 대해 안내 드리고 동의를 받습니다.

<strong>개인정보의 제3자 제공</strong>
① 회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보보호법」 제17조, 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
② 회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 개인정보를 제공합니다.
- 제공받는 자 : “앨리” 파트너 제휴기관
- 제공 목적 및 제공 항목 : 
	- 전체 : 원격진단, 기술지원을 위한 상담/예약 접수 서비스 제공 및 내역 확인
	- 마케팅 : 본인 여부 확인 정보, 이름, 생년월일, 성별, 휴대폰 번호, 기본정보, 진단 신청내역
- 보유 및 이용기간 : 이용자의 이용계약 해지(회원 탈퇴) 시까지
- “앨리” 진단 매칭은 랜덤으로 이루어지므로, 제휴기관 전체에 대한 제공동의를 수집합니다.
- 마케팅에 관한 사항은 선택사항으로 별도 동의를 받습니다.
	- 제공받는 자 : “앨리” 파트너 제휴기관
	- 제공 목적 : 원격진단, 기술지원, 상담/예약 접수 서비스 제공 및 내역 확인
	- 제공 항목 : 본인 여부 확인 정보, 이름, 생년월일, 성별, 휴대폰 번호, 기본정보, 신청내역, 콘텐츠 생성 이력
	- 보유 및 이용기간 : 이용자의 이용계약 해지(회원 탈퇴) 시까지
- 단, 다른 관련 법령에 따라 별도 보관되는 정보는 다른 법령에서 정한 기간 동안 보관
- 동의 거부에 따른 불이익: “앨리” 서비스 이용 제한

③ 회사는 예외적으로 아래의 경우 이용자의 개인정보를 제3자에게 제공, 공유할 수 있습니다.
- 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
- 정보주체와의 계약의 체결 및 이행을 위하여 불가피하게 필요한 경우
- 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우
- 수사기관이 수사 목적을 위해 관계법령이 정한 절차를 거쳐 요구하는 경우
- 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우

<strong>개인정보 처리의 위탁</strong>
① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
- 네이버클라우드: 서비스 제공 및 분석을 위한 인프라 관리를 위해 회원탈퇴 후 6개월까지 보유 및 이용합니다.
- 토스페이먼츠: 주문을 위한 주문자, 주문정보 등 주문 및 취소에 따른 관련 정보를 회원탈퇴 후 6개월까지 보유 및 이용합니다.
- ㈜카카오: 카카오톡 채널을 이용한 메시지 발송, 서비스 제공 및 분석을 위한 인프라 관리를 위해 회원탈퇴 후 6개월까지 보유 및 이용합니다.
② 회사는 위탁계약 체결 시 「개인정보보호법」 제26조, 같은 법 시행령 제28조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 개인정보의 기술적·관리적 보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보에 대한 접근 제한 등 안정성 확보 조치, 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독, 수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 책임에 관한 사항을 계약서 등 문서(전자문서)에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
③ 위탁업무의 내용이나 수탁자가 변경될 경우, 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

<strong>개인정보의 파기절차 및 방법</strong>
① 회사는 개인정보 보유기간이 경과된 경우, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
② 회사는 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 다른 개인정보와 별도의 데이터베이스(DB)로 옮겨 분리하여 저장, 관리합니다. 회사는 별도 DB로 옮긴 개인정보를 법률에 의한 경우가 아니고서는 보유하는 외 다른 목적으로 이용하지 않습니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법 : 회사는 전자적 파일 형태로 기록·저장된 개인정보는 복원이 불가능한 방법 내지 기록을 재생할 수 없는 기술적 방법으로 영구히 삭제합니다. 종이나 그 밖의 기록 매체에 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.

<strong>개인정보주체와 법정대리인의 권리·의무 및 행사방법</strong>
① 정보주체는 회사에 대해 언제든지 “앨리” 서비스를 통하여 등록된 개인정보의 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.
※ 만 14세 미만 아동에 관한 개인정보의 열람 등의 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.
② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘이용자정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “이용자탈퇴”를 클릭하여 본인 확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
③ 권리 행사는 개인정보 보호책임자 및 담당자에 대하여 서면, 전화, 이메일 등을 통하여 하실 수 있으며, 이 경우 회사는 지체 없이 그 개인정보를 조사하여 이용자의 요구에 따라 지체없이 필요한 조치를 한 후 그 결과를 이용자에게 알리겠습니다.
④ 개인정보 열람 및 처리정지 요구의 경우, 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
⑦ “앨리 베타테스터 신청자”의 경우, 회원 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “앨리 베타테스터 신청자”가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다. “앨리 베타테스터 신청자”의 신청취소는 “인시퀀스”의 다른 서비스의 탈퇴와 무관합니다.

<strong>개인정보의 안전성 확보조치</strong>
① 회사는 개인정보의 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.
1. 기술적 조치
- 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
- 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
- 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.
2. 관리적 조치
회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자, 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을 엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있습니다.
3. 물리적 조치
전산실, 자료보관실 등의 접근 통제
② 회사는 이용자 본인의 부주의나 기본적인 네트워크 상의 위험성으로 인해 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 일체의 책임을 지지 않습니다. 이용자 개개인이 본인의 개인정보 보호를 위하여 ID와 비밀번호를 적절하게 관리하고, 제3자에게 노출되지 않도록 주의하셔야 합니다.

<strong>개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</strong>
① 회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용 정보를 저장하고 수시로 불러오는 ’쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
1. 쿠키의 사용 목적
이용자들이 방문한 “인시퀀스”의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.
2. 쿠키의 설치/운영 및 거부
- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
- 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
- 설정방법 예(구글 크롬의 경우): 웹 브라우저 상단의 ‘︙’ > 설정 > 개인정보 및 보안 > 쿠키 및 기타 사이트 데이터 > 쿠키 차단
- 다만, 쿠키의 저장을 거부할 경우에는 맞춤형 서비스 이용에 어려움이 있을 수 있습니다.

<strong>개인정보 보호책임자 및 개인정보 열람청구</strong>
① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
② 정보주체는 「개인정보보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
③ 정보주체는 회사의 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다.
- 개인정보보호 책임자 : 임미선
- 개인정보보호 담당자 : 이영민
- 전화번호 : 070-8667-3773
- 이메일 : a11y@inseq.co.kr

<strong>권익침해 구제 방법</strong>
① 회사는 개인정보보호와 관련하여 이용자들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 이용자들은 위 “개인정보 보호책임자 및 개인정보 열람청구” 항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 회사는 이용자들의 신고사항에 대하여 신속하게 처리하여 답변해 드립니다.
② 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국 인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
- 개인정보분쟁조정위원회: 1833-6972 (www.kopico.go.kr)
- 개인정보침해신고센터: 118 (privacy.kisa.or.kr)
- 대검찰청: 1301 (www.spo.go.kr)
- 경찰청: 182 (ecrm.police.go.kr)
③ 「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
- 중앙행정심판위원회: 110 (www.simpan.go.kr)

<strong>개인정보 처리방침의 변경</strong>
회사는 개인정보 처리방침을 변경하는 경우에 개인정보주체인 이용자가 그 변경 여부, 변경된 사항의 시행시기와 변경된 내용을 언제든지 쉽게 알 수 있도록 지속적으로 서비스를 통하여 공개합니다. 이 경우에 변경된 내용은 변경 전과 후를 비교하여 공개합니다.
- 공고일자: 2024년 6월 1일
- 시행일자: 2024년 6일 10일`;

export default privacyPolicyText;
