// LayoutDefault.js
import React, { useEffect, useState } from "react";
import HeaderHome from "@/components/layouts/HeaderHome";
import Sidebar from "@/components/layouts/Sidebar";
import Toast from "../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "@/services/cookies/UserCookies";
import { hideToast } from "@/services/store/action/ToastAction";
// import PopupFeedback from "@/components/feedback/PopupFeedback";
// import TopBanner from "@/components/layouts/TopBanner";
import MobileHeader from "@/components/layouts/MobileHeader";

const LayoutDefault = ({ children }) => {
	const dispatch = useDispatch();
	const toastState = useSelector((state) => state.toast.showToast);
	const toastKey = useSelector((state) => state.toast.toastKey);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const token = getCookie("token");
		const authenticated = !!token;
		setIsAuthenticated(authenticated);
	});

	useEffect(() => {}, [toastKey, toastState]);
	const hadelColstTost = () => {
		dispatch(hideToast());
	};

	return (
		<>
			<div className={`wrapper layout-default ${isAuthenticated ? "mode-logged" : "mode-public"}`}>
				<>
					{isAuthenticated ? (
						<>
							<MobileHeader />
							<Sidebar />
						</>
					) : (
						<HeaderHome setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />
					)}
				</>

				{toastState && (
					<Toast
						key={toastKey}
						message="파일을 내 작업공간에 복원했습니다."
						onClose={hadelColstTost}
						icon={<i className="ico ico-add-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
				<article id="content">
					{/* <TopBanner closeButtonVisible={false} /> */}

					{children}
				</article>
				{/* <PopupFeedback /> */}
			</div>
		</>
	);
};

export default LayoutDefault;
