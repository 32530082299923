// src/services/util/sendToGoogleSheet.js

const GOOGLE_SHEET_URL =
	"https://script.google.com/macros/s/AKfycbwdMro2y2yt6ZLF20FEiRqCohjd_6BgCq8dAzrfwXuhEBlKkNVA26kKaQz0Ct7Lu7Gl/exec";

export const sendToGoogleSheet = async (sheetName, data) => {
	try {
		fetch(GOOGLE_SHEET_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ sheetName, ...data }),
			mode: "no-cors"
		});
		return "Success";
	} catch (error) {
		console.error("Error sending data to Google Sheet:", error);
		return "Fail";
	}
};
