import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { CHECK_PASSWORD } from "../url/URL";
import api from "@/services/util/Interceptor";

export const CheckPassword = async (currentPassword) => {
	try {
		const response = await api.post(CHECK_PASSWORD, {
			password: currentPassword
		});
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		return "fail";
	}
};
