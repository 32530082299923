import axios from "axios";
import { getCookie, removeCookie } from "../cookies/UserCookies";
import { navigateTo } from "./Navigate";
import store, { persistor } from "../store/ReduxStore";
import { setAuthError } from "../store/action/AuthAction";
import resetState from "../store/action/ResetAction";
const { config } = require("react-transition-group");

const api = axios.create();
let cancelTokenSource = axios.CancelToken.source()


api.interceptors.request.use(

	(config) => {

		if (config.headers.requiresAuth !== false) {
			const token = getCookie("token");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
		}
		delete config.headers.requiresAuth;
		config.cancelToken = cancelTokenSource.token;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (error.response && [401, 403].includes(error.response.status)) {
			removeCookie("token", { path: "/" });
			await persistor.purge();
			store.dispatch(resetState());
			store.dispatch(setAuthError(error.response.status));
			// 에러를 다시 전파
			return Promise.reject(error);
		}

		if (error.response && [404, 422, 409, 500].includes(error.response.status)) {
			store.dispatch(setAuthError(error.response.status));
			// 에러를 다시 전파
			return Promise.reject(error);
		}

		// 다른 모든 경우에도 에러를 전파
		return Promise.reject(error);
	}
);

export const refreshCancelTokenSource = () => {
	cancelTokenSource.cancel("Previous request canceled");
	cancelTokenSource = axios.CancelToken.source();
  };
  

export default api;
