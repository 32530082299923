import { MyPageChangeName } from "@/services/core/mypage/MyPageChangeName";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "@/services/store/action/LoginAction";
import DragAndDrop from "@/components/form/DragAndDrop";
import { MyPageProfileImgUpload } from "@/services/core/mypage/MyPageProfile";
import { MyPageChangePhone } from "@/services/core/mypage/MyPageChangePhone";
import { CheckPassword } from "@/services/core/mypage/CheckPassword";
import { MyChangePassword } from "@/services/core/mypage/MyPageChangePassword";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "@/services/cookies/UserCookies";
import { persistor } from "@/services/store/ReduxStore";
// import resetState from "@/services/store/action/ResetAction";
import { MyPageDefaultProfile } from "@/services/core/mypage/MyPageDefaultProfile";
import { nameRegex, passwordRegex } from "@/services/util/const";
const { default: PopupLayout } = require("@/components/popup/PopupLayout");

/// 이름변경
export const ChangeNamePopup = ({ isOpen, closePopup, userName }) => {
	const [isUserName, setIsUserName] = useState(userName);
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);
	const [nameError, setNameError] = useState(false);

	const handleInputChange = (e) => {
		if (nameRegex.test(e.target.value)) {
			setNameError(false);
		} else {
			setNameError(true);
		}

		setIsUserName(e.target.value);
	};

	const handleNameChange = async () => {
		const trimmedName = isUserName.trim();
		if (!trimmedName) {
			return;
		}
		if (nameRegex.test(isUserName)) {
			setNameError(false);
		} else {
			setNameError(true);
			return false;
		}
		const result = await MyPageChangeName(isUserName);
		if (result === "Success") {
			const updatedUserData = {
				...userData,
				data: {
					...userData.data,
					name: isUserName
				}
			};
			dispatch(setUserData(updatedUserData));
			closePopup();
		} else {
		}
	};
	useEffect(() => {
		setIsUserName(userName);
		setNameError(false);
	}, [isOpen]);

	return (
		<PopupLayout
			isOpen={isOpen} // isOpen prop을 사용하여 팝업의 열림 상태를 제어합니다.
			closePopup={closePopup} // closePopup prop으로 팝업을 닫는 함수를 전달받습니다.
			title="이름 변경"
			size="default"
			footerContent={
				<>
					<div className="btn-group">
						<button type="button" className="btn btn-xl btn-outline-gray" onClick={closePopup}>
							취소
						</button>
						<button type="submit" className="btn btn-xl btn-primary" onClick={handleNameChange} disabled={nameError}>
							변경
						</button>
					</div>
				</>
			}
		>
			<div className="change-name-popup">
				<div className="input-group-form">
					<input
						type="text"
						id="user-name"
						title="현재 이름"
						className="form-control"
						value={isUserName}
						onChange={handleInputChange}
					/>
				</div>
				{nameError && <p className="guide-text text-invalid">공백 없이 한글 또는 영어로만 입력해주세요.</p>}
			</div>
		</PopupLayout>
	);
};

/// 프로필 사진 변경
export const ChangePhotoPopup = ({ isOpen, closePopup, profile, baseProfile }) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [resetProfile, setResetProfile] = useState(null);
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);

	const handleFileUploaded = (file) => {
		setSelectedFile(file);
	};

	const handleReset = async () => {
		const resp = await MyPageDefaultProfile();

		const resetUserData = {
			...userData,
			data: {
				...userData.data,
				profileImg: resetProfile
			}
		};
		dispatch(setUserData(resetUserData));
	};

	const ProfileChange = async () => {
		if (selectedFile) {
			const formData = new FormData();
			formData.append("profileimg", selectedFile);

			const result = await MyPageProfileImgUpload(formData);

			if (result.status === "Success") {
				const updatedUserData = {
					...userData,
					data: {
						...userData.data,
						profileImg: result.data.data
					}
				};
				dispatch(setUserData(updatedUserData));
				closePopup();
			} else {
				return;
			}
		}
	};

	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={closePopup}
			title="사진 변경"
			size="default"
			footerContent={
				<>
					<div className="btn-group">
						<button
							type="button"
							className="btn btn-xl btn-outline-gray"
							onClick={() =>
								window.confirm({
									title: "프로필 사진을 삭제 하시겠어요?",
									message: "아래 기본 프로필 사진으로 변경됩니다.",
									content: `<span class='popup-profile-img'><img src='${baseProfile}' alt='프로필 이미지' /></span>`,
									onConfirm: (result) => {
										handleReset(baseProfile);
										closePopup();
									},
									confirmButtonText: "저장",
									cancelButtonText: "취소"
								})
							}
						>
							프로필 사진 삭제
						</button>
						<button type="submit" className="btn btn-xl btn-primary" onClick={() => ProfileChange()}>
							변경
						</button>
					</div>
				</>
			}
		>
			<div className="change-photo-popup">
				<DragAndDrop onFileUploaded={handleFileUploaded} service={"default"} />
				<div className="box box-outline">
					<div className="info-area">
						<strong>업로드 가능 이미지 포맷</strong>
						<ul className="bul-list">
							<li>JPG(JPEG), PNG, GIF, BMP</li>
						</ul>
					</div>
				</div>
			</div>
		</PopupLayout>
	);
};

/// 전화번호 변경
export const ChangePhoneNumber = ({ isOpen, closePopup, userNumber }) => {
	const [isUserPhone, setIsUserPhone] = useState(userNumber);
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);

	const handleInputChange = (e) => {
		const inputVal = e.target.value;

		const onlyNums = inputVal.replace(/[^0-9]/g, "");
		if (inputVal.length >= 12) {
			return false;
		}
		setIsUserPhone(onlyNums);
	};
	const isValidPhone = isUserPhone.startsWith("010") && isUserPhone.length === 11;

	const handleChangePhoneNumber = async () => {
		if (!isValidPhone) return;

		const result = await MyPageChangePhone(isUserPhone);
		if (result.status === "Success") {
			const updatedUserData = {
				...userData,
				data: {
					...userData.data,
					phone: isUserPhone
				}
			};
			dispatch(setUserData(updatedUserData));
			closePopup();
		} else {
			return;
		}
	};
	useEffect(() => {
		setIsUserPhone(userNumber);
	}, [isOpen]);
	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={closePopup}
			title="전화번호 변경"
			size="default"
			footerContent={
				<>
					<div className="btn-group">
						<button type="button" className="btn btn-xl btn-outline-gray" onClick={closePopup}>
							취소
						</button>
						<button
							type="submit"
							className="btn btn-xl btn-primary"
							onClick={() => handleChangePhoneNumber(isUserPhone)}
							disabled={!isValidPhone}
						>
							변경
						</button>
					</div>
				</>
			}
		>
			<div className="change-number-popup">
				<div className="input-group-form">
					<input
						type="text"
						id="user-number"
						title="현재 전화번호"
						className="form-control"
						value={isUserPhone}
						onChange={handleInputChange}
					/>
					<p className="guide-text">'-'를 제거하고 숫자만 입력해주세요.</p>
				</div>
			</div>
		</PopupLayout>
	);
};
// userPassword
/// 비밀번호 변경
export const ChangePassWord = ({ isOpen, closePopup }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [currentPassword, setCurrentPassword] = useState("");

	const [isStep, setIsStep] = useState(false);
	const [checkStatus, setCheckStatus] = useState(null);

	const [isNewPassword, setIsNewPassword] = useState("");
	const [isCheckNewPassword, setIsCheckNewPassword] = useState("");
	const [passwordValid, setPasswordValid] = useState(null);
	const regex = passwordRegex;
	useEffect(() => {
		setCurrentPassword("");
		setIsStep(false);
		setCheckStatus(null);
		setIsNewPassword("");
		setIsCheckNewPassword("");
		setPasswordValid(null);
		// setNameError(false)
	}, [isOpen]);
	const handelNewPasswordChange = (e) => {
		const newPassword = e.target.value;
		setIsNewPassword(newPassword);
		setPasswordValid(regex.test(newPassword));
	};

	const handleCheckNewPasswordChange = (e) => {
		setIsCheckNewPassword(e.target.value);
	};

	const passwordsMatch = isNewPassword === isCheckNewPassword && isCheckNewPassword !== "";

	const handleInputPassword = (e) => {
		const inputVal = e.target.value;
		setCurrentPassword(inputVal);
	};

	const handleCheckPassword = async () => {
		const result = await CheckPassword(currentPassword);
		if (result.status === "Success") {
			setIsStep(true);
			setCheckStatus(true);
			return;
		} else {
			setCheckStatus(false);
			return;
		}
	};

	const handleClosePopup = () => {
		// 상태 초기화
		setCurrentPassword("");
		setIsStep(false);
		setCheckStatus(null);
		setPasswordValid(null);
		setIsNewPassword("");
		setIsCheckNewPassword("");
		// 부모 컴포넌트에서 전달받은 팝업 닫기 함수 호출
		closePopup();
	};

	const handleChangePassword = async () => {
		const result = await MyChangePassword(currentPassword, isCheckNewPassword);

		if (result.status === "Success") {
			try {
				removeCookie("token", { path: "/" }); // 쿠키 제거
				await persistor.purge();
				navigate("/login");
			} catch (error) {
				navigate("/login");
			}
			// navigate("/login");
		} else if (result.status === "fail") {
			return;
		}
		return;
	};

	return (
		<PopupLayout
			isOpen={isOpen}
			closePopup={handleClosePopup}
			title="비밀번호 변경"
			size="default"
			footerContent={
				<>
					<div className="btn-group">
						<button type="button" className="btn btn-xl btn-outline-gray" onClick={closePopup}>
							취소
						</button>
						{/* 새 비밀번호 영역 노출 시 텍스트 변경 필요 : 다음 → 저장 후 재로그인 으로 변경 */}
						{(checkStatus === null || checkStatus === false) && (
							<button type="button" className="btn btn-xl btn-primary" onClick={handleCheckPassword}>
								비밀번호 확인
							</button>
						)}
						{checkStatus === true && (
							<button
								type="button"
								className="btn btn-xl btn-primary"
								onClick={handleChangePassword}
								disabled={
									!(passwordsMatch && passwordValid) | (currentPassword === isNewPassword && isNewPassword !== "")
								}
							>
								저장 후 재로그인
							</button>
						)}
					</div>
				</>
			}
		>
			<div className="change-pw-popup">
				<div className="form-group">
					<dl className="form-item">
						<dt>
							<label htmlFor="user-pw">현재 비밀번호</label>
						</dt>
						<dd>
							<div className="input-group-form">
								<input
									type="password"
									id="user-pw"
									className="form-control"
									placeholder="비밀번호를 입력해주세요."
									value={currentPassword}
									onChange={handleInputPassword}
									disabled={checkStatus}
								/>
							</div>
						</dd>
					</dl>
					{/* 비밀번호가 일치하지 않을 경우 노출 */}
					{checkStatus === false && (
						<p className="error-text">비밀번호가 올바르지 않습니다. 입력한 내용을 다시 확인해 주세요.</p>
					)}

					{/* 현재 비밀번호 정상적으로 입력 후, 다음 버튼 클릭 시 노출 */}
					{isStep === true && (
						<dl className="form-item">
							<dt>
								<label htmlFor="user-new-pw">새 비밀번호</label>
							</dt>
							<dd>
								<div className="input-group-form">
									<input
										type="password"
										id="user-new-pw"
										className="form-control"
										placeholder="새 비밀번호를 입력해주세요.."
										value={isNewPassword}
										onChange={handelNewPasswordChange}
									/>
									{/* 조건을 달성하지 못한 경우 : error 클래스 추가 */}
									{currentPassword === isNewPassword && isNewPassword !== "" ? (
										<p className="error-text">이전 비밀번호와 동일합니다. 변경된 비밀번호를 입력해주세요.</p>
									) : (
										<>
											{passwordValid === false ? (
												<p className="error-text">
													영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.
												</p>
											) : (
												<p className="guide-text">
													영문 대소문자, 숫자, 특수문자를 3가지 이상으로 조합해 8자 이상 16자 이하로 입력해주세요.
												</p>
											)}
										</>
									)}
									<input
										type="password"
										id="user-new-pw-check"
										title="새 비밀번호를 다시 입력해주세요."
										className="form-control pw-chk"
										placeholder="새 비밀번호를 다시 입력해주세요."
										value={isCheckNewPassword}
										onChange={handleCheckNewPasswordChange}
									/>
									{/* 상태에 따라 안내 문구 노출 필요 */}
									{!(currentPassword === isNewPassword && isNewPassword !== "") &&
										(passwordsMatch ? (
											<p className="waiting-text">비밀번호가 일치합니다.</p>
										) : isCheckNewPassword !== "" && !passwordsMatch ? (
											<p className="error-text">비밀번호가 일치하지 않습니다.</p>
										) : null)}
								</div>
							</dd>
						</dl>
					)}
				</div>
			</div>
		</PopupLayout>
	);
};
