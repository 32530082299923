import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchGoogleSheetData } from "@/services/util/GoogleSheet";
import LoadingSpinner from "@/components/loading/LoadingSpinner";
import { filterOptions } from "@/data/communityFilter"; // 공통 데이터 임포트

function getCategoryOption(value) {
	for (const category of filterOptions[0].options) {
		if (category.value === value) {
			return category;
		}
	}
	return null;
}

export default function PopularResources({ limit = 3 }) {
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null); // 오류 상태 추가

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setError(null); // 오류 상태 초기화
			try {
				const data = await fetchGoogleSheetData("리소스");
				const itemsData = data
					.filter((row) => row[7] === "Y") // 게시여부가 "Y"인 항목만 필터링
					.map((row) => ({
						difficulty: row[0], // 난이도
						category: row[1], // 카테고리
						type: row[2], // 유형
						lang: row[3], // 제공언어
						subject: row[4], // 서비스명
						url: row[5], // URL
						content: row[6], // 특징
						date: new Date(row[8]) // 등록일시
					}))
					.sort((a, b) => b.date - a.date); // 등록일시 기준으로 최신순 정렬

				setItems(itemsData.slice(0, limit)); // 최신 데이터 limit개 가져오기
			} catch (err) {
				setError("불러오지 못했어요."); // 오류 발생 시 오류 메시지 설정
			} finally {
				setLoading(false); // 로딩 상태 종료
			}
		};
		fetchData();
	}, [limit]);

	return (
		<div className="popular-resources">
			{loading ? (
				<LoadingSpinner message="데이터를 불러오고 있어요" />
			) : error ? (
				<p>{error}</p>
			) : items.length === 0 ? (
				<p className="no-data text-center">불러오지 못했어요.</p>
			) : (
				<ol className="data-list">
					{items.map((item, index) => {
						const categoryOption = getCategoryOption(item.category);
						return (
							<li key={index}>
								<div className="inner">
									<span className="num">{index + 1}</span>
									<div className="icon" style={categoryOption ? { backgroundColor: categoryOption.bgcolor } : {}}>
										{categoryOption && categoryOption.emoji}
									</div>
									<div className="info">
										<strong className="subject">{item.subject}</strong>
										<span className="content">{item.content}</span>
									</div>
								</div>
							</li>
						);
					})}
				</ol>
			)}
		</div>
	);
}
