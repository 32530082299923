import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const DropdownWork = ({ title, options, selectedOptions, onSelect, size,desc, isDashborad = false }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(selectedOptions);

	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);
	const optionsRefs = useRef(options.map(() => React.createRef()));
	const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			setFocusedOptionIndex(0);
		}
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
		if (onSelect) {
			onSelect(option);
		}
		buttonRef.current.focus();
	};

	const handleCancel = () => {
		setSelectedOption(options[0]);
		setIsOpen(false);
		if (onSelect) {
			onSelect(null);
		}
	};

	const handleKeyDown = (e) => {
		if (isOpen) {
			if (e.key === "ArrowDown" || e.key === "Tab") {
				e.preventDefault();
				const nextIndex = (focusedOptionIndex + 1) % options.length;
				setFocusedOptionIndex(nextIndex);
				optionsRefs.current[nextIndex].current.focus();
			} else if (e.key === "ArrowUp") {
				e.preventDefault();
				const prevIndex = (focusedOptionIndex - 1 + options.length) % options.length;
				setFocusedOptionIndex(prevIndex);
				optionsRefs.current[prevIndex].current.focus();
			}
		}
	};
	useEffect(() => {
		setSelectedOption(selectedOptions);
	}, [selectedOptions]);
	useEffect(() => {
		if (isDashborad) {
	
			setSelectedOption(options[ 0]);
		}
	}, [options]);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div
			className={`dropdown dropdown${size === "sm" ? "-sm" : ""} dropdown-list ${isOpen ? "on" : ""}`}
			onKeyDown={handleKeyDown}
			ref={dropdownRef}
		>
			<dl className="select">
				{selectedOption && (
					<dt>
						<button type="button" className="btn-select" onClick={toggleDropdown} ref={buttonRef}>
							<span>
								{selectedOption.iconClass && <i className={`ico ${selectedOption.iconClass}`}></i>}
								{selectedOption.label}
							</span>
						</button>
						{selectedOption.value !== null && size !== "sm" && (
							<span className="btn-cancel">
								<button type="button" onClick={handleCancel}>
									<i className="ico ico-cancel-xs"></i>
									<span className="sr-only">취소</span>
								</button>
							</span>
						)}
					</dt>
				)}

				{isOpen && (
					<dd>
						<ul className="option-list">
							{options
								.filter((option) => option.value !== null)
								.map((option, index) => (
									<li key={index} onClick={() => handleOptionClick(option)}>
										<Link to="#" ref={optionsRefs.current[index]}>
											{option.iconClass && <i className={`ico ${option.iconClass}`}></i>}
											<span>{option.label}</span>
										</Link>
									</li>
								))}
						</ul>
					</dd>
				)}
			</dl>
		</div>
	);
};

export default DropdownWork;
