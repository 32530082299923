import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import LoadingBarFullDimed from "@/components/loading/LoadingBarFullDimed";

import { useDispatch, useSelector } from "react-redux";

import { goDetail, seoDetail } from "@/services/core/workspace/WorkspaceDetail";
import SeoWorkDetailPage from "./SeoWorkDetailPage";
import { getCookie } from "@/services/cookies/UserCookies";

export default function SeoWorkDetailMiddlePage() {
	// popup

	const data = useSelector((state) => state?.seo?.seoData?.data);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { workId } = useParams();
	const queryParams = new URLSearchParams(location.search);
	const isLoad = queryParams.get("isLoad");
	const [isLoading, setIsLoading] = useState(true);
	const handleDetail = async () => {
		setIsLoading(true);
		const response = await goDetail(workId, navigate);
		seoDetail(dispatch, response);
		// setDetailLoaded(true)
		navigate("/tool/seo/work-detail/" + workId + "?isLoad=true");
	};

	useEffect(() => {
		if ((data?.resData?.workspaceId != workId) & !isLoad) {
			handleDetail();
			setIsLoading(false);
		}
	}, []);
	if (data?.resData?.workspaceId == workId) {
		return <SeoWorkDetailPage></SeoWorkDetailPage>;
	} else if (!isLoad) {
		return (
			<>
				<div className="container seowork-page">
					<LoadingBarFullDimed isLoading={isLoading} />
				</div>
			</>
		);
	} else {
		return <SeoWorkDetailPage></SeoWorkDetailPage>;
	}
}
