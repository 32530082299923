import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { CHANGE_NAME } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const MyPageChangeName = async (userName) => {
	try {
		const response = await api.post(CHANGE_NAME, {
			name: userName
		});
		
		return "Success";
	} catch (error) {
		console.error("이른변경 실패", error)
		navigateTo("/error");

		return;
	}
};
