import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PopupAgreement from "@/pages/popup/PopupAgreement";
import PopupPrivacy from "@/pages/popup/PopupPrivacy";
import CiInseq from "@/assets/images/ci-inseq@2x.png";
import { getCookie } from "@/services/cookies/UserCookies";

// SNS
import IcoSnsKakao from "@/assets/images/ico-sns-kakao@2x.png";
import IcoSnsInsta from "@/assets/images/ico-sns-insta@2x.png";
import IcoSnsBlog from "@/assets/images/ico-sns-blog@2x.png";
// import IcoSnsFacebook from "@/assets/images/ico-sns-facebook@2x.png";
// import IcoSnsThread from "@/assets/images/ico-sns-thread@2x.png";
// import IcoSnsX from "@/assets/images/ico-sns-x@2x.png";
// import IcoSnsLinkedin from "@/assets/images/ico-sns-linkedin@2x.png";

export default function FooterLanding() {
	const [isPopupAgreement, setisPopupAgreement] = useState(false);
	const [isPopupPrivacy, setisPopupPrivacy] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const token = getCookie("token");
		setIsAuthenticated(!!token);
	}, []);

	const openPopupAgreement = () => setisPopupAgreement(true);
	const openPopupPrivacy = () => setisPopupPrivacy(true);

	return (
		<>
			<footer id="footer" className="footer-landing">
				<div className="container">
					<ul className="footer-menu">
						<li>
							<button type="button" onClick={openPopupAgreement}>
								이용약관
							</button>
						</li>
						<li>
							<button type="button" onClick={openPopupPrivacy} className="text-medium">
								개인정보처리방침
							</button>
						</li>
						{!isAuthenticated && (
							<li>
								<Link to="/Login">회원가입</Link>
							</li>
						)}
						<li>
							<Link to="https://tally.so/r/w4kBDO" target="_blank" title="새창" className="text-secondary">
								제휴문의
							</Link>
						</li>
					</ul>
					<address className="address">
						<Link className="company-name" to="https://inseq.co.kr/ko/cntnts/i-17/web.do" target="_blank" title="새창">
							<img src={CiInseq} alt="INSEQ" className="ci" />
							주식회사 인시퀀스
						</Link>

						<ul className="company-info">
							<li>
								대표전화 : 070-8667-3773 <span>|</span>
								대표메일 : a11y@inseq.co.kr <span>|</span>
								서울시 구로구 부광로 88, SK V1 Center B동 1015호
							</li>
							<li>
								사업자등록번호 : 691-87-01091 <span>|</span>
								대표자명 : 임미선 <span>|</span>
								개인정보보호책임자 : 임미선
							</li>
							{/* <li>통신판매업 신고번호 : 2024-</li> */}
						</ul>
						<p className="copyright">COPYRIGHT 2024 INSEQUENCE Co., Ltd. ALL RIGHT RESERVED.</p>
					</address>

					<ul className="sns-list">
						<li>
							<Link to="https://open.kakao.com/o/g9jkTvug" target="_blank" title="새창">
								<img src={IcoSnsKakao} alt="카카오톡 오픈채팅 앨리(A11Y) 사용자 커뮤니티" />
							</Link>
						</li>
						<li>
							<Link to="https://www.instagram.com/a11y.official/" target="_blank" title="새창">
								<img src={IcoSnsInsta} alt="앨리 공식 인스타그램" />
							</Link>
						</li>
						<li>
							<Link to="https://blog.naver.com/inseq" target="_blank" title="새창">
								<img src={IcoSnsBlog} alt="앨리 네이버 블로그" />
							</Link>
						</li>
						{/* <li>
							<Link to="" target="_blank" title="새창">
								<img src={IcoSnsFacebook} alt="페이스북" />
							</Link>
						</li>
						<li>
							<Link to="" target="_blank" title="새창">
								<img src={IcoSnsThread} alt="스레드" />
							</Link>
						</li>
						<li>
							<Link to="" target="_blank" title="새창">
								<img src={IcoSnsX} alt="엑스" />
							</Link>
						</li>
						<li>
							<Link to="" target="_blank" title="새창">
								<img src={IcoSnsLinkedin} alt="링크드인" />
							</Link>
						</li> */}
					</ul>
				</div>
			</footer>
			<PopupAgreement isOpen={isPopupAgreement} closePopup={() => setisPopupAgreement(false)} />
			<PopupPrivacy isOpen={isPopupPrivacy} closePopup={() => setisPopupPrivacy(false)} />
		</>
	);
}
