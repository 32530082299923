import { useState, useEffect } from "react";

const WorkUsePopup = (initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const [triggerElement, setTriggerElement] = useState(null);
	const [selectedId, setSelectedId] = useState(null); // ID 상태
	const [selectedName, setSelectedName] = useState(null); // 이름 상태 추가

	const open = (id = null, name = null, e = null) => {
		// id와 name 인자 추가
		if (e && e.currentTarget) {
			setTriggerElement(e.currentTarget);
		}
		setSelectedId(id); // id 상태 업데이트
		setSelectedName(name); // name 상태 업데이트
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
		setSelectedId(null); // id 초기화
		setSelectedName(null); // name 초기화
	};

	useEffect(() => {
		if (!isOpen && triggerElement) {
			triggerElement.focus();
			setTriggerElement(null);
		}
	}, [isOpen, triggerElement]);

	return { isOpen, open, close, selectedId, selectedName }; // selectedId와 selectedName 반환
};

export default WorkUsePopup;
