import { RESET_STATE } from "../action/ResetAction";

const {
	SET_SORT_DESC,
	SET_TRASH_DESC,
	SET_DASHBOARD_DESC,
	SET_CARD_MYPAGE_OPTION2,
	SET_CARD_MYPAGE_OPTION,
	SET_TRASH_OPTION,
	SET_WORK_ORDER_BY,
	SET_WORK_ACTIVE,
	SET_TRASH_ORDER_BY,
	SET_TRASH_ACTIVE,
	SET_TRASH_OPTION2,
	SET_DASHBOARD_LIST_SORT,
	SET_DASHBOARD_CARD_SORT,
	UPDATE_DASHBOARD_LIST_FIELD,
	UPDATE_DASHBOARD_CARD_DIRECTION,
	UPDATE_DASHBOARD_CARD_LIST_ORDERBY,
	UPDATE_DASHBOARD_CARD_LIST_DESC
} = require("../action/SortOrderAction");

// 초기 상태 정의
const initialState = {
	desc: {
		desc1: true,
		desc2: null,
		desc3: null,
		desc4: null,
		desc5: null
	},
	orderBy: 1,
	isActive: true
};

const trashInitialState = {
	desc: {
		desc1: true,
		desc2: null,
		desc3: null,
		desc4: null,
		desc5: null
	},
	orderBy: 1,
	isActive: true
};

const dashboardInitialState = {
	desc: {
		desc1: true,
		desc2: null,
		desc3: null,
		desc4: null,
		desc5: null
	},
	orderBy: 1,
	isActive: true
};

const workCardInitialState = {
	orderBy: 1,
	desc: true
};

const trashCardInitialState = {
	orderBy: 1,
	desc: true
};


const dashBoardInitialState = {
	sort: {
		list: {
			field: "lastModified",
			direction: "desc"
		},
		card: {
			orderBy: 1,
			desc: true
		},
		
	}
}



// 리듀서

export const sortDashBoardReducer = (state = dashBoardInitialState, action) => {
	switch (action.type) {
		case SET_DASHBOARD_LIST_SORT:
			return {
				...state,
				sort: {
					...state.sort,
					list: {
						...state.sort.list,
						field: action.payload.field,
						direction: action.payload.direction
					}
				}
			};
			break;
			case SET_DASHBOARD_CARD_SORT:
				return {
					...state,
					sort: {
						...state.sort,
						card: {
							...state.sort.card,
							orderBy: action.payload.orderBy,
							desc: action.payload.desc
						}
					}
				}
				break;
				case UPDATE_DASHBOARD_LIST_FIELD:
					return {
						...state,
						sort: {
							...state.sort,
							list: {
								...state.sort.list,
								field: action.payload.field
							}
						}
					}
				break;
				case UPDATE_DASHBOARD_CARD_DIRECTION:
					return {
						...state,
						sort: {
							...state.sort,
							list: {
								...state.sort.list,
								direction: action.payload.direction
							}
						}
					}
					break;
					case UPDATE_DASHBOARD_CARD_LIST_ORDERBY:
						return {
							...state,
							sort: {
								...state.sort,
								card: {
									...state.sort.card,
									orderBy: action.payload.orderBy
								}
							}
						}
						break;
						case UPDATE_DASHBOARD_CARD_LIST_DESC:
							return {
								...state,
								sort: {
									...state.sort,
									card: {
										...state.sort.card,
										desc: action.payload.desc
									}
								}
							}
							break;
				case RESET_STATE:
					return dashBoardInitialState;
				default: return state;
	}
}

export const sortReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SORT_DESC:
			const { descName, value } = action.payload;
			return {
				...state,
				desc: {
					...state.desc,
					[descName]: value
				}
			};
			break;
			case SET_WORK_ORDER_BY:
				return {
					...state,
					orderBy: action.payload
				};
				break;
				case SET_WORK_ACTIVE:
					return {
						...state,
						isActive: action.payload
					}
					break;
		case RESET_STATE:
			return initialState;
			break;
		default:
			return state;
	}
};

export const trashSortReducer = (state = trashInitialState, action) => {
	switch (action.type) {
		case SET_TRASH_DESC:
			const { descName, value } = action.payload;
			return {
				...state,
				desc: {
					...state.desc,
					[descName]: value
				}
			};
			break;
			case SET_TRASH_ACTIVE:
				return {
					...state,
					isActive: action.payload
				}
				break;
			case SET_TRASH_ORDER_BY:
				return {
					...state,
					orderBy: action.payload
				};
				break;
		case RESET_STATE:
			return trashInitialState;
			break;
		default:
			return state;
	}
};

export const dashboardReducer = (state = dashboardInitialState, action) => {
	switch (action.type) {
		case SET_DASHBOARD_DESC:
			const { descName, value } = action.payload;
			return {
				...state,
				desc: {
					...state.desc,
					[descName]: value
				}
			};
			break;
		case RESET_STATE:
			return dashboardInitialState;
			break;
		default:
			return state;
	}
};

export const workCardSortingReducer = (state = workCardInitialState, action) => {
	switch (action.type) {
		case SET_CARD_MYPAGE_OPTION:
			return {
				...state,
				orderBy: action.payload
			};
		case SET_CARD_MYPAGE_OPTION2:
			return {
				...state,
				desc: action.payload
			};
			break;
		case RESET_STATE:
			return workCardInitialState;
			break;
		default:
			return state;
	}
};

export const trashCardSortingReducer = (state = trashCardInitialState, action) => {
	switch (action.type) {
		case SET_TRASH_OPTION:
			return {
				...state,
				orderBy: action.payload
			};
			case SET_TRASH_OPTION2:
				return {
					...state,
					desc: action.payload
				}
break;
		case RESET_STATE:
			return trashCardInitialState;
			break;
		default:
			return state;
	}
};
