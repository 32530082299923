import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "@/services/cookies/UserCookies";
import PopupLayout from "@/components/popup/PopupLayout";
import Toast from "@/components/toast/Toast";
import usePopup from "@/hooks/usePopup";
import PopupLogin from "@/pages/popup/PopupLogin";

const EventCodeAlert = ({ isOpen, closePopup, code }) => {
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const popupLoginCommon = usePopup();

	useEffect(() => {
		// Check if the user is authenticated based on the token
		const token = getCookie("token");
		setIsAuthenticated(!!token);
	}, []);

	// 로그인 성공 후 처리할 핸들러 추가
	const handleLoginSuccess = (userData) => {
		closePopup(); // 이벤트 코드 알림 팝업 닫기
		popupLoginCommon.close(); // 로그인 팝업 닫기
		navigate(`/setting/license?code=${code}`); // 사용권 등록 페이지로 이동
	};

	const handleRegisterClick = () => {
		if (isAuthenticated) {
			closePopup();
			navigate(`/setting/license?code=${code}`);
		} else {
			popupLoginCommon.open();
		}
	};

	return (
		<>
			<PopupLayout
				className="event-code-alert"
				size="md"
				isOpen={isOpen}
				closePopup={closePopup}
				title="앨리 이벤트 사용권 코드"
				footerContent={
					<div className="btn-group">
						<button type="button" className="btn btn-xl btn-primary" onClick={handleRegisterClick}>
							지금 사용권 등록하기
						</button>
					</div>
				}
			>
				<div className="box">
					<span className="text">
						<span className="d-up-lg">사용권</span> 코드
					</span>
					<span className="num">
						<span className="code">🎫 {code}</span>
					</span>
				</div>
				<div className="license-results-notice">
					<ul className="list list1 text-sm">
						<li>이벤트 기간 내 1회만 등록할 수 있어요.</li>
						<li>지금 창을 닫으면 코드를 다시 확인할 수 없어요.</li>
						{!isAuthenticated && <li>앨리 로그인 후 사용권 등록 메뉴에 코드를 입력하세요.</li>}
					</ul>
				</div>
			</PopupLayout>
			<PopupLogin
				isOpen={popupLoginCommon.isOpen}
				closePopup={popupLoginCommon.close}
				to={`/setting/license?code=${code}`}
				onLoginSuccess={handleLoginSuccess}
			/>
		</>
	);
};

export default EventCodeAlert;
