import api from "@/services/util/Interceptor";

import { WORKSPACE_DETAIL } from "../url/URL_workspace";
import { DataCount } from "../mypage/MyDataCount";
import { DashBoard } from "./DashBoard";
import { setSeoData } from "@/services/store/action/seoAction";
import { setTableData } from "@/services/store/action/tableAction";
import { createHtmlSuccess } from "@/services/store/action/Img2HtmlAction";
import {
	outPutImgData,
	outPutSaveData,
	refreshSaveData,
	resetImageOutput,
	setBrightnessData,
	setBrightnessStep,
	setImageData
} from "@/services/store/action/BrightnessAction";
import createMetaTag from "@/services/util/seoMeta";

import { workSpaceLoaded, workSpaceLoading } from "@/services/store/action/WorkSpaceAction";
import { dashboardLoaded, dashboardLoading } from "@/services/store/action/DashBoard";
import { selectSid } from "@/services/store/action/SelectAction";


export const seoDetail= (dispatch,response) =>{

	dispatch(selectSid("SEO 메타태그 생성기"));

	var keyword = "";	

	response.data.data.keyword.forEach((key, index, array) => {
		
		// keyword.join(", ")
		// 배열의 마지막 요소인 경우의 처리
		if (index === array.length - 1) {
			// 여기서 원하는 작업을 수행하세요.
			// 예: 마지막 요소임을 확인하거나, 마지막에 특별한 처리를 하고 싶은 경우
			keyword += `${key.text}`;
		} else {
			keyword += `${key.text}, `;
		}
	});
	let metaTag
	const url = response.data.data.url ??""
	if (!response.data.data.meta_html){
		metaTag=createMetaTag(response.data.data.title[0].content,response.data.data.description.text,keyword,url,response.data.data.siteName)
	}else{
		metaTag=response.data.data.meta_html
	}
	const keywords = response.data.data.keyword.map(item => {
		if (item.choice === true) {
		  return { skid: item.skId, keyword: item.text };
		}
	  })
	  .filter(item => item !== undefined);
	
	const title = response.data.data.title.map((item)=>
		{
			if (item.choice===true){
				return {title:item.content,sotId:item.sotId}
				
			}
		}).filter(item => item !== undefined);
	let titleData 
	if (title.length==0){
		titleData=response.data.data.title[0]
	}else{
		titleData=title[0]
	}
	
	const updatedSeoData = {
		data: {
		
			html: response.data.data.html,
			resData: response.data.data,
			choiceData: {
				description: response.data.data.description,
				title: titleData,
				keywords: keywords,
				sumnail: {
					ssId: ""
				}
			},
			isChangeTag: false,
			metaTag:metaTag,
			url:url,
			saveTime:undefined
		}
	};


	dispatch(setSeoData(updatedSeoData));

}
export const tableDetail = (dispatch,response) =>{
	dispatch(selectSid("표 접근성 최적화"));

	// 표접근성
	const transformedData = response.data.data.tables.map((item) => {
		
		return {
			id: item.toId,
			caption: {
				text:item.caption,
				status: (item.caption === null) ? 'none' : (item.caption === '') ? 'empty' : 'present',
				recommendedCaptions:[item.summaryCaption ?? null, item.naturalCaption ?? null].filter(item => item !== null),
				
			},
			htmlCode: item.ori_html,
			oriHtml : item.ori_html,
			isLoading:false,
			choice:item.choice
		};
  })
	const updatedTableData = {
		data: {
			resData: response.data.data,				
			transformedData:transformedData
		}

	};

	dispatch(setTableData(updatedTableData));
}

export const img2htmlDetail=(dispatch,response)=>{
	dispatch(selectSid("이미지 HTML 변환기"));

	// 이미지투html
	const originalData = response.data;
	const newDataState = {
		response: {
			data: originalData
		}
	};
	dispatch(createHtmlSuccess(newDataState));
}

export const contrastDetail=(dispatch,response)=>{
	dispatch(selectSid("이미지 명도대비 최적화"));

		if (response.data.data.stepTwo !== null) {
			dispatch(setBrightnessStep(2));
			const outPutImg = response.data?.data?.stepTwo?.newImgPath;
			const brightness = response.data?.data?.ratio;
			dispatch(outPutSaveData(outPutImg));
			dispatch(outPutImgData(outPutImg));
			const stepOneData = response.data?.data?.stepOne;
			const selectedColors = response.data.data.stepOne.results.result;
			const itemsArray = Object.values(selectedColors);
			const changeWordColors = ["", ...itemsArray.map((item) => item.change_word_color)];
			dispatch(setBrightnessData(brightness));
			dispatch(refreshSaveData(changeWordColors));
			/// 스텝투가 null이 아닌경우 즉 사용자가 stepTwo까지 작성한경우
			const OneData = {
				data: stepOneData,
				output: null
			};
			delete OneData.data.results.mask;
			OneData.data.results = { ...OneData.data.results.result };

			dispatch(setImageData(OneData));
		} else {
			dispatch(setBrightnessStep(1));
			dispatch(resetImageOutput());
			/// 사용자가 stepOne만 작성한경우
			const stepOneData = response.data.data.stepOne;
			const selectedColors = response.data.data.stepOne.results.result;
			const brightness = response.data?.data?.ratio;
			dispatch(setBrightnessData(brightness));

			// selectedColors 객체에서 모든 값들을 배열로 변환
			const itemsArray = Object.values(selectedColors);

			// 변환된 배열을 사용하여 change_word_color 속성만 추출
			const changeWordColors = itemsArray.map((item) => item.change_word_color);

			const adjustedChangeWordColors = [null, ...changeWordColors];

			dispatch(refreshSaveData(adjustedChangeWordColors));

			const OneData = {
				data: stepOneData,
				output: null
			};
			delete OneData.data.results.mask;
			OneData.data.results = { ...OneData.data.results.result };

			dispatch(setImageData(OneData));
		}
}

export const goDetail=async (workspaceId,navigate)=>{
	try {
		const response = await api.get(WORKSPACE_DETAIL + "/" + workspaceId);
		const data = response.data.data;
		return response
	} catch (error) {
		console.error(error);
		navigate("/error");
	}
	
}

export const handleWorkDetail = async (workspaceType, workspaceId, navigate, dispatch, reduxData,isDashboard=false) => {
	let response;
	if(isDashboard){
		dispatch(dashboardLoading())
	}else{
		dispatch(workSpaceLoading())
	}
	
	try {
		response = await api.get(WORKSPACE_DETAIL + "/" + workspaceId);
		const data = response.data.data;
	} catch (error) {
		console.error(error);
		navigate("/error");
	}
	
    if (workspaceType==="seo"){
		seoDetail(dispatch,response)

    }else if (workspaceType==="table"){
		tableDetail(dispatch,response)
	} else if (workspaceType === "img2html") {
		img2htmlDetail(dispatch,response)
	} else if (workspaceType === "contrast") {
		
		contrastDetail(dispatch,response)

	}
	if(isDashboard){
		dispatch(dashboardLoaded())
	}else{
		dispatch(workSpaceLoaded())
	}
	if (workspaceType === "contrast") {
		navigate(`/tool/${workspaceType}/work-detail/${workspaceId}?additionalData=true&isLoad=true`);
	} else {
		navigate(`/tool/${workspaceType}/work-detail/${workspaceId}?isLoad=true`);

	}
};
