import { getCookie } from "@/services/cookies/UserCookies";
import api from "@/services/util/Interceptor";
import { TABLE_AUTU_SAVE, TABLE_CAPTION_CREATE, TABLE_CAPTION_SAVE, TABLE_CREATE, TABLE_RESULTHTML_SAVE } from "../url/URL_Table";

export const TableCreate = async (data) => {
	
	try {
		const response = await api.post(TABLE_CREATE, data, 
		);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		console.error(error)
		if (error.code=="ERR_CANCELED"){
			return {
				status: "ERR_CANCELED"
				
			};
		}else{
			return {
				status:error.response.data.message,
				data:error
			};
		}
		
	}
};


export const TableCaptionCreate = async (data) => {
	
	try {
		const response = await api.post(TABLE_CAPTION_CREATE, data, 
		);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		console.error(error)
		if (error.code=="ERR_CANCELED"){
			return {
				status: "ERR_CANCELED"
				
			};
		}else{
			return {
				status:"fail",
				data:error
			};
		}

		
	}
};


export const TableCaptionALLHtmlSave = async (data) => {
	
	try {
		const response = await api.post(TABLE_RESULTHTML_SAVE, data, 
		);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		console.error(error)
		return {
			status:"fail",
			data:error
		};
	}
};


export const TableCaptionSave = async (data) => {
	
	try {
		const response = await api.post(TABLE_CAPTION_SAVE, data, 
		);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		console.error(error)
		return {
			status:"fail",
			data:error
		};
	}
};


export const TableAutoSave = async (data)=>{

	try {
		const response = await api.post(TABLE_AUTU_SAVE, data, 
		);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		console.error(error)
		return {
			status:"fail",
			data:error
		};
	}
}