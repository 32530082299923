// LoadingOverlay.js
import React from "react";

const LoadingOverlay = ({ loadingText, type = "spinner" }) => {
	const renderLoader = () => {
		switch (type) {
			case "progress":
				return (
					<div className="progress-bar">
						<div className="progress" style={{ width: "50%" }}></div> {/* 예시 진행률: 50% */}
					</div>
				);
			case "spinner":
			default:
				return (
					<svg
						className="spinner"
						viewBox="0 0 50 50"
						style={{ backgroundColor: "transparent", width: 40, height: 40 }}
					>
						<circle
							cx="25"
							cy="25"
							r="20"
							fill="#1364FF"
							stroke="#1364FF"
							fillOpacity="0.75"
							strokeOpacity="0.75"
							strokeWidth="6"
						></circle>
						<circle cx="25" cy="25" r="20" fill="#1364FF" strokeWidth="6">
							<animateTransform
								attributeName="transform"
								type="rotate"
								values="-90;810"
								keyTimes="0;1"
								dur="2s"
								repeatCount="indefinite"
							></animateTransform>
							<animate
								attributeName="stroke-dashoffset"
								values="0%;0%;-157.080%"
								calcMode="spline"
								keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
								keyTimes="0;0.5;1"
								dur="2s"
								repeatCount="indefinite"
							></animate>
							<animate
								attributeName="stroke-dasharray"
								values="0% 314.159%;157.080% 157.080%;0% 314.159%"
								calcMode="spline"
								keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
								keyTimes="0;0.5;1"
								dur="2s"
								repeatCount="indefinite"
							></animate>
						</circle>
					</svg>
				);
		}
	};

	return (
		<div className="loading-overlay">
			<div className="loading-bar" style={{ backgroundColor: "transparent" }}>
				{renderLoader()}
			</div>
			<p className="loading-text">{loadingText}</p>
		</div>
	);
};

export default LoadingOverlay;
