import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { UPLOAD_PROFILEIMG } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const MyPageProfileImgUpload = async (formData) => {
	try {
		const response = await api.post(UPLOAD_PROFILEIMG, formData);
		return {
			status: "Success",
			data: response.data
		};
	} catch (error) {
		window.alert({
			title: "이미지 업로드 실패했어요.",
			content: `<p>업로드 한 이미지 파일 형식이 지원되는지,<br> 파일 크기가 너무 크지 않은지 확인해 주세요.</p>
			<span class="sub-message">업로드 가능 이미지 형식 : JPG(JPEG), PNG, GIF, BMP</span>`,
			btnClassName: "primary",
			alertBtnText: "확인"
		})
		return {
			status: "fail",
			// data: response.data
		};
	}
};
