// actionTypes.js
export const CREATE_HTML_SUCCESS = "CREATE_HTML_SUCCESS";
export const CREATE_HTML_FAILURE = "CREATE_HTML_FAILURE";
export const SHARE_HTML = "SHARE_HTML";
export const AUTO_SAVE_HTML = "AUTO_SAVE_HTML";

export const SHARE_RESET_STATE = "SHARE_RESET_STATE";
export const UPDATE_IS_SHARE = "UPDATE_IS_SHARE";
export const UPDATE_IS_ADDRESS = "UPDATE_IS_ADDRESS";
export const RESET_SAVE_TIME = "RESET_SAVE_TIMe";

export const resetSaveTime = () => ({
  type: RESET_SAVE_TIME
});
export const updateIsAddress = (address) => ({
	type: UPDATE_IS_ADDRESS,
	payload: address
})

export const updateIsShare = (isShare) => ({
	type: UPDATE_IS_SHARE,
	payload: isShare,
})

export const createHtmlSuccess = (data) => ({
	type: CREATE_HTML_SUCCESS,
	payload: data
});

export const createHtmlFailure = (error) => ({
	type: CREATE_HTML_FAILURE,
	payload: error
});

export const shareHtmlSuccess = (data) => ({
	type: SHARE_HTML,
	payload: data
});

export const shareResetState = () => {
	return {
		type: SHARE_RESET_STATE
	};
};

export const autoSaveHtml = (data) => {
	return {
		type: AUTO_SAVE_HTML,
		payload: data
	};
};

