const fetchWithRetry = async (url, maxRetries, initialDelay = 1000, maxDelay = 32000) => {
	let retries = 0;
	let delay = initialDelay;

	while (retries < maxRetries) {
		try {
			const response = await fetch(url);
			if (response.ok) {
				const data = await response.json();
				return data.values ? data.values.slice(1) : [];
			} else if (response.status === 429) {
				// Too many requests, apply exponential backoff
				retries++;
				await new Promise((resolve) => setTimeout(resolve, delay));
				delay = Math.min(delay * 2, maxDelay); // 다음 재시도 시 딜레이 시간을 두 배로 증가, 최대 딜레이 제한
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			if (retries === maxRetries) {
				throw new Error("Max retries reached");
			}
			retries++;
			await new Promise((resolve) => setTimeout(resolve, delay));
			delay = Math.min(delay * 2, maxDelay); // 다음 재시도 시 딜레이 시간을 두 배로 증가, 최대 딜레이 제한
		}
	}
	throw new Error("Max retries reached");
};

const isDataExpired = (timestamp, expirationPeriod) => {
	const currentTime = new Date().getTime();
	return currentTime - timestamp > expirationPeriod;
};

const getLocalStorageData = (key) => {
	const cachedData = localStorage.getItem(key);
	const cachedTimestamp = localStorage.getItem(`${key}_timestamp`);
	return { cachedData, cachedTimestamp };
};

const setLocalStorageData = (key, data) => {
	localStorage.setItem(key, JSON.stringify(data));
	localStorage.setItem(`${key}_timestamp`, new Date().getTime());
};

export const fetchGoogleSheetData = async (
	sheetName,
	{
		useLocalStorage = true,
		expirationPeriod = 60000,
		maxRetries = 5,
		initialDelay = 1000,
		maxDelay = 32000,
		apiKey = "AIzaSyAL1KacowOJ_2iAnOeNuY-BEcn1-4BKOiE",
		sheetId = "1sFLJiBVpqjr3C-n17E-7Tx8R4EyAVCOMaqdDqHu5nrk"
	} = {}
) => {
	const localStorageKey = `googleSheetData_${sheetName}`;
	const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`;

	if (useLocalStorage) {
		const { cachedData, cachedTimestamp } = getLocalStorageData(localStorageKey);

		if (cachedData && cachedTimestamp && !isDataExpired(cachedTimestamp, expirationPeriod)) {
			return JSON.parse(cachedData);
		}
	}

	try {
		const data = await fetchWithRetry(url, maxRetries, initialDelay, maxDelay);
		if (useLocalStorage) {
			setLocalStorageData(localStorageKey, data);
		}
		return data;
	} catch (error) {
		console.error("Error fetching data from Google Sheets:", error);
		return [];
	}
};
