import { useState, useEffect, useRef } from "react";
import ArticleContainer from "@/components/community/ArticleContainer";
import CommunityInput from "@/components/community/CommunityInput";
import { tagData } from "@/data/communityFilter";

export default function CommunityPage() {
	const [filterTag, setFilterTag] = useState("");
	const [filterTool, setFilterTool] = useState("");
	const [placeholder, setPlaceholder] = useState("");
	const [placeholderEmoji, setPlaceholderEmoji] = useState("");
	const articleContainerRef = useRef(null);

	useEffect(() => {
		const randomPlaceholder = tagData[Math.floor(Math.random() * tagData.length)];
		setPlaceholder(randomPlaceholder.placeholder);
		setPlaceholderEmoji(randomPlaceholder.emoji);
	}, []);

	const handleTagFilter = (tag) => {
		if (tag === "전체") {
			setFilterTag("");
			setFilterTool("");
		} else {
			setFilterTag(tag);
			setFilterTool("");
		}

		const selectedTag = tagData.find((t) => t.text === tag);
		if (selectedTag) {
			setPlaceholder(selectedTag.placeholder);
			setPlaceholderEmoji(selectedTag.emoji);
		}
	};

	const handleToolFilter = (tool) => {
		console.log("handleToolFilter called with tool:", tool);

		const allyReviewTag = tagData.find((t) => t.text === "앨리후기");
		if (allyReviewTag) {
			const selectedTool = allyReviewTag.tools.find((t) => t.keyword === tool);
			if (selectedTool) {
				setFilterTool(selectedTool.keyword);
				setFilterTag("앨리후기");
				setPlaceholder(allyReviewTag.placeholder);
				setPlaceholderEmoji(allyReviewTag.emoji);
			}
		}
	};

	const refreshArticles = () => {
		if (articleContainerRef.current) {
			articleContainerRef.current.refresh();
		}
	};

	return (
		<div className="container community-page">
			<div className="col col-content">
				<div className="page-header">
					<div className="title-area">
						<h2 className="page-title">앨리 커뮤니티</h2>
						<CommunityInput
							placeholder={placeholder}
							placeholderEmoji={placeholderEmoji}
							filterTag={filterTag}
							filterTool={filterTool}
							onSubmitSuccess={refreshArticles}
						/>
					</div>
				</div>
				<div className="category-filter">
					<div className="row">
						<button
							type="button"
							className={`btn ${filterTag === "" ? "active" : ""}`}
							onClick={() => handleTagFilter("전체")}
						>
							#전체
						</button>
						{tagData.map((tag, index) => (
							<button
								type="button"
								key={index}
								className={`btn ${filterTag === tag.text ? "active" : ""}`}
								onClick={() => handleTagFilter(tag.text)}
							>
								#{tag.text}
							</button>
						))}
					</div>
					<div className="row">
						{tagData
							.find((tag) => tag.text === "앨리후기")
							.tools.map((tool, index) => (
								<button
									type="button"
									key={index}
									className={`btn ${filterTool === tool.keyword ? "active" : ""}`}
									onClick={() => handleToolFilter(tool.keyword)}
								>
									#{tool.text}
								</button>
							))}
					</div>
				</div>
				<ArticleContainer
					ref={articleContainerRef}
					filterTag={filterTag}
					filterTool={filterTool}
					handleTagFilter={handleTagFilter} // 필터링 함수 전달
					handleToolFilter={handleToolFilter} // 필터링 함수 전달
				/>
			</div>
		</div>
	);
}
