import { API_URL2 } from "./URL";

export const TABLE_CREATE = `${API_URL2}table_api/create`;

export const TABLE_CAPTION_CREATE = `${API_URL2}table_api/create/caption`;

export const TABLE_CAPTION_SAVE = `${API_URL2}table_api/save/caption`;

export const TABLE_RESULTHTML_SAVE = `${API_URL2}table_api/save/result_html`;

export const TABLE_AUTU_SAVE = `${API_URL2}table_api/save`;

