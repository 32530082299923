import CodeMirrorMerge from "react-codemirror-merge";
import { EditorView } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { html } from "@codemirror/lang-html";
import HeaderDetails from "@/components/layouts/HeaderDetails";
import FooterDetails from "@/components/layouts/FooterDetails";
import { HtmlcodeData, HtmlcodeData2 } from "@/mock/HtmlcodeData"; // 삭제 필요

export const CodeCompare = () => {
	return (
		<div className="container linter-code-compare-page">
			<HeaderDetails className="service-linter" />
			<div className="body-details">
				<div className="linter-detail-container">
					<CodeMirrorMerge className="codemirror-merge-container" orientation="a-b">
						<CodeMirrorMerge.Original
							value={HtmlcodeData}
							extensions={[EditorView.lineWrapping, EditorState.readOnly.of(true), html()]}
						/>
						<CodeMirrorMerge.Modified
							value={HtmlcodeData2}
							extensions={[EditorView.lineWrapping, EditorState.readOnly.of(true), html()]}
						/>
					</CodeMirrorMerge>
				</div>
			</div>
			<FooterDetails>
				<div className="warp-left">
					<button type="button" className="btn btn-lg btn-primary">
						커스텀
					</button>
				</div>
				<div className="warp-right">
					<button type="button" className="btn btn-lg btn-primary">
						커스텀
					</button>
				</div>
			</FooterDetails>
		</div>
	);
};

export default CodeCompare;
