import api from "@/services/util/Interceptor";
import { UPLOAD_DEFAULTIMG } from "../url/URL";
import { navigateTo } from "@/services/util/Navigate";

export const MyPageDefaultProfile = async () => {
	try {
		const response = await api.get(UPLOAD_DEFAULTIMG);

		return response;
	} catch (error) {
		navigateTo("/error");
	}
};
