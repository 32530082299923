import React, { useState, useEffect } from "react";
import Toast from "@/components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setSeoData, updateSeoMetaTag } from "@/services/store/action/seoAction";
import { SeoSave } from "@/services/core/seo/SeoSave";

export default function SeoMetaKeyword({setIsChangeTag}) {
	const data = useSelector((state) => state.seo.seoData.data.resData.keyword);
	const choicedata = useSelector((state) => state.seo.seoData.data.choiceData.keywords);
	const [fristRender,setFristRender]=useState(true)
	
	const seoData = useSelector((state) => state.seo.seoData);
	const [selectedValue, setSelectedValue] = useState(choicedata.map((item) => item.keyword));
	const [selectedKeyword, setSelectedKeyword] = useState(choicedata.map((item) => ({ skid: item.skid, keyword: item.keyword })));
	const [showToast, setShowToast] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const dispatch = useDispatch();
	
	useEffect(() => {
		setIsButtonDisabled(selectedValue.length === 0);
	}, [selectedValue]);

	const handleCheckboxChange = (event) => {
		const value = event.target.value;
		const id = event.target.id;
		let skId = id.split("_")[1];
		const data = { skid: skId, keyword: value };

		if (selectedValue.includes(value)) {
			setSelectedValue(selectedValue.filter((item) => item !== value));
			setSelectedKeyword(selectedKeyword.filter((item) => item["keyword"] !== value));
		} else {
			setSelectedValue([...selectedValue, value]);
			setSelectedKeyword([...selectedKeyword, data]);
		}
	};
	useEffect(() => {
		const updatedSeoData = {
			...seoData,
			data: {
				...seoData.data,
				choiceData: {
					...seoData.data.choiceData,
					keywords: selectedKeyword
				},
				isChangeTag: true
			}
		};
		
		dispatch(setSeoData(updatedSeoData));
		if (fristRender){
			setFristRender(false)
		}else{
			setIsChangeTag(true)
		}
		
		

	}, [selectedKeyword]);
	const handleToastClick = () => {
		if (selectedValue.length > 0) {
			const copiedKeywords = selectedValue.join(", ");
			
			const dummy = document.createElement("textarea");
			document.body.appendChild(dummy);
			dummy.value = copiedKeywords;
			dummy.select();
			document.execCommand("copy");
			document.body.removeChild(dummy);

			setShowToast(true);
			setIsButtonDisabled(true); // 버튼 비활성화
		}
	};

	const handleToastClose = () => {
		setShowToast(false);
		setIsButtonDisabled(false); // 토스트가 닫힐 때 버튼 활성화
	};
	useEffect(()=>{
		

		const newValue = choicedata.map((item) => item.keyword)
		const newSelect=choicedata.map((item) => ({ skid: item.skid, keyword: item.keyword }))
		if (!fristRender){
			setSelectedKeyword(newSelect)
			setSelectedValue(newValue)
		}

	},[data])

	return (
		<>
			<div className="title-wrap">
				<div className="wrap-left">
					<h4 className="title3">SEO 키워드 추천</h4>
				</div>
			</div>
			<div className="seowork-content">
				<ul className="list-metatitle">
					{data.map((item, index) => (
						<li key={item.skId}>
							<span className="check-item-sm">
								<input
									type="checkbox"
									id={`checkbox_${item.skId}`}
									name="metaTitle"
									value={item.text}
									checked={selectedValue.includes(item.text)}
									onChange={handleCheckboxChange}
								/>
								<label htmlFor={`checkbox_${item.skId}`}>{item.text}</label>
							</span>
						</li>
					))}
				</ul>
				<button type="button" className="btn btn-copy" onClick={handleToastClick} disabled={isButtonDisabled}>
					<i className={`ico ${isButtonDisabled ? "ico-copy-gray-sm" : "ico-copy-sm"}`}></i>
					선택한 키워드 복사
				</button>

				{showToast && (
					<Toast
						message="SEO 키워드가 복사되었습니다."
						onClose={handleToastClose}
						icon={<i className="ico ico-copy-white-sm"></i>}
						position="bottom"
						theme="dark"
					/>
				)}
			</div>
		</>
	);
}
