import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingBarFullDimed from "@/components/loading/LoadingBarFullDimed";
import ContrastWorkDetailPage from "./ContrastWorkDetailPage";
import { contrastDetail, goDetail } from "@/services/core/workspace/WorkspaceDetail";

export default function ContrastWorkMiddlePage() {
	const data = useSelector((state) => state.brightness || []);
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { workId } = useParams();
	const queryParams = new URLSearchParams(location.search);
	const isLoad = queryParams.get("isLoad");
	const [isLoading, setIsLoading] = useState(true);

	const handleDetail = async () => {
		setIsLoading(true);
		const response = await goDetail(workId, navigate);
		contrastDetail(dispatch, response);
		navigate(`/tool/contrast/work-detail/${workId}?isLoad=true&additionalData=true`);
	};

	useEffect(() => {
		if (data.images.data.workspaceId !== workId && !isLoad) {
			handleDetail();
			setIsLoading(false);
		}
	}, [workId, isLoad, data]);

	if (data?.images?.data?.workspaceId === workId) {
		return <ContrastWorkDetailPage />;
	} else if (!isLoad) {
		return (
			<div className="container contrast-work-detail-page">
				<LoadingBarFullDimed isLoading={isLoading} />
			</div>
		);
	} else {
		return <ContrastWorkDetailPage />;
	}
}
