import { RESET_STATE } from "../action/ResetAction";

const initialState = {
	myLicense: {
		data: []
	}
};

const licenseReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_MY_LICENSE":
			if (action.payload.append) {
				return {
					...state,
					myLicense: {
						...state.myLicense,
						data: [action.payload.newLicenseData, ...state.myLicense.data] // 맨 위에 추가
					}
				};
			} else {
				return {
					...state,
					myLicense: {
						...state.myLicense,
						data: Array.isArray(action.payload.newLicenseData)
							? action.payload.newLicenseData
							: [action.payload.newLicenseData]
					}
				};
			}
		case RESET_STATE:
			return initialState; // RESET_STATE 케이스에서 초기 상태로 리셋
		default:
			return state;
	}
};

export default licenseReducer;

// Selector 함수: 전체 사용권 데이터를 선택
export const selectLicenseData = (state) => state.license.myLicense.data;

/**
 * 최근 1년 이내의 사용권 title을 반환하는 헬퍼 함수
 * @param {Array} licenseData - 사용권 데이터 배열
 * @returns {Array} - 최근 1년 이내의 사용권 title 배열
 */
export const getRecentLicenseTitles = (licenseData) => {
	const oneYearAgo = new Date();
	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

	return licenseData.filter((item) => new Date(item.createAt) >= oneYearAgo).map((item) => item.title);
};

/**
 * 사용 예:
 * import { useSelector } from 'react-redux';
 * import { selectLicenseData, getRecentLicenseTitles } from './reducer/LicenseReducer';
 *
 * const licenseData = useSelector(selectLicenseData);
 * const recentLicenseTitles = getRecentLicenseTitles(licenseData);
 *
 * // 이후 recentLicenseTitles를 이용하여 필요한 로직 구현
 */
