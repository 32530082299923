import axios from "axios";
import { API_URL2 } from "../url/URL";

const LoginWithNaver = async (to) => {
	await axios
		.get(`${API_URL2}user/naver/login`, {
			params: {
				state: to || ""
			}
		})
		.then((res) => {
			const url = res.data;
			window.location.href = url;
		})
		.catch((err) => {
			console.error(err);
		});
};

export default LoginWithNaver;
