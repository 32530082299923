import React, { useState } from "react";

function LoadingBarFull() {
	const [isLoading] = useState(false);

	if (!isLoading) {
		return null; // isLoading이 false이면 아무것도 렌더링하지 않음
	}
	return (
		<div className="loading-bar full">
			{isLoading && (
				<svg className="spinner" viewBox="0 0 50 50">
					<circle cx="50" cy="50" r="50" fill="none" strokeWidth="6">
						<animateTransform
							attributeName="transform"
							type="rotate"
							values="-90;810"
							keyTimes="0;1"
							dur="2s"
							repeatCount="indefinite"
						></animateTransform>
						<animate
							attributeName="stroke-dashoffset"
							values="0%;0%;-157.080%"
							calcMode="spline"
							keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
							keyTimes="0;0.5;1"
							dur="2s"
							repeatCount="indefinite"
						></animate>
						<animate
							attributeName="stroke-dasharray"
							values="0% 314.159%;157.080% 157.080%;0% 314.159%"
							calcMode="spline"
							keySplines="0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0"
							keyTimes="0;0.5;1"
							dur="2s"
							repeatCount="indefinite"
						></animate>
					</circle>
				</svg>
			)}
		</div>
	);
}

export default LoadingBarFull;
