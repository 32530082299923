export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const UPDATE_TOAST_KEY = "UPDATE_TOAST_KEY";

export const showToast = () => ({
	type: SHOW_TOAST
});

export const hideToast = () => ({
	type: HIDE_TOAST
});

export const updateToastKey = () => ({
	type: UPDATE_TOAST_KEY
});
