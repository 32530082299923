import React from "react";
import PropTypes from "prop-types";

function hexToRgb(hex) {
	hex = hex.replace("#", "");
	const bigint = parseInt(hex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;
	return { r, g, b };
}

function LoadingSpinner({
	size = "lg",
	color = "#888888",
	borderColor = "#999999",
	message = "데이터를 불러오고 있어요"
}) {
	const spinnerClass = `loading-spinner loading-spinner-${size}`;
	const computedBorderColor = borderColor || color;
	const borderColorRgb = hexToRgb(computedBorderColor);

	const spinnerStyle = {
		borderColor: `rgba(${borderColorRgb.r}, ${borderColorRgb.g}, ${borderColorRgb.b}, 0.5)`,
		borderRightColor: color
	};

	return (
		<div className="spinner-container">
			<div className={spinnerClass} style={spinnerStyle}>
				<p className="sr-only">{message}</p>
			</div>
		</div>
	);
}

LoadingSpinner.propTypes = {
	size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
	color: PropTypes.string,
	borderColor: PropTypes.string,
	message: PropTypes.string
};

export default LoadingSpinner;
