import createMetaTag from "@/services/util/seoMeta";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCaptionALLHtmlSave } from "./TableApi";
import { updateTableResultHtml, updateTableSaveTime } from "@/services/store/action/tableAction";
import {getTimeMin} from "@/services/util/DateTimeModule"

const useTablePopup = (html,initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const [triggerElement, setTriggerElement] = useState(null);	
	const resData =  useSelector((state) => state.table.tableData?.data?.resData ||[]);
	const tranData =  useSelector((state) => state.table.tableData?.data?.transformedData||[]);
	const dispatch = useDispatch();
	
	
	const open = (e) => {
		if (e && e.currentTarget) {
			setTriggerElement(e.currentTarget);
		}
		setIsOpen(true);
	};


	const close = async () => {
		const data = {
			tiId:resData.tiId,
			html : html,
			trandata:tranData
		}
		await TableCaptionALLHtmlSave(data);
		dispatch(updateTableResultHtml(html))		
		dispatch(updateTableSaveTime())
		setIsOpen(false);
	};


	useEffect(() => {
		if (!isOpen && triggerElement) {
			triggerElement.focus();
			setTriggerElement(null);
		}
	}, [isOpen, triggerElement]);

	return { isOpen, open, close};
};

export default useTablePopup;
