export const WORKSPACE_STATUS = "WORKSPACE_STATUS";
export const WORKSPACE_LOADING = "WORKSPACE_LOADING";
export const WORKSPACE_LOADED = "WORKSPACE_LOADED";
export const WORKSPACE_IMG2HTML = "WORKSPACE_IMG2HTML";
export const WORKSPACE_SEO = "WORKSPACE_SEO";
export const WORKSPACE_CONTRAST = "WORKSPACE_CONTRAST";
export const WORKSPACE_TABLE = "WORKSPACE_TABLE";

/// 기본 디폴트
export const workSpaceData = (data) => {
	return {
		type: WORKSPACE_STATUS,
		payload: data
	};
};

export const workSpaceLoading = () => ({
	type: WORKSPACE_LOADING
});

export const workSpaceLoaded = () => ({
	type: WORKSPACE_LOADED
});

/// 이미지 HTML
export const setWorkSpaceImg2htmlData = (data) => {
	return {
		type: WORKSPACE_IMG2HTML,
		payload: data
	};
};

/// SEO 메타태크
export const setWorkSpaceSEOData = (data) => {
	return {
		type: WORKSPACE_SEO,
		payload: data
	};
};

/// 이미지 명도대비
export const setWorkSpaceContrastData = (data) => {
	return {
		type: WORKSPACE_CONTRAST,
		payload: data
	};
};

/// 표 접근성
export const setWorkSpaceTableData = (data) => {
	return {
		type: WORKSPACE_TABLE,
		payload: data
	};
};

