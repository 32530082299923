import { Link } from "react-router-dom";
import ArticleContainer from "@/components/community/ArticleContainer"; // ArticleContainer 임포트
import PopularResources from "@/components/community/PopularResources";
import PromotionBanner from "@/components/promotion/PromotionBanner";

export default function InfoPannel() {
	return (
		<div className="info-pannel">
			<div className="col promotion">
				<PromotionBanner type="card-sm" />
			</div>
			<div className="col community">
				<h3 className="title">앨리 커뮤니티</h3>
				<ArticleContainer
					// filterTag="앨리후기" // 필터 조건
					filterTool="" // 필터 조건 초기 값
					limit={3} // 불러올 건수 설정
					showInfo={false} // 정보 영역 표시 여부
				/>
				<Link to="/community" className="more">
					더보기
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.33008 2.34326L10.9869 8.00012L5.33008 13.657"
							stroke="#4B5563"
							strokeWidth="1.3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
			</div>

			<div className="col popular">
				<h3 className="title">지금 막 올라온 리소스</h3>
				<PopularResources size="md" limit={2} />
				<Link to="/resource" className="more">
					더보기
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.33008 2.34326L10.9869 8.00012L5.33008 13.657"
							stroke="#4B5563"
							strokeWidth="1.3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Link>
			</div>
		</div>
	);
}
