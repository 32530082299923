
export const RESET_STATE = "RESET_STATE";

 const resetState = () => {
	return {
		type: RESET_STATE
	};
};

export default resetState;
