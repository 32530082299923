import { getCookie } from "@/services/cookies/UserCookies";
import axios from "axios";
import { TRASHCAN_THROW } from "../url/URL";
import api from "@/services/util/Interceptor";
import { navigateTo } from "@/services/util/Navigate";

export const TrashingIt = async (workId) => {
	try {
		const response = await api.post(TRASHCAN_THROW, {
			workspaceIds: [workId]
		});
		return response;
	} catch (error) {
		return "Fail";
	}
};

export const TrashingListIt = async (workId) => {
	try {
		const response = await api.post(TRASHCAN_THROW, {
			workspaceIds: workId
		});
		return true;
	} catch (error) {
		navigateTo("/error");
	}
};
