import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LayoutHome from "@/components/layouts/LayoutHome";
import LayoutFull from "@/components/layouts/LayoutFull";
import LayoutDefault from "@/components/layouts/LayoutDefault";
import LayoutDetails from "@/components/layouts/LayoutDetails";
import LayoutCommunity from "@/components/layouts/LayoutCommunity";
import mockData from "@/mock/mockData";
import usePopup from "@/hooks/usePopup";
import PopupLogin from "@/pages/popup/PopupLogin";
import PopupLayout from "@/components/popup/PopupLayout";
import TallyIframe from "@/components/plugins/TallyIframe";
import EventCodeAlert from "@/pages/popup/EventCodeAlert"; // 분리된 컴포넌트 임포트

const defaultMeta = {
	title: mockData.title,
	description: mockData.description,
	keywords: mockData.keywords,
	ogTitle: mockData.ogTitle,
	ogDescription: mockData.ogDescription,
	ogUrl: mockData.ogUrl,
	ogImage: mockData.ogImage,
	supportDocRoot: mockData.supportDocRoot
};

const LayoutWrapper = ({ layout, children, meta = {} }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [redirectPath, setRedirectPath] = useState(""); // 리다이렉트 상태 관리
	const popupLoginCommon = usePopup();
	const EventCodePopup = usePopup();

	const { isOpen: isSurveyPopup, open: openSurveyPopup, close: closeSurveyPopup } = usePopup();

	// 인증 상태 가져오기
	const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
	const userData = useSelector((state) => state.user?.userData);
	const userEmail = userData?.data?.email || "";
	const userName = userData?.data?.name || "";
	const userTel = userData?.data?.phone || "";

	useEffect(() => {
		// 설문조사 팝업을 열기 위한 로직
		if (location.search.includes("popup=survey") && !isSurveyPopup) {
			openSurveyPopup(); // 인증 체크 없이 바로 팝업 오픈
		}
	}, [location.search, openSurveyPopup, isSurveyPopup]);

	// useEffect(() => {
	// 	// 설문조사 팝업을 열기 위한 로직
	// 	if (location.search.includes("popup=survey") && !isSurveyPopup) {
	// 		if (isAuthenticated) {
	// 			openSurveyPopup(); // 로그인된 경우 팝업 오픈
	// 		} else {
	// 			// 로그인되지 않은 경우, 로그인 페이지로 리다이렉트
	// 			const redirectTo = `${location.pathname}${location.search}`;
	// 			if (!redirectPath) {
	// 				setRedirectPath(redirectTo); // 중복 리다이렉트 방지
	// 				navigate(`/login?redirect=${encodeURIComponent(redirectTo)}`);
	// 			}
	// 		}
	// 	}
	// }, [isAuthenticated, location.search, openSurveyPopup, isSurveyPopup, navigate, redirectPath]);

	useEffect(() => {
		if (meta.excludeMeta) return;
		const defaultTitle = defaultMeta.title;
		const title = meta?.title ? `${meta.title} | ${defaultTitle}` : defaultTitle;
		document.title = title;
	}, [meta?.title, meta.excludeMeta]);

	useEffect(() => {
		document.body.classList.remove("body-full", "body-default", "body-details", "body-community");
		if (layout) {
			document.body.classList.add(`body-${layout}`);
		}
	}, [layout]);

	// Event Code 팝업을 위한 로직
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const sendParam = searchParams.get("send");
		const codeParam = searchParams.get("code");

		if (sendParam === "complete" && codeParam) {
			EventCodePopup.open();
		}
	}, [location.search, EventCodePopup]);

	const openLoginPopup = (path) => {
		setRedirectPath(path);
		popupLoginCommon.open();
	};

	const closeLoginPopup = () => {
		setRedirectPath("");
		popupLoginCommon.close();
	};

	const effectiveMeta = { ...defaultMeta, ...meta };

	const handleCloseSurveyPopup = () => {
		closeSurveyPopup();
		const urlWithoutQuery = location.pathname;
		navigate(urlWithoutQuery, { replace: true });
	};

	const getTallyCodeFromUrl = () => {
		const params = new URLSearchParams(location.search);
		return params.get("tally");
	};

	const getPopupSizeFromUrl = () => {
		const params = new URLSearchParams(location.search);
		return params.get("size") || "sm";
	};

	const getTitleFromUrl = () => {
		const params = new URLSearchParams(location.search);
		return params.get("title") || "앨리 설문조사";
	};

	const userDataForTally = isAuthenticated
		? {
				userEmailId: userEmail,
				userName: userName,
				userTel: userTel,
				currentTitle: document.title,
				currentUrl: window.location.href
		  }
		: {};

	const handleCloseCompletePopup = () => {
		navigate(location.pathname);
		EventCodePopup.close();
	};

	const codeParam = new URLSearchParams(location.search).get("code");

	return (
		<>
			{!meta.excludeMeta && (
				<Helmet>
					<title>{meta?.title ? `${meta.title} | ${defaultMeta.title}` : defaultMeta.title}</title>
					<meta name="description" content={effectiveMeta.description} />
					<meta name="keywords" content={effectiveMeta.keywords} />
					<meta property="og:title" content={effectiveMeta.ogTitle} />
					<meta property="og:description" content={effectiveMeta.ogDescription} />
					<meta property="og:url" content={effectiveMeta.ogUrl} />
					<meta property="og:image" content={effectiveMeta.ogImage} />
				</Helmet>
			)}
			{layout === "home" && (
				<LayoutHome isAuthenticated={isAuthenticated} openLoginPopup={openLoginPopup}>
					{children}
				</LayoutHome>
			)}
			{layout === "full" && (
				<LayoutFull isAuthenticated={isAuthenticated} openLoginPopup={openLoginPopup}>
					{children}
				</LayoutFull>
			)}
			{layout === "default" && <LayoutDefault>{children}</LayoutDefault>}
			{layout === "details" && <LayoutDetails>{children}</LayoutDetails>}
			{layout === "community" && <LayoutCommunity>{children}</LayoutCommunity>}
			{!layout && <>{children}</>}

			{/* 설문조사 팝업 */}
			<PopupLayout
				className="send-survey-popup"
				isOpen={isSurveyPopup}
				closePopup={handleCloseSurveyPopup}
				size={getPopupSizeFromUrl()}
				title={getTitleFromUrl()}
				footerContent={null}
			>
				<TallyIframe
					className="send-survey"
					dataTallyOpen={getTallyCodeFromUrl()}
					frameTitle="설문조사"
					userData={userDataForTally} // userData 전달
				/>
			</PopupLayout>

			<EventCodeAlert
				isOpen={EventCodePopup.isOpen}
				closePopup={handleCloseCompletePopup}
				code={codeParam} // code를 프롭스로 전달
			/>

			<PopupLogin isOpen={popupLoginCommon.isOpen} closePopup={closeLoginPopup} to={redirectPath} />
		</>
	);
};

export default LayoutWrapper;
